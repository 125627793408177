import React, { useState, FC, useEffect, useRef } from "react";
import {
  chakra,
  useBreakpointValue,
  useMultiStyleConfig,
  Text,
  Flex,
} from "@chakra-ui/react";

export type Props = {
  refBox?: any;
  img?: any;
  title?: any;
  subtitle?: any;
  type?: string;
  handleClick?: any;
  selected: boolean;
  height?: any;
  width?: any;
};
const ClusterBox: FC<Props> = (
  {
    refBox,
    img,
    title,
    subtitle,
    type,
    handleClick,
    selected,
    height,
    width,
  }: Props,
  ...props
) => {
  const [isClicked, setIsClicked] = useState(false);
  const ref1 = useRef<any>();
  useEffect(() => {
    if (isClicked) {
      handleClick(type);
    }
  }, [isClicked]);

  const handleSelect = () => {
    setIsClicked(!isClicked);
  };

  return (
    <Flex
      onClick={handleSelect}
      ref={refBox}
      bgImage={img}
      overflow="hidden"
      backgroundPosition="center"
      backgroundSize="cover"
      backgroundRepeat="no-repeat"
      h={height}
      w={width}
      cursor="pointer"
      fontFamily="Arial"
      color="white"
      textTransform="uppercase"
    >
      <Flex
        w="100%"
        h="100%"
        flexDir="column"
        justifyContent="center"
        px="10px"
        bgColor={selected ? "rgba(0, 0, 0, 0.7)" : "transparent"}
        _hover={{
          bgColor: "rgba(0,0,0,0.7)",
        }}
        >
        <Text fontSize="18px" fontFamily="Arial Black, Arial-BoldMT, Arial">
          {title}
        </Text>
        <Text fontSize="12px">{subtitle}</Text>
      </Flex>
    </Flex>
  );
};

export default ClusterBox;
