import React, { FC, useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";

import Constants from "../../helpers/constants";
import {
  getSubscription,
  sendMessage,
  removeSubscription,
} from "../../helpers/lsHelper";

import { DailyParticipant } from "@daily-co/daily-js";

import { localizedDateFromString } from "../../helpers/utils";

import VideoPlayerOld from "../../components/VideoPlayerOld";
import PlenaryThankYou from "../../components/plenary/PlenaryThankYou";
import axiosHelper from "../../helpers/axiosHelper";
import { OneToOneTile } from "../../components/onetoone/OneToOneTile";
import OneToOneBlock from "../../components/OneToOneBlock";

import bgImage from "../../assets/tutorial/shutterstock_1117734563 (2)/shutterstock_1117734563@2x.png";
import qeaIcon from "../../img/icon-qea.svg";
import liveIcon from "../../img/icon-live.svg";
import qeaIconWhite from "../../img/icon-qea-white.svg";
import liveIconWhite from "../../img/icon-live-white.svg";
import closeIcon from "../../img/icon-close.png";

import {
  chakra,
  Container,
  Text,
  useDisclosure,
  Flex,
  useBreakpointValue,
} from "@chakra-ui/react";

export type LayoutHeaderProps = {
  usertype: string;
  eventStatus: EventStatus;
};

type MessageData = {
  qeaMessage: string;
};

const PlenaryContentLiveQA: FC<LayoutHeaderProps> = (
  { usertype, eventStatus }: LayoutHeaderProps,

  ...props
) => {
  const [hlsSource, setHlsSource] = useState(eventStatus.videoUrl);
  const [bottomButtons, setBottomButtons] = useState(eventStatus.extra1);
  // const [webRtcSource, setWebRtcSource] = useState(
  //   "https://raisehand.glgconference.com/?view=2&scene&room=raisehandprg"
  // );

  const [userProfile, setUserProfile] = useState<UserProfile>(
    axiosHelper.getUserProfile()!
  );

  const { handleSubmit, register, errors, watch } = useForm<FormData>();

  const [showThankYou, setShowThankYou] = useState(false);

  const { t } = useTranslation();
  const isMobile = useBreakpointValue({ base: true, lg: false });

  const [showVideo, setShowVideo] = useState(true);
  const [showCall, setShowCall] = useState(false);

  const [modalVisible, setModalVisible] = useState<boolean>(false);

  const [oneToOneEnabled, setOneToOneEnabled] = useState<boolean>(true);

  //QEA
  const [queaOpened, setQueaOpened] = useState<boolean>(false);
  const [queaSubmitted, setQueaSubmitted] = useState<boolean>(false);

  const [liveOpened, setLiveOpened] = useState<boolean>(false);

  const [remoteUser, setRemoteUser] = useState<null | DailyParticipant>(null);

  useEffect(() => {
    axiosHelper.logWebAppEvent("PlenaryQA", null, null);
    // parseStatus(axiosHelper.getDefaultStatusForPlenary());
    let lsSubscription = getSubscription(
      "PLENARYQA_" + eventStatus?.vKey,
      (message: string) => {
        if (message==='SHOWTHANKYOU'){
          setShowThankYou(true);
        }
      }
    );
    return () => {
      // console.log('PLENARY CLEANUP');
      removeSubscription(lsSubscription);
    };
  }, []);

  const parseStatus = (statusString: string | null) => {
    // if (statusString !== null) {
    //   axiosHelper.setCachedStatusForPlenary(statusString);
    //   const splitted = statusString.split("|");
    //   console.log(splitted);
    //   if (splitted[0].includes("http")) {
    //     if (splitted[0].length > 8) {
    //       setHlsSource(splitted[0]);
    //     }
    //     if (splitted[1].length > 8) {
    //       setWebRtcSource(splitted[1]);
    //     }
    //     // setOneToOneEnabled(!(splitted[5] === "false"));
    //     // setOneToOneEnabled(!(splitted[5] === 'false'))
    //   }
    // }
  };

  const onSubmit = (values: MessageData) => {
    // console.log('onQUANASubmit sending to LS');
    sendMessage("PRESS/" + values.qeaMessage);
    setQueaSubmitted(true);
    setTimeout(() => setQueaOpened(false), 4000);
  };

  const handleOpening = (container: string) => {
    if (container === "qea") {
      setQueaSubmitted(false);
      setQueaOpened(true);
      setLiveOpened(false);
    } else if (container === "live") {
      setQueaOpened(false);
      setLiveOpened(true);
    } else if (container === "fwll") {
      setQueaOpened(false);
      setLiveOpened(false);
    }
  };

  const handleClosing = () => {
    setQueaOpened(false);
    setLiveOpened(false);
  };

  const onetooneCallback = (user: null | DailyParticipant) => {
    console.log("onetooneCallback user");
    console.log(user);
    if (user != null) {
      setShowCall(true);
      setTimeout(() => {
        setShowVideo(false);
      }, 1000);
    }
    setRemoteUser(user);
  };

  const CloseButton = (props: { theme: string; onClose: () => void }) => {
    const { theme, onClose } = props;
    return (
      <div className={`${theme} container-close-button`} onClick={onClose}>
        <img src={closeIcon} className="icon" alt="Close" />
        <div className="closeLabel">Close</div>
      </div>
    );
  };

  const StartButton = (props: {
    title: string;
    text: string;
    theme: string;
    iconSrc: string;
    handleOpen: (theme: string) => void;
  }) => {
    const { iconSrc, handleOpen, theme, title, text } = props;
    return (
      <div className="start-button" onClick={() => handleOpen(theme)}>
        <div className="box col-4 nopadding icon">
          <img src={iconSrc} className="icon" alt="Q&A" />
        </div>
        <div className="box col-8 nopadding content">
          <div className="title">{title}</div>
          <div className="help">{text}</div>
        </div>
      </div>
    );
  };

  useEffect(() => {
    console.log("USE EFFECT PLENARYCONTENT");
    // console.log(axiosHelper.getEventStatus());

    return () => { };
  }, []);

  useEffect(() => {
    console.log(userProfile.events[0]);
  }, [t("LNG"), userProfile]);

  const onTimeUpdate = (time: number) => {
    console.log("TIME UPDATE " + time);
  };
  const continueChattingPressed = () => {
    setShowThankYou(false);
  }
  const okThankYouPressed = () => {
    setTimeout(() => { window.location.reload(); }, 100);
  }
  const platformCallback = () => {
    setTimeout(() => { window.location.reload(); }, 100);
  }
  return (
    <>
      <chakra.div
        maxW="container.xl"
        w="100%"
        m="0 auto"
        d="flex"
        flexDir="column"
        flexGrow={1}
        justifyContent="center"
        alignItems="stretch"
        bgImage={bgImage}
        style={{
          overflow: "hidden",
          backgroundPosition: "center",
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
        }}
      >
        {showThankYou ? (
          <PlenaryThankYou status={eventStatus} continueChatting={continueChattingPressed} okCallback={okThankYouPressed} platformCallback={platformCallback}/>
        ) : (
          <>
            <Container
              maxW="container.xl"
              className="page-plenary"
              d="flex"
              w="100%"
              flexDir={isMobile ? "column" : "row"}
              justifyContent="center"
              alignContent="stretch"
              alignItems="stretch"
              pt={isMobile ? "0" : "1rem"}
              p={isMobile ? "0" : ""}
            >
              <Flex
                position="relative"
                flexDir="column"
                flexGrow={1}
                alignItems="stretch"
                alignContent="stretch"
                justifyContent="center"
                w={isMobile ? "100%" : "70%"}
                pr={isMobile ? "0" : "1rem"}
                className="data-vjs-player"
              >
                <div className="video-wrapper">
                  {showVideo && <VideoPlayerOld src={hlsSource} />}
                  {showCall && (
                    <div className="remoteVideoCamera data-vjs-player">
                      <OneToOneTile
                        videoTrackState={remoteUser?.tracks.video ?? null}
                        audioTrackState={remoteUser?.tracks.audio ?? null}
                      />
                    </div>
                  )}
                </div>
                {bottomButtons === 'quea' ?
                  <div className="columns-wrapper questions-wrapper">
                    <>
                      {/* QEA QEA QEA QEA QEA QEA QEA QEA QEA QEA QEA QEA QEA QEA QEA QEA QEA QEA QEA QEA QEA QEA QEA QEA */}
                      {/* QEA QEA QEA QEA QEA QEA QEA QEA QEA QEA QEA QEA QEA QEA QEA QEA QEA QEA QEA QEA QEA QEA QEA QEA */}
                      {/* QEA QEA QEA QEA QEA QEA QEA QEA QEA QEA QEA QEA QEA QEA QEA QEA QEA QEA QEA QEA QEA QEA QEA QEA */}
                      {!liveOpened && (
                        <div
                          className={`box qea action-container ${!queaOpened && !liveOpened
                            ? "neutral"
                            : queaOpened
                              ? "opened"
                              : "closed"
                            } `}
                        >
                          {queaOpened ? (
                            <div className="columns-wrapper">
                              <div className="columns-wrapper action-content">
                                <div className="content">
                                  <div className="box col-6 nopadding left">
                                    <div className="columns-wrapper">
                                      <div className="box col-4 icon-container">
                                        <img
                                          src={qeaIcon}
                                          className="icon"
                                          alt="Q&A"
                                        />
                                      </div>
                                      <div className="box col-8 info-container">
                                        <div className="title">Q&A</div>
                                        <div className="help">
                                          Write your question (English only, 500 char max) and
                                          submit it.
                                        </div>
                                      </div>
                                    </div>
                                  </div>

                                  <div className="box col-6">
                                    {queaSubmitted ? (
                                      <div className="question-thankyou">
                                        <p>
                                          Thank you, your question has been
                                          submitted. <br />
                                          If the time allows, speakers will answer
                                          live.
                                        </p>
                                      </div>
                                    ) : (
                                      <form onSubmit={handleSubmit(onSubmit)}>
                                        <textarea
                                          name="qeaMessage"
                                          className="textarea"
                                          cols={30}
                                          rows={10}
                                          placeholder="Your question (500 char. max)"
                                          ref={register}
                                        ></textarea>
                                        <button className="button--submit_small">
                                          Submit
                                        </button>
                                      </form>
                                    )}
                                  </div>
                                </div>
                                <CloseButton
                                  theme="live"
                                  onClose={() => handleClosing()}
                                />
                              </div>
                            </div>
                          ) : (
                            <StartButton
                              handleOpen={() => handleOpening("qea")}
                              title="Q&A"
                              text="Type your question and submit it"
                              theme="qea"
                              iconSrc={qeaIcon}
                            />
                          )}
                        </div>
                      )}
                      {/* LIVE LIVE LIVE LIVE LIVE LIVE LIVE LIVE LIVE LIVE LIVE LIVE LIVE LIVE LIVE LIVE LIVE LIVE LIVE */}
                      {/* LIVE LIVE LIVE LIVE LIVE LIVE LIVE LIVE LIVE LIVE LIVE LIVE LIVE LIVE LIVE LIVE LIVE LIVE LIVE */}
                      {/* LIVE LIVE LIVE LIVE LIVE LIVE LIVE LIVE LIVE LIVE LIVE LIVE LIVE LIVE LIVE LIVE LIVE LIVE LIVE */}
                      {!queaOpened && (
                        <div
                          className={`box live action-container ${!queaOpened && !liveOpened
                            ? "neutral"
                            : liveOpened
                              ? "opened"
                              : "closed"
                            } `}
                        >
                          {liveOpened ? (
                            <div className="columns-wrapper action-content">
                              <OneToOneBlock
                                onUser={(user) => {
                                  onetooneCallback(user);
                                }}
                                switchToHLS={() => {
                                  setShowCall(false);
                                  setShowVideo(true);
                                }}
                                onClose={() => handleClosing()}
                              />
                              <CloseButton
                                theme="qea"
                                onClose={() => handleClosing()}
                              />
                            </div>
                          ) : oneToOneEnabled ? (
                            <StartButton
                              handleOpen={() => handleOpening("live")}
                              title="Live Question"
                              text="Join live via webcam to ask your question"
                              theme="live"
                              iconSrc={liveIcon}
                            />
                          ) : (
                            <p className="liveDisabled">
                              In this session,
                              <br />
                              the Live Questions are not availaible
                            </p>
                          )}
                        </div>
                      )}
                    </>
                  </div>
                  : null}
              </Flex>
            </Container>
          </>
        )}
      </chakra.div>
    </>
  );
};

export default PlenaryContentLiveQA;
