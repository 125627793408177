import React, { FC } from "react";
import {
  chakra,
  Text,
  Image,
  Flex,
  useMultiStyleConfig,
  useDisclosure,
} from "@chakra-ui/react";

type Props = {
  image?: any;
  title?: string;
  label?: string;
};

const RegisterCard: FC<Props> = ({ image, title, label }: Props, ...props) => {
  const styles = useMultiStyleConfig("Hall", props);

  return (
    <Flex sx={styles.registerCardWrapper}>
      <Image src={image} w="100%" h="90px" />
      <Flex sx={styles.registerCardContainer}>
        <Text sx={styles.registerCardTitle}>{title}</Text>
        <Text sx={styles.registerCardLabel}>{label}</Text>
      </Flex>
    </Flex>
  );
};

export default RegisterCard;
