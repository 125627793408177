const PrizePage = {
  parts: [
    "prizesPageSectionTitle",
    "prizesPageSectionSubtitle",
    "prizesPageItemImage",
    "prizesPageCardTitle",
    "prizesPageCardSubtitle",
    "prizesPageCardLabel",
  ],

  baseStyle: ({ colorMode }: any) => ({
    prizesPageSectionTitle: {
      fontSize: { base: "18px", lg: "25px" },
      fontWeight: "normal",
      fontFamily: "Arial Black, Arial-BoldMT, Arial",
      color: "white",
      textTransform: "uppercase",
      opacity: "1",
    },
    prizesPageSectionSubtitle: {
      fontSize: { base: "18px", lg: "18px" },
      fontWeight: "normal",
      fontFamily: "Arial Black, Arial-BoldMT, Arial",
      color: "red",
      textTransform: "uppercase",
      opacity: "1",
    },
    prizesPageItemImage: {
      size: "cover",
      bgRepeat: "no-repeat",
      h: { base: "auto", lg: "150px" },
      w: { base: "100%", lg: "344px" },
    },
    prizesPageCardTitle: {
      fontFamily: "Arial",
      fontSize: { base: "16px", lg: "16px" },
      fontWeight: "bold",
      letterSpacing: "0px",
      color: "red.500",
      textTransform: "uppercase",
      opacity: "1",
      d: "flex",
      mt: "15px",
    },
    prizesPageCardSubtitle: {
      fontFamily: "Arial",
      fontSize: { base: "12px", lg: "12px" },
      fontStyle: "italic",
      fontWeight: "normal",
      letterSpacing: "0px",
      color: "white",
    },
    prizesPageCardLabel: {
      fontFamily: "Arial",
      fontSize: { base: "14px", lg: "14px" },
      fontWeight: "normal",
      letterSpacing: "0px",
      color: "white",
      opacity: "1",
      mt: "15px",
    },
  }),
};

export default PrizePage;
