import React, { FC } from "react";
import Constants from "../../helpers/constants";
import { isInThePast } from "../../helpers/utils";

import {
  chakra,
  useBreakpointValue,
  useMultiStyleConfig,
  Text,
  Image,
  useDisclosure,
} from "@chakra-ui/react";

export type Props = {
  video: LibraryContent;
  active: boolean;
  onClickEvent: (video: any, toggled: boolean) => void;
};

const VideoCard: FC<Props> = (
  { video, onClickEvent, active }: Props,
  ...props
) => {
  const styles = useMultiStyleConfig("Hall", props);
  const isMobile = useBreakpointValue({ base: true, lg: false });

  const selectVideo = (active: boolean) => {
    if (active) {
      return "active-video-box";
    } else {
      return "video-box";
    }
  };

  return (
    <chakra.div
      p={{ base: "3px" }}
      w={{ lg: "216px" }}
      h={{ lg: "124px" }}
    >
      {isInThePast(new Date(Date.parse(video.availableFrom!))) ? (
        <Image
          src={
            Constants.VIDEO_THUMB_PATH + "active/" + video.thumbUrlPath + ".jpg"
          }
          sx={styles.prizesCardImg}
          onClick={(event) => {
            onClickEvent(video, !active);
          }}
          className={selectVideo(active)}
        />
      ) : (
        <Image
          src={
            Constants.VIDEO_THUMB_PATH +
            "inactive/" +
            video.thumbUrlPath +
            ".jpg"
          }
          sx={styles.prizesCardImg}
          className={"video-box"}
        />
      )}
    </chakra.div>
  );
};

export default VideoCard;
