import React, { useState, useRef } from "react";
import PrizeItem from "./PrizeItem";
import { useTranslation } from "react-i18next";
import { useHistory, Link } from "react-router-dom";

import {
  chakra,
  useBreakpointValue,
  useMultiStyleConfig,
  Container,
  Text,
  Image,
  useDisclosure,
  Flex,
  Divider,
  Box,
} from "@chakra-ui/react";

import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import axiosHelper from "./../../helpers/axiosHelper";
import bgImage from "./../../assets/prizes/prizes-page-bg.jpg";
import prize1Image from "./../../assets/prizes/Level-1-Gift-1_Discount.png";
import prize2Image from "./../../assets/prizes/Level-1-Gift-2_MFGuidewithRTKcorrectionforfree.png";
import prize3Image from "./../../assets/prizes/Level-1-Gift-3_WinAnMF1520.png";
import prize4Image from "./../../assets/prizes/Level-2-Gift-1_MFSummerPack.png";
import prize5Image from "./../../assets/prizes/Level-2-Gift-2_MFWithFarmersEveryday.png";
import prize6Image from "./../../assets/prizes/Level-2-Gift-3_MFSuperHeroServices.png";
import prize7Image from "./../../assets/prizes/Level-3-Gift-1_MFConnectivityPack.png";
import prize8Image from "./../../assets/prizes/Level-3-Gift-2_MFMerchandisingE-Voucher.png";
import prize9Image from "./../../assets/prizes/Level-3-Gift-3_MFGameAddictPack.png";

import { ChevronLeftIcon, ChevronRightIcon } from "@chakra-ui/icons";

const PrizesContent = ({ ...props }) => {
  const { t } = useTranslation();
  const history = useHistory();
  const [userProfile, setUserProfile] = useState<UserProfile>(
    axiosHelper.getUserProfile()!
  );
  const styles = useMultiStyleConfig("PrizePage", props);

  const isMobile = useBreakpointValue({ base: true, lg: false });
  const customSlider = useRef<any>();

  return (
    <Container
      maxW="container.xl"
      bgImage={bgImage}
      d="flex"
      flexDir="column"
      flexDirection="column"
      style={{
        overflow: "hidden",
        backgroundPosition: "center",
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
      }}
      alignItems="center"
    >
      <Flex
        flexDir={{ base: "column", lg: "column" }}
        justifyContent="space-between"
        w="100%"
        mt="20px"
      >
        {/* <Text color="white"> {t('prizespage.subtitle')}</Text> */}
        <Text color="white" mb="50px">
          {t("tutorial.game.text")}
        </Text>
      </Flex>
      <chakra.div w="100%">
        <Text sx={styles.prizesPageSectionTitle}>{t("prizes.ranking")}</Text>
        <Text sx={styles.prizesPageSectionSubtitle}>1 - 1000</Text>
        <Flex
          flexDir={{ base: "column", lg: "column" }}
          justifyContent="space-between"
          w="100%"
          mt="20px"
        >
          <Slider
            ref={(slider: any) => (customSlider.current = slider)}
            dots={true}
            infinite={true}
            speed={500}
            slidesToShow={isMobile ? 1 : 3}
            slidesToScroll={isMobile ? 1 : 3}
            accessibility={true}
            autoplay
            autoplaySpeed={5000}
            arrows={false}
            centerPadding={isMobile ? "60px" : "0px"}
          >
            <PrizeItem
              title={t("prizescard_1.title")}
              subtitle={t("prizescard_1.subtitle")}
              label={t("prizescard_1.description")}
              promotext={t("prizescard_1.promotext")}
              image={prize1Image}
            />
            <PrizeItem
              title={t("prizescard_2.title")}
              subtitle={t("prizescard_2.subtitle")}
              label={t("prizescard_2.description")}
              promotext={t("prizescard_2.promotext")}
              image={prize2Image}
            />
            <PrizeItem
              title={t("prizescard_3.title")}
              subtitle={t("prizescard_3.subtitle")}
              label={t("prizescard_3.description")}
              promotext={t("prizescard_3.promotext")}
              image={prize3Image}
            />
          </Slider>
        </Flex>
      </chakra.div>
      <Divider
        orientation="horizontal"
        borderColor="#C8C8C8"
        my="20px"
        mt="50px"
        mb="50px"
        w="100%"
      />
      <chakra.div w="100%">
        <Text sx={styles.prizesPageSectionTitle}>{t("prizes.ranking")}</Text>
        <Text sx={styles.prizesPageSectionSubtitle}>1001 - 2000</Text>
        <Flex
          flexDir={{ base: "column", lg: "column" }}
          justifyContent="space-between"
          w="100%"
          mt="20px"
        >
          <Slider
            ref={(slider: any) => (customSlider.current = slider)}
            dots={true}
            infinite={true}
            speed={500}
            slidesToShow={isMobile ? 1 : 3}
            slidesToScroll={isMobile ? 1 : 3}
            accessibility={true}
            autoplay
            autoplaySpeed={5000}
            arrows={false}
            centerPadding={isMobile ? "60px" : "0px"}
          >
            <PrizeItem
              title={t("prizescard_4.title")}
              subtitle={t("prizescard_4.subtitle")}
              label={t("prizescard_4.description")}
              promotext={t("prizescard_4.promotext")}
              image={prize4Image}
            />
            <PrizeItem
              title={t("prizescard_5.title")}
              subtitle={t("prizescard_5.subtitle")}
              label={t("prizescard_5.description")}
              promotext={t("prizescard_5.promotext")}
              image={prize5Image}
            />
            <PrizeItem
              title={t("prizescard_6.title")}
              subtitle={t("prizescard_6.subtitle")}
              label={t("prizescard_6.description")}
              promotext={t("prizescard_6.promotext")}
              image={prize6Image}
            />
          </Slider>
        </Flex>
      </chakra.div>
      <Divider
        orientation="horizontal"
        borderColor="#C8C8C8"
        my="20px"
        mt="50px"
        mb="50px"
        w="100%"
      />
      <chakra.div w="100%">
        <Text sx={styles.prizesPageSectionTitle}>{t("prizes.ranking")}</Text>
        <Text sx={styles.prizesPageSectionSubtitle}>2001 +</Text>

        <Flex
          flexDir={{ base: "column", lg: "column" }}
          justifyContent="space-between"
          w="100%"
          mt="20px"
          mb="20px"
        >
          <Slider
            ref={(slider: any) => (customSlider.current = slider)}
            dots={true}
            infinite={true}
            speed={500}
            slidesToShow={isMobile ? 1 : 3}
            slidesToScroll={isMobile ? 1 : 3}
            accessibility={true}
            autoplay
            autoplaySpeed={5000}
            arrows={false}
            centerPadding={isMobile ? "60px" : "0px"}
          >
            <PrizeItem
              title={t("prizescard_7.title")}
              subtitle={t("prizescard_7.subtitle")}
              label={t("prizescard_7.description")}
              promotext={t("prizescard_7.promotext")}
              image={prize7Image}
            />
            <PrizeItem
              title={t("prizescard_8.title")}
              subtitle={t("prizescard_8.subtitle")}
              label={t("prizescard_8.description")}
              promotext={t("prizescard_8.promotext")}
              image={prize8Image}
            />
            <PrizeItem
              title={t("prizescard_9.title")}
              subtitle={t("prizescard_9.subtitle")}
              label={t("prizescard_9.description")}
              promotext={t("prizescard_9.promotext")}
              image={prize9Image}
            />
          </Slider>
        </Flex>
      </chakra.div>
      <Flex
        flexDir={{ base: "column", lg: "row" }}
        justifyContent="space-between"
        w="100%"
        mt={isMobile ? "100px" : "30px"}
      >
        <Text
          color="white"
          mb={isMobile ? "20px" : "50px"}
          fontStyle="italic"
          fontSize="sm"
        >
          {t(`tutorial.rap.titledetail`)}
        </Text>
        <Link to="/termsandconditions">
          <Text color="white" fontSize="sm" mb="50px">
            {t(`termsandconditions`)}
          </Text>
        </Link>
      </Flex>
    </Container>
  );
};

export default PrizesContent;
