import React, { FC, useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import Leaderboard from "./Leaderboard";
import {
  chakra,
  useBreakpointValue,
  useMultiStyleConfig,
  Container,
  Text,
  Flex,
  Divider,
  HStack,
  Image,
  Wrap,
} from "@chakra-ui/react";

import bgImage from "./../../assets/home/counter/shutterstock_1117734563/shutterstock_1117734563@2x.png";
import img1 from "./../../assets/hall/shutterstock_524403220 (1).svg";
import img2 from "./../../assets/hall/shutterstock_524403220 (2).svg";
import Clock from "../home/counter/Clock";

//export type Props = {};
interface Props {
  endDateString: string;
  usertype: string;
  ranking: number;
}

const CounterHall: FC<Props> = (
  { endDateString, usertype, ranking }: Props,
  ...props
) => {
  const endDate = new Date(endDateString);
  const [showLeaderBoard, setShowLeaderBoard] = useState(false);
  //   usertype === "R" || usertype === "S" || usertype === "I"
  // );
  const styles = useMultiStyleConfig("Hall", props);
  const { t } = useTranslation();

  const isMobile = useBreakpointValue({ base: true, lg: false });
  // const isTablet = useBreakpointValue({ base: true, md: false });

  const [time, setNewTime] = useState({
    days: 0,
    hours: 0,
    mins: 0,
    secs: 0,
  });
  useEffect(() => {
    setShowLeaderBoard(
      usertype === "R" || usertype === "S" || usertype === "I"
    );
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      const date = calculateCountDown(endDate);
      setNewTime(date);
    }, 1000);
    return function cleanup() {
      clearInterval(interval);
    };
  }, [endDate]);

  const calculateCountDown = (endDate: Date) => {
    let startDate = new Date();
    let diff = (endDate.getTime() - startDate.getTime()) / 1000;

    const timeLeft = {
      days: 0,
      hours: 0,
      mins: 0,
      secs: 0,
    };

    if (diff <= 0) return timeLeft;

    if (diff >= 86400) {
      // 24 * 60 * 60
      timeLeft.days = Math.floor(diff / 86400);
      diff -= timeLeft.days * 86400;
    }
    if (diff >= 3600) {
      // 60 * 60
      timeLeft.hours = Math.floor(diff / 3600);
      diff -= timeLeft.hours * 3600;
    }
    if (diff >= 60) {
      timeLeft.mins = Math.floor(diff / 60);
      diff -= timeLeft.mins * 60;
    }
    timeLeft.secs = Math.floor(diff);

    return timeLeft;
  };

  return (
    <Container
      maxW="container.xl"
      bgImage={bgImage}
      overflow="hidden"
      backgroundPosition="center"
      backgroundSize="cover"
      backgroundRepeat="no-repeat"
      flexDir={isMobile ? "column" : "row"}
      d="flex"
      pt={{ lg: "56px" }}
      pb={{ lg: "20px" }}
      justifyContent="space-between"
    >
      <Flex flexDir="column" w={isMobile ? "full" : (showLeaderBoard ? "60%" : "full")}>
        <Flex
          alignItems="center"
          justify={showLeaderBoard ? "" : "center"}
          mb="60px"
          w="100%"
          mt={isMobile ? "30px" : "0"}
        >
          <Text as="h2" textStyle="h2" textAlign="center" w="100%">
            {t("counter.title")}
          </Text>
        </Flex>

        <Flex
          alignItems="center"
          justifyContent="space-around"
          justify={showLeaderBoard ? "" : "center"}
          margin={showLeaderBoard ? "0" : { base: "0", lg: "0 auto" }}
          mb="30px"
          className={showLeaderBoard ? "counterWR" : "counterFull"}
          w={showLeaderBoard ? "100%" : { base: "100%", lg: "70%" }}
        >
          <Clock
            digit={time.days}
            label={t("countdown.days")}
            maxNum={60}
            id={"hdays"}
            width="150px"
          />
          <Clock
            digit={time.hours}
            label={t("countdown.hours")}
            maxNum={24}
            id={"hhours"}
            width="150px"
          />
          <Clock
            digit={time.mins}
            label={t("countdown.minutes")}
            maxNum={60}
            id={"hmins"}
            width="150px"
          />
          <Clock
            digit={time.secs}
            label={t("countdown.seconds")}
            maxNum={60}
            id={"hsecs"}
            width="150px"
          />
        </Flex>
      </Flex>
      {showLeaderBoard ? <Leaderboard ranking={ranking} /> : null}
    </Container>
  );
};

export default CounterHall;
