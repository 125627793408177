import { styled } from "@stitches/react";
import { DailyTrackState } from "@daily-co/daily-js";
import React, { useEffect, useMemo, useRef } from "react";
import { getTrackUnavailableMessage } from "./getTrackUnavailableMessage";
import videoPlaceholder from "../../img/video-placeholder.png";

export const OneToOneTile: React.FC<{
  videoTrackState: null | DailyTrackState;
  audioTrackState: null | DailyTrackState;
}> = ({ videoTrackState, audioTrackState }) => {
  const videoEl = useRef<null | HTMLVideoElement>(null);
  const audioEl = useRef<null | HTMLVideoElement>(null);

  const videoUnavailableMessage = useMemo(() => {
    return getTrackUnavailableMessage("video", videoTrackState);
  }, [videoTrackState]);

  const audioUnavailableMessage = useMemo(() => {
    return getTrackUnavailableMessage("audio", audioTrackState);
  }, [audioTrackState]);

  // console.log({ videoTrackState })

  const videoTrack = useMemo(() => {
    return videoTrackState && videoTrackState.state === "playable"
      ? videoTrackState.track
      : null;
  }, [videoTrackState]);

  const audioTrack = useMemo(() => {
    return audioTrackState && audioTrackState.state === "playable"
      ? audioTrackState.track
      : null;
  }, [audioTrackState]);

  function getOverlayComponent() {
    // Show overlay when video is unavailable. Audio may be unavailable too.
    return (
      videoUnavailableMessage && (
        <div className="overlay">
          <img src={videoPlaceholder} className="video-placeholder" alt="" />
          <div className="video-messages">
            {videoUnavailableMessage}
            {audioUnavailableMessage && (
              <>
                <br />
                {audioUnavailableMessage}
              </>
            )}
          </div>
        </div>
      )
    );
  }

  /**
   * When video track changes, update video srcObject
   */
  useEffect(() => {
    videoEl.current &&
      videoTrack &&
      (videoEl.current.srcObject = new MediaStream([videoTrack]));
  }, [videoTrack]);

  /**
   * When audio track changes, update audio srcObject
   */
  useEffect(() => {
    audioEl.current &&
      audioTrack &&
      (audioEl.current.srcObject = new MediaStream([audioTrack]));
  }, [audioTrack]);

  function getVideoComponent() {
    return videoTrack && <video autoPlay muted playsInline ref={videoEl} />;
  }

  function getAudioComponent() {
    return audioTrack && <audio autoPlay playsInline ref={audioEl} />;
  }

  return (
    <>
      {getOverlayComponent()}
      {getVideoComponent()}
      {getAudioComponent()}
      {!videoUnavailableMessage && !videoTrack ? (
        <>
          <div className="overlay">
            <img src={videoPlaceholder} className="video-placeholder" alt="" />
          </div>
        </>
      ) : (
        <></>
      )}
    </>
  );
};
