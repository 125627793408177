import React, { FC, useState } from "react";
import { useTranslation } from "react-i18next";
import theGameIcon from "../../assets/plenary/thegame-icon.svg";

import {
  Container,
  Flex,
  Text,
  Image,
  useBreakpointValue,
} from "@chakra-ui/react";

const PlenaryTutorial = () => {
  const { t } = useTranslation();
  const isMobile = useBreakpointValue({ base: true, lg: false });

  return (
    <>
      <Flex
        maxW="container.xl"
        d="flex"
        flexFlow="row nowrap"
        justifyContent="center"
        alignItems="stretch"
        m={isMobile ? "0" : "1rem 2rem 2rem 2rem"}
        bgColor="rgba(255, 255, 255, 0.1)"
        boxShadow="0px 3px 6px #00000029"
      >
        <Flex
          d="flex"
          flexFlow="column nowrap"
          justifyContent="center"
          alignItems="stretch"
        >
          <Flex
            d="flex"
            width={isMobile ? "100%" : "50%"}
            flexDir="column"
            p={isMobile?"1rem":"2rem"}
            pb="0"
          >
            <Flex d="flex" flexDir="row" p="0" alignItems="stretch">
              <Flex
                fontSize={isMobile?"22px":"40px"}
                lineHeight="40px"
                color="white"
                fontFamily="Arial Black, Arial-BoldMT, Arial"
              >
                {t("plenary.thegame.title")}
              </Flex>
              <Flex>
                <Image
                  src={theGameIcon}
                  alt="The Game"
                  w="45px"
                  h="45px"
                  ml="1.5rem"
                />
              </Flex>
            </Flex>
          </Flex>
          <Flex
            d="flex"
            flexFlow={isMobile ? "column nowrap" : "row nowrap"}
            justifyContent="center"
            alignItems="stretch"
          >
            <Flex
              d="flex"
              width={isMobile ? "100%" : "50%"}
              flexDir="column"
              p={isMobile?"1rem":"1rem 2rem 2rem 2rem"}
              fontSize={isMobile?".8rem":"1rem"}
            >
              <Text color="white">
                {t("plenary.thegame.line1")}
                <br />
                {t("plenary.thegame.line2")} {t("plenary.thegame.line3")}
                <br />
                {t("plenary.thegame.line4")}
                <br />
                {t("plenary.thegame.line5")} {t("plenary.thegame.line6")}
              </Text>
            </Flex>

            <Flex width={isMobile ? "100%" : "50%"} p="2rem">
              <Image
                objectFit="contain"
                objectPosition="center"
                bgRepeat="no-repeat"
                overflow="hidden"
                alignItems="center"
                justifyContent="center"
                src={"/plenary/tutorial-" + t("LNG") + ".svg"}
                w={isMobile ? "100%" : "100%"}
                zIndex="5"
              />
            </Flex>
          </Flex>
        </Flex>
      </Flex>
    </>
  );
};

export default PlenaryTutorial;
