import React, { useEffect, useState } from "react";

import axiosHelper from "../helpers/axiosHelper";

import MainLayoutPlenary from "../layouts/MainLayoutPlenary";
import PlenaryRedirect from "../components/plenary/PlenaryRedirect";

const RedirectThanks = () => {
    const [eventStatus, setEventStatus] = useState<EventStatus>();

    const [userProfile, setUserProfile] = useState<UserProfile>(axiosHelper.getUserProfile()!);



    useEffect(() => {
        const fetchEventStatus = async () => {
            try {
                const result = await axiosHelper.getEventStatus();
                if (result != null) {
                    console.log("###PLENARY### GOT THE EVENT STATUS");
                    console.log(result);
                    setEventStatus(result)
                }
            } catch {
                console.log("###PLENARY### COULD NOT FETCH EVENT STATUS");
            }
        };

        fetchEventStatus();

    }, [])

    const reloadUserProfile = () => {
    };



    return (
        <MainLayoutPlenary reloadUserProfile={reloadUserProfile}>
            {eventStatus?
            <PlenaryRedirect usertype={userProfile.userType} status={eventStatus!} />:null}
        </MainLayoutPlenary> 
    );
};

export default RedirectThanks;
