import React, { FC, useState, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import Constants from "../../helpers/constants";
import { localizedDateFromString } from "../../helpers/utils";
import VideoPlayer from "../../components/VideoPlayer";
import PlenaryChat from "../../components/plenary/PlenaryChat";
import PlenaryTutorial from "../../components/plenary/PlenaryTutorial";
import PlenaryQuiz from "../../components/plenary/PlenaryQuiz";
import PlenaryCountdown from "../../components/plenary/PlenaryCountdown";
import PlenaryLeaderboard from "../../components/plenary/PlenaryLeaderboard";
import PlenaryThankYou from "../../components/plenary/PlenaryThankYou";
import PlenaryChatCountdown from '../../components/plenary/PlenaryChatCountdown';
import axiosHelper from "../../helpers/axiosHelper";
import {
  getSubscription,
  sendMessage,
  removeSubscription,
} from "../../helpers/lsHelper";

import bgImage from "../../assets/tutorial/shutterstock_1117734563 (2)/shutterstock_1117734563@2x.png";

import {
  chakra,
  Container,
  Text,
  useDisclosure,
  Flex,
  useBreakpointValue,
} from "@chakra-ui/react";

export type LayoutHeaderProps = {
  usertype: string;
  status: EventStatus;
};

const PlenaryContent: FC<LayoutHeaderProps> = (
  { usertype, status }: LayoutHeaderProps,

  ...props
) => {
  let coundownDiscriminator = 8;
  const [videoSource, setVideoSource] = useState<string>();
  const [videoMime, setVideoMime] = useState<string>();
  const [videoSeconds, setVideoSeconds] = useState<number>(0);
  const [userProfile, setUserProfile] = useState<UserProfile>(axiosHelper.getUserProfile()!);
  const [eventStatus, setEventStatus] = useState<EventStatus>(status);
  const [showChat, setShowChat] = useState(true); // TODO X WOLF lasciare a true, gestire solo i 4 flag sotto
  const [showTutorial, setShowTutorial] = useState(false);
  const [showQuiz, setShowQuiz] = useState<boolean>();
  const [showCountdown, setShowCountdown] = useState(false);
  const [showLeaderboard, setShowLeaderboard] = useState(false);
  const [cdSeconds, setCdSeconds] = useState(8);
  const [maxCdSeconds, setMaxCdSeconds] = useState(8);
  const [showThankYou, setShowThankYou] = useState(false);
  const [gameEnded, setGameEnded] = useState(false);

  const { t } = useTranslation();
  const isMobile = useBreakpointValue({ base: true, lg: false });

  const [quiz, setQuiz] = useState<Quiz>();
  const quizIndex = useRef(-1);
  const [question, setQuestion] = useState<Question>();
  // const currentQuizQuestion = useRef<QuizQuestion>();

  const countdownVisible = useRef(false);
  const nextQuestionAtTime = useRef(-1)

  const redirectInterval = useRef<any>(null);


  const isCustomer = () => {
    return "RSI".includes(userProfile.userType);
  }
  const currentQuizQuestion = () => {
    console.log('currentQuizQuestion ' + quizIndex.current);
    return quiz?.quizQuestionsSorted[quizIndex.current];
  }
  useEffect(() => {
    // console.log("USE EFFECT PLENARYCONTENT");
    axiosHelper.logWebAppEvent("Plenary", null, null);
    // parseStatus(axiosHelper.getDefaultStatusForPlenary());
    let lsSubscription = getSubscription(
      "PLENARY_" + eventStatus?.vKey,
      (message: string) => {
        if (message === 'CHATTHANKYOU') {
          eventStatus.extra2 = '5';
          setShowThankYou(true);
        }
      }
    );
    return () => {
      // console.log('PLENARY CLEANUP');
      removeSubscription(lsSubscription);
    };
  }, []);


  useEffect(() => {
    if (eventStatus != null) {
      handleEventStatus();
    }
  }, [eventStatus]);


  const handleEventStatus = () => {
    console.log(eventStatus);
    const now = new Date();
    const startDate = new Date(Date.parse(eventStatus!.startTime));
    const endDate = new Date(Date.parse(eventStatus!.endTime));
    if (now.getTime() < startDate.getTime()) {
      // console.log('EVENT NOT YET ACTIVE CHE CI FACCIO QUA?');
    } else if (now.getTime() < endDate.getTime()) {
      // console.log("EVENT ACTIVE");
      if (now.getTime() < startDate.getTime() + eventStatus!.vodDuration * 1000) {
        // console.log("VIDEO VISIBLE");
        if (isCustomer()) {
          setShowTutorial(true);
          fetchQuiz();
        } else {
          // setShowThankYou(true);
        }
        setVideoSeconds(eventStatus!.videoSeconds);
        setVideoMime(eventStatus!.videoMime);
        setVideoSource(eventStatus!.videoUrl);
      } else {
        // console.log("EVENT STILL ACTIVE BUT VIDEO FINISHED");
        if (isCustomer()) {
          setVideoSeconds(eventStatus!.vodDuration);
          setVideoMime(eventStatus!.videoMime);
          setVideoSource(undefined);
          setShowLeaderboard(true);
        } else {
          setShowThankYou(true);
        }
        // setShowThankYou(true);
      }
    }
  }

  const onTimeUpdate = (time: number) => {
    // console.log(nextQuestionAtTime.current + " TIME UPDATE " + Math.trunc(time));
    if (time >= eventStatus!.vodDuration) {
      setShowThankYou(true);
    }
    if (nextQuestionAtTime.current > 0) {
      const delta = nextQuestionAtTime.current - time;
      if (Math.floor(Math.abs(delta)) < 1) {
        console.log(countdownVisible.current);
        if (countdownVisible.current) {
          // console.log('!!!!!!!!!! COUNTDOWN WILL HANDLE THAT');
        } else {
          // console.log('!!!!!!!!!! ONTIMEUPDATE WILL HANDLE THAT');
          setShowQuiz(true);
        }
      } else if (delta < -1) {
        if (countdownVisible.current) {
          // console.log('!!!!!!!!!! COUNTDOWN WILL HANDLE THAT');
        } else {
          // console.log('!!!!!!!!!! ONTIMEUPDATE WILL HANDLE THAT');
          setShowQuiz(true);
        }
      } else if (delta > 1) {
        decideWhatToShow(delta);
      }
    } else {
      if (!showQuiz) {
        // setShowTutorial(true);
        // setShowCountdown(false);
      }
    }

  };

  useEffect(() => {
    if (showCountdown) {
      // console.log('MOSTRO IL COUNTDOWN');
      countdownVisible.current = true;
    } else {
      // console.log('NASCONDO IL COUNTDOWN');
      countdownVisible.current = false;
    }
  }, [showCountdown])

  const decideWhatToShow = (seconds: number) => {
    if ((Math.floor(seconds) <= coundownDiscriminator) && (seconds > 3)) {
      if (!countdownVisible.current && !showQuiz) {
        // console.log('decideWhatToShow cd')
        coundownDiscriminator = 25;
        setCdSeconds(seconds);
        setMaxCdSeconds(seconds);
        setShowCountdown(true);
        // nextQuestionAtTime.current=0;
        setShowLeaderboard(false);
        setShowTutorial(false);
      }
    } else if (Math.floor(seconds) > 60) {
      coundownDiscriminator = 8;
      // console.log('decideWhatToShow lb')
      if (quizIndex.current > 4) {
        setShowLeaderboard(true);
        setShowTutorial(false);
      }
      // setShowCountdown(false);
    }
  }

  useEffect(() => {
    // console.log('SHOW QUIZ USE EFFECT ' + showQuiz);
    if (showQuiz != null) {
      if (showQuiz) {
        setShowCountdown(false);
        setShowLeaderboard(false);
        setShowTutorial(false);
        nextQuestionAtTime.current = -1;
      } else {
        if (quiz != null) {

          if (quizIndex.current >= 0) {
            currentQuizQuestion()!.answered = true;
            quizIndex.current = -1;
          }
          setQuestion(undefined);
          preloadNextQuestion();
        } else {
          console.log('QUIZ WAS NULL')
        }
      }
    }
  }, [showQuiz]);

  const onQuestionClose = (didUseDouble: boolean, didWin: boolean, didLoose: boolean, didTimeOut: boolean) => {
    // console.log('On QuestionClose ' + didUseDouble);
    // console.log('On QuestionClose ' + didWin);
    // console.log('On QuestionClose ' + didLoose);
    // console.log('On QuestionClose ' + didTimeOut);
    if (didUseDouble) {
      quiz!.dbl = quiz!.dbl - 1;
    }
    setShowQuiz(false);
  }

  const preloadNextQuestion = () => {
    // console.log('PRELOAD NEXT QUESTION ' + videoSeconds);
    let noMoreQuestions = true;
    for (let i = 0; i < quiz!.quizQuestionsSorted.length; i++) {
      const quizQuestion = quiz!.quizQuestionsSorted[i];
      if (!quizQuestion.answered && quizQuestion.appearAtTime) {
        if (quizQuestion.appearAtTime > videoSeconds) {
          quizIndex.current = i;
          nextQuestionAtTime.current = quizQuestion.appearAtTime;
          console.log('##NEXT QUESTION IS ' + i + ' AND SHOULD APPEAR AT ' + nextQuestionAtTime.current);
          noMoreQuestions = false;
          fetchQuestion();
          return false;
        }
      }
    }
    // console.log('PRELOAD NEXT QUESTION ' + noMoreQuestions);
    if (noMoreQuestions) {
      setShowQuiz(false);
      setShowTutorial(false);
      setGameEnded(true);
      setShowLeaderboard(true);
    }
  }


  //QUIZ
  useEffect(() => {
    if (quiz) {
      // console.log('QUIZ NOT NULL PRELOADING QUESTION');
      preloadNextQuestion();
    } else {
      console.log('QUIZ NULL');
    }
  }, [quiz])

  const fetchQuiz = async () => {
    try {
      const result = await axiosHelper.getQuiz();
      if (result != null) {
        console.log(result);
        setQuiz(result);
      }
    } catch {
      console.log("no quiz for me");
    }
  };


  const fetchQuestion = async () => {
    // console.log('FETCH QUESTION');
    if (currentQuizQuestion() != null) {
      try {
        const result = await axiosHelper.getQuestion(currentQuizQuestion()?.questionUuid, t("LNG"));
        if (result != null) {
          console.log(result.data);
          setShowChat(false);
          setQuestion(result.data);

          // setShowTutorial(false);
        }
      } catch {
        console.log("exception happened");
      }
    }
  };

  const calculatedBG = () => {
    if (showThankYou) {
      return bgImage;
    }
    if (isCustomer()) {
      return bgImage;
    }
    return Constants.PLENARY_BG_PATH + userProfile.userType + ".jpg"
  }

  const continueChattingPressed = () => {
    setVideoSource(undefined);
    setShowThankYou(false);
    setShowLeaderboard(true);
  }

  const okThankYouPressed = () => {
    setTimeout(() => { window.location.reload(); }, 100);
  }

  const chatCountdownCallback = () => {
    setTimeout(() => {
      eventStatus.extra2 = '5';
      setShowThankYou(true)
    }, 100);
  }

  const platformCallback = () => {
    setTimeout(() => { window.location.reload(); }, 100);
  }


  return (
    <>
      <chakra.div
        maxW="container.xl"
        w="100%"
        m="0 auto"
        d="flex"
        flexDir="column"
        alignItems="stretch"
        alignContent="stretch"
        flexGrow={1}
        // pb={isMobile ? "inherit" : "350px"}

        bgImage={isMobile ? bgImage : calculatedBG()}
        style={{
          overflow: "hidden",
          backgroundPosition: "bottom",
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
        }}
      >
        {showThankYou ? (
          <PlenaryThankYou status={status} continueChatting={continueChattingPressed} okCallback={okThankYouPressed} platformCallback={platformCallback} />
        ) : (
          <>
            <Container
              maxW="container.xl"
              d="flex"
              w="100%"
              flexDir={isMobile ? "column" : "row"}
              justifyContent="center"
              alignContent="stretch"
              alignItems="stretch"
              pt={isMobile ? "0" : "1rem"}
              p={isMobile ? "0" : ""}
            >
              <Flex
                position="relative"
                flexDir="column"
                flexGrow={0}
                justifyContent="stretch"
                w={isMobile ? "100%" : "70%"}
                pr={isMobile ? "0" : "1rem"}
                className="data-vjs-player"
              >
                {videoSource ? (
                  <VideoPlayer
                    src={videoSource}
                    seconds={videoSeconds}
                    mime={videoMime}
                    language={t("LNG")}
                    onTimeUpdate={onTimeUpdate}
                  />
                ) : <PlenaryChatCountdown endDateString={status.endTime} callback={chatCountdownCallback} />}
              </Flex>
              {!isMobile && <PlenaryChat eventStatus={status} />}
            </Container>
            {!isMobile && (
              <>
                {showTutorial && <PlenaryTutorial />}
                {showCountdown && <PlenaryCountdown callback={() => { setShowQuiz(true) }} countdown={cdSeconds} maxNum={maxCdSeconds} />}
                {showQuiz && <>
                  {question && (
                    <PlenaryQuiz
                      question={question}
                      doubleCount={quiz!.dbl}
                      quizQuestion={currentQuizQuestion()!}
                      closeCallback={onQuestionClose}
                      quizIndex={quizIndex.current}
                    />
                  )}
                </>}
                {showLeaderboard && <PlenaryLeaderboard showLastMessage={gameEnded} />}
              </>
            )}
            {isMobile && (
              <>
                <Container
                  maxW="container.xl"
                  d="flex"
                  w="100%"
                  flexDir="column"
                  justifyContent="center"
                  alignItems="stretch"
                  p={isMobile ? "0" : ""}
                >
                  {isCustomer() &&
                    <Flex
                      d="flex"
                      w="100%"
                      flexDir="row"
                      justifyContent="center"
                      alignItems="stretch"
                    >
                      <Text
                        style={{
                          fontSize: "18px",
                          fontFamily: "Arial",
                          fontWeight: "bold",
                          letterSpacing: "0px",
                          opacity: 1,
                          textTransform: "uppercase",
                        }}
                        bgColor={showChat ? "red.500" : "transparent"}
                        color={showChat ? "white" : "#707070"}
                        py="5px"
                        px={{ lg: "30px" }}
                        cursor="pointer"
                        whiteSpace="nowrap"
                        alignItems="center"
                        textAlign="center"
                        m={{ base: "10px", lg: "0" }}
                        w={{ base: "100%", lg: "auto" }}
                        onClick={() => setShowChat(true)}
                      >
                        {t("plenary.button.chat")}
                      </Text>
                      <Text
                        style={{
                          fontSize: "18px",
                          fontFamily: "Arial",
                          fontWeight: "bold",
                          letterSpacing: "0px",
                          opacity: 1,
                          textTransform: "uppercase",
                        }}
                        bgColor={!showChat ? "red.500" : "transparent"}
                        color={!showChat ? "white" : "#707070"}
                        py="5px"
                        px={{ lg: "30px" }}
                        cursor="pointer"
                        whiteSpace="nowrap"
                        alignItems="center"
                        textAlign="center"
                        m={{ base: "10px", lg: "0" }}
                        w={{ base: "100%", lg: "auto" }}
                        onClick={() => setShowChat(false)}
                      >
                        {t("plenary.button.game")}
                      </Text>
                    </Flex>}

                  <div style={{ display: showChat ? "flex" : "none" }}>
                    <PlenaryChat eventStatus={status} />
                  </div>
                  <div style={{ display: !showChat ? "flex" : "none", alignItems: "stretch", alignContent:"stretch", flexFlow:"column nowrap" }}>
                    {showTutorial && <PlenaryTutorial />}
                    {showCountdown && <PlenaryCountdown callback={() => { setShowQuiz(true) }} countdown={cdSeconds} maxNum={maxCdSeconds} />}
                    {showQuiz && <>
                      {question && (
                        <PlenaryQuiz
                          question={question}
                          doubleCount={quiz!.dbl}
                          quizQuestion={currentQuizQuestion()!}
                          closeCallback={onQuestionClose}
                          quizIndex={quizIndex.current}
                        />
                      )}
                    </>}
                    {showLeaderboard && <PlenaryLeaderboard showLastMessage={gameEnded} />}
                  </div>
                </Container>
              </>
            )}
          </>
        )}
      </chakra.div>
    </>
  );
};

export default PlenaryContent;
