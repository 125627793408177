import React, { FC, useState } from "react";
import {
  Container,
  Flex,
  useBreakpointValue,
  useMultiStyleConfig,
  Image,
  chakra,
  Text,
  Wrap,
  Link,
} from "@chakra-ui/react";
import logo from "./../assets/footer/agco_logo_187_wht@2x.png";
import { useTranslation } from "react-i18next";

type Props = {};

const Footer: FC<Props> = ({}: Props, ...props) => {
  // const styles = useMultiStyleConfig("Footer", props);

  const isMobile = useBreakpointValue({ base: true, lg: false });
  const { t } = useTranslation();
  const textToMapOthers = [
    {
      label: t("footer.privacy.text"),
      href: t("footer.privacy.link"),
    },
    { label: t("footer.cookies.text"), href: t("footer.cookies.link") },
    {
      label: t("footer.imprint.text"),
      href: t("footer.imprint.link"),
    },
    {
      label: t("footer.accessibility.text"),
      href: t("footer.accessibility.link"),
    },
    { label: t("footer.terms.text"), href: t("footer.terms.link") },
  ];
  const textToMapEN = [
    {
      label: t("footer.privacy.text"),
      href: t("footer.privacy.link"),
    },
    { label: t("footer.cookies.text"), href: t("footer.cookies.link") },
    {
      label: t("footer.imprint.text"),
      href: t("footer.imprint.link"),
    },
    {
      label: t("footer.accessibility.text"),
      href: t("footer.accessibility.link"),
    },
    { label: t("footer.terms.text"), href: t("footer.terms.link") },
    {
      label: t("footer.dontsell.text"),
      href: t("footer.dontsell.link"),
    },
    {
      label: t("footer.california.text"),
      href: t("footer.california.link"),
    },
  ];

  return (
    <chakra.div w="100%" flexShrink={0}>
      <Container
        maxW="container.xl"
        h={{ lg: "200px" }}
        paddingBottom={{ lg: "50px;" }}
        bgColor="#333333"
        display="flex"
        flexDir={{ base: "column", lg: "row" }}
        justifyContent="space-between"
        py="10px"
        zIndex="100"
      >
        <Flex
          flexDir="column"
          justifyContent="flex-end"
          alignItems={{ base: "center", lg: "flex-start" }}
        >
          <Flex my="5">
            <Text color="white" fontWeight="normal" fontSize="11px">
              &copy; 2021 Massey Ferguson
            </Text>
          </Flex>
          <Wrap
            spacing={{ base: "5px", lg: "24px" }}
            justify={{ base: "center", lg: "left" }}
          >
            {t("LNG") === "en" ? (
              <>
                {textToMapEN.map((item: any, index: any) => (
                  <Link
                    href={item.href}
                    key={index}
                    target="_blank"
                    style={{ textDecoration: "none" }}
                  >
                    <Text
                      kay={`${index}-key-footer`}
                      color="white"
                      opacity="0.5"
                      fontWeight="normal"
                      fontSize={{ base: "10px", lg: "10px" }}
                      w="fit-content"
                      _hover={{ color: "#c41230" }}
                    >
                      {item.label}
                    </Text>
                  </Link>
                ))}
              </>
            ) : (
              <>
                {textToMapOthers.map((item: any, index: any) => (
                  <Link
                    href={item.href}
                    key={index}
                    target="_blank"
                    style={{ textDecoration: "none" }}
                  >
                    <Text
                      kay={`${index}-key-footer`}
                      color="white"
                      opacity="0.5"
                      fontWeight="normal"
                      fontSize={{ base: "10px", lg: "10px" }}
                      w="fit-content"
                      _hover={{ color: "#c41230" }}
                    >
                      {item.label}
                    </Text>
                  </Link>
                ))}
              </>
            )}
          </Wrap>
        </Flex>
        <Flex
          flexDir={{ base: "column", md: "column" }}
          justifyContent="space-between"
          alignItems={{ base: "center", md: "flex-end" }}
          py={{ base: "10px", md: "0px" }}
        >
          <Image
            objectFit="cover"
            objectPosition="center"
            bgRepeat="no-repeat"
            overflow="hidden"
            alignItems="center"
            justifyContent="center"
            src={logo}
            h={{ base: "50px", lg: "78px" }}
            w={{ base: "130px", lg: "199px" }}
            marginTop={{ lg: "40px;" }}
          />
          <Text
            textAlign="right"
            color="white"
            opacity="0.5"
            fontSize={{ base: "10px", lg: "10px" }}
          >
            Massey Ferguson is a worldwide brand of AGCO
          </Text>
        </Flex>
      </Container>
    </chakra.div>
  );
};

export default Footer;
