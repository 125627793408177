import React, { useState, useRef } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";

import {
  chakra,
  useBreakpointValue,
  useMultiStyleConfig,
  Container,
  Text,
  Link,
  Flex,
  Divider,
  ListItem,
  UnorderedList,
  SimpleGrid,
  Box,
} from "@chakra-ui/react";

import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import axiosHelper from "./../../helpers/axiosHelper";
import bgImage from "./../../assets/prizes/prizes-page-bg@2x.jpg";

import { ChevronLeftIcon, ChevronRightIcon } from "@chakra-ui/icons";

const TermsAndConditionsContentSV = ({ ...props }) => {
  const { t } = useTranslation();
  const history = useHistory();
  const [userProfile, setUserProfile] = useState<UserProfile>(
    axiosHelper.getUserProfile()!
  );
  const styles = useMultiStyleConfig("PrizePage", props);

  const isMobile = useBreakpointValue({ base: true, lg: false });
  const customSlider = useRef<any>();

  return (
    <Container
      maxW="container.xl"
      bgImage={bgImage}
      d="flex"
      flexDir="column"
      flexDirection="column"
      style={{
        overflow: "hidden",
        backgroundPosition: "center",
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
      }}
      alignItems="center"
    >
      <Flex
        flexDir={{ base: "column", lg: "column" }}
        justifyContent="space-between"
        w={isMobile?"100%":"50%"}
        mt="2%"
      >
        <Text color="white" mb="2%" mt={isMobile ? "30px" : "0"} align="center">
          VILLKOR
          <br />
          MF SUPER CONTEST
          <br />
          Arrangeras från 2 juli 2021 till 3 september 2021
          <br />
          Av varumärket MASSEY FERGUSON AGCO
          <br />
        </Text>
      </Flex>
      <chakra.div w="100%" pb="100px">
        <Flex
          flexDir={{ base: "column", lg: "column" }}
          justifyContent="space-between"
          w="100%"
          mt="3%"
        >
          <Text color="red.500" mt="1%" fontWeight="bold">
            Paragraf 1 - Allmän organization
          </Text>
          <Text color="white" mt="1%">
            MASSEY FERGUSON EME marknadsavdelning inom AGCO SAS, med huvudkontor
            placerat på 41 Blaise Pascal Avenue i Beauvais, arrangerar NEW
            MASSEY FERGUSON DIGITAL EVENT den 3 september, inklusive en allmän
            och kostnadsfri dataspelstävling utan förpliktelser.
          </Text>

          <Text color="red.500" fontWeight="bold" mt="1%">
            Paragraf 2 – Behörighet att delta
          </Text>

          <Text color="white" mt="1%">
            Endast deltagare från länder i Europa och Mellanöstern (utom länder
            som omfattas av handelsbegränsande åtgärder) är berättigade att
            delta. Endast lantbrukare, entreprenörer och de som är relaterade
            till lantbruksföretagtillåts vinna i denna tävling.
          </Text>

          <Text color="white" mt="1%">
            Uteslutna är:
          </Text>

          <chakra.div>
            <UnorderedList ml="40px" color="white">
              <ListItem>Alla personer under 18 år.</ListItem>
              <ListItem>Anställda inom AGCO Group och deras familjer</ListItem>
              <ListItem>
                Återförsäljare och distributörer av AGCOs produkter och tjänster
              </ListItem>
              <ListItem>Anställda i företag knutna till AGCO</ListItem>
              <ListItem>
                Agenturer och leverantörer anlitade av AGCO och anhöriga till
                respektive anställd
              </ListItem>
              <ListItem>
                Journalister inbjudna av MASSEY FERGUSON att delta i evenemanget
              </ListItem>
            </UnorderedList>
          </chakra.div>
          <Text color="white" mt="1%">
            Endast ett deltagande per person, per hushåll och jordbruksenhet,
            samma namn, samma förnamn, samma telefonnummer, samma e-postadress
            är tillåtet. Deltagande i spelet är strikt personligt. Arrangerande
            företag förbehåller sig rätten att utföra alla kontroller som de
            anser lämpliga.
          </Text>
          <Text color="red.500" fontWeight="bold" mt="1%">
            Paragraf 3 - Villkor för deltagande
          </Text>
          <Text color="white" mt="1%">
            Genom att registrera sig till NEW MASSEY FERGUSON DIGITAL EVENT via
            webbplattformen hos den arrangerande divisionen inom AGCO SAS ingår
            den deltagande automatiskt i tävlingen, eftersom den är basen för
            evenemanget. Deltagaren samtycker till att ge tillgång till sin
            personliga information för att kontaktas av AGCO SAS via e-post,
            telefon, sociala medier
          </Text>
          <Text color="red.500" fontWeight="bold" mt="1%">
            Paragraf 4 - Hur vinnare utses
          </Text>
          <Text color="white" mt="1%">
            Tävlingsresultatet kommer att tillkännages mellan 6 september 2021
            och 6 oktober 2021 på kontoret hos AGCO SAS. - Massey Ferguson
            marknadsavdelning EME i Beauvais – Frankrike.
          </Text>
          <Box display="block" mt="1%"></Box>
          <Text color="white">
            Dragningen äger rum mellan 6 september 2021 och 6 oktober 2021 på
            kontoret hos AGCO SAS. - Massey Ferguson marknadsavdelning EME i
            Beauvais – Frankrike. Massey Ferguson marknadsavdelning EME i
            Beauvais genomför denna dragning bakom stängda dörrar.
          </Text>
          <Text color="red.500" fontWeight="bold" mt="1%">
            Paragraf 5 – Priser
          </Text>
          <Text color="white" mt="1%">
            Det kommer att finnas tre nivåer och tre priser per nivå, så nio
            vinnare totalt i den här tävlingen. Genom att svara på frågor efter
            registreringen kommer deltagaren att ha möjlighet att öka sin poäng
            och försöka nå den högsta nivån av vinster i denna tävling.
          </Text>
          <Text color="white" mt="1%">
            Efter evenemanget kommer varje deltagare att placeras i en av de tre
            utlottningarna nedan, beroende på den slutliga rankningspositionen i
            spelet – Slutlig placering meddelas på webbplattformen direkt efter
            evenemanget (3 september 2021, kl. 15.00). Även om den deltagande
            inte svarar på några frågor räknas de automatiskt in i nivå 3 för
            vinsterna som kan vinnas.
          </Text>

          <Text color="white" fontWeight="bold" mt="1%" fontStyle="italic">
            Nivå 1: Värde upp till 20 000 € - totalrankning: 1 - 1 000
          </Text>
          <Text color="white" mt="1%">
            Deltagare från plats 1 till plats 1000 i tävlingen kommer att ingå i
            listan över deltagare för utlottning av gåvor på nivå 1.
            <br /> Tre gåvor finns på denna nivå, med ett högsta värde på 20 580
            €.
          </Text>
          <UnorderedList color="white" ml="40px" mt="1%">
            <ListItem>
              Första pris på nivå 1 är en rabatt på upp till 10 000 € vid köp av
              en ny MF-maskin.
              <br />
              <br />
              Rabatten gäller endast vid köp av nya maskiner: Vallmaskiner,
              traktorer, skördetröskor, pressar och teleskoplastare (med hänsyn
              tagen till lagret av maskiner hos återförsäljaren) ur MASSEY
              FERGUSONs modellprogram, som görs före 31 mars 2022.
              <br />
              <br />
              Rabattregler (*baserat på listpriser, inklusive tillval):
              <br />
              <SimpleGrid
                w={isMobile ? "100%" : "50%"}
                autoFlow=""
                borderColor="white"
                columns={[2, null, 2]}
                border="1px solid white"
                mt="5px"
              >
                <Box bg="red.500" border="1px solid white">
                  <Text
                    color="white"
                    fontWeight="bold"
                    padding="1.5"
                    fontSize={isMobile ? ".7rem" : "1rem"}
                  >
                    Värde på ny maskin*:{" "}
                  </Text>
                </Box>
                <Box bg="red.500" border="1px solid white">
                  <Text
                    color="white"
                    fontWeight="bold"
                    padding="1.5"
                    fontSize={isMobile ? ".7rem" : "1rem"}
                  >
                    Tillämpad rabatt{" "}
                  </Text>
                </Box>
                <Box border="1px solid white">
                  <Text
                    color="white"
                    padding="1.5"
                    fontSize={isMobile ? ".7rem" : "1rem"}
                  >
                    Under 25 000 €*{" "}
                  </Text>
                </Box>
                <Box border="1px solid white">
                  <Text
                    color="white"
                    padding="1.5"
                    fontSize={isMobile ? ".7rem" : "1rem"}
                  >
                    1500€
                  </Text>
                </Box>
                <Box border="1px solid white">
                  <Text
                    color="white"
                    padding="1.5"
                    fontSize={isMobile ? ".7rem" : "1rem"}
                  >
                    Mellan 25 000 € &gt; 50 000 €*{" "}
                  </Text>
                </Box>
                <Box border="1px solid white">
                  <Text
                    color="white"
                    padding="1.5"
                    fontSize={isMobile ? ".7rem" : "1rem"}
                  >
                    2500€
                  </Text>
                </Box>
                <Box border="1px solid white">
                  <Text
                    color="white"
                    padding="1.5"
                    fontSize={isMobile ? ".7rem" : "1rem"}
                  >
                    Mellan 50 000€ &gt; 100 000€*
                  </Text>
                </Box>
                <Box border="1px solid white">
                  <Text
                    color="white"
                    padding="1.5"
                    fontSize={isMobile ? ".7rem" : "1rem"}
                  >
                    4000€
                  </Text>
                </Box>
                <Box border="1px solid white">
                  <Text
                    color="white"
                    padding="1.5"
                    fontSize={isMobile ? ".7rem" : "1rem"}
                  >
                    Mellan 100 000€ &gt; 150 000€*
                  </Text>
                </Box>
                <Box border="1px solid white">
                  <Text
                    color="white"
                    padding="1.5"
                    fontSize={isMobile ? ".7rem" : "1rem"}
                  >
                    6000€
                  </Text>
                </Box>
                <Box border="1px solid white">
                  <Text
                    color="white"
                    padding="1.5"
                    fontSize={isMobile ? ".7rem" : "1rem"}
                  >
                    Mellan 150 000€ &gt; 200 000€*
                  </Text>
                </Box>
                <Box border="1px solid white">
                  <Text
                    color="white"
                    padding="1.5"
                    fontSize={isMobile ? ".7rem" : "1rem"}
                  >
                    8000€
                  </Text>
                </Box>
                <Box border="1px solid white">
                  <Text
                    color="white"
                    padding="1.5"
                    fontSize={isMobile ? ".7rem" : "1rem"}
                  >
                    Från 200 000€*
                  </Text>
                </Box>
                <Box border="1px solid white">
                  <Text
                    color="white"
                    padding="1.5"
                    fontSize={isMobile ? ".7rem" : "1rem"}
                  >
                    10 000€
                  </Text>
                </Box>
              </SimpleGrid>
            </ListItem>
            <ListItem mt="26px">
              Andra pris i denna nivå 1 är ett MF Guide RTK-korrigering utan
              kostnad för alla kompatibla MF-traktorer / skördetröskor, köpta
              före den 31 mars 2022.
              <br />
              <br />
              MF Guide måste väljas av vinnaren när maskinen köps. Maximalt
              värde för detta pris överstiger inte 16 060 €.
              <br />
              Årliga abonnemangsavgifter ingår inte (betalas av vinnaren).
            </ListItem>
            <ListItem mt="26px">
              Det tredje och sista priset på denna nivå 1 är en MF 1520 A Steg
              5, till ett värde av 19 890 €*, inklusive däcksval värt 690 €* (*
              prislista 1 februari 2021)
            </ListItem>
          </UnorderedList>

          <Text color="white" mt="1%">
            1 deltagare kan endast vinna ett pris i det totala spelet, alla
            nivåer kombinerade.
          </Text>
          <Text color="white" fontWeight="bold" mt="1%" fontStyle="italic">
            Nivå 2: Värde upp till 5000 € - totalrankning: 1001 – 2000
          </Text>
          <Text color="white" mt="1%">
            För deltagare från plats 1001 till plats 2000 i tävlingen kommer de
            att ingå i listan över deltagare för utlottning av vinster på nivå
            2.
            <br />
            Tre vinster finns på denna nivå, med ett högsta värde på 5 000 €
          </Text>

          <UnorderedList color="white" ml="40px" mt="1%">
            <ListItem>
              Första pris på denna nivå 2 är ett MF Summer Pack till ett totalt
              värde på 1000 € innehållande:
              <UnorderedList color="white">
                <ListItem ml="20px" type="circle">
                  En uppsättning med MF/AGCO Parts oljefatsgrill och 4 x MF/AGCO
                  Parts däckstolar, till ett totalt värde av 340 €
                </ListItem>
              </UnorderedList>
              <Text color="white" ml="-40px">
                Och
              </Text>
              <UnorderedList color="white">
                <ListItem ml="20px" type="circle">
                  En personlig iPad Air Grey WIFI 64 GO till ett värde av 669€
                </ListItem>
              </UnorderedList>
            </ListItem>
          </UnorderedList>

          <Text color="white" mt="1%">
            Dessa vinster levereras separat till vinnaren.
          </Text>

          <UnorderedList color="white" ml="40px">
            <ListItem mt="1%">
              Andra pris på denna nivå 2 är en professionell högtrycksstvätt
              till ett värde av 2000 €.
              <br />
              <br />
              Modellen av högtryckstvätt som presenteras är inte bunden enligt
              avtal, utan kan ändras beroende på vinnarens marknad. Vår AGCO
              Parts eftermarknadsteam meddelar uppgifter om exakt modell till
              vinnaren efter vinnaren utsetts.
            </ListItem>
            <ListItem mt="1%">
              Det tredje och sista priset på denna nivå 2 till ett totalt värde
              på 5000 € är ett MF Service-paket som innehåller:
              <UnorderedList color="white" listStyleType="circle" mt="1%">
                <ListItem>
                  5000 € voucher, som vinnaren kan användas i den lokala MF
                  återförsäljarens butik gällande alla tillgängliga delar från
                  AGCO Parts.
                </ListItem>
                <ListItem mt="1%">
                  Möjligheten till ett besök på gården av vårt AGCO tekniska
                  servicecenter, tillsammans med den lokala återförsäljaren, för
                  råd om användning av utrustning för Massey Ferguson traktorer
                  och teleskoplastare.
                </ListItem>
              </UnorderedList>
            </ListItem>
          </UnorderedList>

          <Text color="white" mt="1%">
            1 deltagare kan endast vinna ett pris i det totala spelet, alla
            nivåer kombinerade.
          </Text>

          <Text color="white" fontWeight="bold" mt="2%" fontStyle="italic">
            Nivå 3: Värde upp till 250 € - totalrankning: 2001 – sist
          </Text>

          <Text color="white" mt="2%">
            Deltagare från plats 2001 till slutet av prislistan kommer att ingå
            i listan över deltagare för utlottning av vinster på nivå 3.
            <br /> 3 Vinster finns på denna nivå, med motsvarande totalvärde på
            250€
          </Text>

          <UnorderedList color="white " mt="1%">
            <ListItem>
              Första pris på denna nivå 3 är ett Connectivity Pack som
              innehåller 3 exklusiva MASSEY FERGUSON-gåvor:
              <UnorderedList color="white">
                <ListItem type="circle">
                  En MASSEY FERGUSON Smart Watch – pris per styck: 180 €
                </ListItem>
                <ListItem type="circle">
                  En uppsättning av MASSEY FERGUSON Ear pods MASSEY FERGUSON –
                  pris per styck: 39,90 €
                </ListItem>
                <ListItem type="circle">
                  En MASSEY FERGUSON S Collection Powerbank – pris per styck:
                  29,90 €
                </ListItem>
                <Box display="block" mt="1%"></Box>
              </UnorderedList>
            </ListItem>

            <ListItem mt="1%">
              Andra pris i denna nivå 3 är en e-voucher till ett värde av 250 €,
              som kan användas i flera omgångar i MASSEY FERGUSON-onlinebutik:{" "}
              <Link href="https://shop.masseyferguson.com">
                https://shop.masseyferguson.com
              </Link>.{" "}
              Denna e-voucher gäller i 36 månader efter erhållande.
            </ListItem>

            <ListItem mt="1%">
              Tredje och sista pris i denna nivå 3 är ett spelpaket som
              innehåller:
              <UnorderedList color="white" listStyleType="circle">
                <ListItem>En MF 8S skalmodell – pris per styck: 70 €</ListItem>
                <ListItem>
                  Exklusiv MF 5S skalmodell – pris per styck: 65 €
                </ListItem>
                <ListItem>
                  En MASSEY FERGUSON trådlös datormus – pris per styck: 15 €
                </ListItem>
                <ListItem>
                  2 Farming Simulator 2022 spel för dator - pris per styck: 40 €
                </ListItem>
                <ListItem>
                  Exklusiv Farming Simulator 2022 FS baseballkeps – pris per
                  styck: 20 €
                </ListItem>
              </UnorderedList>
            </ListItem>
          </UnorderedList>
          <Text color="white" mt="1%">
            1 deltagare kan endast vinna ett pris i det totala spelet, alla
            nivåer kombinerade.
          </Text>
          <Text color="red.500" fontWeight="bold" mt="1%">
            Paragraf 6 – Underrättelse och tillkännagivande av vinnare
          </Text>
          <Text color="white" mt="1%">
            Massey Fergusons marknadsavdelning meddelar vinnarna genom att
            skicka ett e-postmeddelande med uppmaningen att kontakta AGCO.
            <br />
            Om den som valts i dragningen inte svarar på detta e-postmeddelande
            inom tio dagar, kommer en andra och sista dragning att äga rum och
            första vinnaren kommer att diskvalificeras.
            <br />
            Vinnaren kommer att kontaktas av AGCO SAS Company via e-post eller
            på annat sätt för att skicka den information som krävs för
            prisutdelningen.
            <br />
            Deltagaren samtycker till att, i händelse av att tävlingen vinns,
            dennes namn kommer att offentliggöras i annonser, kampanjer eller på
            relevanta webbplatser och i sociala medier.
            <br />
            Vinnaren förbinder sig att göra sig tillgänglig utan kostnad inom
            rimliga gränser för ljud-, foto-, bild- och/eller textreklam via
            radio, internet, TV eller tryckta medier och samtycker till
            publicering och användning för reklamändamål av AGCO och av AGCOs
            dotterföretag.
            <br />
          </Text>
          <Text color="red.500" fontWeight="bold" mt="1%">
            Paragraf 7 – Uteslutning av deltagare
          </Text>

          <Text color="white" mt="1%">
            AGCO förbehåller sig rätten att utesluta deltagare från tävlingen.
            Detta gäller speciellt brott mot villkoren för deltagande, felaktiga
            personuppgifter eller manipulation eller misstänkt manipulation.
            AGCO kan också utesluta deltagare som rimligen misstänks använda
            AGCO:s tjänster.
            <br />
            Om AGCO därefter konstaterar att deltagaren har orsakat någon
            anledning som leder till uteslutning eller faktiskt inte hade rätt
            att delta i den mening som avses i Paragraf 2, kan AGCO utesluta
            deltagaren från utdelningen eller överlämnandet av priserna eller
            återkräva alla utdelade priser.
          </Text>
          <Text color="red.500" fontWeight="bold" mt="1%">
            Paragraf 8 – Återkallande av priser
          </Text>
          <Text color="white" mt="1%">
            Ett ekonomiskt utbyte, retur eller kompensation är inte tillåten
            under några omständigheter.
            <br />
            Det arrangerande företaget kan ersätta priserna med ett annat, med
            motsvarande värde, om yttre omständigheter förhindrar utdelning av
            de annonserade priserna. Detta är inte förhandlingsbart och inget
            kontantalternativ kommer att finnas tillgängligt.
            <br />
            Vinsten kommer att skickas till vinnaren inom tre månader efter att
            Massey Fergusons marknadsföringsavdelning fått all information som
            behövs för leveransen av vinsten.
          </Text>
          <Text color="red.500" fontWeight="bold" mt="1%">
            Paragraf 9 - Användarvillkor och garanti
          </Text>
          <Text color="white" mt="1%">
            Arrangörerna avsäger sig allt ansvar för alla incidenter och/eller
            olyckor som kan inträffa under åtnjutandet av det tilldelade priset
            och/eller på grund av dess användning.
            <br />
            Arrangörerna förbehåller sig rätten att förkorta, förlänga, ändra
            eller avbryta denna aktivitet om omständigheterna så kräver, utan
            att ådra sig något ansvar.
            <br />
            Arrangörerna kan inte hållas ansvariga vid avbrott i tjänsten
            orsakad av en teknisk händelse i kommunikationsnätet eller en
            teknisk händelse utanför deras kontroll. Arrangörerna kan inte
            hållas ansvariga om tävlingen, av någon anledning, helt eller delvis
            modifieras, skjuts upp eller avbryts. Ingen ersättning kan krävas.
            Alla oläsliga, ofullständiga och förfalskade formulär räknas inte.
            Därför tar det arrangerande företaget inget ansvar om formuläret
            inte är registrerat eller omöjligt att kontrollera.
            <br />
          </Text>
          <Text color="red.500" fontWeight="bold" mt="1%">
            Paragraf 10 - Godkännande och tvister
          </Text>

          <Text color="white" mt="1%">
            Genom att delta i denna tävling bekräftar alla deltagare att de
            accepterar dessa villkor.
            <br />
            Varje deltagare vet att slumpens medverkan inte kan ge honom någon
            annan försäkran än förväntningen om en möjlig och slumpmässig vinst.
            <br />
            Eventuella tvister sänds till AGCO SAS juridiska avdelning - Avenue
            41 Blaise Pascal - BP 60307 - BEAUVAIS (60026) Frankrike, senast den
            3 september (poststämpel gäller som bevis) per brev.
            <br />
            Denna förordning är enligt fransk lag, vilken är den enda som är
            tillämplig.
            <br />
            AGCO SAS. - MASSEY FERGUSON EME marknadsavdelning kommer att ge
            feedback angående eventuella praktiska svårigheter, tolkningar eller
            ansökningar om denna förordning.
            <br />
          </Text>
          <Text color="red.500" fontWeight="bold" mt="1%">
            Paragraf 11 - Offentliggörande av tävlingsvillkor
          </Text>
          <Text color="white" mt="1%">
            Dessa villkor kommer att finnas tillgängliga online på MF officiella
            evenemangsplattform.
            <br />
            Deltagare med en internetanslutning kan inte göra anspråk på någon
            återbetalning, eftersom deltagandet inte medför någon extra kostnad.
            <br />
            En fullständig kopia av dessa villkor finns på det arrangerande
            företagets juridiska avdelning.
            <br />
          </Text>
          <Text color="red.500" fontWeight="bold" mt="1%">
            Paragraf 12 – Skydd av personuppgifter
          </Text>
          <Text color="white" mt="1%">
            För att delta i denna tävling måste deltagarna acceptera att viss
            personlig information om dem själva kommer att användas av det
            arrangerande företaget i syfte att hantera tävlingen men också för
            marknadsföringsändamål.
            <br />
            För mer information kan du läsa vår integritetspolicy på följande
            länk{" "}<Link href="http://www.agcocorp.com/privacy.html">
              http://www.agcocorp.com/privacy.html
            </Link>
            <br />
            Alla vinnare godkänner utan någon ekonomisk eller annan ersättning
            att ge arrangerande företag rätten att använda dennes förnamn,
            efternamn, sammanfattande angivelse av bostadsort och fotografering
            för alla reklam- eller redaktionella ändamål.
            <br />I enlighet med tillämpliga regler kan du dra dig ur helt eller
            delvis när som helst och utan att ge AGCO någon motivering. Du kan
            hävda dina rättigheter till åtkomst, rättelse, radering och motstånd
            via e-post till dataprivacy@agcocorp.com.
          </Text>
        </Flex>
      </chakra.div>
    </Container>
  );
};

export default TermsAndConditionsContentSV;
