import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";
import { useHistory, Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import axiosHelper from "../helpers/axiosHelper";
import { useTranslation } from "react-i18next";
import ModalBox from "../components/ModalBox";
import logo from "../assets/inner-logo.svg";
import Constants from "../helpers/constants";
import MainLayout from "../layouts/MainLayout";
import Footer from "../layouts/Footer";
import { localizedDateFromString } from "../helpers/utils";

//Media
import MainLayoutLogin from "../layouts/MainLayoutLogin";
import {
  chakra,
  Spacer,
  Image,
  Container,
  Divider,
  useBreakpointValue,
  Flex,
  Text,
  Center,
  Grid,
  Box,
} from "@chakra-ui/react";

// import background from "../assets/home/prizesSection/shutterstock_1386762362/shutterstock_1386762362.png";
import titleImg from "../assets/home/BornToFarm/BornToFarm@2x.png";
import background from "../assets/header/Raggruppa 460/Raggruppa 460@2x.png";
import backgroundMobile from "../assets/home/Visuel-Sans-Textes-Degrade@2x.png";

const eye = <FontAwesomeIcon icon={faEye} />;
const eyeSlash = <FontAwesomeIcon icon={faEyeSlash} />;

const ThankYou = () => {
  const history = useHistory();
  const [modalVisible, setModalVisible] = useState<boolean>(false);
  const { t } = useTranslation();
  const isMobile = useBreakpointValue({ base: true, lg: false });

  return (
    <MainLayoutLogin>
      <Container maxW="container.xl" bgImage={background} bgRepeat="no-repeat">
        <Flex
          d="flex"
          flexDir="column"
          bgColor="black"
          h={{ lg: "307px" }}
          pb={{ base: "30px", lg: "0px" }}
          mt={{ lg: "-100px" }}
        >
          <Image
            objectFit="cover"
            objectPosition="center"
            bgRepeat="no-repeat"
            objectSize="cover"
            overflow="hidden"
            alignItems="center"
            justifyContent="center"
            src={!isMobile ? background : backgroundMobile}
            h={{ base: "307px", lg: "307px" }}
            w={!isMobile ? "1200px" : "100%"}
            left={!isMobile ? "none" : "0px"}
            pos="absolute"
            zIndex="5"
            top={isMobile ? "50px" : "0px"}
          />
          <Flex zIndex="10" flexDir={{ base: "column", lg: "row" }}>
            <Flex
              flexDir="column"
              ml={{ lg: "30px" }}
              justifyContent={{ base: "center", lg: "flex-end" }}
              position="relative"
              left={{ lg: "150px" }}
            >
              <Image
                objectFit="contain"
                objectPosition="center"
                bgRepeat="no-repeat"
                objectSize="cover"
                overflow="hidden"
                alignItems="center"
                justifyContent="center"
                src={titleImg}
                h={{ base: "auto", lg: "62px" }}
                w={{ base: "100%", lg: "604px" }}
                mt={{ base: "60px", lg: "0px" }}
              />

              <Text as="h3" textStyle="h3" mt="5px" ml={{ base: "5px", md: "15px" }}>
                {localizedDateFromString(Constants.EVENT_DATE, t("LNG"))}
              </Text>
            </Flex>
          </Flex>
          <Flex flexDir="column" color="white" mt="30px" zIndex="10">
            <Text as="h3" textStyle="h3"></Text>
            <Text
              w={{ lg: "40%" }}
              mt="5px"
              fontFamily="Arial"
              fontWeight="normal"
              fontSize={{ base: "16px", lg: "24px" }}
            ></Text>
          </Flex>
        </Flex>
        {!isMobile && <Divider orientation="vertical" w="170px" />}
        <Text textStyle="h2" fontSize={{ base: "25px", md: "35px" }} mt="3rem" mb="1rem" textAlign="center">
          {t("thankyou.title")}
        </Text>
        {!isMobile && <Divider orientation="horizontal" w="170px" />}
        <Text fontSize={{ base: "16px", md: "26px" }} color="white" textAlign="center" mb="200px">
          {t("thankyou.message1")}
          <br />
          {t("thankyou.message2")}
        </Text>
      </Container>
    </MainLayoutLogin>
  );
};
export default ThankYou;
