class Reaction {
  constructor(reactionNumber, container) {
    //posizione su schermo

    // var container = document.getElementById("reactionsContainer");
    this.container = container;
    if (container != null) {
      // console.log("WIDTH " + container.clientWidth + container.offsetLeft);
      // console.log("HEIGHT " + container.clientHeight + container.offsetTop);
      this.x = parseFloat(container.clientWidth + container.offsetLeft - 15);
      this.y = parseFloat(container.clientHeight + container.offsetTop - 30);

      this.phase = Math.random() * 360;
      //ampiezza onda
      this.radius = Math.random() * 1;
      //velocità
      this.speed = 1 + Math.random() * 2;
      //scala finale
      this.scale = 1.0;
      //scala iniziale
      this.grow = 0.7;
      //trasparenza
      this.alpha = 1;
      this.done = false;
      this.outer = document.createElement("div");
      this.outer.className = "reaction-outer";
      this.inner = document.createElement("div");
      //const randomReactionNumber = Math.floor(Math.random() * 5) + 1
      this.reactionNumber = reactionNumber;
      this.inner.className = "reaction-inner reaction" + reactionNumber;

      this.outer.appendChild(this.inner);

      container.appendChild(this.outer);

      //document.body.appendChild(this.outer);
      this.draw();
      this.initialized = true;
    } else {
      this.initialized = false;
    }
  }

  flush() {
    // var container = document.getElementById("reactionsContainer");
    if (this.initialized) {
      this.container.appendChild(this.outer);

      if (this.container.contains(this.outer)) {
        this.container.removeChild(this.outer);
      }
      this.outer = null;
      this.inner = null;
    }
  }

  draw() {
    if (this.done) return;
    if (this.initialized) {
      this.outer.style.transform =
        "translateX( " +
        this.x +
        "px ) translateY( " +
        this.y +
        "px ) scale( " +
        this.grow +
        " )";
      this.outer.style.opacity = this.alpha;
    }
  }

  update() {
    if (this.initialized) {
      this.alpha = this.alpha > 0 ? this.alpha - 0.0015 : this.alpha;
      this.alpha = this.alpha < 0 ? 0 : this.alpha;
      // console.log("trasparenza " + this.alpha);
      // console.log("ciao " + this.scale);
      // console.log("numero " + this.reactionNumber);

      this.x += Math.cos(this.phase / 50) * this.radius;
      this.y -= this.speed;
      // console.log("velocita " + this.speed);
      // console.log("grow 1 " + this.grow);
      this.grow += (this.scale - this.grow) / 50; //<<
      // console.log("grow 2 " + this.grow);
      this.phase += 1;
      // console.log(this.grow);
      this.done = this.y < -100 || this.alpha <= 0 ? true : false;
    }
  }
}

class ReactionsHelper {
  reactions;

  constructor() {
    console.log("reactionsHelper constructor");
    this.reactions = [];
    this.multiplier = 10000;
  }

  setContainerName = (name) => {
    var container = document.getElementById(name);
    if (container != null && this.savedContainer == null) {
      this.savedContainer = container;
      this.animationsLoop();
      this.createRandomReactions();
    }
  };
  setMultiplier(newmult) {
    console.log("SETTING MULTIPLIER " + newmult);
    this.multiplier = newmult;
  }
  createRandomReactions = () => {
    const randomReactionNumber = Math.floor(Math.random() * 5) + 1;
    this.anima(randomReactionNumber);
    const timeout = Math.floor(Math.random() * this.multiplier);
    setTimeout(this.createRandomReactions, timeout);
  };

  anima(number) {
    // console.log("sono anima " + number);
    return this.reactions.push(new Reaction(number, this.savedContainer));
  }

  // main loop
  animationsLoop = () => {
    requestAnimationFrame(this.animationsLoop);
    let i;

    // cleanup
    for (i = 0; i < this.reactions.length; ++i) {
      if (this.reactions[i].done) {
        this.reactions[i].flush();
        this.reactions.splice(i, 1.5);
      }
    }
    // animate
    for (i = 0; i < this.reactions.length; ++i) {
      this.reactions[i].update();
      this.reactions[i].draw();
    }
  };
}

export default new ReactionsHelper();
