import React, { FC, useState, useRef } from "react";
import { useForm } from "react-hook-form";
import axiosHelper from "./../../helpers/axiosHelper";
import { useHistory } from "react-router-dom";
import { ErrorMessage } from "@hookform/error-message";
import { useTranslation } from "react-i18next";
import {
  Box,
  chakra,
  Checkbox,
  Flex,
  Modal,
  ModalBody,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Text,
  VStack,
  Image,
  ModalFooter,
  ModalCloseButton,
} from "@chakra-ui/react";

import imageforI from "./../../assets/welcomecluster/buttonForI.jpg";
import imageforR from "./../../assets/welcomecluster/buttonForR.jpg";
import imageforS from "./../../assets/welcomecluster/buttonForS.jpg";
import ClusterBox from "../cluster/ClusterBox";

type Props = {
  clickOpen?: boolean;
  clickClose: () => void;
  refreshCluster: ()=> void;
};

const ClusterModal: FC<Props> = ({ clickOpen = false, clickClose, refreshCluster }) => {
  const { t } = useTranslation();

  const [isSelected, setIsSelected] = useState({
    R: false,
    I: false,
    S: false,
  });

  const refBrave = useRef<any>();
  const refBig = useRef<any>();
  const refSpecialist = useRef<any>();

  const handleCard = (item: string) => {
    // console.log("HANDLE CARD " + item);
    if (item === "brave") {
      // console.log(item);
      // axiosHelper.setCluster("R");
      setIsSelected({ R: true, I: false, S: false });
    } else if (item === "big") {
      // console.log(item);
      // axiosHelper.setCluster("I");
      setIsSelected({ R: false, I: true, S: false });
    } else if (item === "specialist") {
      // console.log(item);
      // axiosHelper.setCluster("S");
      setIsSelected({ R: false, I: false, S: true });
    } else if (item === "") {
      setIsSelected({ R: false, I: false, S: false });
    }
  };

  const saveCluster = () => {
    if (isSelected.R) {
      axiosHelper.setCluster("R");
    } else if (isSelected.I) {
      axiosHelper.setCluster("I");
    } else if (isSelected.S) {
      axiosHelper.setCluster("S");
    }
    setTimeout(refreshCluster,300);
    clickClose();
  };

  return (
    <Modal isOpen={clickOpen} onClose={clickClose} size="xl">
      <ModalOverlay backgroundColor="rgba(0, 0, 0, 0.8)" />
      <ModalContent
        borderRadius="0px"
        bgColor="white"
        boxShadow="0px 5px 15px rgba(225,225,225,0.15)"
      >
        <ModalHeader>
          <Flex justifyContent="space-between" alignItems="center">
            <Text as="h1" textStyle="h1" color="red.500">
              {t("header.changecluster")}
            </Text>
          </Flex>
          <Text
            style={{
              font: "normal normal normal 16px/24px Arial",
              letterSpacing: "0px",
              color: "#000000",
              opacity: 1,
            }}
            mt="20px"
          >{t("register.subtitle")}</Text>
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <VStack
            spacing="10px"
            fontFamily="Arial"
            color="white"
            textTransform="uppercase"
          >
            <ClusterBox
              refBox={refBrave}
              img={imageforR}
              title={t("registercard.title.R")}
              subtitle={t("registercard.description.R")}
              type="brave"
              handleClick={handleCard}
              selected={isSelected.R}
              width={{ base: "100%" }}
              height={{ base: "90px" }}
            />

            <ClusterBox
              refBox={refBig}
              img={imageforI}
              title={t("registercard.title.I")}
              subtitle={t("registercard.description.I")}
              type="big"
              handleClick={handleCard}
              selected={isSelected.I}
              width={{ base: "100%" }}
              height={{ base: "90px" }}
            />

            <ClusterBox
              refBox={refSpecialist}
              img={imageforS}
              title={t("registercard.title.S")}
              subtitle={t("registercard.description.S")}
              type="specialist"
              handleClick={handleCard}
              selected={isSelected.S}
              width={{ base: "100%" }}
              height={{ base: "90px" }}
            />
          </VStack>
        </ModalBody>
        <ModalFooter py="0" mb="50px" mt="20px">
          <Flex
            onClick={saveCluster}
            px={{ lg: "47px" }}
            py="5px"
            w={{ base: "100%", lg: "fit-content" }}
            bgColor="red.500"
            color="white"
            _hover={{ opacity: 0.8 }}
            fontFamily="Arial"
            fontWeight="bold"
            fontSize="18px"
            justifyContent="center"
          >
            {t("login.okbtn")}
          </Flex>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default ClusterModal;
