import React, { useState, useRef } from "react";
import { useHistory, Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

import {
  chakra,
  useBreakpointValue,
  useMultiStyleConfig,
  Container,
  Text,
  Image,
  useDisclosure,
  Flex,
  Divider,
  Box,
} from "@chakra-ui/react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import axiosHelper from "./../../helpers/axiosHelper";
import bgImage from "./../../assets/tutorial/shutterstock_1117734563 (2)/shutterstock_1117734563@2x.png";
import prize1Image from "./../../assets/tutorial/prizes/Level-1-Gift-1_Discount.png";
import prize2Image from "./../../assets/tutorial/prizes/Level-1-Gift-2_MFGuidewithRTKcorrectionforfree.png";
import prize3Image from "./../../assets/tutorial/prizes/Level-1-Gift-3_WinAnMF1520.png";
import prize4Image from "./../../assets/tutorial/prizes/Level-2-Gift-1_MFSummerPack.png";
import prize5Image from "./../../assets/tutorial/prizes/Level-2-Gift-2_MFWithFarmersEveryday.png";
import prize6Image from "./../../assets/tutorial/prizes/Level-2-Gift-3_MFSuperHeroServices.png";
import prize7Image from "./../../assets/tutorial/prizes/Level-3-Gift-1_MFConnectivityPack.png";
import prize8Image from "./../../assets/tutorial/prizes/Level-3-Gift-2_MFMerchandisingE-Voucher.png";
import prize9Image from "./../../assets/tutorial/prizes/Level-3-Gift-3_MFGameAddictPack.png";

import { ChevronLeftIcon, ChevronRightIcon } from "@chakra-ui/icons";
import QuestionModal from "../modal/QuestionModal";

const TutorialContent = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const [userProfile, setUserProfile] = useState<UserProfile>(
    axiosHelper.getUserProfile()!
  );
  const isMobile = useBreakpointValue({ base: true, lg: false });
  const customSlider = useRef<any>();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [rankArea, setRankArea] = useState("1 - 1000");

  const sliderBeforeChange = (previous: number, newIndex: number) => {
    // console.log(newIndex);
    if (newIndex >= 0 && newIndex < 3) {
      setRankArea("1 - 1000");
    } else if (newIndex >= 3 && newIndex < 6) {
      setRankArea("1001 - 2000");
    } else if (newIndex >= 6 && newIndex < 9) {
      setRankArea("2001 +");
    }
  };
  const renderArrows = () => {
    return (
      <Flex justifyContent="space-between" my={{ base: "20px", lg: "5px" }}>
        <Flex
          onClick={() => customSlider.current.slickPrev()}
          cursor="pointer"
          zIndex="10"
        >
          <ChevronLeftIcon color="white" w="40px" h="40px" />
        </Flex>

        <Flex
          onClick={() => customSlider.current.slickNext()}
          cursor="pointer"
          zIndex="10"
        >
          <ChevronRightIcon color="white" w="40px" h="40px" />
        </Flex>
      </Flex>
    );
  };

  return (
    <Container
      maxW="container.xl"
      // minH="auto"
      bgImage={bgImage}
      flex="1 0 auto"
      d="flex"
      flexDir="column"
      flexDirection="column"
      pt={{ base: "56px" }}
      style={{
        overflow: "hidden",
        backgroundPosition: "center",
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
      }}
      alignItems="center"
    >
      <Flex
        flexDir={{ base: "column", lg: "row" }}
        justifyContent="space-between"
        w="100%"
        mt="20px"
      >
        <Image
          objectFit="contain"
          objectPosition="center"
          bgRepeat="no-repeat"
          overflow="hidden"
          alignItems="center"
          justifyContent="center"
          src={"/tutorial/tutorial-" + t("LNG") + ".svg"}
          w={isMobile ? "100%" : "45%"}
          zIndex="5"
        />
        <Flex color="white" flexDir="column" w={{ lg: "45%" }}>
          <Text
            fontSize="40px"
            fontFamily="Arial Black, Arial-BoldMT, Arial"
            fontWeight="regular"
            my={{ base: "20px", lg: "0" }}
          >
            {t(`tutorial.game.title`)}
          </Text>
          <Text fontSize="16px"> {t(`tutorial.game.subtitle`)}</Text>
          <Text fontSize="16px"> {t(`tutorial.game.text`)}</Text>
          <Text fontSize="16px"> {t(`tutorial.game.remember`)}</Text>
        </Flex>
      </Flex>
      <Divider
        orientation="horizontal"
        borderColor="#C8C8C8"
        my="20px"
        w="30%"
        //   w={{ lg: "30%" }}
      />
      <Flex
        flexDir={{ base: "column", lg: "row" }}
        justifyContent="space-between"
        w="100%"
      >
        <Flex color="white" flexDir="column" w={{ lg: "45%" }}>
          <Text
            fontSize="40px"
            fontFamily="Arial Black, Arial-BoldMT, Arial"
            fontWeight="regular"
            my={{ base: "20px", lg: "0" }}
          >
            {t(`tutorial.rap.title`)}
          </Text>
          <Text fontSize="16px"> {t(`tutorial.rap.subtitle`)}</Text>
          <Text fontSize="16px"> {t(`tutorial.rap.text_1`)}</Text>
          <Text fontSize="16px"> {t(`tutorial.rap.text_2`)}</Text>
          <Text fontSize="sm" mt="20px" fontStyle="italic">
            {" "}
            {t(`tutorial.rap.titledetail`)}
          </Text>
          <Text fontSize="sm" mt="10px">
            <Link to="termsandconditions">
              {t(`termsandconditions`)}
            </Link>
          </Text>
        </Flex>
        <Flex w={{ lg: "45%" }} flexDir="column" my="20px">
          <Text
            fontSize="18px"
            fontFamily="Arial Black, Arial-BoldMT, Arial"
            fontWeight="regular"
            color="red.500"
            ml="15px"
            my={{ base: "20px", lg: "5px" }}
          >
            {rankArea}
          </Text>
          <Slider
            ref={(slider: any) => (customSlider.current = slider)}
            dots={true}
            infinite={true}
            speed={500}
            beforeChange={sliderBeforeChange}
            slidesToShow={isMobile ? 1 : 3}
            slidesToScroll={isMobile ? 1 : 3}
            accessibility={true}
            autoplay
            autoplaySpeed={5000}
            arrows={false}
            centerPadding={isMobile ? "60px" : "0px"}
          >
            <Image
              objectFit="cover"
              objectPosition="center"
              bgRepeat="no-repeat"
              overflow="hidden"
              alignItems="center"
              justifyContent="center"
              src={prize1Image}
              px="15px"
            />
            <Image
              objectFit="cover"
              objectPosition="center"
              bgRepeat="no-repeat"
              overflow="hidden"
              alignItems="center"
              justifyContent="center"
              src={prize2Image}
              px="15px"
            />
            <Image
              objectFit="cover"
              objectPosition="center"
              bgRepeat="no-repeat"
              overflow="hidden"
              alignItems="center"
              justifyContent="center"
              src={prize3Image}
              px="15px"
            />
            <Image
              objectFit="cover"
              objectPosition="center"
              bgRepeat="no-repeat"
              overflow="hidden"
              alignItems="center"
              justifyContent="center"
              src={prize4Image}
              px="15px"
            />
            <Image
              objectFit="cover"
              objectPosition="center"
              bgRepeat="no-repeat"
              overflow="hidden"
              alignItems="center"
              justifyContent="center"
              src={prize5Image}
              px="15px"
            />
            <Image
              objectFit="cover"
              objectPosition="center"
              bgRepeat="no-repeat"
              overflow="hidden"
              alignItems="center"
              justifyContent="center"
              src={prize6Image}
              px="15px"
            />
            <Image
              objectFit="cover"
              objectPosition="center"
              bgRepeat="no-repeat"
              overflow="hidden"
              alignItems="center"
              justifyContent="center"
              src={prize7Image}
              px="15px"
            />
            <Image
              objectFit="cover"
              objectPosition="center"
              bgRepeat="no-repeat"
              overflow="hidden"
              alignItems="center"
              justifyContent="center"
              src={prize8Image}
              px="15px"
            />
            <Image
              objectFit="cover"
              objectPosition="center"
              bgRepeat="no-repeat"
              overflow="hidden"
              alignItems="center"
              justifyContent="center"
              src={prize9Image}
              px="15px"
            />
          </Slider>
          <Box display="block" mt="1rem">
            {renderArrows()}
          </Box>
        </Flex>
      </Flex>
      {userProfile.firstLogin && (
        <Flex
          w="100%"
          my="20px"
          justifyContent="space-between"
          alignItems="center"
          flexDir={{ base: "column", lg: "row" }}
        >
          <Text
            color="red.500"
            textAlign="left"
            w="100%"
            fontSize="18px"
            fontFamily="Arial Black, Arial-BoldMT, Arial"
            my={{ base: "20px", lg: 0 }}
          >
            <Text fontSize="16px">
              {" "}
              {t(`tutorial.rap.redtext_1`)}&nbsp;
              {t(`tutorial.rap.redtext_2`)}
            </Text>
          </Text>
          <Flex
            fontSize="18px"
            fontFamily="Arial"
            fontWeight="bold"
            textTransform="uppercase"
            color="white"
            bgColor="red.500"
            px="55px"
            py="7px"
            w={{ base: "100%", lg: "auto" }}
            justifyContent="center"
            cursor="pointer"
            _hover={{ opacity: 0.8 }}
            onClick={() => {
              axiosHelper.setTutorialSeen();
              history.push("/");
            }}
          >
            {t("tutorial.startbtn")}
          </Flex>
        </Flex>
      )}
    </Container>
  );
};

export default TutorialContent;
