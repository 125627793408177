export const generateUniqueID = (prefix: string) => {
  let ts = new Date();
  let seed = Math.round(Math.random() * (5000 - 1000) + 1000);
  let timestamp =
    seed +
    ts.getUTCDate().toString() +
    ts.getUTCHours().toString() +
    ts.getUTCMinutes().toString() +
    ts.getUTCSeconds().toString() +
    ts.getUTCMilliseconds().toString();

  //    console.log('TIME_ID:' + timestamp)
  return `${prefix}${timestamp}`;
}

export const longDateAndTimeString = (dtstr: string) => {
  var date = new Date(Date.parse(dtstr));
  return longDateAndTimeStringFromDate(date);
  // return date.toLocaleString('it', {
  //   day: 'numeric',
  //   month: 'long',
  //   weekday: 'long',
  //   year: 'numeric'
  // }) + ' - ORE ' + date.toLocaleString('it', {
  //   hour12: false,
  //   hour: '2-digit',
  //   minute: '2-digit',
  // });
}
export const localizedDateFromString = (dateString: string, localeString: string) => {
  var date = new Date(Date.parse(dateString));
  localeString='fr';
  return date.toLocaleString(localeString, {
    day: '2-digit',
    month: '2-digit',
    year: 'numeric'
  }) + ' - ' + date.toLocaleString(localeString, {
    hour: '2-digit',
    minute: '2-digit',
  });
}

export const longDateAndTimeStringFromDate = (date: Date) => {
  return date.toLocaleString('it', {
    day: 'numeric',
    month: 'long',
    weekday: 'long',
    year: 'numeric'
  }) + ' - ORE ' + date.toLocaleString('it', {
    hour12: false,
    hour: '2-digit',
    minute: '2-digit',
  });
}

export const shortDateString = (dtstr: string) => {
  var date = new Date(Date.parse(dtstr));
  return date.toLocaleString('it', {
    day: 'numeric',
    month: 'long',
    weekday: 'long',
  });
}

export const shortTimeString = (dtstr: string) => {
  var date = new Date(Date.parse(dtstr));
  return date.toLocaleString('it', {
    hour12: false,
    hour: '2-digit',
    minute: '2-digit',
  });
}

export const chatTimeString = (dtstr: string) => {
  var date = new Date(Date.parse(dtstr));
  return date.toLocaleString('it', {
    hour12: false,
    hour: '2-digit',
    minute: '2-digit',
    // second: '2-digit'
  });
}

export const isOlderThan10mins = (date: Date) => {
  var now = new Date();
  return ((now.getTime() - date.getTime()) > 5000)
}

export const isOlderThanSeconds = (date: Date, seconds: number) => {
  var now = new Date();
  return ((now.getTime() - date.getTime()) > seconds * 1000)
}

export const isInThePast = (date: Date) => {
  var now = new Date();
  return (date.getTime() < now.getTime())
}
