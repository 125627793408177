import React, { FC } from "react";
import {
  chakra,
  useBreakpointValue,
  useMultiStyleConfig,
  Text,
  Image,
  useDisclosure,
} from "@chakra-ui/react";

export type Props = {
  logo?: string;
  title?: string;
  label?: string;
};

const SliderCard: FC<Props> = ({ logo, title, label }: Props, ...props) => {
  const styles = useMultiStyleConfig("Hall", props);
  const isMobile = useBreakpointValue({ base: true, lg: false });

  return (
    <chakra.div sx={styles.sliderCardContainer}>
      {logo && (
        <Image
          src={logo}
          w="fit-content"
          h={{ base: "70px", lg: "93px" }}
          mx="auto"
        />
      )}
      {title && (
        <Text
          sx={styles.sliderCardTitle}
          as="h4"
          textStyle="h4"
          wordBreak="break-word"
        >
          {title}
        </Text>
      )}
      {label && <Text sx={styles.sliderCardLabel}>{label}</Text>}
    </chakra.div>
  );
};

export default SliderCard;
