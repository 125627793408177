import React, { useEffect, useReducer } from "react";

import { BrowserRouter as Router } from "react-router-dom";
import { MainProvider } from "./context";
import reducer from "./reducer";
import Container from "./Container";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./App.scss";
import theme from "./../src/styles/theme";
import { ChakraProvider } from "@chakra-ui/react";
import axiosHelper from "./helpers/axiosHelper";

function App() {
  const [state] = useReducer(reducer, {
    isLoading: false,
    isLoggedIn: false,
  });

  useEffect(() => {
    const qs = new URLSearchParams(window.location.search);
    if (qs.get("utm_campaign")) {
      const utmData: UtmData = {
        utm_campaign: qs.get("utm_campaign"),
        utm_source: qs.get("utm_source"),
        utm_medium: qs.get("utm_medium"),
        utm_term: qs.get("utm_term"),
        utm_content: qs.get("utm_content"),
      };
      axiosHelper.saveUtmData(utmData);
    }
  }, []);

  return (
    <ChakraProvider resetCSS theme={theme}>
      <MainProvider value={state}>
        <Router>
          <Container />
        </Router>
      </MainProvider>
    </ChakraProvider>
  );
}

export default App;
