import React, { FC, useState, useEffect } from "react";

import {
  chakra,
  useBreakpointValue,
  Container,
  Text,
  Image,
  Flex,
  Button,
  IconButton,
  useDisclosure,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
} from "@chakra-ui/react";
import { useHistory, Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { ChevronDownIcon, CloseIcon, HamburgerIcon } from "@chakra-ui/icons";
import axiosHelper from "./../helpers/axiosHelper";
import LanguageSwitcher from "../components/header/LanguageSwitcher";
import LogInModal from "../components/modal/LogInModal";
import MenuModal from "../components/modal/MenuModal";

import logo from "./../assets/logo/MFlogo RGB187_K_S.png";
import bornToFarmLogo from "./../assets/plenary/header.png";
import bornToFarmMobileLogo from "./../assets/plenary/header-mobile.png";

export type LayoutHeaderProps = {
  reloadUserProfile: () => void;
};

const LayoutHeaderPlenary: FC<LayoutHeaderProps> = (
  { reloadUserProfile }: LayoutHeaderProps,
  ...props
) => {
  const { t } = useTranslation();
  const history = useHistory();
  const [userProfile, setUserProfile] = useState<UserProfile>(
    axiosHelper.getUserProfile()!
  );
  const [usertype, setUsertype] = useState("");
  const [name, setName] = useState("");

  // const styles = useMultiStyleConfig("LayoutHeader", props);

  const isMobile = useBreakpointValue({ base: true, lg: false });
  const {
    isOpen: isOpenMenu,
    onOpen: onOpenMenu,
    onClose: onCloseMenu,
  } = useDisclosure();
  const { isOpen, onOpen, onClose } = useDisclosure();

  const refreshCluster = () => {
    setUserProfile(axiosHelper.getUserProfile()!);
    reloadUserProfile();
  };

  const performLogout = async () => {
    try {
      const result = await axiosHelper.logout();
    } catch (e) {
      console.error(e);
    } finally {
      axiosHelper.clearUserProfile();
      history.push("/login");
    }
  };

  useEffect(() => {
    if (userProfile) {
      switch (userProfile.userType) {
        case "R":
          setUsertype(t("hallhero.title.R"));
          break;
        case "S":
          setUsertype(t("hallhero.title.S"));
          break;
        case "I":
          setUsertype(t("hallhero.title.I"));
          break;
        case "P":
          setUsertype(t("hallhero.title.P"));
          break;
        case "D":
          setUsertype(t("hallhero.title.D"));
          break;
        case "V":
          setUsertype(t("hallhero.title.V"));
          break;
        case "E":
          setUsertype(t("hallhero.title.E"));
          break;
        default:
          setUsertype("");
          break;
      }
      setName(userProfile.firstName);
    }
  }, [t("LNG"), userProfile]);

  return (
    <>
      <chakra.div
        h="121px"
        position="sticky"
        top="0px"
        zIndex="100"
        flexShrink={0}
      >
        <Flex h={{ base: "48px", lg: "52px" }} bgColor="white">
          <Container maxW="container.xl" d="flex" flexDir="row">
            <Flex
              boxShadow="0px 1px 45px rgba(63, 63, 68, 0.35)"
              bgColor="white"
              h={{ base: "70px", lg: "119px" }}
              w={{ base: "100px", lg: "170px" }}
              justifyContent="center"
              alignItems="center"
              onClick={() => {
                history.push("/");
              }}
              cursor="pointer"
              zIndex="1000"
            >
              <Image
                objectFit="cover"
                objectPosition="center"
                bgRepeat="no-repeat"
                overflow="hidden"
                alignItems="center"
                justifyContent="center"
                src={logo}
                h={{ base: "40px", lg: "68px" }}
                w={{ base: "70px", lg: "120px" }}
              />
            </Flex>

            <Flex
              w={{ base: "100%", lg: "100%" }}
              justifyContent={{ base: "flex-end" }}
              alignItems="center"
            >
              <LanguageSwitcher />
            </Flex>
          </Container>
        </Flex>
        <Flex
          h={{ base: "48px", lg: "69px" }}
          bgColor="rgb(141,141,141,0.95)"
          bgImage={isMobile?bornToFarmMobileLogo:bornToFarmLogo}
          bgRepeat="no-repeat"
          bgSize={isMobile?"auto 80%":"auto 100%"}
          bgPosition={isMobile?"right":"center"}
          d="flex"
          flexDir="row"
        >
          <Container
            maxW="container.xl"
            d="flex"
            flexDir="row"
            justifyContent="flex-end"
            alignItems="center"
            color="white"
            fontWeight="bold"
          >
            {isMobile ? (
              <>
                
                {/* <IconButton
                  size={"lg"}
                  icon={isOpen ? <CloseIcon /> : <HamburgerIcon />}
                  aria-label={"Open Menu"}
                  display={{ lg: "none" }}
                  onClick={isOpen ? onClose : onOpenMenu}
                  bgColor="transparent"
                /> */}
              </>
            ) : (
              <>
                {name && usertype ? (
                  <Menu>
                    <MenuButton
                      as={Button}
                      rightIcon={<ChevronDownIcon />}
                      bgColor="transparent"
                      color="white"
                      _active={{
                        bgColor: "transparent",
                        border: "none",
                        color: "white",
                      }}
                      _hover={{ bgColor: "transparent", border: "none" }}
                      _focus={{ bgColor: "transparent", border: "none" }}
                      _selected={{ bgColor: "transparent", border: "none" }}
                      borderRadius="0px"
                      borderColor="transparent"
                    >
                      <Flex flexDir="column" alignItems="flex-end">
                        <Text color="red.500">
                          {t("tutorial.welcome.title")} {name}
                        </Text>
                        {userProfile.userType === "I" ||
                        userProfile.userType === "S" ||
                        userProfile.userType === "R" ? (
                          <>
                            <Text>{usertype.toUpperCase()}</Text>
                          </>
                        ) : userProfile.cluster === "I" ? (
                          t("hallhero.title.I").toUpperCase()
                        ) : userProfile.cluster === "R" ? (
                          t("hallhero.title.R").toUpperCase()
                        ) : userProfile.cluster === "S" ? (
                          t("hallhero.title.S").toUpperCase()
                        ) : (
                          ""
                        )}
                      </Flex>
                    </MenuButton>

                    <MenuList
                      textTransform="uppercase"
                      px="0"
                      borderRadius="0px"
                      mt="5px"
                    >
                      <MenuItem
                        key="logout"
                        textTransform="uppercase"
                        justifyContent="center"
                        color="red.500"
                        fontFamily="Arial"
                        fontWeight="bold"
                        fontSize="14px"
                        onClick={() => {
                          history.push("/logout");
                        }}
                      >
                        {t("header.logout").toUpperCase()}
                      </MenuItem>
                    </MenuList>
                  </Menu>
                ) : (
                  <Text color="red" onClick={onOpen} cursor="pointer">
                    {t("header.login").toUpperCase()}
                  </Text>
                )}
              </>
            )}
          </Container>
        </Flex>
      </chakra.div>
      <MenuModal
        clickOpen={isOpenMenu}
        clickClose={onCloseMenu}
        prizesTabs={false}
        gameTabs={false}
        tutorialTabs={false}
        userTabs={userProfile != null}
        refreshCluster={refreshCluster}
      />
      <LogInModal clickOpen={isOpen} clickClose={onClose} />
    </>
  );
};

const LayoutHeaderProps = {
  home: false,
};

export default LayoutHeaderPlenary;
