import React, { useEffect, useState } from "react";
// import { useHistory } from "react-router-dom";
// import axiosHelper from "../helpers/axiosHelper";
import Constants from "../helpers/constants";

//Media

//Media mobile
import MainLayout from "../layouts/MainLayout";

import Hero from "../components/home/Hero";
import Register from "../components/home/Register";
import Counter from "../components/home/counter/Counter";
import SliderSection from "../components/home/slider/SliderSection";
import PrizesSection from "../components/home/prizesSection/PrizesSection";

const Home = () => {
  // const history = useHistory();
  // const [eventDate, setEventDate] = useState(Date.parse(Constants.EVENT_DATE));

  useEffect(() => {
    // axiosHelper.logWebAppEvent("Home", null, null);
    // console.log(eventDate);
  }, []);

  return (
    <MainLayout reloadUserProfile={()=>{}}>
      <Register />
      <Hero />
      <Counter endDateString={Constants.EVENT_DATE} />
      <SliderSection />
      <PrizesSection />
    </MainLayout>
  );
};

export default Home;
