const Constants = {

  EVENT_DATE: "2021-09-03T12:00:00.000Z",
  ONLINE_DATE: "2021-07-05T15:00:00.000Z",

  //FIXME controllare prima di mettere in produzione

  //LOCALHOST DEVELOPMENT ENV
  //API_ENDPOINT: "http://localhost/cgi-bin/WebObjects/TSApi.woa/-52830/ra",
  //LS_ENDPOINT: "http://localhost:8080",

  //WLF DEVELOPMENT ENV
  // API_ENDPOINT: "http://127.0.0.1/cgi-bin/WebObjects/MF21Api.woa/-52831/ra",
  // LS_ENDPOINT: "http://192.168.2.121:8080",

  // //PRODUCTION ENV
  // API_ENDPOINT: "https://api.teamsystemdigitalevents.com/v1",
  LS_ENDPOINT: "https://mfpush.janet.altera.it",
  LS_ACTIVE: true,
  // //TEST ENV
  // API_ENDPOINT: "https://mfuat.janet.altera.it/cgi-bin/WebObjects/MF21Api.woa/ra",
  API_ENDPOINT: "https://mfapi.janet.altera.it/v1",
  // API_ENDPOINT: "http://192.168.2.121/cgi-bin/WebObjects/MF21Api.woa/-52831/ra",

  // LS_ENDPOINT: "https://pushtest.cchbci-regeneration.it",
  LEADERBOARD_PATH: "https://media.borntofarm.masseyferguson.com/leaderboard/",

  WR_BG_PATH: "https://media.borntofarm.masseyferguson.com/waitingroom/normal/wrbackground",
  VIDEO_THUMB_PATH: "https://media.borntofarm.masseyferguson.com/video/thumbs/",
  PLENARY_BG_PATH: "https://media.borntofarm.masseyferguson.com/plenary/normal/plbackground",


  LS_ADAPTOR: "JNT",

  QUESTIONS_ENDPOINT: "https://media.borntofarm.masseyferguson.com/questions",
  AVATAR_EP: "https://media.teamsystemdigitalevents.com/avatars/",
  STATUS_TOPIC_NAME: "status",

  COUNTDOWN_STATUS_NAME: "countdown",
  LIVE_STATUS_NAME: "live",
  END_STATUS_NAME: "end",

  QUESTION_TOPIC_NAME: "question",
  VIDEO_TOPIC_NAME: "video",

  FOLLOWUP_ON_EP: "https://media.teamsystemdigitalevents.com/loghi_on/",
  FOLLOWUP_OFF_EP: "https://media.teamsystemdigitalevents.com/loghi_off/",

  INFORMATIVA_PRIVACY_LINK: "https://www.teamsystem.com/privacy-policy",
  CONDIZIONI_UTILIZZO_LINK: "https://www.teamsystem.com/privacy-policy",
  LIBERATORIA_LINK: "https://www.teamsystem.com/privacy-policy",
  GUIDA_TAGLIE_LINK: "https://www.teamsystem.com/privacy-policy",
  GUIDA_VIDEO_LINK: "https://www.teamsystem.com/privacy-policy",

  INFODESK_PLANARY_IMG1: "https://media.cchbci-regeneration.it/images/INFODESK-plenary-01.jpg",
  INFODESK_PLANARY_IMG2: "https://media.cchbci-regeneration.it/images/INFODESK-plenary-02.jpg",
  INFODESK_PLANARY_IMG3: "https://media.cchbci-regeneration.it/images/INFODESK-plenary-03.jpg",

  REDFLIX_VIDEOS_PATH: "https://media.cchbci-regeneration.it/hbcflix/videos/",
  REDFLIX_DOCS_PATH: "https://media.cchbci-regeneration.it/hbcflix/documents/",
  REDFLIX_THUMBS_PATH: "https://media.cchbci-regeneration.it/hbcflix/thumbs/",
};

export default Constants;
