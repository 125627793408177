import React from "react";
import ReactDom from "react-dom";
import videojs from "video.js";
import "../lib/video-js.css";
import { getSubscription, removeSubscription } from "../helpers/lsHelper";
import Constants from "../helpers/constants";
import { isIOS } from "react-device-detect";
import { chakra, Flex } from "@chakra-ui/react";

class VideoPlayer extends React.Component {
  urlDelBottone;
  seconds;
  languageOptions;
  hasPlayed

  constructor(props) {
    super(props);
    this.seconds = props.seconds;
    this.hasPlayed = false;
    this.languageOptions = [
      { srclang: "da", label: "DANSK" },
      { srclang: "de", label: "DEUTSCH" },
      { srclang: "en", label: "ENGLISH" },
      { srclang: "es", label: "ESPAÑOL" },
      { srclang: "fr", label: "FRANCAIS" },
      { srclang: "it", label: "ITALIANO" },
      { srclang: "nl", label: "NEDERLANDS" },
      { srclang: "no", label: "NORSK" },
      { srclang: "pl", label: "POLSKI" },
      { srclang: "pt", label: "PORTUGUÊS" },
      { srclang: "pt-BR", label: "Brazileiro" },
      { srclang: "ru", label: "РУССКИЙ" },
      { srclang: "sv", label: "SVENSKA" },
      { srclang: "tr", label: "TÜRKÇE" },
      { srclang: "uk", label: "УКРАЇНСЬКИЙ" },
    ];

    // console.log(this.seconds);
  }

  callback = () => {
    // console.log('SONO la callback');
    // console.log('CALLBACK');
    // console.log(this.props);
    this.props.callback();
  };
  componentDidUpdate(previousProps) {
    if (previousProps.src !== this.props.src) {
      // console.log(this.player);
      this.player.src({
        src: `${this.props.src}`,
        type: `${this.props.mime}`,
        // type: "application/x-mpegURL",
      });
      this.player.pause();
      this.play();
    }
    if (previousProps.language !== this.props.language) {
      this.handleSubtitles();
    }
    // if (previousProps.mute !== this.props.mute){
    //   console.log('TOGGLING AUDIO TO ALLOW USER INTERACTION');
    //   this.player.muted(this.props.mute);
    // }
  }

  componentDidMount() {
    const videoJsOptions = {
      autoplay: "auto",
      controls: true,
      controlBar: {
        pictureInPictureToggle: false,
        subsCapsButton: false,
        progressControl: false,
        remainingTimeDisplay: false,
      },
      sources: [
        {
          src: this.props.src,
          type: this.props.mime,
        },
      ],
    };
    var seconds = this.props.seconds;
    console.log(seconds);
    console.log("start player");
    const to = this.props.src.lastIndexOf('.');
    const filename = this.props.src.substring(0, to);

    const languages = this.languageOptions
    const thisLang = this.props.language
    this.player = videojs(
      this.videoNode,
      videoJsOptions,
      function onPlayerReady(player) {
        languages.forEach(language => {
          const mode = (language.srclang === thisLang ? 'showing' : 'disabled')
          this.addRemoteTextTrack({ kind: 'subtitles', srclang: language.srclang, label: language.label, src: `${filename}-${language.srclang}.vtt`, mode: mode }, false);
        });
        //this.play();
        // if (seconds != null) {
        //   this.currentTime(seconds);
        // }
      }
    );
    // this.lsSubscription = getSubscription(
    //   Constants.QUESTION_TOPIC_NAME,
    //   (uuid) => {
    //     if (uuid != null && uuid.length > 4) {
    //       this.openModal(uuid);
    //     } else {
    //       this.closeModal();
    //     }
    //   }
    // );
    const onTimeUpdate = this.props.onTimeUpdate
    if (onTimeUpdate != null) {
      // this.player.on("timeupdate", () => { if (!this.player.paused){ onTimeUpdate(this.player.currentTime()) } else {console.log('PAUSED')}});
      this.player.on("timeupdate", () => { onTimeUpdate(this.player.currentTime()) });
    }
    console.log(this.player);
    this.player.on("play", () => {
      console.log('PLAY CALLBACK ' + this.hasPlayed);
      if (!this.hasPlayed && this.props.seconds != null) {
        console.log('SETTING THE SECONDS');
        this.player.currentTime(this.props.seconds);
      }
      this.hasPlayed = true;
      this.handleSubtitles();
    })
    // this.player.on("pause", () => {
    //   this.play();
    // });

    // () => {
    //   // if (this.questionsArray.length > 0) {
    //   //   const item = this.questionsArray[0];
    //   //   if (this.player.currentTime() > item.time) {
    //   //     console.log("CHIUDIAMO EVENTO, NIENTE DOMANDA");
    //   //     this.callback();

    //   //     //FIXME FATTO PER TEAMSYSTEM
    //   //     // this.openModal(item.uuid);
    //   //     // this.questionsArray.shift();
    //   //   }
    //   // }
    // });

    // const ModalDialog = videojs.getComponent("ModalDialog");

    //Con questo ci assicuriamo che carichi il contenuto anche la prima volta che apre la modal
    // var contentEl = document.createElement("div");

    //Lo aggiungiamo sotto in "content"
    // var modal = new ModalDialog(this.player, {
    //   content: contentEl,
    //   temporary: false,
    //   pauseOnOpen: false,
    //   closeable: false,
    //   description: "Poll",
    //   label: "test",
    //   //fillAlways: true,
    // });

    // this.modal = this.player.addChild(modal);
    this.handleSubtitles();
  }

  handleSubtitles() {
    var tracks = this.player.textTracks();
    for (var i = 0; i < tracks.length; i++) {
      var track = tracks[i];
      // Find the English captions track and mark it as "showing".
      if (track.language === this.props.language) {
        track.mode = 'showing';
      } else {
        track.mode = 'disabled'
      }
    }

  }
  // destroy player on unmount
  componentWillUnmount() {
    if (this.player) {
      this.player.dispose();
    }
    if (this.lsSubscription) {
      removeSubscription(this.lsSubscription);
    }
  }

  play() {
    if (this.player) {
      var promise = this.player.play();

      if (promise !== undefined) {
        promise
          .then(function () {
            // Autoplay started!
            console.log("AUTOPLAY STARTED");
          })
          .catch(function (error) {
            // Autoplay was prevented.
            console.log("AUTOPLAY PREVENTED");
            console.log(error);
          });
      } else {
        console.log("PROMISE FAILED");
      }
    } else {
      console.log("tpnx");
    }
  }

  // wrap the player in a div with a `data-vjs-player` attribute
  // so videojs won't create additional wrapper in the DOM
  // see https://github.com/videojs/video.js/pull/3856

  render() {
    return (
      <video
        playsInline="playsinline"
        ref={(node) => (this.videoNode = node)}
        className="video-js vjs-16-9"
        poster={"/poster.jpg"}
      ></video>
    );
  }
}

export default VideoPlayer;
