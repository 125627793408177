import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import daIcon from "./../../assets/flags/da.png";
import deIcon from "./../../assets/flags/de.png";
import enIcon from "./../../assets/flags/en.png";
import esIcon from "./../../assets/flags/es.png";
import frIcon from "./../../assets/flags/fr.png";
import itIcon from "./../../assets/flags/it.png";
import nlIcon from "./../../assets/flags/nl.png";
import noIcon from "./../../assets/flags/no.png";
import plIcon from "./../../assets/flags/pl.png";
import ptIcon from "./../../assets/flags/pt.png";
import ptBRIcon from "./../../assets/flags/pt-BR.png";
import ruIcon from "./../../assets/flags/ru.png";
import svIcon from "./../../assets/flags/sv.png";
import trIcon from "./../../assets/flags/tr.png";
import ukIcon from "./../../assets/flags/uk.png";
import {
  Button,
  Flex,
  Image,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import downIcon from "./../../assets/Tracciato 137.svg";
import axiosHelper from "../../helpers/axiosHelper";

const LanguageSwitcher = () => {
  const { t, i18n } = useTranslation();
  const handleLanguageChange = (selected: any) => {
    let sendToServer = false;
    if (selected != selectedLanguage) {
      sendToServer = true;
    }
    // console.log(selected);
    // console.log(selectedLanguage);
    i18n.changeLanguage(selected.value);
    setSelectedLanguage(selected);
    if (sendToServer){
      axiosHelper.changeLanguage(selected.value);
    }
  };

  const languageOptions = [
    { value: "da", label: "DANSK", icon: daIcon },
    { value: "de", label: "DEUTSCH", icon: deIcon },
    { value: "en", label: "ENGLISH", icon: enIcon },
    { value: "es", label: "ESPAÑOL", icon: esIcon },
    { value: "fr", label: "FRANCAIS", icon: frIcon },
    { value: "it", label: "ITALIANO", icon: itIcon },
    { value: "nl", label: "NEDERLANDS", icon: nlIcon },
    { value: "no", label: "NORSK", icon: noIcon },
    { value: "pl", label: "POLSKI", icon: plIcon },
    { value: "pt", label: "PORTUGUÊS", icon: ptIcon },
    { value: "pt-BR", label: "PORTUGUÊS", icon: ptBRIcon },
    { value: "ru", label: "РУССКИЙ", icon: ruIcon },
    { value: "sv", label: "SVENSKA", icon: svIcon },
    { value: "tr", label: "TÜRKÇE", icon: trIcon },
    { value: "uk", label: "УКРАЇНСЬКИЙ", icon: ukIcon },
  ];

  const [selectedLanguage, setSelectedLanguage] = useState(
    languageOptions.find((item) => item.value === t("LNG")) ||
    languageOptions[2]
  );

  // let classNameDrop = props.home
  //     ? "language-selector-wrapper home"
  //     : "language-selector-wrapper";
  let classNameDrop = "language-selector-wrapper";
  //FIXME wolf put the flags, they're in public...

  return (
    <Menu>
      {({ onClose }) => (
        <>
          <MenuButton
            as={Button}
            bgColor="white"
            _focus={{ bgColor: "white" }}
            rightIcon={<Image src={downIcon} w="20px" h="10px" />}
          >
            <Flex>
              <Image src={selectedLanguage.icon} w="20px" h="14px" />
              <Text mx="10px" fontSize="14px">
                {selectedLanguage.label}
              </Text>
            </Flex>
          </MenuButton>
          <MenuList>
            {languageOptions.map((item: any, index: any) => (
              <MenuItem
                value={item}
                key={`${index}-key-FiltrDrawer-catagoryItem`}
                onClick={() => {
                  handleLanguageChange(item);
                  onClose();
                }}
                style={{
                  color: "#707070",
                  backgroundColor: "white",
                }}
              >
                <Image src={item.icon} w="25px" h="20px" mr="20px" />
                <span>{item.label}</span>
              </MenuItem>
            ))}
          </MenuList>
        </>
      )}
    </Menu>
  );
};

export default LanguageSwitcher;
