import React, { FC } from "react";
import {
  chakra,
  useBreakpointValue,
  useMultiStyleConfig,
  Text,
  Image,
  useDisclosure,
} from "@chakra-ui/react";

export type Props = {
  title?: string;
  subtitle?: string;
  label?: string;
  promotext?: string;
  image?: any;
};

const PrizeItem: FC<Props> = (
  { subtitle, title, label, promotext, image }: Props,
  ...props
) => {
  const styles = useMultiStyleConfig("PrizePage", props);

  return (
    <chakra.div paddingBottom="10px" paddingRight="20px" paddingTop="10px">
      <Image src={image} sx={styles.prizesPageItemImage} />
      {title && <Text sx={styles.prizesPageCardTitle}>{title}</Text>}
      {subtitle && <Text sx={styles.prizesPageCardSubtitle}>{subtitle}</Text>}
      {label && <Text sx={styles.prizesPageCardLabel}>{label}</Text>}
      {promotext && <Text sx={styles.prizesPageCardLabel}>{promotext}</Text>}
    </chakra.div>
  );
};

export default PrizeItem;
