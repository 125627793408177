import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";
import { useHistory } from "react-router-dom";
import axiosHelper from "../helpers/axiosHelper";
import Header from "../components/Header";
import { useTranslation } from "react-i18next";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";

// Media

import {
  chakra,
  Checkbox,
  Container,
  Flex,
  Input,
  InputGroup,
  InputRightElement,
  Text,
  useMultiStyleConfig,
} from "@chakra-ui/react";
import background from "../assets/home/counter/shutterstock_1117734563/shutterstock_1117734563@2x.png";
import MainLayoutLogin from "../layouts/MainLayoutLogin";

const eye = <FontAwesomeIcon icon={faEye} />;
const eyeSlash = <FontAwesomeIcon icon={faEyeSlash} />;

interface FormData {
  password: string;
  confirmpassword: string;
  rememberme: boolean;
}
interface UserData {
  id: number;
  objecttype: string;
  email: string;
  firstName: string;
  language: string;
  lastName: string;
  userType: string;
}

const Activate = ({ match, ...props }: any) => {
  const { t } = useTranslation();

  const history = useHistory();
  const [isExpired, setIsExpired] = useState(false);
  const [isReset] = useState(history.location.pathname.startsWith("/reset"));
  const [token, setToken] = useState<string>("");
  const [data, setData] = useState<UserData>();

  const [error, setError] = useState<boolean>(false);
  const [loginErrorMessage, setLoginErrorMessage] = useState<string>();
  const [modalVisible, setModalVisible] = useState<boolean>(false);
  const [activated, setActivated] = useState<boolean>(false);
  const { handleSubmit, register, errors, watch } = useForm<FormData>();
  const [passwordShown, setPasswordShown] = useState(false);

  const styles = useMultiStyleConfig("Login", props);

  const togglePasswordVisiblity = () => {
    setPasswordShown(passwordShown ? false : true);
  };

  const savePassword = async (password: string, rememberme: boolean) => {
    // console.log(data);
    if (data?.id !== undefined) {
      try {
        const result = await axiosHelper.savePassword<UserProfile>(
          data!.id,
          password,
          token
        );

        if (result.data.jntToken !== undefined) {
          axiosHelper.setJntToken(result.data.jntToken, rememberme);
          axiosHelper.saveUserProfile(result.data);

          // if (result.data.firstLogin) {
          //     history.push("/tutorial");
          // } else {
          history.push("/");
          // }
        }
      } catch (error) {
        //TODO gestire il caso...
      }
    }
  };

  const onSubmit = (values: FormData) => {
    // console.log(values);
    if (values.password === values.confirmpassword) {
      savePassword(values.password, values.rememberme);
    }
  };

  useEffect(() => {
    setToken(match.params.token);
    const fetchData = async () => {
      // console.log(token);
      if (token !== "") {
        try {
          if (isReset) {
            const result = await axiosHelper.reset(token);
            setData(result.data);
          } else {
            const result = await axiosHelper.activate(token);
            setData(result.data);
          }
        } catch (error) {
          if (error.response?.status === 401) {
            history.push("/");
          }
          if (error.response?.status === 410) {
            setIsExpired(true);
          }
          //setError(error);
        }
      }
    };
    fetchData();
    window.scrollTo(0, 0);
  }, [match.params.token, token, history, isReset]);

  return (
    <>
      <MainLayoutLogin home>
        <Container maxW="container.xl" d="flex" flexDir="column">
          {isReset ? (
            <Header
              title=""
              subtitle1={t("activate.title")}
              logo
              date="test"
              mainLayoutLogin
            />
          ) : (
            <Header
              title={t("header.welcome")}
              subtitle1={t("activate.title")}
              logo
              date="test"
              mainLayoutLogin
            />
          )}
        </Container>
        <Container alignContent="center">
          <Flex
            flexDir="column"
            w={{ lg: "100%" }}
            m="0 auto"
            align="center"
            pt="100px"
            pb="100px"
          >
            <Text as="h1" textStyle="h1" textAlign="center">
              {t("activate.subtitle")}
            </Text>

            <form onSubmit={handleSubmit(onSubmit)}>
              <input
                autoComplete="username"
                type="hidden"
                name="email"
                value={data?.email}
                style={{
                  height: "50px",
                }}
              ></input>

              <Flex flexDir="column">
                <Flex flexDir="column" my="30px">
                  <InputGroup>
                    <Input
                      w="100%"
                      autoComplete="new-password"
                      type={passwordShown ? "text" : "password"}
                      placeholder={t("activate.passwordph")}
                      name="password"
                      ref={register({
                        required: t("err.required") as string,
                        pattern: {
                          // value: /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z]).{8,}$/gm,
                          value:
                            /^(?=.*[a-z])(?=.*[A-Z])(?=.*[|\-_\\0123456789!@#&()\[{}\]:;',?/*~$^+=<>]).{8,}$/gm,
                          message: t("err.passtooweak") as string,
                        },
                      })}
                      h="50px"
                      borderRadius="0px"
                      bgColor="white"
                    />
                    <InputRightElement h="100%" mr="10px">
                      <i onClick={togglePasswordVisiblity}>
                        {!passwordShown ? eyeSlash : eye}
                      </i>
                    </InputRightElement>
                  </InputGroup>
                  <ErrorMessage
                    errors={errors}
                    as="div"
                    name="password"
                    className="error-message"
                  />
                </Flex>
                <Flex flexDir="column" w="100%">
                  <InputGroup>
                    <Input
                      ref={register({
                        required: t("err.required") as string,
                        validate: (value) =>
                          value === watch("password") ||
                          (t("err.mismatch") as string),
                      })}
                      type={passwordShown ? "text" : "password"}
                      autoComplete="new-password"
                      placeholder={t("activate.confirmpasswordph")}
                      name="confirmpassword"
                      h="50px"
                      borderRadius="0px"
                      bgColor="white"
                    />
                    <InputRightElement h="100%" mr="10px">
                      <i onClick={togglePasswordVisiblity}>
                        {!passwordShown ? eyeSlash : eye}
                      </i>
                    </InputRightElement>
                  </InputGroup>

                  <ErrorMessage
                    errors={errors}
                    as="div"
                    name="confirmpassword"
                    className="error-message"
                  />
                </Flex>
                <Text sx={styles.label}>{t("activate.passwordrules")}</Text>
              </Flex>

              <Flex justifyContent="space-between" mt="30px">
                <Flex>
                  <Checkbox
                    colorScheme="red"
                    type="checkbox"
                    placeholder="Ricordami"
                    name="rememberme"
                    ref={register}
                    fontFamily="Arial"
                    fontWeight="normal"
                    fontSize={{ base: "16px", lg: "24px" }}
                    color="white"
                  >
                    {t("login.remember")}
                  </Checkbox>
                </Flex>

                <button
                  className="button--submit"
                  style={{
                    backgroundColor: "red",
                    paddingTop: "10px",
                    paddingBottom: "10px",
                    paddingLeft: "30px",
                    paddingRight: "30px",
                    border: "none",
                    color: "white",
                    width: "35%",
                  }}
                >
                  {t("activate.okbtn")}
                </button>
              </Flex>

              {error ? (
                <div className="modal-text error-message">
                  {loginErrorMessage}
                </div>
              ) : null}
            </form>
          </Flex>
        </Container>
      </MainLayoutLogin>
    </>
  );
};

export default Activate;
