import React, { FC, useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import axiosHelper from "../../helpers/axiosHelper";
import { Link, useHistory } from "react-router-dom";

import bgImage from "../../assets/plenary/fondo-thankyou.png";
import newLogo from "../../assets/plenary/logo-thankyou.png";
import partnerImage from "../../assets/plenary/trelleborg.png";

import {
  Button,
  Flex,
  Text,
  Image,
  useBreakpointValue,
} from "@chakra-ui/react";
import { stat } from "fs";

type Props = {
  status: EventStatus;
  continueChatting: () => void;
  okCallback: () => void;
  platformCallback: () => void;
};

const PlenaryThankYou = (props: Props) => {
  const { status, continueChatting, okCallback, platformCallback } = props;
  const [userProfile, setUserProfile] = useState<UserProfile>(axiosHelper.getUserProfile()!);
  const history = useHistory();

  const { t } = useTranslation();
  const isMobile = useBreakpointValue({ base: true, lg: false });

  const redirectToPlatform = () => {
    window.location.replace(t('thankyou.redirecturl'));
  }

  useEffect(() => {
    let interval: any = null;
    // if ('RSI'.includes(userProfile.userType) && status.extra2.includes('3')) {
    if (status.extra2.includes('3')) {
      interval = setTimeout(() => {
        redirectToPlatform();
      }, 30 * 1000);
    }
    if (status.extra2.includes('5')) {
      interval = setTimeout(() => {
        redirectToPlatform();
      }, 30 * 1000);
    }
    if ('PV'.includes(userProfile.userType) && status.extra2.includes('2')) {
      const now = new Date();
      const endDate = new Date(status.endTime);
      setTimeout(() => { okCallback() }, endDate.getTime() - now.getTime());
    }
    return () => {
      if (interval != null) {
        clearTimeout(interval);
      }
    }
  }, [])

  return (
    <>
      <Flex
        maxW="container.xl"
        d="flex"
        flexFlow="column nowrap"
        justifyContent="space-around"
        alignItems="center"
        m="0"
        color="#fff"
        w="100%"
      >
        <Flex
          d="flex"
          flexFlow="column nowrap"
          justifyContent="space-around"
          alignItems="center"
          pb="4rem"
          w="100%"
          bgImage={bgImage}
          style={{
            backgroundPosition: "top",
            backgroundSize: "auto",
            backgroundRepeat: "no-repeat",
          }}

        >
          <Image src={newLogo} alt="Massey Ferguson" py="3rem" maxW={isMobile ? "150px" : "230px"} />
          <Text
            fontSize={isMobile ? "10px" : "12px"}
            textTransform="uppercase"
            textAlign="center"
          >
            {t("thankyou.partnership")}
          </Text>
          <Image src={partnerImage} alt="Trelleborg" mb="50px" maxW={isMobile ? "120px" : "170px"} />
          <Text
            fontFamily="Arial Black, Arial-BoldMT, Arial"
            fontSize={isMobile ? "30px" : "40px"}
            textTransform="uppercase"
            textAlign="center"
          >
            {t("thankyou.pagetitle")}
          </Text>
          {/******************** CASISTICHE TODO WOLF *******************/}
          {/* 2 SETTEMBRE: FINE EVENTO DEALER */}
          {status.extra2.includes('2') ?
            <>
              {userProfile.userType === 'D' ?
                <>
                  <Text
                    fontWeight="bold"
                    fontSize={isMobile ? "20px" : "30px"}
                    textTransform="uppercase"
                    p=".3rem 0"
                    textAlign="center"
                  >
                    {t("thankyou.2sept.line1")}
                  </Text>
                  <Text fontSize={isMobile ? "15px" : "21px"} p=".7rem 2rem" textAlign="center">
                    {t("thankyou.2sept.line2")} <br />
                    {t("thankyou.2sept.line3")}
                  </Text>
                  <Button className="question-button-select" w="unset!important" onClick={okCallback}>{t("thankyou.go")}</Button>
                </>
                :
                null}
              {/* 2 SETTEMBRE: FINE STREAMING PRESS >> PRIMA DI PASSARE A Q&A */}
              {'VP'.includes(userProfile.userType) ?
                <>
                  <Text
                    fontWeight="bold"
                    fontSize={isMobile ? "20px" : "30px"}
                    textTransform="uppercase"
                    p=".3rem 2rem"
                    textAlign="center"
                  >
                    {t("thankyou.2sept.pleasewait")}
                  </Text>
                </>
                :
                null}
            </>
            :
            null}
          {/* 2 SETTEMBRE: FINE EVENTO PRESS */}
          {status.extra2.includes('4') ?
            <>
              <Text
                fontWeight="bold"
                fontSize={isMobile ? "20px" : "30px"}
                textTransform="uppercase"
                p=".3rem 0"
                textAlign="center"
              >
                {t("thankyou.2sept.qa.line1")}
              </Text>
              <Text fontSize={isMobile ? "15px" : "21px"} p=".7rem 0" textAlign="center">
                {t("thankyou.2sept.qa.line2")} <br />
                {t("thankyou.2sept.qa.line3")}
              </Text>
              <Button className="question-button-select" w="unset!important" onClick={okCallback}>{t("thankyou.go")}</Button>

            </>
            :
            null}
          {/* 3 SETTEMBRE: FINE EVENTO CUSTOMER */}
          {status.extra2.includes('3') ?
            <>
              {'RSI'.includes(userProfile.userType) ?
                <>
                  <Text
                    fontWeight="bold"
                    fontSize={isMobile ? "20px" : "30px"}
                    textTransform="uppercase"
                    p=".3rem 0"
                    textAlign="center"
                  >
                    {t("thankyou.3sept.line1")}
                  </Text>
                  <Text fontSize={isMobile ? "15px" : "21px"} p=".7rem 0" textAlign="center">
                    {t("thankyou.3sept.line2")} <br />
                    {t("thankyou.3sept.line3")}
                  </Text>
                  <Button className="question-button-select" w="unset!important" onClick={continueChatting}>{t("thankyou.3sept.continuechatting")}</Button>
                </>
                :
                <>
                  <Text
                    fontWeight="bold"
                    fontSize={isMobile ? "20px" : "30px"}
                    textTransform="uppercase"
                    p=".3rem 0"
                    textAlign="center"
                  >
                    {t("thankyou.3sept.line1")}
                  </Text>
                  <Text fontSize={isMobile ? "15px" : "21px"} p=".7rem 0" textAlign="center">
                    {t("thankyou.3sept.line2bis")} <br />
                    {t("thankyou.3sept.line4")}
                  </Text>
                  <Button className="question-button-select" w="unset!important" onClick={redirectToPlatform}>{t("thankyou.go")}</Button>
                </>
              }
            </>
            : null}
          {status.extra2.includes('5') ?
            <>
              <Text
                fontWeight="bold"
                fontSize={isMobile ? "20px" : "30px"}
                textTransform="uppercase"
                p=".3rem 0"
                textAlign="center"
              >
                {/* {t("thankyou.3sept.line1")} */}
              </Text>
              <Text fontSize={isMobile ? "15px" : "21px"} p=".7rem 0" textAlign="center">
                {t("thankyou.3sept.line2bis")} <br />
                {t("thankyou.3sept.line4")}
              </Text>
              <Button className="question-button-select" w="unset!important" onClick={redirectToPlatform}>{t("thankyou.go")}</Button>
            </>
            : null}

        </Flex>
      </Flex>
    </>
  );
};

export default PlenaryThankYou;
