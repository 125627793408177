import { extendTheme } from "@chakra-ui/react";
// import { mode } from "@chakra-ui/theme-tools";
import Login from "./Login";
import Hall from "./Hall";
import PrizePage from "./PrizePage";

const Container = {
  baseStyle: {
    px: "2rem",
  },
};

const theme = extendTheme({
  components: {
    Container,
    Login,
    Hall,
    PrizePage,
  },
  textStyles: {
    h1: {
      fontFamily: "Arial",
      fontWeight: "bold",
      fontSize: { base: "24px", lg: "40px" },
      // lineHeight: "24px",
      letterSpacing: "0px",
      color: "white",
      textTransform: "uppercase",
    },
    h2: {
      fontFamily: "Arial",
      fontWeight: "bold",
      fontSize: { base: "24px", lg: "30px" },
      // lineHeight: "24px",
      letterSpacing: "0px",
      color: "#C41230",
      textTransform: "uppercase",
    },
    h3: {
      fontFamily: "Arial",
      fontWeight: "bold",
      fontSize: { base: "24px", lg: "30px" },
      // lineHeight: "35px",
      letterSpacing: "0px",
      color: "white",
      textTransform: "uppercase",
    },
    h4: {
      fontFamily: "Arial",
      fontWeight: "bold",
      fontSize: { base: "22px", lg: "25px" },
      // lineHeight: "24px",
      letterSpacing: "0px",
      textTransform: "uppercase",
      color: "red.500",
    },
    h5: {
      fontFamily: "Arial Black, Arial-BoldMT, Arial",
      fontWeight: "regular",
      fontSize: { base: "24px", lg: "30px" },
      // lineHeight: "24px",
      letterSpacing: "0px",
      color: "white",
    },
  },

  config: {
    // initialColorMode: "light",
    // useSystemColorMode: false,
  },
  colors: {
    greyLight: {
      500: "#0000007F",
      600: "#C8C8C8",
      700: "#00000029",
    },
    greyDark: {
      500: "#707070",
    },
    red: {
      500: "#C41230",
    },
  },
  shadows: {},
  borders: {},
});

export default theme;
