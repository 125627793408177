import React, { FC } from "react";

import LayoutHeader from "./LayoutHeader";
import Footer from "./Footer";
import { chakra } from "@chakra-ui/react";

type Props = {
  home?: boolean;
  coppetta?:number;
  reloadUserProfile: () => void
  // children?: React.ReactChild | React.ReactChild[];
};

const MainLayout: FC<Props> = ({ home, children, coppetta, reloadUserProfile }) => {
  return (
    <chakra.div d="flex" flexDir="column" bgColor="#000000" minH="100vh">
      <LayoutHeader home={home} reloadUserProfile={reloadUserProfile} coppetta={coppetta} />
      <chakra.div flex="1 0 auto">{children}</chakra.div>
      <Footer />
    </chakra.div>
  );
};

const Props = {
  home: false,
};

export default MainLayout;
