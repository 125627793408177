import React, { FC } from "react";

import LayoutHeaderPlenary from "./LayoutHeaderPlenary";
import Footer from "./Footer";
import { chakra } from "@chakra-ui/react";

type Props = {
  reloadUserProfile: () => void;
  // children?: React.ReactChild | React.ReactChild[];
};

const PlenaryLayout: FC<Props> = ({ children, reloadUserProfile }) => {
  return (
    <chakra.div d="flex" flexDir="column" bgColor="#000000" minH="100vh">
      <LayoutHeaderPlenary reloadUserProfile={reloadUserProfile} />
      <chakra.div
        flex="1 0 auto"
        d="flex"
        flexDir="column"
        alignItems="stretch"
      >
        {children}
      </chakra.div>
      <Footer />
    </chakra.div>
  );
};

const Props = {
  home: false,
};

export default PlenaryLayout;
