import React, { useState, useRef } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";

import {
  chakra,
  useBreakpointValue,
  useMultiStyleConfig,
  Container,
  Text,
  Link,
  Flex,
  Divider,
  ListItem,
  UnorderedList,
  SimpleGrid,
  Box,
} from "@chakra-ui/react";

import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import axiosHelper from "../../helpers/axiosHelper";
import bgImage from "./../../assets/prizes/prizes-page-bg@2x.jpg";

import { ChevronLeftIcon, ChevronRightIcon } from "@chakra-ui/icons";

const TermsAndConditionsContentTR = ({ ...props }) => {
  const { t } = useTranslation();
  const history = useHistory();
  const [userProfile, setUserProfile] = useState<UserProfile>(
    axiosHelper.getUserProfile()!
  );
  const styles = useMultiStyleConfig("PrizePage", props);

  const isMobile = useBreakpointValue({ base: true, lg: false });
  const customSlider = useRef<any>();

  return (
    <Container
      maxW="container.xl"
      bgImage={bgImage}
      d="flex"
      flexDir="column"
      flexDirection="column"
      style={{
        overflow: "hidden",
        backgroundPosition: "center",
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
      }}
      alignItems="center"
    >
      <Flex
        flexDir={{ base: "column", lg: "column" }}
        justifyContent="space-between"
        w={isMobile?"100%":"50%"}
        mt="2%"
      >
        <Text color="white" mb="2%" mt={isMobile ? "30px" : "0"} align="center">
          ŞARTLAR VE KOŞULLAR
          <br />
          MF SÜPER YARIŞMA
          <br />
          2 Temmuz 2021 ve 3 Eylül 2021 tarihleri arasında düzenlenmektedir
          <br />
          MASSEY FERGUSON AGCO Markası tarafından
          <br />
        </Text>
      </Flex>
      <chakra.div w="100%" pb="100px">
        <Flex
          flexDir={{ base: "column", lg: "column" }}
          justifyContent="space-between"
          w="100%"
          mt="3%"
        >
          <Text color="red.500" mt="1%" fontWeight="bold">
            Madde 1 - Genel Organizasyon
          </Text>
          <Text color="white" mt="1%">
            Genel merkezi 41 Blaise Pascal Avenue, Beauvais adresinde yer alan,
            AGCO SAS’ın MASSEY FERGUSON EME (Avrupa ve Orta Doğu) Pazarlama
            Departmanı, 3 Eylül tarihinde, ücretsiz ve hiçbir yükümlülüğü
            olmayan, genel halka yönelik bir etkinlik olan YENİ MASSEY FERGUSON
            DİJİTAL ETKİNLİĞİNİ düzenlemektedir.
          </Text>

          <Text color="red.500" fontWeight="bold" mt="1%">
            Madde 2 - Katılım uygunluğu
          </Text>

          <Text color="white" mt="1%">
            Sadece Avrupa ve Orta Doğu ülkelerinden (ticaret kısıtlaması
            tedbirlerine tabi olan ülkeler hariç) katılımcılar katılım hakkına
            sahiptir. Sadece çiftçiler, tarımsal müteahhitler ve tarım
            işletmeleriyle ilişkili kişiler bu yarışmada ödül kazanabilir.
          </Text>

          <Text color="white" mt="1%">
            Şu kişiler yarışmaya katılamaz:
          </Text>

          <chakra.div>
            <UnorderedList ml="40px" color="white">
              <ListItem>18 yaşından küçük kişiler.</ListItem>
              <ListItem>AAGCO Grubu çalışanları ve onların aileleri</ListItem>
              <ListItem>AGCO ürünlerinin ve hizmetlerinin bayileri</ListItem>
              <ListItem>AGCO’ya bağlı şirketlerin çalışanları</ListItem>
              <ListItem>
                AGCO tarafından hizmet alınan Acenteler ve Tedarikçiler ve
                ilgili çalışanların akrabaları
              </ListItem>
              <ListItem>
                MASSEY FERGUSON tarafından etkinliğe katılmaya davet edilen
                gazeteciler
              </ListItem>
            </UnorderedList>
          </chakra.div>
          <Text color="white" mt="1%">
            Her bir kişi, hane ve tarım tüzel kişiliği, aynı soyad, aynı ad,
            aynı telefon numarası ve aynı e-posta adresi için sadece tek bir
            katılıma izin verilir. Yarışmaya katılım tamamen kişiseldir.
            Düzenleyici Şirket, uygun gördüğü tüm kontrolleri gerçekleştirme
            hakkını saklı tutar.
          </Text>
          <Text color="red.500" fontWeight="bold" mt="1%">
            Madde 3 – Katılım koşulları
          </Text>
          <Text color="white" mt="1%">
            Yarışma, düzenlenen etkinliğin odak noktası olduğundan dolayı,
            katılımcı AGCO SAS organizasyon bölümünün web platformu üzerinden
            YENİ MASSEY FERGUSONDİJİTAL ETKİNLİĞİ'ne kaydolduğunda yarışmaya da
            otomatik olarak katılmış olur. Katılımcı, AGCO SAS (Fransa)
            tarafından e-posta, telefon ve sosyal medya aracılığıyla iletişim
            kurulması için kişisel bilgilerine erişim sağlanmasına onay verir.
          </Text>
          <Text color="red.500" fontWeight="bold" mt="1%">
            Madde 4 – Kazananların belirlenmesi
          </Text>
          <Text color="white" mt="1%">
            Yarışma sonucu 6 Eylül 2021 ve 6 Ekim 2021 tarihleri arasında AGCO
            SAS ofislerinde açıklanacaktır. - Massey Ferguson Pazarlama
            Departmanı EME, Beauvais – Fransa.
          </Text>
          <Box display="block" mt="1%"></Box>
          <Text color="white">
            Çekiliş 6 Eylül 2021 ve 6 Ekim 2021 tarihleri arasında AGCO SAS
            (Fransa) ofislerinde gerçekleştirilecektir. - Massey Ferguson
            Pazarlama Departmanı EME, Beauvais – Fransa. Beauvais'deki Massey
            Ferguson Pazarlama Departmanı EME bu çekilişi kapalı kapılar ardında
            gerçekleştirecektir.doors.
          </Text>
          <Text color="red.500" fontWeight="bold" mt="1%">
            Madde 5 – Ödüller
          </Text>
          <Text color="white" mt="1%">
            Yarışmada 3 seviye ve her seviye için 3 ödül olmak üzere toplam 9
            kazanan olacaktır. Katılımcı kayıt olduktan sonra soruları
            cevaplayarak puanını artırma imkanına sahip olacak ve bu yarışmadaki
            en üst seviyedeki hediyelere ulaşmaya çalışacaktır.
          </Text>
          <Text color="white" mt="1%">
            Etkinlikten sonra, her katılımcı yarışmadaki nihai başarı
            sıralamasına göre aşağıdaki 3 ödül çekilişinden 1'ine atanacaktır –
            Nihai sıralama etkinliğin hemen ardından Web platformunda ilan
            edilecektir (3 Eylül 2021, 15:00). Katılımcı hiçbir soruya cevap
            vermese bile otomatik olarak 3. seviyedeki hediyelerin çekilişine
            dahil edilecektir.
          </Text>

          <Text color="white" fontWeight="bold" mt="1%" fontStyle="italic">
            1. Seviye: Maksimum 20.000 Euro değerinde - Genel Sıralama: 1 -
            1.000
          </Text>
          <Text color="white" mt="1%">
            Yarışmada 1. sıradan 1.000’inci sıraya kadarki katılımcılar, 1.
            seviye hediyeler için yapılacak çekilişin katılımcı listesine dahil
            edilecektir.
            <br /> Bu seviyede maksimum 20.580 Euro değerinde 3 hediye yer alır.
          </Text>
          <UnorderedList color="white" ml="40px" mt="1%">
            <ListItem>
              1. seviyedeki ilk ödül, yeni bir MF Makinesi alımında 10.000
              Euro'ya varan indirimdir.
              <br />
              <br />
              Bu indirim yalnızca yeni makine alımında geçerlidir: MASSEY
              FERGUSON ürün yelpazesinden Yem Hasat Makineleri, Traktörler,
              Biçerdöverler, Balya Makineleri ve Teleskopik Taşıyıcılar için
              (bayideki makine stoku göz önünde bulundurulur), ve 31 Mart
              2022'den önce yapılan alımları kapsamaktadır.
              <br />
              <br />
              İndirim Kuralları (*liste fiyatlarına göre, opsiyonlar dahil):
              <br />
              <SimpleGrid
                w={isMobile ? "100%" : "50%"}
                autoFlow=""
                borderColor="white"
                columns={[2, null, 2]}
                border="1px solid white"
                mt="5px"
              >
                <Box bg="red.500" border="1px solid white">
                  <Text
                    color="white"
                    fontWeight="bold"
                    padding="1.5"
                    fontSize={isMobile ? ".7rem" : "1rem"}
                  >
                    Yeni Makinenin değeri*:
                  </Text>
                </Box>
                <Box bg="red.500" border="1px solid white">
                  <Text
                    color="white"
                    fontWeight="bold"
                    padding="1.5"
                    fontSize={isMobile ? ".7rem" : "1rem"}
                  >
                    Uygulanacak indirim
                  </Text>
                </Box>
                <Box border="1px solid white">
                  <Text
                    color="white"
                    padding="1.5"
                    fontSize={isMobile ? ".7rem" : "1rem"}
                  >
                    25.000 Euro'nun altında*
                  </Text>
                </Box>
                <Box border="1px solid white">
                  <Text
                    color="white"
                    padding="1.5"
                    fontSize={isMobile ? ".7rem" : "1rem"}
                  >
                    1500€
                  </Text>
                </Box>
                <Box border="1px solid white">
                  <Text
                    color="white"
                    padding="1.5"
                    fontSize={isMobile ? ".7rem" : "1rem"}
                  >
                    25.000 Euro - 50.000 Euro arasında*
                  </Text>
                </Box>
                <Box border="1px solid white">
                  <Text
                    color="white"
                    padding="1.5"
                    fontSize={isMobile ? ".7rem" : "1rem"}
                  >
                    2500€
                  </Text>
                </Box>
                <Box border="1px solid white">
                  <Text
                    color="white"
                    padding="1.5"
                    fontSize={isMobile ? ".7rem" : "1rem"}
                  >
                    50.000 Euro - 100.000 Euro arasında*
                  </Text>
                </Box>
                <Box border="1px solid white">
                  <Text
                    color="white"
                    padding="1.5"
                    fontSize={isMobile ? ".7rem" : "1rem"}
                  >
                    4000€
                  </Text>
                </Box>
                <Box border="1px solid white">
                  <Text
                    color="white"
                    padding="1.5"
                    fontSize={isMobile ? ".7rem" : "1rem"}
                  >
                    100.000 Euro - 150.000 Euro arasında*
                  </Text>
                </Box>
                <Box border="1px solid white">
                  <Text
                    color="white"
                    padding="1.5"
                    fontSize={isMobile ? ".7rem" : "1rem"}
                  >
                    6000€
                  </Text>
                </Box>
                <Box border="1px solid white">
                  <Text
                    color="white"
                    padding="1.5"
                    fontSize={isMobile ? ".7rem" : "1rem"}
                  >
                    150.000 Euro - 200.000 Euro arasında*
                  </Text>
                </Box>
                <Box border="1px solid white">
                  <Text
                    color="white"
                    padding="1.5"
                    fontSize={isMobile ? ".7rem" : "1rem"}
                  >
                    8000€
                  </Text>
                </Box>
                <Box border="1px solid white">
                  <Text
                    color="white"
                    padding="1.5"
                    fontSize={isMobile ? ".7rem" : "1rem"}
                  >
                    200.000 Euro’nun üstünde*
                  </Text>
                </Box>
                <Box border="1px solid white">
                  <Text
                    color="white"
                    padding="1.5"
                    fontSize={isMobile ? ".7rem" : "1rem"}
                  >
                    10 000€
                  </Text>
                </Box>
              </SimpleGrid>
            </ListItem>
            <ListItem mt="26px">
              1. seviyedeki ikinci ödül, 31 Mart 2022'den önce satın alınan
              herhangi bir uyumlu MF Traktör / Biçerdöver için ücretsiz MF Guide
              RTK düzeltme hizmeti opsiyonudur.
              <br />
              <br />
              Makine satın alındığında MF Guide opsiyonunun kazanan kişi
              tarafından seçilmiş olması gereklidir.
              <br />
              Bu ödülün maksimum değeri 16.060 Euro'yu geçmeyecektir. Yıllık
              abonelik ücretleri hariçtir (bu ücretler kazanan tarafından
              ödenir).
            </ListItem>
            <ListItem mt="26px">
              1. seviyedeki üçüncü ve son ödül, bir MF 1520 A Stage 5'tir ve 690
              Euro* değerinde bir lastik seçeneği dahil olmak üzere 19.890 Euro*
              değerindedir (* 1 Şubat 2021 Fiyat Listesi değerine göre)
            </ListItem>
          </UnorderedList>

          <Text color="white" mt="1%">
            1 katılımcı, tüm seviyeler dahil olmak üzere tüm yarışma kapsamında
            yalnızca bir ödül kazanabilir.
          </Text>
          <Text color="white" fontWeight="bold" mt="1%" fontStyle="italic">
            2. Seviye: Maksimum 5.000 Euro değerinde - Genel Sıralama: 1001 –
            2000
          </Text>
          <Text color="white" mt="1%">
            Yarışmada 1.001’inci sıradan 2.000’inci sıraya kadarki katılımcılar,
            2. seviye hediyeler için yapılacak ödül çekilişinin katılımcı
            listesine dahil edilecektir.
            <br />
            Bu seviyede en fazla 5.000 Euro değerinde olan 3 hediye yer alır
          </Text>

          <UnorderedList color="white" ml="40px" mt="1%">
            <ListItem>
              2. seviyedeki ilk ödül, toplam değeri 1.000 Euro olan ve
              aşağıdakileri içeren bir MF Summer Pack’tir (Yaz Paket):
              <UnorderedList color="white">
                <ListItem ml="20px" type="circle">
                  Toplam değeri 340 Euro olan, MF/AGCO Parts Varil Barbekü ve 4
                  adet MF/AGCO Parts şezlong seti
                </ListItem>
              </UnorderedList>
              <Text color="white" ml="-40px">
                Ve{" "}
              </Text>
              <UnorderedList color="white">
                <ListItem ml="20px" type="circle">
                  669 Euro değerinde kişiselleştirilmiş iPad Air Grey WIFI 64 GO
                </ListItem>
              </UnorderedList>
            </ListItem>
          </UnorderedList>

          <Text color="white" mt="1%">
            Bu hediyeler kazanana ayrı ayrı teslim edilecektir.
          </Text>

          <UnorderedList color="white" ml="40px">
            <ListItem mt="1%">
              2. seviyedeki ikinci ödül 2.000 Euro değerinde profesyonel bir
              basınçlı yıkama makinesidir.
              <br />
              <br />
              Sunulan basınçlı yıkama makinesi modeli sözleşmeye bağlı değildir
              ve kazananın içinde bulunduğu pazara göre değişebilir. 'AGCO Parts
              Tamamlayıcı Ürünler ekibimiz, seçimden sonra modele ait tüm
              detayları kazanana bildirecektir.
            </ListItem>
            <ListItem mt="1%">
              2. seviyede yer alan toplam 5.000 Euro değerindeki üçüncü ve son
              ödül, aşağıdakileri içeren bir MF Service paketidir:
              <UnorderedList color="white" listStyleType="circle" mt="1%">
                <ListItem>
                  Mevcut herhangi bir AGCO Parts ürünü için kazananın MF bölge
                  bayi mağazasında kullanılabilecek 5.000 Euro değerinde kupon.
                </ListItem>
                <ListItem mt="1%">
                  Massey Ferguson Traktör ve Teleskopik Taşıyıcı ekipmanının
                  kullanımına ilişkin tavsiyeler için bölge bayisi eşliğinde
                  AGCO Teknik Servis Merkezimizden 1 çiftlik ziyareti fırsatı.
                </ListItem>
              </UnorderedList>
            </ListItem>
          </UnorderedList>

          <Text color="white" mt="1%">
            1 katılımcı, tüm seviyeler dahil olmak üzere tüm yarışma kapsamında
            yalnızca bir ödül kazanabilir.
          </Text>

          <Text color="white" fontWeight="bold" mt="2%" fontStyle="italic">
            Seviye 3: 250 Euro'ya kadar - Genel Sıralama: 2001 - son katılıcı
          </Text>

          <Text color="white" mt="2%">
            Yarışmada 2001. sıradan son sıraya kadarki katılımcılar, 3. seviye
            hediye çekilişinin katılımcı listesine dahil edilecektir.
            <br /> Bu seviyede toplam değeri 250 Euro olan 3 hediye yer alır
          </Text>

          <UnorderedList color="white " mt="1%">
            <ListItem>
              3. seviyedeki ilk ödül, 3 özel MASSEY FERGUSON hediyesi içeren bir
              Connectivity Pack’tir:
              <UnorderedList color="white">
                <ListItem type="circle">
                  - MASSEY FERGUSON Akıllı Saat - Birim fiyat: 180 Euro
                </ListItem>
                <ListItem type="circle">
                  - MASSEY FERGUSON Kulaklık seti MASSEY FERGUSON – Birim Fiyat:
                  39,90 Euro
                </ListItem>
                <ListItem type="circle">
                  - MASSEY FERGUSON S Koleksiyonu Power Bank – Birim Fiyat:
                  29,90 Euro
                </ListItem>
                <Box display="block" mt="1%"></Box>
              </UnorderedList>
            </ListItem>

            <ListItem mt="1%">
              3. seviyedeki ikinci ödül, aşağıdaki MASSEY FERGUSON çevrimiçi
              mağazasında birkaç parçada kullanılabilecek 250 Euro değerinde bir
              E-Kupondur{" "}
              <Link href="https://shop.masseyferguson.com">
                https://shop.masseyferguson.com
              </Link>.{" "}
              Bu e-kupon, alındıktan sonra 36 ay boyunca geçerlidir.
            </ListItem>

            <ListItem mt="1%">
              3. seviyedeki üçüncü ve son ödül, aşağıdakileri içeren bir Game
              (Oyuncak) Pack’tir:
              <UnorderedList color="white" listStyleType="circle">
                <ListItem>
                  - MF 8S Ölçekli Maket – Birim fiyat: 70 Euro
                </ListItem>
                <ListItem>
                  Özel MF 5S Ölçekli Maket – Birim fiyat: 65 Euro
                </ListItem>
                <ListItem>
                  MASSEY FERGUSON PC kablosuz fare - Birim fiyat: 15 Euro
                </ListItem>
                <ListItem>
                  PC için 2 adet Farming Simulator 2022 oyunu - Birim fiyat: 40
                  Euro
                </ListItem>
                <ListItem>
                  Özel Farming Simulator 2022 FS beyzbol şapkası – Birim fiyat:
                  20 Euro
                </ListItem>
              </UnorderedList>
            </ListItem>
          </UnorderedList>
          <Text color="white" mt="1%">
            1 katılımcı, tüm seviyeler dahil olmak üzere tüm yarışma kapsamında
            yalnızca bir ödül kazanabilir.
          </Text>
          <Text color="red.500" fontWeight="bold" mt="1%">
            Madde 6 – Kazananların bildirilmesi ve ilan edilmesi
          </Text>
          <Text color="white" mt="1%">
            Massey Ferguson Pazarlama ekibi, kazananları AGCO ile iletişime
            geçmeye davet eden bir e-posta göndererek onları bilgilendirecektir.
            <br />
            Çekiliş sonucunda belirlenen kişi 10 gün içinde bu e-postaya cevap
            vermezse ikinci ve son çekiliş yapılacak ve ilk kazanan diskalifiye
            edilecektir.
            <br />
            Kazanan kişiyle, ödülün teslimatı için gerekli olan bilgilerin
            gönderilmesi için AGCO SAS Şirketi tarafından e-posta veya başka bir
            yolla iletişime geçilecektir.
            <br />
            Katılımcı, yarışmayı kazanması durumunda isminin reklamlarda,
            promosyonlarda veya ilgili internet sitelerinde ve sosyal medyada
            halka açık şekilde duyurulmasını kabul eder.
            <br />
            Kazanan kişi, radyo, internet, televizyon veya basılı medya yoluyla
            ses, fotoğraf, görüntü ve/veya metin tanıtımlarına hiçbir ücret
            talep etmeden makul sınırlar içinde katılmayı taahhüt eder ve
            bunların AGCO ve AGCO'ya bağlı şirketler tarafından reklam amaçlı
            yayınlanmasını ve kullanılmasını kabul eder.
            <br />
          </Text>
          <Text color="red.500" fontWeight="bold" mt="1%">
            Madde 7 – Katılımcıların Yarışma Dışı Bırakılması
          </Text>

          <Text color="white" mt="1%">
            AGCO, katılımcıları yarışma dışı bırakma hakkını saklı tutar. Bu,
            özellikle katılım koşullarının ihlali, hatalı kişisel veriler veya
            manipülasyon (hile) veya manipülasyon (hile) şüphesi için
            geçerlidir. AGCO ayrıca AGCO’nun hizmetlerini kullandığına dair
            makul bir şüphe oluşturan katılımcıları da yarışma dışı bırakabilir.
            <br />
            AGCO sonradan katılımcının, yarışma dışı bırakılmasına yol açacak
            herhangi bir neden oluşturduğunu veya 2. madde uyarınca katılmaya
            hak sahibi olmadığını tespit ederse, katılımcıyı ödüllerin dağıtımı
            veya teslimi kapsamından çıkarabilir veya daha önce teslim edilmiş
            olan herhangi bir ödülü geri alabilir.
          </Text>
          <Text color="red.500" fontWeight="bold" mt="1%">
            Madde 8 – Ödüllerin alınması
          </Text>
          <Text color="white" mt="1%">
            Maddi takasa, iadeye veya tazmine hiçbir koşul altında izin
            verilmez.
            <br />
            Harici koşulların açıklanan ödüllerin verilmesine engel teşkil
            etmesi durumunda Düzenleyici Şirket ödülleri eş değerde bir başka
            ödülle değiştirebilir. Bu, değişikliğe açık değildir ve nakit para
            alternatifi bulunmamaktadır.
            <br />
            Hediye, Massey Ferguson Pazarlama ekibinin bu hediyenin teslimatı
            için gereken tüm bilgileri almasının ardından 3 ay içinde kazanana
            gönderilecektir.
          </Text>
          <Text color="red.500" fontWeight="bold" mt="1%">
            Madde 9 - Kullanım Şartları ve Garanti
          </Text>
          <Text color="white" mt="1%">
            Düzenleyiciler, verilen ödülün kullanımından kaynaklı ve/veya
            kullanımı esnasında gerçekleşebilecek herhangi bir vaka ve/veya
            kazayla ilgili tüm sorumluluğu reddeder.
            <br />
            Düzenleyiciler, iletişim ağlarındaki teknik bir durumdan veya kendi
            kontrolleri dışındaki teknik bir olaydan kaynaklı hizmet kesintisi
            olması durumunda hiçbir şekilde sorumlu tutulamaz. Düzenleyiciler,
            yarışma herhangi bir sebeple kısmen veya tamamen değiştirildiği,
            ertelendiği veya iptal edildiği takdirde hiçbir şekilde sorumlu
            tutulamaz. Herhangi bir tazminat talep edilemez. Okunaksız, eksik ve
            sahte formlar dahil edilmeyecektir.
            <br />
            Dolayısıyla, formların kaydedilmemesi durumunda veya kontrol
            edilmesi mümkün olmaması halinde Düzenleyici Şirket herhangi bir
            sorumluluk kabul etmez.
            <br />
          </Text>
          <Text color="red.500" fontWeight="bold" mt="1%">
            Madde 10 - Koşulların Kabulü ve Anlaşmazlıklar
          </Text>

          <Text color="white" mt="1%">
            Tüm katılımcılar, bu yarışmaya katılarak işbu şartları ve koşulları
            kabul ettiğini onaylamış olur. Katılımcılar, şans unsurunun
            bulunduğunu ve çekilişle gerçekleşen muhtemel bir kazanma
            olasılığından başka hiçbir garantinin verilmediğini bilmekte ve
            anlamaktadır.
            <br />
            Olası anlaşmazlıklar, 3 Eylül tarihine kadar (posta damgası ispat
            sayılır) mektup gönderilerek, 41 avenue Blaise Pascal – BP 60307 -
            BEAUVAIS (60026) Fransa adresinde yer alan AGCO SAS Hukuk
            Departmanı’na iletilecektir. 'Bu düzenleme, Fransız yasalarına
            tabidir ve sadece Fransız yasaları uygulanacaktır.
            <br />
            'AGCO SAS. - MASSEY FERGUSON EME Pazarlama Departmanı, bu
            Düzenlemeye ilişkin uygulama güçlükleri, yorumlama veya uygulama
            talebiyle ilgili geribildirimde bulunacaktır.
            <br />
          </Text>
          <Text color="red.500" fontWeight="bold" mt="1%">
            Madde 11 - Yarışma şartları ve koşullarının bildirimi
          </Text>
          <Text color="white" mt="1%">
            Bu şartlar ve koşullar, resmi MF etkinlik platformunda çevrimiçi
            olarak sunulacaktır.
            <br />
            İnternet bağlantısına sahip olan katılımcılar, katılımcı herhangi
            bir ek masraf yapmadığından dolayı, herhangi bir geri ödeme
            talebinde bulunmayacaktır.
            <br />
            Bu şartlar ve koşulların tam kopyası, Düzenleyici Şirket’in hukuk
            departmanında yer almaktadır.
            <br />
          </Text>
          <Text color="red.500" fontWeight="bold" mt="1%">
            'Madde 12 - Kişisel Verilerin Korunması
          </Text>
          <Text color="white" mt="1%">
            Bu yarışmaya katılmak için katılımcıların, kendileri hakkında bazı
            kişisel bilgilerin Düzenleyici Şirket tarafından yarışmanın
            yürütülmesi ve ayrıca pazarlama amacıyla yurt dışında işleneceğini
            ve kullanılacağını kabul etmesi zorunludur.
            <br />
            Daha fazla bilgi için {" "}<Link href="http://www.agcocorp.com/privacy.html">
              http://www.agcocorp.com/privacy.html
            </Link>{" "}
            bağlantısından ulaşabileceğiniz Gizlilik Politikasına
            başvurabilirsiniz.
            <br />
            Her katılımcı, herhangi bir mali veya başka bir bedel olmaksızın,
            Düzenleyici Şirket’e soyadını, adını, ikamet ettiği yer hakkında
            özet bir bilgilendirmeyi ve fotoğrafını, her türlü tanıtım veya
            editöryal amaçlar için kullanma hakkını vermeyi kabul eder.
            <br />
            Yürürlükteki yönetmelikler uyarınca, herhangi bir zamanda ve AGCO’ya
            herhangi bir gerekçe sunmadan, tamamen veya kısmen yarışmadan
            çekilebilirsiniz. 'Erişim, düzeltme, silme ve itiraz etme hakkınızı
            dataprivacy@agcocorp.com adresine e-posta göndererek
            kullanabilirsiniz.
          </Text>
        </Flex>
      </chakra.div>
    </Container>
  );
};

export default TermsAndConditionsContentTR;
