import React, { FC, useState } from "react";
import {
  chakra,
  useBreakpointValue,
  useMultiStyleConfig,
  Container,
  Image,
  Flex,
  useDisclosure,
  Center,
} from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { useHistory, Link } from "react-router-dom";
import logo from "./../assets/logo/MFlogo RGB187_K_S.png";
import homeOff from "./../assets/home-icon-off.svg";
import homeOn from "./../assets/home-icon-on.svg";

export type LayoutHeaderProps = { home?: boolean };

const LayoutHeaderLogin: FC<LayoutHeaderProps> = (
  { home }: LayoutHeaderProps,
  ...props
) => {
  const { t } = useTranslation();
  const [isHoverHome, setIsHoverHome] = useState(false);
  // const styles = useMultiStyleConfig("LayoutHeader", props);

  const isMobile = useBreakpointValue({ base: true, lg: false });
  const { isOpen, onOpen, onClose } = useDisclosure();
  return (
    <chakra.div
      h="121px"
      position={isMobile ? "sticky" : "relative"}
      top="0px"
      zIndex="100"
      flexShrink={0}
    >
      <Flex h={{ base: "48px", lg: "0px" }} bgColor="white">
        <Container
          maxW="container.xl"
          d="flex"
          flexDir="row"
          justifyContent="space-between"
        >
          <Flex
            boxShadow="0px 1px 45px rgba(63, 63, 68, 0.35)"
            bgColor="white"
            h={{ base: "70px", lg: "119px" }}
            w={{ base: "100px", lg: "170px" }}
            justifyContent="center"
            alignItems="center"
          >
            <Image
              objectFit="cover"
              objectPosition="center"
              bgRepeat="no-repeat"
              overflow="hidden"
              alignItems="center"
              justifyContent="center"
              src={logo}
              h={{ base: "40px", lg: "68px" }}
              w={{ base: "70px", lg: "120px" }}
            />
          </Flex>
          {home && (
            <Center bgColor="white" mt="0px" h="30px" w="30px">
              <Link
                to="/"
                style={{
                  color: "gray",
                  textDecoration: "none",
                  padding: "5px",
                }}
                onMouseEnter={() => setIsHoverHome(true)}
                onMouseLeave={() => setIsHoverHome(false)}
              >
                <Image src={isHoverHome ? homeOn : homeOff} w="20px" />
              </Link>
            </Center>
          )}
        </Container>
      </Flex>
    </chakra.div>
  );
};

const LayoutHeaderProps = {
  home: false,
};

export default LayoutHeaderLogin;
