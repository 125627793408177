import React, { useState, useRef } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";

import {
  chakra,
  useBreakpointValue,
  useMultiStyleConfig,
  Container,
  Text,
  Link,
  Flex,
  Divider,
  ListItem,
  UnorderedList,
  SimpleGrid,
  Box,
} from "@chakra-ui/react";

import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import axiosHelper from "./../../helpers/axiosHelper";
import bgImage from "./../../assets/prizes/prizes-page-bg@2x.jpg";

import { ChevronLeftIcon, ChevronRightIcon } from "@chakra-ui/icons";

const TermsAndConditionsContentEN = ({ ...props }) => {
  const { t } = useTranslation();
  const history = useHistory();
  const [userProfile, setUserProfile] = useState<UserProfile>(
    axiosHelper.getUserProfile()!
  );
  const styles = useMultiStyleConfig("PrizePage", props);

  const isMobile = useBreakpointValue({ base: true, lg: false });
  const customSlider = useRef<any>();

  return (
    <Container
      maxW="container.xl"
      bgImage={bgImage}
      d="flex"
      flexDir="column"
      flexDirection="column"
      style={{
        overflow: "hidden",
        backgroundPosition: "center",
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
      }}
      alignItems="center"
    >
      <Flex
        flexDir={{ base: "column", lg: "column" }}
        justifyContent="space-between"
        w={isMobile?"100%":"50%"}
        mt="2%"
      >
        <Text color="white" mb="2%" mt={isMobile ? "30px" : "0"} align="center">
          TERMS AND CONDITIONS <br />
          MF SUPER CONTEST
          <br />
          Organised from July 2, 2021 to September 3, 2021
          <br />
          By MASSEY FERGUSON AGCO Brand
          <br />
        </Text>
      </Flex>
      <chakra.div w="100%" pb="100px">
        <Flex
          flexDir={{ base: "column", lg: "column" }}
          justifyContent="space-between"
          w="100%"
          mt="3%"
        >
          <Text color="red.500" mt="1%" fontWeight="bold">
            Article 1 - General Organization
          </Text>
          <Text color="white" mt="1%">
            The MASSEY FERGUSON EME Marketing department of AGCO SAS, head
            office located at 41 Blaise Pascal Avenue in Beauvais, is organizing
            the NEW MASSEY FERGUSON DIGITAL EVENT on the 3rd of September,
            inclusive a free and no-obligation game contest for the general
            public.
          </Text>

          <Text color="red.500" fontWeight="bold" mt="1%">
            Article 2 – Eligibility to participate
          </Text>

          <Text color="white" mt="1%">
            Only the participants of the Europe and Middle East countries
            (except countries subject to trade restrictive measure) are eligible
            to participate. Only farmers, contractors & those related to
            agricultural enterprises are allowed to win in this competition.
          </Text>

          <Text color="white" mt="1%">
            Are excluded:
          </Text>

          <chakra.div>
            <UnorderedList ml="40px" color="white">
              <ListItem>All-natural persons under 18 years.</ListItem>
              <ListItem>AGCO Group employees and their families</ListItem>
              <ListItem>
                Dealers and distributors of AGCO products & services
              </ListItem>
              <ListItem>Employees of companies affiliated with AGCO</ListItem>
              <ListItem>
                Agencies and Suppliers engaged by AGCO and the relatives of the
                respective employees.
              </ListItem>
              <ListItem>
                Journalists invited by MASSEY FERGUSON to attend the event
              </ListItem>
            </UnorderedList>
          </chakra.div>
          <Text color="white" mt="1%">
            Only the participants of the Europe and Middle East countries
            (except countries subject to trade restrictive measure) are eligible
            to participate. Only farmers, contractors & those related to
            agricultural enterprises are allowed to win in this competition.
          </Text>
          <Text color="red.500" fontWeight="bold" mt="1%">
            Article 3 - Terms of participation
          </Text>
          <Text color="white" mt="1%">
            By registering to the NEW MASSEY FERGUSON DIGITAL EVENT via the web
            platform of the organizing division of AGCO SAS, the participant
            automatically attends to the gaming contest, structuring element of
            the event. By the way, the participant consents to give access to
            their personal information to be contacted by AGCO SAS in case of
            gain via email, phone, social media.
          </Text>
          <Text color="red.500" fontWeight="bold" mt="1%">
            Article 4 - Determination of winners
          </Text>
          <Text color="white" mt="1%">
            The contest result will be announced between the 06th September 2021
            and 6th October 2021 at the offices of AGCO SAS. - Massey Ferguson
            Marketing Department EME in Beauvais – France.
          </Text>
          <Box display="block" mt="1%"></Box>
          <Text color="white">
            The draw will take place between 06th September 2021 and 6th October
            2021 at the offices of AGCO SAS. - Massey Ferguson Marketing
            Department EME in Beauvais – France. The Massey Ferguson Marketing
            Department EME in Beauvais will conduct this draw behind closed
            doors.
          </Text>
          <Text color="red.500" fontWeight="bold" mt="1%">
            Article 5 – Prizes
          </Text>
          <Text color="white" mt="1%">
            They will be 3 levels and 3 prizes per level so 9 winners in total
            in this game contest. By answering questions after the registration,
            the participants will have the possibility to increase their score
            and try to reach the highest level of gifts in this competition.
          </Text>
          <Text color="white" mt="1%">
            After the event, each participant will be assigned in 1 of the
            3-prize draws below, depending of his final ranking position into
            the game – Final position declared on the Web platform right after
            the event (3 September 2021, 3pm). Even if the participant doesn’t
            respond to any questions, he will automatically be integrated into
            the level 3 of gifts to win
          </Text>

          <Text color="white" fontWeight="bold" mt="1%" fontStyle="italic">
            Level 1: Worth up to 20,000 euros - Overall Rank: 1 - 1000
          </Text>
          <Text color="white" mt="1%">
            For participants from position 1 to position 1000 into the
            competition, they will integrate the list of participants for the
            prize draw of level 1 gifts.
            <br /> 3 gifts are accessible in this level, with a highest value of
            20 580€{" "}
          </Text>
          <UnorderedList color="white" ml="40px" mt="1%">
            <ListItem>
              The first prize in this level 1 is a discount up to 10 000€ on a
              new MF Machine purchase
              <br />
              <br />
              This discount applies only on new machine purchase: Haytools,
              Tractors, Combine, Balers, and Telehandlers (dealer stock OK) from
              the MASSEY FERGUSON range, done before the 31th March 2022.
              <br />
              <br />
              Discount Rules (*based on list prices; options included):
              <br />
              <SimpleGrid
                w={isMobile ? "100%" : "50%"}
                autoFlow=""
                borderColor="white"
                columns={[2, null, 2]}
                border="1px solid white"
                mt="5px"
              >
                <Box bg="red.500" border="1px solid white">
                  <Text
                    color="white"
                    fontWeight="bold"
                    padding="1.5"
                    fontSize={isMobile ? ".7rem" : "1rem"}
                  >
                    Value New Machine*
                  </Text>
                </Box>
                <Box bg="red.500" border="1px solid white">
                  <Text
                    color="white"
                    fontWeight="bold"
                    padding="1.5"
                    fontSize={isMobile ? ".7rem" : "1rem"}
                  >
                    Discount to apply
                  </Text>
                </Box>
                <Box border="1px solid white">
                  <Text
                    color="white"
                    padding="1.5"
                    fontSize={isMobile ? ".7rem" : "1rem"}
                  >
                    Below 25 000€*
                  </Text>
                </Box>
                <Box border="1px solid white">
                  <Text
                    color="white"
                    padding="1.5"
                    fontSize={isMobile ? ".7rem" : "1rem"}
                  >
                    1500€
                  </Text>
                </Box>
                <Box border="1px solid white">
                  <Text
                    color="white"
                    padding="1.5"
                    fontSize={isMobile ? ".7rem" : "1rem"}
                  >
                    Between 25 000€ &gt; 50 000€*
                  </Text>
                </Box>
                <Box border="1px solid white">
                  <Text
                    color="white"
                    padding="1.5"
                    fontSize={isMobile ? ".7rem" : "1rem"}
                  >
                    2500€
                  </Text>
                </Box>
                <Box border="1px solid white">
                  <Text
                    color="white"
                    padding="1.5"
                    fontSize={isMobile ? ".7rem" : "1rem"}
                  >
                    Between 50 000€ &gt; 100 000€*
                  </Text>
                </Box>
                <Box border="1px solid white">
                  <Text
                    color="white"
                    padding="1.5"
                    fontSize={isMobile ? ".7rem" : "1rem"}
                  >
                    4000€
                  </Text>
                </Box>
                <Box border="1px solid white">
                  <Text
                    color="white"
                    padding="1.5"
                    fontSize={isMobile ? ".7rem" : "1rem"}
                  >
                    Between 100 000€ &gt; 150 000€*
                  </Text>
                </Box>
                <Box border="1px solid white">
                  <Text
                    color="white"
                    padding="1.5"
                    fontSize={isMobile ? ".7rem" : "1rem"}
                  >
                    6000€
                  </Text>
                </Box>
                <Box border="1px solid white">
                  <Text
                    color="white"
                    padding="1.5"
                    fontSize={isMobile ? ".7rem" : "1rem"}
                  >
                    Between 150 000€ &gt; 200 000€*
                  </Text>
                </Box>
                <Box border="1px solid white">
                  <Text
                    color="white"
                    padding="1.5"
                    fontSize={isMobile ? ".7rem" : "1rem"}
                  >
                    8000€
                  </Text>
                </Box>
                <Box border="1px solid white">
                  <Text
                    color="white"
                    padding="1.5"
                    fontSize={isMobile ? ".7rem" : "1rem"}
                  >
                    From 200 000€*
                  </Text>
                </Box>
                <Box border="1px solid white">
                  <Text color="white" padding="1.5" fontSize={isMobile?".7rem":"1rem"}>
                    10 000€
                  </Text>
                </Box>
              </SimpleGrid>
            </ListItem>
            <ListItem mt="26px">
              The second prize in this level 1 is an MF Guide correction RTK
              option for free for any MF Tractor / Combine compatible, bought
              before the 31th March 2022
              <br />
              <br />
              The MF Guide option needs to be selected by the winner when the
              machine is purchased
              <br />
              The maximum value of this price will not exceed 16 060€. Annual
              subscription fees are excluded (charge to the winner).
            </ListItem>
            <ListItem mt="26px">
              The third and last prize in this level 1 is an MF 1520 A Stage 5
              to win, for a value of 19 890€*, and with the tires option
              included of 690€ * (* Price List 1st February 2021)
            </ListItem>
          </UnorderedList>

          <Text color="white" mt="1%">
            1 participant can only win one prize in the overall game, all levels
            combined.
          </Text>
          <Text color="white" fontWeight="bold" mt="1%" fontStyle="italic">
            Level 2: Worth up to 5000 € - Overall Rank: 1001 - 2000
          </Text>
          <Text color="white" mt="1%">
            For participants from position 1001 to position 2000 into the
            competition, they will integrate the list of participants for the
            prize draw of level 2 gifts.
            <br />3 gifts are accessible in this level, with a highest value of
            5 000€
          </Text>

          <UnorderedList color="white" ml="40px" mt="1%">
            <ListItem>
              The first prize in this level 2 is an MF Summer Pack of a total
              value of 1000€ included:
              <UnorderedList color="white">
                <ListItem ml="20px" type="circle">
                  A set of MF/AGCO Parts Oil Barrel Barbecue and 4 x MF/AGCO
                  Parts deckchairs, for a total value of 340€
                </ListItem>
              </UnorderedList>
              <Text color="white" ml="-40px">
                And
              </Text>
              <UnorderedList color="white">
                <ListItem ml="20px" type="circle">
                  A Personalised Ipad Air Grey WIFI 64 GO for a value of 669€
                </ListItem>
              </UnorderedList>
            </ListItem>
          </UnorderedList>

          <Text color="white" mt="1%">
            These gifts will be delivered separately to the winner
          </Text>

          <UnorderedList color="white" ml="40px">
            <ListItem mt="1%">
              The second prize in this level 2 is a professional pressure washer
              for a value of 2000€. <br />
              <br />
              The model of pressure washer presented is not contractual and can
              change depending of the winner market. Our AGCO Parts
              Complementary team will provide exact model details to the winner
              after selection.
            </ListItem>
            <ListItem mt="1%">
              The third and last prize in this level 2 for a total value of
              5000€ is an MF Service package integrated:
              <UnorderedList color="white" listStyleType="circle" mt="1%">
                <ListItem>
                  5000€ Voucher, usable in the local MF dealership’s shop of the
                  winner for any AGCO Parts items available.
                </ListItem>
                <ListItem mt="1%">
                  The opportunity to have 1 on-farm visit from our AGCO
                  Technical Service Centre, accompanied by the MF local dealer,
                  for advice on the use of Massey Ferguson Tractor and
                  Telescopic Handler equipment.
                </ListItem>
              </UnorderedList>
            </ListItem>
          </UnorderedList>

          <Text color="white" mt="1%">
            1 participant can only win one prize in the overall game, all levels
            combined.
          </Text>

          <Text color="white" fontWeight="bold" mt="2%" fontStyle="italic">
            Level 3: Worth up to 250€ - Overall Rank: 2001 - last
          </Text>

          <Text color="white" mt="2%">
            For participants from position 2001 to the end of the ranking, they
            will integrate the list of participants for the prize draw of level
            3 gifts.
            <br /> 3 gifts are accessible in this level, with a similar total
            value of 250€
          </Text>

          <UnorderedList color="white " mt="1%">
            <ListItem>
              The first prize in this level 3 is a Pack connectivity included 3
              exclusive MASSEY FERGUSON Gifts:
              <UnorderedList color="white">
                <ListItem type="circle">
                  A Smart Watch MASSEY FERGUSON – Unit price: 180€
                </ListItem>
                <ListItem type="circle">
                  A set of Ear pods MASSEY FERGUSON – Unit Price: 39.90€
                </ListItem>
                <ListItem type="circle">
                  A Powerbank S Collection MASSEY FERGUSON – Unit Price: 29.90€
                </ListItem>
                <Box display="block" mt="1%"></Box>
              </UnorderedList>
            </ListItem>

            <ListItem mt="1%">
              The second prize in this level 3 is an E-Voucher with a value of
              250€, usable in several instalments on the MASSEY FERGUSON online
              shop:{" "}
              <Link href="https://shop.masseyferguson.com">
                https://shop.masseyferguson.com
              </Link>.
              This e-voucher is valid 36 months upon reception.{" "}
            </ListItem>

            <ListItem mt="1%">
              The third and last prize in this level 3 is a Pack Game including:
              <UnorderedList color="white" listStyleType="circle">
                <ListItem>A Scale Model MF 8S – Unit price: 70€</ListItem>
                <ListItem>
                  The exclusive Scale Model MF 5S – Unit price: 65€
                </ListItem>
                <ListItem>
                  A PC wireless mouse MASSEY FERGUSON – Unit price: 15€
                </ListItem>
                <ListItem>
                  2 games Farming Simulator 2022 for PC - Unit price: 40€
                </ListItem>
                <ListItem>
                  The exclusive FS baseball Cap Farming Simulator 2022 –Unit
                  price: 20€
                </ListItem>
              </UnorderedList>
            </ListItem>
          </UnorderedList>
          <Text color="white" mt="1%">
            1 participant can only win one prize in the overall game, all levels
            combined.
          </Text>
          <Text color="red.500" fontWeight="bold" mt="1%">
            Article 6 – Winner's notification & announcement
          </Text>
          <Text color="white" mt="1%">
            The Massey Ferguson Marketing team will notify winners by sending
            them an email inviting them to contact AGCO.
            <br />
            If the person selected in the draw does not reply to this e-mail
            within a period of 10 days, a second and final draw will take place
            and this first winner will be disqualified.
            <br />
            The winner will be contacted by AGCO SAS Company via email or by
            another way to send the information mandatory for the prize
            delivery.
            <br />
            The participant agrees that, in the event of winning the
            competition, their name will be publicly announced in
            advertisements, promotions or on relevant websites and social media.
            <br />
            The winner undertakes to make themselves available free of charge
            within reasonable limits for audio, photo, image and/or text
            promotion via radio, internet, television or printed media and
            agrees to publication and use for advertising purposes by AGCO and
            by companies affiliated with AGCO.
            <br />
          </Text>
          <Text color="red.500" fontWeight="bold" mt="1%">
            Article 7 – Exclusion of participants
          </Text>

          <Text color="white" mt="1%">
            AGCO reserves the right to exclude participants from the
            competition. This applies in particular to violations of the
            conditions for participation, false personal data or manipulation or
            suspected manipulation. AGCO may also exclude participants who are
            reasonably suspected of using AGCO's services
            <br />
            If AGCO subsequently establishes that the participant has created
            any reason leading to exclusion or was not actually entitled to
            participate within the meaning of Article 2, AGCO may exclude the
            participant from the distribution or handover of the prizes or
            reclaim any prizes already handed over.
          </Text>
          <Text color="red.500" fontWeight="bold" mt="1%">
            Article 8 – Withdrawal of lots
          </Text>
          <Text color="white" mt="1%">
            A financial exchange, return or compensation is not permitted in any
            circumstances.
            <br />
            The organizing company may replace the prizes with another, of
            equivalent values, if external circumstances prevent awarding the
            advertised prizes. This is non-negotiable and no cash alternative
            will be available.
            <br />
            The gift will be sent to the winner within 3 months after reception
            by Massey Ferguson Marketing team to all information necessary for
            the delivery of this gift.
          </Text>
          <Text color="red.500" fontWeight="bold" mt="1%">
            Article 9 - Terms of use & Warranty
          </Text>
          <Text color="white" mt="1%">
            The organizers decline all responsibility for all incidents and / or
            accidents that may occur during the enjoyment of the prize awarded
            and / or because of its use.
            <br />
            The organizers reserve the right to shorten, extend, modify or
            cancel this operation if the circumstances required, without
            incurring any liability.
            <br />
            The organizers cannot be held responsible in case of interruption of
            service caused by a technical incident of the communication networks
            or a technical incident beyond their control. The organizers cannot
            be held responsible if, for any reasons, the competition is
            partially or completely modified, postponed or cancelled. No
            compensation can be claimed. Any illegible, incomplete and
            counterfeited forms will not be accounting. Therefore, the
            responsibility of the organizing company cannot be engaged if the
            forms are not registered or impossible to check.
            <br />
          </Text>
          <Text color="red.500" fontWeight="bold" mt="1%">
            Article 10 - Acceptance & Disputes
          </Text>

          <Text color="white" mt="1%">
            By entering this game contest, all the participants acknowledge to
            accept those terms and conditions.
            <br />
            Each participant knows that the intervention of chance can give him
            no assurance other than that of the expectation of a possible and
            random gain.
            <br />
            Eventual disputes will be sent to AGCO SAS Legal department – 41
            avenue Blaise Pascal – BP 60307 - BEAUVAIS (60026) FRANCE, no later
            than September 3 (postmark as proof) directing a letter.
            <br />
            This regulation is under the French law which is the only one
            applicable.
            <br />
            AGCO SAS. - MASSEY FERGUSON EME Marketing department will provide
            feedback regarding any practical difficulty, interpretation or
            application request about this Regulation.
            <br />
          </Text>
          <Text color="red.500" fontWeight="bold" mt="1%">
            Article 11 - Disclosure of the contest terms and conditions
          </Text>
          <Text color="white" mt="1%">
            These terms and conditions will be available online, on the official
            MF event platform.
            <br />
            The participant with an internet connection will not be able to
            claim any refund, as the participation does not carry any additional
            cost.
            <br />A full copy of those terms and condition is located into to
            the legal department of the Organizing Company.
            <br />
          </Text>
          <Text color="red.500" fontWeight="bold" mt="1%">
            Article 12 – Personal Data Protection
          </Text>
          <Text color="white" mt="1%">
            To participate in this contest, participants must necessarily agree
            that some personal information about themselves will be used by the
            organizing company for the purpose of managing the contest but also
            for marketing purposes.
            <br />
            For more information, you can consult our Privacy Policy at the
            following link {" "}<Link href="http://www.agcocorp.com/privacy.html">
              http://www.agcocorp.com/privacy.html
            </Link>
            <br />
            Each winner agrees without any financial or other compensation to
            grant to the organizing company the right to use his name, first
            name, summary indication of his place of residence and photography
            for all promotional or editorial supports.
            <br />
            In accordance with applicable regulations, you may withdraw in whole
            or in part at any time and without providing AGCO with any
            justification. You can exercise your rights of access,
            rectification, deletion and opposition by e-mail to
            dataprivacy@agcocorp.com
          </Text>
        </Flex>
      </chakra.div>
    </Container>
  );
};

export default TermsAndConditionsContentEN;
