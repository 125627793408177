import React, { useState, useRef } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";

import {
  chakra,
  useBreakpointValue,
  useMultiStyleConfig,
  Container,
  Text,
  Link,
  Flex,
  Divider,
  ListItem,
  UnorderedList,
  SimpleGrid,
  Box,
} from "@chakra-ui/react";

import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import axiosHelper from "./../../helpers/axiosHelper";
import bgImage from "./../../assets/prizes/prizes-page-bg@2x.jpg";

import { ChevronLeftIcon, ChevronRightIcon } from "@chakra-ui/icons";

const TermsAndConditionsContentNL = ({ ...props }) => {
  const { t } = useTranslation();
  const history = useHistory();
  const [userProfile, setUserProfile] = useState<UserProfile>(
    axiosHelper.getUserProfile()!
  );
  const styles = useMultiStyleConfig("PrizePage", props);

  const isMobile = useBreakpointValue({ base: true, lg: false });
  const customSlider = useRef<any>();

  return (
    <Container
      maxW="container.xl"
      bgImage={bgImage}
      d="flex"
      flexDir="column"
      flexDirection="column"
      style={{
        overflow: "hidden",
        backgroundPosition: "center",
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
      }}
      alignItems="center"
    >
      <Flex
        flexDir={{ base: "column", lg: "column" }}
        justifyContent="space-between"
        w={isMobile?"100%":"50%"}
        mt="2%"
      >
        <Text color="white" mb="2%" mt={isMobile ? "30px" : "0"} align="center">
          ALGEMENE VOORWAARDEN
          <br />
          MF SUPERWEDSTRIJD
          <br />
          Van 2 juli 2021 tot 3 september 2021
          <br />
          Door MASSEY FERGUSON een merk van AGCO
          <br />
        </Text>
      </Flex>
      <chakra.div w="100%" pb="100px">
        <Flex
          flexDir={{ base: "column", lg: "column" }}
          justifyContent="space-between"
          w="100%"
          mt="3%"
        >
          <Text color="red.500" mt="1%" fontWeight="bold">
            Artikel 1 - Algemene organisatie
          </Text>
          <Text color="white" mt="1%">
            De MASSEY FERGUSON EME marketingafdeling van AGCO SAS, met als
            hoofdkantoor 41 Blaise Pascal Avenue in Beauvais, organiseert het
            nieuwe DIGITALE MASSEY FERGUSON EVENEMENT op 3 september, inclusief
            een gratis en vrijblijvende gamewedstrijd voor het grote publiek.
          </Text>

          <Text color="red.500" fontWeight="bold" mt="1%">
            Artikel 2 – Recht op deelname
          </Text>

          <Text color="white" mt="1%">
            Alleen deelnemers uit landen in Europa en het Midden-Oosten (met
            uitzondering van landen waarvoor beperkende handelsmaatregelen
            gelden) hebben recht op deelname. Alleen agrariërs, loonwerkers en
            personen die verbonden zijn aan agrarische ondernemingen kunnen in
            deze wedstrijd een prijs winnen.
          </Text>

          <Text color="white" mt="1%">
            De volgende personen zijn uitgesloten van deelname:
          </Text>

          <chakra.div>
            <UnorderedList ml="40px" color="white">
              <ListItem>Iedereen onder de 18 jaar.</ListItem>
              <ListItem>
                Medewerkers van de AGCO-groep en hun gezinsleden
              </ListItem>
              <ListItem>
                Dealers en distributeurs van AGCO-producten en -services
              </ListItem>
              <ListItem>Medewerkers van aan AGCO gelieerde bedrijven</ListItem>
              <ListItem>
                Bureaus en leveranciers die door AGCO worden ingehuurd en de
                gezinsleden van hun medewerkers
              </ListItem>
              <ListItem>
                Journalisten die door MASSEY FERGUSON worden uitgenodigd voor
                het evenement
              </ListItem>
            </UnorderedList>
          </chakra.div>
          <Text color="white" mt="1%">
            Er is slechts één deelname per persoon, per huishouden en agrarische
            eenheid, met dezelfde naam, dezelfde voornaam, hetzelfde
            telefoonnummer en hetzelfde e-mailadres toegestaan. Deelname aan het
            spel is strikt persoonlijk. Het organiserende bedrijf behoudt zich
            het recht voor eventuele controles uit te voeren als dat nodig wordt
            geacht.
          </Text>
          <Text color="red.500" fontWeight="bold" mt="1%">
            Artikel 3 - Voorwaarden voor deelname
          </Text>
          <Text color="white" mt="1%">
            Door inschrijving voor het NIEUWE DIGITALE EVENEMENT van MASSEY
            FERGUSON op het internetplatform van de organiserende afdeling van
            AGCO SAS neemt de deelnemer automatisch deel aan de wedstrijd, want
            dit vormt de basis van het evenement. De deelnemer geeft toestemming
            voor toegang tot zijn persoonsgegevens voor contact door AGCO SAS
            via e-mail, telefoon en/of sociale media.
          </Text>
          <Text color="red.500" fontWeight="bold" mt="1%">
            Artikel 4 - Aanwijzing van winnaars
          </Text>
          <Text color="white" mt="1%">
            De uitslag van de wedstrijd wordt bekendgemaakt tussen 6 september
            2021 en 6 oktober 2021 op het kantoor van AGCO SAS. - Massey
            Ferguson Marketing Department EME in Beauvais – Frankrijk.
          </Text>
          <Box display="block" mt="1%"></Box>
          <Text color="white">
            De trekking vindt plaats tussen 6 september 2021 en 6 oktober 2021
            op het kantoor van AGCO SAS. - Massey Ferguson Marketing Department
            EME in Beauvais – Frankrijk. Massey Ferguson Marketing Department
            EME in Beauvais zal de trekking achter gesloten deuren uitvoeren.
          </Text>
          <Text color="red.500" fontWeight="bold" mt="1%">
            Artikel 5 – Prijzen
          </Text>
          <Text color="white" mt="1%">
            Er worden op 3 niveaus 3 prijzen per niveau toegekend, dus in totaal
            zijn er 9 winnaars in deze wedstrijd. Nadat de deelnemer zich heeft
            aangemeld, moet deze vragen beantwoorden waarmee hij zijn score kan
            verhogen om het hoogste prijsniveau in deze wedstrijd te bereiken.
          </Text>
          <Text color="white" mt="1%">
            Na afloop van het evenement wordt iedere deelnemer toegewezen aan 1
            van de 3 onderstaande trekkingen, afhankelijk van de uiteindelijke
            plaats in de wedstrijd. – De definitieve plaats wordt direct na het
            evenement bekendgemaakt op het internetplatform (3 september 2021,
            15.00 uur). Zelfs als een deelnemer geen vragen beantwoordt, wordt
            hij automatisch toegewezen aan niveau 3 en maakt hij kans op een
            prijs in deze categorie.
          </Text>

          <Text color="white" fontWeight="bold" mt="1%" fontStyle="italic">
            Niveau 1: waarde tot 20.000 euro - rangnummer: 1 / 1.000
          </Text>
          <Text color="white" mt="1%">
            Deelnemers die op plaats 1 tot en met 1.000 eindigen in de
            wedstrijd, maken kans op een prijs van niveau 1.
            <br /> Op dit niveau zijn 3 prijzen beschikbaar, met een hoogste
            waarde van € 20.580.
          </Text>
          <UnorderedList color="white" ml="40px" mt="1%">
            <ListItem>
              De eerste prijs op dit niveau 1 is een korting van € 10.000 bij
              aanschaf van een nieuwe MF-machine.
              <br />
              <br />
              Deze korting is alleen geldig bij aanschaf van een nieuwe machine:
              hooimachines, trekkers, maaidorsers, balenpersen en verreikers
              (rekening houdend met de machinevoorraad bij de dealer) uit het
              programma van MASSEY FERGUSON, die plaatsvindt vóór 31 maart 2022.
              <br />
              <br />
              Regels voor korting (*op basis van prijslijsten, inclusief
              opties):
              <br />
              <SimpleGrid
                w={isMobile ? "100%" : "50%"}
                autoFlow=""
                borderColor="white"
                columns={[2, null, 2]}
                border="1px solid white"
                mt="5px"
              >
                <Box bg="red.500" border="1px solid white">
                  <Text
                    color="white"
                    fontWeight="bold"
                    padding="1.5"
                    fontSize={isMobile ? ".7rem" : "1rem"}
                  >
                    Waarde van nieuwe machine*:{" "}
                  </Text>
                </Box>
                <Box bg="red.500" border="1px solid white">
                  <Text
                    color="white"
                    fontWeight="bold"
                    padding="1.5"
                    fontSize={isMobile ? ".7rem" : "1rem"}
                  >
                    Toepasselijke korting{" "}
                  </Text>
                </Box>
                <Box border="1px solid white">
                  <Text
                    color="white"
                    padding="1.5"
                    fontSize={isMobile ? ".7rem" : "1rem"}
                  >
                    Onder € 25.000*{" "}
                  </Text>
                </Box>
                <Box border="1px solid white">
                  <Text
                    color="white"
                    padding="1.5"
                    fontSize={isMobile ? ".7rem" : "1rem"}
                  >
                    1500€
                  </Text>
                </Box>
                <Box border="1px solid white">
                  <Text
                    color="white"
                    padding="1.5"
                    fontSize={isMobile ? ".7rem" : "1rem"}
                  >
                    Tussen € 25.000 en € 50.000*{" "}
                  </Text>
                </Box>
                <Box border="1px solid white">
                  <Text
                    color="white"
                    padding="1.5"
                    fontSize={isMobile ? ".7rem" : "1rem"}
                  >
                    2500€
                  </Text>
                </Box>
                <Box border="1px solid white">
                  <Text
                    color="white"
                    padding="1.5"
                    fontSize={isMobile ? ".7rem" : "1rem"}
                  >
                    Tussen € 50.000 en € 100.000*{" "}
                  </Text>
                </Box>
                <Box border="1px solid white">
                  <Text
                    color="white"
                    padding="1.5"
                    fontSize={isMobile ? ".7rem" : "1rem"}
                  >
                    4000€
                  </Text>
                </Box>
                <Box border="1px solid white">
                  <Text
                    color="white"
                    padding="1.5"
                    fontSize={isMobile ? ".7rem" : "1rem"}
                  >
                    Tussen € 100.000 en € 150.000*{" "}
                  </Text>
                </Box>
                <Box border="1px solid white">
                  <Text
                    color="white"
                    padding="1.5"
                    fontSize={isMobile ? ".7rem" : "1rem"}
                  >
                    6000€
                  </Text>
                </Box>
                <Box border="1px solid white">
                  <Text
                    color="white"
                    padding="1.5"
                    fontSize={isMobile ? ".7rem" : "1rem"}
                  >
                    Tussen € 150.000 en € 200.000*{" "}
                  </Text>
                </Box>
                <Box border="1px solid white">
                  <Text
                    color="white"
                    padding="1.5"
                    fontSize={isMobile ? ".7rem" : "1rem"}
                  >
                    8000€
                  </Text>
                </Box>
                <Box border="1px solid white">
                  <Text
                    color="white"
                    padding="1.5"
                    fontSize={isMobile ? ".7rem" : "1rem"}
                  >
                    Boven € 200.000*{" "}
                  </Text>
                </Box>
                <Box border="1px solid white">
                  <Text
                    color="white"
                    padding="1.5"
                    fontSize={isMobile ? ".7rem" : "1rem"}
                  >
                    10 000€
                  </Text>
                </Box>
              </SimpleGrid>
            </ListItem>
            <ListItem mt="26px">
              De tweede prijs op dit niveau 1 is een MF Guide met optionele
              RTK-correctie voor een compatibele MF-trekker/-maaidorser die vóór
              31 maart 2022 wordt aangeschaft.
              <br />
              <br />
              De MF Guide-optie moet door de winnaar worden geselecteerd bij
              aanschaf van de machine.
              <br />T De maximumwaarde van deze prijs mag niet hoger zijn dan
              € 16.060. Jaarlijkse abonnementskosten zijn niet inbegrepen (voor
              rekening van de winnaar).
            </ListItem>
            <ListItem mt="26px">
              Als derde en laatste prijs op dit niveau 1 kan een MF 1520 A Stage
              5 worden gewonnen ter waarde van € 19.890*, inclusief bandenoptie
              ter waarde van € 690* (* prijslijst van 1 februari 2021)
            </ListItem>
          </UnorderedList>

          <Text color="white" mt="1%">
            1 deelnemer kan slechts één prijs winnen in de hele wedstrijd,
            gecombineerd voor alle niveaus.
          </Text>
          <Text color="white" fontWeight="bold" mt="1%" fontStyle="italic">
            Niveau 2: waarde tot € 5.000 - rangnummer: 1001 – 2000
          </Text>
          <Text color="white" mt="1%">
            Deelnemers die op plaats 1001 tot en met 2000 eindigen in de
            wedstrijd, maken kans op een prijs van niveau 2.
            <br />
            Op dit niveau zijn 3 prijzen beschikbaar, met een hoogste waarde van
            € 5.000.
          </Text>

          <UnorderedList color="white" ml="40px" mt="1%">
            <ListItem>
              De eerste prijs op dit niveau is een MF Summer Pack met een totale
              waarde van € 1.000 inclusief:
              <UnorderedList color="white">
                <ListItem ml="20px" type="circle">
                  een MF/AGCO Parts Oil Barrel-barbecue en 4 x MF/AGCO
                  Parts-strandstoelen met een totale waarde van € 340
                </ListItem>
              </UnorderedList>
              <Text color="white" ml="-40px">
                en
              </Text>
              <UnorderedList color="white">
                <ListItem ml="20px" type="circle">
                  een gepersonaliseerde iPad Air Grey WIFI 64 GO ter waarde van
                  € 669
                </ListItem>
              </UnorderedList>
            </ListItem>
          </UnorderedList>

          <Text color="white" mt="1%">
            Deze prijzen zullen afzonderlijk aan de winnaar worden overhandigd.
          </Text>

          <UnorderedList color="white" ml="40px">
            <ListItem mt="1%">
              De tweede prijs op dit niveau 2 is een professionele drukreiniger
              ter waarde van € 2.000.
              <br />
              <br />
              Het model van de drukreiniger is niet vastgelegd en kan variëren,
              afhankelijk van de markt waarin de winnaar is gevestigd. Het AGCO
              Parts Complementary-team zal de details van het model aan de
              winnaar mededelen nadat de trekking heeft plaatsgevonden.
            </ListItem>
            <ListItem mt="1%">
              De derde en laatste prijs op dit niveau 2 met een totale waarde
              van € 5.000 is een MF Service-pakket dat bestaat uit:
              <UnorderedList color="white" listStyleType="circle" mt="1%">
                <ListItem>
                  een waardebon van € 5.000, te besteden bij de plaatselijke MF
                  dealer van de winnaar aan beschikbare items van AGCO Parts.
                </ListItem>
                <ListItem mt="1%">
                  De mogelijkheid van 1 bezoek op het bedrijf van ons AGCO
                  Technical Service Centre, onder begeleiding van de
                  plaatselijke MF dealer, voor advies over gebruik van
                  apparatuur voor een Massey Ferguson trekker en verreiker.
                </ListItem>
              </UnorderedList>
            </ListItem>
          </UnorderedList>

          <Text color="white" mt="1%">
            1 deelnemer kan slechts één prijs winnen in de hele wedstrijd,
            gecombineerd voor alle niveaus.
          </Text>

          <Text color="white" fontWeight="bold" mt="2%" fontStyle="italic">
            Niveau 3: waarde tot € 250 - rangnummer: 2001 – laatste
          </Text>

          <Text color="white" mt="2%">
            Deelnemers die op plaats 2001 tot en met de laatste plaats eindigen
            in de wedstrijd, maken kans op een prijs van niveau 3.
            <br /> Op dit niveau zijn 3 prijzen beschikbaar, met een hoogste
            waarde van € 250.
          </Text>

          <UnorderedList color="white " mt="1%">
            <ListItem>
              De eerste prijs op dit niveau 3 is een Connectivity Pack dat 3
              exclusieve prijzen van MASSEY FERGUSON bevat:
              <UnorderedList color="white">
                <ListItem type="circle">
                  een MASSEY FERGUSON Smart Watch – waarde per stuk: € 180
                </ListItem>
                <ListItem type="circle">
                  een set MASSEY FERGUSON Ear pods – waarde per stuk: € 39,90
                </ListItem>
                <ListItem type="circle">
                  een MASSEY FERGUSON S Collection Powerbank – waarde per stuk:
                  € 29,90
                </ListItem>
                <Box display="block" mt="1%"></Box>
              </UnorderedList>
            </ListItem>

            <ListItem mt="1%">
              De tweede prijs op dit niveau 3 is een e-voucher met een waarde
              van € 250, te besteden in delen in de online shop van MASSEY
              FERGUSON op:{" "}
              <Link href="https://shop.masseyferguson.com">
                https://shop.masseyferguson.com
              </Link>.{" "}
              Deze e-voucher is geldig tot 36 maanden na ontvangst.
            </ListItem>

            <ListItem mt="1%">
              De derde en laatste prijs op dit niveau 3 is een Game Pack met:
              <UnorderedList color="white" listStyleType="circle">
                <ListItem>
                  een schaalmodel van de MF 8S – waarde per stuk: € 70
                </ListItem>
                <ListItem>
                  een schaalmodel van de exclusieve MF 5S – waarde per stuk:
                  € 65
                </ListItem>
                <ListItem>
                  een MASSEY FERGUSON draadloze pc-muis – waarde per stuk: € 15
                </ListItem>
                <ListItem>
                  2 Farming Simulator 22-spellen voor pc - waarde per stuk: € 40
                </ListItem>
                <ListItem>
                  de exclusieve Farming Simulator 22 FS baseball cap – waarde
                  per stuk: € 20
                </ListItem>
              </UnorderedList>
            </ListItem>
          </UnorderedList>
          <Text color="white" mt="1%">
            1 deelnemer kan slechts één prijs winnen in de hele wedstrijd,
            gecombineerd voor alle niveaus.
          </Text>
          <Text color="red.500" fontWeight="bold" mt="1%">
            Artikel 6 - Bericht aan de winnaars en bekendmaking
          </Text>
          <Text color="white" mt="1%">
            Het marketingteam van Massey Ferguson zal winnaars op de hoogte
            brengen over hun prijs door middel van een e-mail met een
            uitnodiging om contact op te nemen met AGCO.
            <br />
            Als degene die geselecteerd is bij de trekking niet binnen 10 dagen
            op deze e-mail reageert, wordt een tweede en laatste trekking
            uitgevoerd en wordt de eerste winnaar gediskwalificeerd.
            <br />
            AGCO SAS Company neemt contact op met de winnaar via e-mail of langs
            een andere weg om de benodigde informatie voor verzending van de
            prijs te verkrijgen.
            <br />
            De deelnemer gaat ermee akkoord dat zijn naam in advertenties,
            promoties of op relevante websites en sociale media wordt vermeld
            als hij de wedstrijd wint.
            <br />
            De winnaar zal zich binnen redelijke grenzen gratis beschikbaar
            stellen voor promotie in de vorm van geluidsopnamen, foto's, beelden
            en/of tekst via radio, internet, televisie of pers en stemt in met
            publicatie en gebruik hiervan voor advertentiedoeleinden door AGCO
            en bedrijven die gelieerd zijn aan AGCO.
            <br />
          </Text>
          <Text color="red.500" fontWeight="bold" mt="1%">
            Artikel 7 - Uitsluiting van deelname
          </Text>

          <Text color="white" mt="1%">
            AGCO behoudt zich het recht voor om deelnemers uit te sluiten van de
            wedstrijd. Dit geldt in het bijzonder voor schending van de
            voorwaarden voor deelname, onjuiste persoonsgegevens of manipulatie
            of vermoede manipulatie van informatie. AGCO mag ook deelnemers
            uitsluiten van wie redelijkerwijs vermoed kan worden dat zij AGCO's
            services gebruiken.
            <br />
            Als AGCO vervolgens vaststelt dat de deelnemer voldoet aan een reden
            voor uitsluiting of eigenlijk niet in aanmerking kwam voor deelname
            volgens artikel 2, mag AGCO de deelnemer uitsluiten van ontvangst of
            overdracht van de prijzen of mag een reeds toegekende prijs worden
            teruggevorderd.
          </Text>
          <Text color="red.500" fontWeight="bold" mt="1%">
            Artikel 8 – Terugtrekking van goederen
          </Text>
          <Text color="white" mt="1%">
            Een financiële vervanging, tegenwaarde of compensatie is in geen
            geval toegestaan.
            <br />
            Het organiserende bedrijf mag de prijzen vervangen door andere van
            vergelijkbare waarde als externe omstandigheden het uitkeren van de
            geadverteerde prijzen onmogelijk maken. Hierover kan niet worden
            onderhandeld en een tegenwaarde in contanten is niet beschikbaar.
            <br />
            De prijs naar de winnaar worden verzonden binnen 3 maanden nadat het
            marketingteam van Massey Ferguson alle benodigde informatie voor het
            verstrekken van de prijs heeft ontvangen.
          </Text>
          <Text color="red.500" fontWeight="bold" mt="1%">
            Artikel 9 - Gebruiksvoorwaarden en garantie
          </Text>
          <Text color="white" mt="1%">
            De organisatoren aanvaarden geen enkele aansprakelijkheid voor alle
            incidenten en/of ongevallen die kunnen plaatsvinden tijdens het
            genot van de toegekende prijzen en/of het gebruik daarvan.
            <br />
            De organisatoren behouden zich het recht voor om deze activiteiten
            in te korten, uit te breiden, te wijzigen of te annuleren als
            omstandigheden dat vereisen, zonder daarvoor aansprakelijk te zijn.
            <br />
            De organisatoren aanvaarden geen aansprakelijkheid in geval van een
            service-onderbreking door een technisch incident in de
            communicatienetwerken of een technische incident buiten hun invloed.
            De organisatoren aanvaarden geen aansprakelijkheid als de wedstrijd
            om welke redenen dan ook gedeeltelijk of volledig wordt gewijzigd,
            uitgesteld of geannuleerd. Er is geen recht op compensatie.
            Onleesbare, onvolledig ingevulde of nagemaakte formulieren worden
            niet meegeteld. Daarom aanvaardt het organiserende bedrijf geen
            aansprakelijkheid als de formulieren niet worden geregistreerd of
            niet gecontroleerd kunnen worden.
            <br />
          </Text>
          <Text color="red.500" fontWeight="bold" mt="1%">
            Artikel 10 - Aanvaarding en geschillen
          </Text>

          <Text color="white" mt="1%">
            Door deelname aan deze wedstrijd verklaren alle deelnemers zich
            akkoord met de algemene voorwaarden.
            <br />
            Iedere deelnemer beseft dat de geluksfactor geen andere zekerheid op
            winst biedt dan de verwachting die hoort bij een mogelijke en
            willekeurige winst.
            <br />
            Eventuele bezwaren moeten uiterlijk 3 september (datum poststempel)
            schriftelijk worden ingediend bij de juridische afdeling van AGCO
            SAS – 41 avenue Blaise Pascal – BP 60307 - BEAUVAIS (60026)
            Frankrijk.
            <br />
            Deze regels vallen onder het Franse recht, dat het enige
            toepasselijke recht is.
            <br />
            De marketingafdeling van AGCO SAS. - MASSEY FERGUSON EME zal
            feedback geven in geval van verzoeken bij praktische problemen,
            vertaling of toepassing van deze regels.
            <br />
          </Text>
          <Text color="red.500" fontWeight="bold" mt="1%">
            Artikel 11 - Bekendmaking van de algemene wedstrijdvoorwaarden
          </Text>
          <Text color="white" mt="1%">
            Deze algemene voorwaarden zullen online beschikbaar zijn op het
            officiële platform van het MF-evenement.
            <br />
            Een deelnemer met een internetverbinding kan geen aanvraag voor
            terugbetaling eisen omdat deelname geen extra kosten met zich
            meebrengt.
            <br />
            Een volledig exemplaar van de algemene voorwaarden bevindt zich bij
            de juridische afdeling van het organiserende bedrijf.
            <br />
          </Text>
          <Text color="red.500" fontWeight="bold" mt="1%">
            Artikel 12 – Bescherming van persoonsgegevens
          </Text>
          <Text color="white" mt="1%">
            Voor deelname aan deze wedstrijd moeten deelnemers ermee instemmen
            dat sommige van hun persoonsgegevens worden gebruikt door het
            organiserende bedrijf ten behoeve van uitvoering van de wedstrijd en
            ook voor marketingdoeleinden.
            <br />
            Voor meer informatie kunt u onze privacybeleid raadplegen via de
            volgende link:{" "}<Link href="http://www.agcocorp.com/privacy.html">
              http://www.agcocorp.com/privacy.html
            </Link>
            <br />
            Iedere winnaar verleent het organiserende bedrijf zonder financiële
            of andere compensatie het recht om zijn voor- en achternaam, een
            indicatie van zijn woonplaats en een foto te gebruiken voor alle
            promotionele en redactionele ondersteuning.
            <br />
            Overeenkomstig toepasselijke voorschriften kunt u zich op ieder
            gewenst moment geheel of gedeeltelijk terugtrekken zonder AGCO
            daarvoor een reden te geven. U kunt uw recht van inzage, correctie,
            verwijdering en verzet via e-mail doen gelden via
            dataprivacy@agcocorp.com.
          </Text>
        </Flex>
      </chakra.div>
    </Container>
  );
};

export default TermsAndConditionsContentNL;
