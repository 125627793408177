import React, { useState, useRef } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";

import {
  chakra,
  useBreakpointValue,
  useMultiStyleConfig,
  Container,
  Text,
  Link,
  Flex,
  Divider,
  ListItem,
  UnorderedList,
  SimpleGrid,
  Box,
} from "@chakra-ui/react";

import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import axiosHelper from "./../../helpers/axiosHelper";
import bgImage from "./../../assets/prizes/prizes-page-bg@2x.jpg";

import { ChevronLeftIcon, ChevronRightIcon } from "@chakra-ui/icons";

const TermsAndConditionsContentDE = ({ ...props }) => {
  const { t } = useTranslation();
  const history = useHistory();
  const [userProfile, setUserProfile] = useState<UserProfile>(
    axiosHelper.getUserProfile()!
  );
  const styles = useMultiStyleConfig("PrizePage", props);

  const isMobile = useBreakpointValue({ base: true, lg: false });
  const customSlider = useRef<any>();

  return (
    <Container
      maxW="container.xl"
      bgImage={bgImage}
      d="flex"
      flexDir="column"
      flexDirection="column"
      style={{
        overflow: "hidden",
        backgroundPosition: "center",
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
      }}
      alignItems="center"
    >
      <Flex
        flexDir={{ base: "column", lg: "column" }}
        justifyContent="space-between"
        w={isMobile?"100%":"50%"}
        mt="2%"
      >
        <Text color="white" mb="2%" mt={isMobile ? "30px" : "0"} align="center">
          TEILNAHMEBEDINGUNGEN
          <br />
          MF SUPER CONTEST
          <br />
          veranstaltet vom 2. Juli 2021 bis zum 3. September 2021
          <br />
          von MASSEY FERGUSON, eine weltweite Marke von AGCO
          <br />
        </Text>
      </Flex>
      <chakra.div w="100%" pb="100px">
        <Flex
          flexDir={{ base: "column", lg: "column" }}
          justifyContent="space-between"
          w="100%"
          mt="3%"
        >
          <Text color="red.500" mt="1%" fontWeight="bold">
            Artikel 1 – Allgemeine Organisation
          </Text>
          <Text color="white" mt="1%">
            Das MASSEY FERGUSON EME Marketing Department von AGCO SAS mit Sitz
            in „41, Avenue Blaise Pascal, Beauvais (Frankreich)“ veranstaltet am
            3. September das NEUE DIGITALE MASSEY FERGUSON EVENT, einschließlich
            eines kostenlosen, unverbindlichen und öffentlichen Gewinnspiels.
          </Text>

          <Text color="red.500" fontWeight="bold" mt="1%">
            Artikel 2 – Teilnahmeberechtigung
          </Text>

          <Text color="white" mt="1%">
            Teilnahmeberechtigt sind ausschließlich Personen aus Ländern in
            Europa und im Nahen Osten (ausgenommen sind Länder, für die
            Handelsbeschränkungen gelten). Nur Landwirte, Lohnunternehmer und
            landwirtschaftliche Unternehmen können an diesem Gewinnspiel
            teilnehmen und Preise gewinnen.
          </Text>

          <Text color="white" mt="1%">
            Von der Teilnahme ausgeschlossen sind:
          </Text>

          <chakra.div>
            <UnorderedList ml="40px" color="white">
              <ListItem>Alle Personen unter 18 Jahren</ListItem>
              <ListItem>
                Mitarbeiter der AGCO Firmengruppe und ihre Familienangehörigen
              </ListItem>
              <ListItem>
                Vertriebspartner und Großhändler von AGCO Produkten und
                Dienstleistungen
              </ListItem>
              <ListItem>
                Mitarbeiter von Unternehmen, die mit dem Unternehmen AGCO
                verbunden sind
              </ListItem>
              <ListItem>
                Agenturen und Lieferanten, die von AGCO beauftragt wurden, sowie
                deren Angehörige und Angestellte
              </ListItem>
              <ListItem>
                Journalisten, die von MASSEY FERGUSON zum Event eingeladen
                wurden
              </ListItem>
            </UnorderedList>
          </chakra.div>
          <Text color="white" mt="1%">
            Jede Person, jeder Haushalt und jeder landwirtschaftliche Betrieb
            mit dem gleichen Vor- und Nachnamen, der gleichen Telefonnummer und
            der gleichen E-Mail-Adresse kann nur einmal teilnehmen. Die
            Teilnahme am Wettbewerb erfolgt persönlich. Der Veranstalter behält
            sich das Recht vor, eine ihm angemessen erscheinende Überprüfung
            durchzuführen.
          </Text>
          <Text color="red.500" fontWeight="bold" mt="1%">
            Artikel 3 – Teilnahmebedingungen
          </Text>
          <Text color="white" mt="1%">
            Durch die Registrierung für das NEUE DIGITALE EVENT VON MASSEY
            FERGUSON über die Internetplattform von AGCO SAS wird der Teilnehmer
            automatisch auch für das Gewinnspiel registriert, da das Event auf
            diesem basiert. Der Teilnehmer erklärt sich damit einverstanden,
            AGCO SAS seine persönlichen Daten zur Verfügung zu stellen, um per
            E-Mail, Telefon oder über die sozialen Medien von AGCO SAS
            kontaktiert zu werden.
          </Text>
          <Text color="red.500" fontWeight="bold" mt="1%">
            Artikel 4 – Ermittlung der Gewinner
          </Text>
          <Text color="white" mt="1%">
            Das Ergebnis des Gewinnspiels wird zwischen dem 6. September 2021
            und dem 6. Oktober 2021 in den Büroräumen von AGCO SAS bekannt
            gegeben. - Massey Ferguson Marketing-Abteilung EME in Beauvais −
            Frankreich.
          </Text>
          <Box display="block" mt="1%"></Box>
          <Text color="white">
            Die Verlosung findet zwischen dem 6. September 2021 und dem 6.
            Oktober 2021 in den Büroräumen von AGCO SAS statt.- Massey Ferguson
            Marketing-Abteilung EME in Beauvais − Frankreich. Die Massey
            Ferguson Marketing-Abteilung EME in Beauvais wird die Ziehung unter
            Ausschluss der Öffentlichkeit durchführen.
          </Text>
          <Text color="red.500" fontWeight="bold" mt="1%">
            Artikel 5 – Preise
          </Text>
          <Text color="white" mt="1%">
            Die Preisziehung findet in drei Kategorien statt, pro Kategorie gibt
            es drei Preise zu gewinnen. Insgesamt gibt es bei diesem Gewinnspiel
            also neun Gewinner. Durch die Beantwortung der Fragen nach der
            Registrierung erhalten die Teilnehmer die Möglichkeit, ihren
            Punktestand zu erhöhen und dadurch die höchste Stufe mit den besten
            Preisen in diesem Wettbewerb zu erreichen.
          </Text>
          <Text color="white" mt="1%">
            Nach dem Event wird jeder Teilnehmer abhängig von seiner
            Gesamtwertung im Gewinnspiel einer der drei unten stehenden
            Preisziehungen zugewiesen. − Die endgültige Platzierung wird einen
            Tag nach dem Event auf der Internetplattform bekannt gegeben
            (3. September 2021, 15:00 Uhr). Auch wenn der Teilnehmer keine der
            Fragen beantwortet, nimmt er automatisch an Stufe 3 der Preisziehung
            teil.
          </Text>

          <Text color="white" fontWeight="bold" mt="1%" fontStyle="italic">
            Stufe 1: Im Wert von bis zu 20.000 Euro – Gesamtplatzierung: 1−1.000
          </Text>
          <Text color="white" mt="1%">
            Teilnehmer, die im Gewinnspiel eine der Platzierungen 1−1.000
            erreicht haben, werden bei der Preisziehung dem Gewinn der Stufe 1
            zugeordnet.
            <br /> Auf dieser Stufe werden drei Preise verlost, mit einem
            Höchstwert von 20.580 €.
          </Text>
          <UnorderedList color="white" ml="40px" mt="1%">
            <ListItem>
              Der erste Preis in Stufe 1 ist ein Rabatt in Höhe von bis zu
              10.000 Euro beim Kauf einer neuen MF Maschine.
              <br />
              <br />
              Dieser Rabatt gilt nur für den Kauf einer Neumaschine:
              Futtererntemaschinen, Traktoren, Mähdrescher und Teleskoplader
              (abhängig vom Lagerbestand der Maschinen beim Vertriebspartner)
              aus dem Produktsortiment von MASSEY FERGUSON bei einem Kauf vor
              dem 31. März 2022.
              <br />
              <br />
              Rabattregelungen (*basierend auf den Listenpreisen, einschließlich
              Optionen):
              <br />
              <SimpleGrid
                w={isMobile ? "100%" : "50%"}
                autoFlow=""
                borderColor="white"
                columns={[2, null, 2]}
                border="1px solid white"
                mt="5px"
              >
                <Box bg="red.500" border="1px solid white">
                  <Text
                    color="white"
                    fontWeight="bold"
                    padding="1.5"
                    fontSize={isMobile ? ".7rem" : "1rem"}
                  >
                    Wert der Neumaschine*:{" "}
                  </Text>
                </Box>
                <Box bg="red.500" border="1px solid white">
                  <Text
                    color="white"
                    fontWeight="bold"
                    padding="1.5"
                    fontSize={isMobile ? ".7rem" : "1rem"}
                  >
                    Preisnachlass{" "}
                  </Text>
                </Box>
                <Box border="1px solid white">
                  <Text
                    color="white"
                    padding="1.5"
                    fontSize={isMobile ? ".7rem" : "1rem"}
                  >
                    Unter 25.000 €*{" "}
                  </Text>
                </Box>
                <Box border="1px solid white">
                  <Text
                    color="white"
                    padding="1.5"
                    fontSize={isMobile ? ".7rem" : "1rem"}
                  >
                    1500€
                  </Text>
                </Box>
                <Box border="1px solid white">
                  <Text
                    color="white"
                    padding="1.5"
                    fontSize={isMobile ? ".7rem" : "1rem"}
                  >
                    Zwischen 25.000 € und 50.000 €*{" "}
                  </Text>
                </Box>
                <Box border="1px solid white">
                  <Text
                    color="white"
                    padding="1.5"
                    fontSize={isMobile ? ".7rem" : "1rem"}
                  >
                    2500€
                  </Text>
                </Box>
                <Box border="1px solid white">
                  <Text
                    color="white"
                    padding="1.5"
                    fontSize={isMobile ? ".7rem" : "1rem"}
                  >
                    Zwischen 50.000 € und 100.000 €*{" "}
                  </Text>
                </Box>
                <Box border="1px solid white">
                  <Text
                    color="white"
                    padding="1.5"
                    fontSize={isMobile ? ".7rem" : "1rem"}
                  >
                    4000€
                  </Text>
                </Box>
                <Box border="1px solid white">
                  <Text
                    color="white"
                    padding="1.5"
                    fontSize={isMobile ? ".7rem" : "1rem"}
                  >
                    Zwischen 100.000 € und 150.000 €*{" "}
                  </Text>
                </Box>
                <Box border="1px solid white">
                  <Text
                    color="white"
                    padding="1.5"
                    fontSize={isMobile ? ".7rem" : "1rem"}
                  >
                    6000€
                  </Text>
                </Box>
                <Box border="1px solid white">
                  <Text
                    color="white"
                    padding="1.5"
                    fontSize={isMobile ? ".7rem" : "1rem"}
                  >
                    Zwischen 150.000 € und 200.000 €*{" "}
                  </Text>
                </Box>
                <Box border="1px solid white">
                  <Text
                    color="white"
                    padding="1.5"
                    fontSize={isMobile ? ".7rem" : "1rem"}
                  >
                    8000€
                  </Text>
                </Box>
                <Box border="1px solid white">
                  <Text
                    color="white"
                    padding="1.5"
                    fontSize={isMobile ? ".7rem" : "1rem"}
                  >
                    Ab 200.000 €*{" "}
                  </Text>
                </Box>
                <Box border="1px solid white">
                  <Text
                    color="white"
                    padding="1.5"
                    fontSize={isMobile ? ".7rem" : "1rem"}
                  >
                    10 000€
                  </Text>
                </Box>
              </SimpleGrid>
            </ListItem>
            <ListItem mt="26px">
              Der zweite Preis, der auf Stufe 1 verlost wird, ist das optionale
              Ausstattungspaket MF Guide RTK-Korrektursignal für kompatible MF
              Traktoren/Mähdrescher, die vor dem 31. März 2022 gekauft werden.
              <br />
              <br />
              Die Option MF Guide muss beim Kauf der Maschine vom Gewinner
              ausgewählt werden.
              <br />
              Der maximale Wert dieses Preises darf 16.060 € nicht übersteigen.
              Die jährlichen Abonnementgebühren sind nicht im Gewinn enthalten
              (und werden dem Gewinner in Rechnung gestellt).
            </ListItem>
            <ListItem mt="26px">
              Der dritte und letzte Preis, den die Teilnehmer auf Stufe 1
              gewinnen können, ist ein MF 1520 A Stufe 5 im Wert von 19.890 €*,
              einschließlich einer Reifenoption im Wert von 690 €* (*
              Listenpreis 1. Februar 2021).
            </ListItem>
          </UnorderedList>

          <Text color="white" mt="1%">
            Im gesamten Gewinnspiel kann ein Teilnehmer nur einen Preis
            gewinnen, alle Stufen eingeschlossen.
          </Text>
          <Text color="white" fontWeight="bold" mt="1%" fontStyle="italic">
            Stufe 2: Im Wert von bis zu 5.000 Euro – Gesamtplatzierung:
            1.001−2.000
          </Text>
          <Text color="white" mt="1%">
            Teilnehmer, die im Wettbewerb eine der Platzierungen 1.001−2.000
            erreicht haben, werden bei der Preisziehung dem Gewinn der Stufe 2
            zugeordnet.
            <br />
            Auf dieser Stufe werden drei Preise verlost, mit einem Höchstwert
            von 5.000 €.
          </Text>

          <UnorderedList color="white" ml="40px" mt="1%">
            <ListItem>
              Beim ersten Preis auf Stufe 2 handelt es sich um ein
              MF Summer Paket im Gesamtwert von 1.000 €. Dieses Pack beinhaltet
              :
              <UnorderedList color="white">
                <ListItem ml="20px" type="circle">
                  Ein MF/AGCO Parts Ölfass-Grill und 4 MF/AGCO Parts Liegestühle
                  im Gesamtwert von 340 €
                </ListItem>
              </UnorderedList>
              <Text color="white" ml="-40px">
                und
              </Text>
              <UnorderedList color="white">
                <ListItem ml="20px" type="circle">
                  Ein personalisiertes iPad Air Grey Wi-Fi 64 Go im Wert von
                  669 €
                </ListItem>
              </UnorderedList>
            </ListItem>
          </UnorderedList>

          <Text color="white" mt="1%">
            Die Preise werden dem Gewinner separat geliefert.
          </Text>

          <UnorderedList color="white" ml="40px">
            <ListItem mt="1%">
              Der zweite Preis, der auf Stufe 2 verlost wird, ist ein
              professioneller Hochdruckreiniger im Wert von 2.000 €.
              <br />
              <br />
              Das präsentierte Hochdruckreiniger-Modell weicht möglicherweise
              vom tatsächlichen Preis ab, dies ist abhängig von der Region des
              Gewinners. Unser AGCO Parts Zusatzprodukte-Team wird den Gewinner
              nach der Auslosung über das tatsächliche Modell informieren.
            </ListItem>
            <ListItem mt="1%">
              Der dritte und letzte Preis auf Stufe 2 im Gesamtwert von 5000 €
              ist ein MF Service-Paket, das Folgendes beinhaltet:
              <UnorderedList color="white" listStyleType="circle" mt="1%">
                <ListItem>
                  Gutschein im Wert von 5.000 €, einlösbar für sämtliche im MF
                  Shop des Vertriebspartners des Gewinners verfügbaren Produkte
                  aus dem Sortiment von AGCO Parts.
                </ListItem>
                <ListItem mt="1%">
                  Ein Besuch vom technischen Kundendienstzentrum von AGCO und
                  Ihres MF örtlichen Vertriebspartners, bei dem Sie wertvolle
                  Hinweise erhalten, wie Sie das Optimum aus Ihrem Massey
                  Ferguson Traktor und Ihrem Teleskoplader herausholen können.
                </ListItem>
              </UnorderedList>
            </ListItem>
          </UnorderedList>

          <Text color="white" mt="1%">
            Im gesamten Gewinnspiel kann ein Teilnehmer nur einen Preis
            gewinnen, alle Stufen eingeschlossen.
          </Text>

          <Text color="white" fontWeight="bold" mt="2%" fontStyle="italic">
            Stufe 3: Im Wert von bis zu 250 Euro – Gesamtplatzierung: 2.001 bis
            letzter Platz
          </Text>

          <Text color="white" mt="2%">
            Teilnehmer, die im Wettbewerb eine der Platzierungen ab 2.001 bis
            zum letzten Platz erreicht haben, werden bei der Preisziehung dem
            Gewinn der Stufe 3 zugeordnet.
            <br /> Auf dieser Stufe werden drei Preise verlost, mit einem
            Gesamtwert von 250 €.
          </Text>

          <UnorderedList color="white " mt="1%">
            <ListItem>
              Der erste Preis, der auf Stufe 3 verlost wird, ist ein
              MF Connectivity Pack, das drei exklusive Geschenke von
              MASSEY FERGUSON beinhaltet :
              <UnorderedList color="white">
                <ListItem type="circle">
                  Eine Smartwatch von MASSEY FERGUSON − Stückpreis: 180 €
                </ListItem>
                <ListItem type="circle">
                  Ear-Pods von MASSEY FERGUSON − Stückpreis: 39,90 €
                </ListItem>
                <ListItem type="circle">
                  Eine Powerbank aus der MASSEY FERGUSON S Kollektion −
                  Stückpreis: 29,90 €
                </ListItem>
                <Box display="block" mt="1%"></Box>
              </UnorderedList>
            </ListItem>

            <ListItem mt="1%">
              Beim zweiten Preis, der auf Stufe 3 verlost wird, handelt es sich
              um einen E-Gutschein im Wert von 250 €, der auch in Teilbeträgen
              im Online-Shop von MASSEY FERGUSON eingelöst werden kann:{" "}

              <Link href="https://shop.masseyferguson.com">
                https://shop.masseyferguson.com
              </Link>.
              Dieser E-Gutschein ist nach dem Erhalt für 36 Monate gültig.
            </ListItem>

            <ListItem mt="1%">
              Der dritte und letzte Preis auf Stufe 3 ist ein Spiele-Paket mit
              folgendem Inhalt:
              <UnorderedList color="white" listStyleType="circle">
                <ListItem>
                  Ein maßstabsgetreues Modell des MF 8S − Stückpreis: 70 €
                </ListItem>
                <ListItem>
                  Exklusives maßstabsgetreues Modell des MF 5S − Stückpreis: 65 
                  €
                </ListItem>
                <ListItem>
                  Eine drahtlose PC-Maus von MASSEY FERGUSON − Stückpreis: 15  €
                </ListItem>
                <ListItem>
                  2 „Farming Simulator 2022“ für den PC − Stückpreis: 40  €
                </ListItem>
                <ListItem>
                  Exklusive FS Baseball-Kappe „Farming Simulator 2022“ −
                  Stückpreis: 20 €
                </ListItem>
              </UnorderedList>
            </ListItem>
          </UnorderedList>
          <Text color="white" mt="1%">
            Im gesamten Gewinnspiel kann ein Teilnehmer nur einen Preis
            gewinnen, alle Stufen eingeschlossen.
          </Text>
          <Text color="red.500" fontWeight="bold" mt="1%">
            Artikel 6 − Benachrichtigung und Verkündung der Gewinner
          </Text>
          <Text color="white" mt="1%">
            Das Marketing-Team von Massey Ferguson wird die Gewinner per E-Mail
            benachrichtigen und darum bitten, AGCO zu kontaktieren.
            <br />
            Falls die in der Ziehung ausgewählte Person diese E-Mail nicht
            innerhalb von zehn Tagen beantwortet, findet eine zweite, finale
            Ziehung statt und der erste Gewinner wird disqualifiziert.
            <br />
            Die Gewinner werden durch das Unternehmen AGCO SAS per E-Mail oder
            auf anderem Wege kontaktiert, um ihnen die für die Preiszustellung
            erforderlichen Informationen zu übermitteln.
            <br />
            Der Teilnehmer erklärt sich damit einverstanden, dass sein Name im
            Falle eines Gewinns bei Werbeaktionen, auf relevanten Webseiten oder
            in den sozialen Medien veröffentlicht wird.
            <br />
            Der Gewinner wird sich kostenlos zur Erstellung von Audio-, Foto-,
            Bild- und/oder Textmaterial für Werbeaktionen im Radio, Internet,
            Fernsehen oder in den Printmedien zur Verfügung stellen und erklärt
            sich mit der Veröffentlichung und Nutzung dieses Materials zu
            Werbezwecken durch AGCO und AGCO zugehörigen Unternehmen
            einverstanden.
            <br />
          </Text>
          <Text color="red.500" fontWeight="bold" mt="1%">
            Artikel 7 − Ausschluss von Teilnehmern
          </Text>

          <Text color="white" mt="1%">
            AGCO behält sich das Recht vor, Teilnehmer vom Wettbewerb
            auszuschließen. Dies gilt insbesondere bei Verstößen gegen die
            Teilnahmebedingungen, bei Angabe falscher persönlicher Daten oder
            bei Manipulation bzw. beim Verdacht auf Manipulation. AGCO kann
            zudem Teilnehmer ausschließen, bei denen der begründete Verdacht
            besteht, dass sie die Dienstleistungen von AGCO nutzen.
            <br />
            Falls AGCO im Nachhinein herausfindet, dass eine Person von der
            Teilnahme ausgeschlossen werden sollte oder gemäß Artikel 2 nicht
            berechtigt war, am Gewinnspiel teilzunehmen, kann AGCO die Lieferung
            oder Übergabe des Preises verweigern bzw. bereits übergebene Preise
            zurückverlangen.
          </Text>
          <Text color="red.500" fontWeight="bold" mt="1%">
            Artikel 8 – Beanspruchung der Preise
          </Text>
          <Text color="white" mt="1%">
            Es wird unter keinen Umständen möglich sein, eine finanzielle
            Entschädigung, Rückzahlung oder Bargeld zu erhalten.
            <br />
            Falls äußere Umstände die Vergabe der genannten Preise verhindern
            sollten, kann der Veranstalter sie durch andere Preise von gleichem
            Wert ersetzen. Dies ist nicht verhandelbar. Eine Barauszahlung ist
            nicht möglich.
            <br />
            Das Marketing Team von Massey Ferguson sendet dem Gewinner seinen
            Preis innerhalb von drei Monaten nach Zugang aller für die Lieferung
            nötigen Informationen zu.
          </Text>
          <Text color="red.500" fontWeight="bold" mt="1%">
            Artikel 9 – Nutzungsbedingungen und Gewährleistung
          </Text>
          <Text color="white" mt="1%">
            Der Veranstalter übernimmt keinerlei Haftung für Unfälle oder
            Vorkommnisse während oder aufgrund der Nutzung der vergebenen
            Preise.
            <br />
            Sollten die Umstände es erfordern, behält sich der Veranstalter das
            Recht vor, diesen Wettbewerb zu verkürzen, zu verlängern, zu ändern
            oder abzubrechen, ohne dass daraus irgendeine Haftung für ihn
            entstünde.
            <br />
            Der Veranstalter kann nicht für Serviceunterbrechungen haftbar
            gemacht werden, die von einer technischen Störung der
            Telekommunikationsnetzwerke oder einer anderen technischen Störung
            außerhalb seiner Kontrolle verursacht wurden. Der Veranstalter kann
            nicht haftbar gemacht werden, wenn der Wettbewerb aus irgendeinem
            Grund teilweise oder vollständig geändert, verschoben oder beendet
            wird. In diesen Fällen kann keine Entschädigung verlangt werden.
            Nicht teilnahmeberechtigte, unvollständige oder gefälschte
            Teilnahmeformulare werden nicht berücksichtigt. Deshalb kann der
            Veranstalter nicht haftbar gemacht werden, wenn Teilnahmeformulare
            nicht registriert wurden oder nicht überprüft werden können.
            <br />
          </Text>
          <Text color="red.500" fontWeight="bold" mt="1%">
            Artikel 10 – Einverständniserklärung
          </Text>

          <Text color="white" mt="1%">
            Durch ihre Teilnahme am Wettbewerb erklären sich alle Teilnehmer mit
            diesen Teilnahmebedingungen einverstanden.
            <br />
            Jeder Teilnehmer ist sich darüber im Klaren, dass ihm aufgrund des
            Zufallsfaktors außer der Möglichkeit eines zufälligen Gewinns
            keinerlei Zusicherung gegeben werden kann.
            <br />
            Eventuelle Streitfälle oder Reklamationen sind bis spätestens zum 3.
            September (Datum des Poststempels) auf dem Postweg an folgende
            Adresse zu richten: AGCO SAS Legal Department – 41, Avenue Blaise
            Pascal – BP 60307 – 60026 Beauvais – Frankreich. Die
            Teilnahmebedingungen unterliegen ausschließlich französischem Recht.
            <br />
            Die AGCO SAS – MASSEY FERGUSON EME Marketing Abteilung gibt
            Auskünfte in Bezug auf praktische Schwierigkeiten sowie die
            Auslegung und Anwendung dieser Bestimmungen.
            <br />
          </Text>
          <Text color="red.500" fontWeight="bold" mt="1%">
            Artikel 11 – Bereitstellung der Teilnahmebedingungen des Wettbewerbs
          </Text>
          <Text color="white" mt="1%">
            Diese allgemeinen Teilnahmebedingungen können online auf der
            offiziellen MF Event-Plattform abgerufen werden.
            <br />
            Der Teilnehmer mit einer Internetverbindung kann keine
            Rückerstattung verlangen, da zur Teilnahme keine zusätzlichen Kosten
            entstehen.
            <br />
            Eine vollständige Fassung dieser Teilnahmebedingungen liegt zudem in
            der Rechtsabteilung des Veranstalters aus.
            <br />
          </Text>
          <Text color="red.500" fontWeight="bold" mt="1%">
            Artikel 12 – Datenschutz
          </Text>
          <Text color="white" mt="1%">
            Zur Teilnahme an diesem Wettbewerb müssen die Teilnehmer sich
            zwingend damit einverstanden erklären, dass persönliche
            Informationen über sie vom Veranstalter zur Durchführung des
            Wettbewerbs sowie zu Werbezwecken verwendet werden.
            <br />
            Weitere Informationen finden Sie in unserer Datenschutzerklärung
            unter der Adresse{" "}
            <Link href="http://www.agcocorp.com/privacy.html">
              http://www.agcocorp.com/privacy.html
            </Link>
            <br />
            Jeder Gewinner erklärt sich damit einverstanden, dem Veranstalter
            ohne finanzielle oder anderweitige Gegenleistung das Recht zu
            gewähren, seinen Vor- und Nachnamen, seinen Wohnort, sein Foto und
            die von ihm im Zusammenhang mit dem Preis gemachten Fotos in allen
            Marketing-, Werbe- oder redaktionellen Materialien zu verwenden.
            <br />
            In Übereinstimmung mit den geltenden Vorschriften können Sie Ihre
            Teilnahme jederzeit ganz oder teilweise widerrufen, ohne dies
            gegenüber AGCO begründen zu müssen.Ihre Rechte auf Zugriff,
            Berichtigung, Löschung und Widerruf können Sie per E-Mail an
            dataprivacy@agcocorp.com geltend machen.
          </Text>
        </Flex>
      </chakra.div>
    </Container>
  );
};

export default TermsAndConditionsContentDE;
