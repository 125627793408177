import { DailyTrackState } from '@daily-co/daily-js'

export function getTrackUnavailableMessage(
  kind: 'video' | 'audio',
  trackState: null | DailyTrackState,
) {
  if (!trackState) {
    return
  }

  switch (trackState.state) {
    case 'blocked':
      if (trackState.blocked?.byPermissions) {
        return `${kind} permission denied`
      } else if (trackState.blocked?.byDeviceMissing) {
        return `${kind} device missing`
      }
      return `${kind} blocked`
    case 'off':
      if (trackState.off?.byUser) {
        return `${kind} muted`
      } else if (trackState.off?.byBandwidth) {
        return `${kind} muted to save bandwidth`
      }
      return `${kind} off`
    case 'sendable':
      return `${kind} not subscribed`
    case 'loading':
      return `${kind} loading...`
    case 'interrupted':
      return `${kind} interrupted`
    case 'playable':
      return null
  }
}
