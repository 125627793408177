import React, { useState, useRef } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";

import {
  chakra,
  useBreakpointValue,
  useMultiStyleConfig,
  Container,
  Text,
  Link,
  Flex,
  Divider,
  ListItem,
  UnorderedList,
  SimpleGrid,
  Box,
} from "@chakra-ui/react";

import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import axiosHelper from "./../../helpers/axiosHelper";
import bgImage from "./../../assets/prizes/prizes-page-bg@2x.jpg";

import { ChevronLeftIcon, ChevronRightIcon } from "@chakra-ui/icons";

const TermsAndConditionsContentPTBR = ({ ...props }) => {
  const { t } = useTranslation();
  const history = useHistory();
  const [userProfile, setUserProfile] = useState<UserProfile>(
    axiosHelper.getUserProfile()!
  );
  const styles = useMultiStyleConfig("PrizePage", props);

  const isMobile = useBreakpointValue({ base: true, lg: false });
  const customSlider = useRef<any>();

  return (
    <Container
      maxW="container.xl"
      bgImage={bgImage}
      d="flex"
      flexDir="column"
      flexDirection="column"
      style={{
        overflow: "hidden",
        backgroundPosition: "center",
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
      }}
      alignItems="center"
    >
      <Flex
        flexDir={{ base: "column", lg: "column" }}
        justifyContent="space-between"
        w={isMobile?"100%":"50%"}
        mt="2%"
      >
        <Text color="white" mb="2%" mt={isMobile ? "30px" : "0"} align="center">
          TERMOS E CONDIÇÕES
          <br />
          SUPER CONCURSO MF
          <br />
          Organizado de 2 de julho a 3 de setembro de 2021
          <br />
          Pela marca MASSEY FERGUSON AGCO
          <br />
        </Text>
      </Flex>
      <chakra.div w="100%" pb="100px">
        <Flex
          flexDir={{ base: "column", lg: "column" }}
          justifyContent="space-between"
          w="100%"
          mt="3%"
        >
          <Text color="red.500" mt="1%" fontWeight="bold">
            Artigo 1 – Organização Geral
          </Text>
          <Text color="white" mt="1%">
            O departamento de Marketing da MASSEY FERGUSON para a Europa e o
            Oriente Médio (EME) da AGCO SAS, sediado no endereço 41 Blaise
            Pascal Avenue em Beauvais, está organizando o NOVO EVENTO DIGITAL DA
            MASSEY FERGUSON para o dia 3 de setembro de 2021, que inclui um
            concurso gratuito e não-obrigatório para o público em geral.
          </Text>

          <Text color="red.500" fontWeight="bold" mt="1%">
            Artigo 2 – Elegibilidade para a participação
          </Text>

          <Text color="white" mt="1%">
            São elegíveis a participar do concurso somente os participantes dos
            países da Europa e do Oriente Médio (com exceção dos países sujeitos
            a medidas comerciais restritivas). Somente agricultores,
            empreiteiros e pessoas vinculadas a empresas agrícolas têm permissão
            para vencer a competição.
          </Text>

          <Text color="white" mt="1%">
            Excluem-se:
          </Text>

          <chakra.div>
            <UnorderedList ml="40px" color="white">
              <ListItem>
                Todas as pessoas com menos de 18 anos de idade.
              </ListItem>
              <ListItem>
                Funcionários do Grupo AGCO e seus respectivos familiares
              </ListItem>
              <ListItem>
                Concessionárias e distribuidoras de produtos e serviços AGCO
              </ListItem>
              <ListItem>Funcionários de empresas afiliadas à AGCO</ListItem>
              <ListItem>
                Funcionários de agências e fornecedores que têm contrato com a
                AGCO e seus respectivos familiares
              </ListItem>
              <ListItem>
                Jornalistas convidados pela MASSEY FERGUSON para participar do
                evento
              </ListItem>
            </UnorderedList>
          </chakra.div>
          <Text color="white" mt="1%">
            É permitida somente uma inscrição por pessoa, por domicílio e por
            entidade agrícola com o mesmo nome, o mesmo sobrenome, o mesmo
            número de telefone e o mesmo endereço de e-mail. A participação no
            concurso é estritamente pessoal. A empresa organizadora reserva-se o
            direito de realizar qualquer fiscalização que julgue pertinente.
          </Text>
          <Text color="red.500" fontWeight="bold" mt="1%">
            Artigo 3 – Termos para a participação
          </Text>
          <Text color="white" mt="1%">
            Ao inscrever-se no NOVO EVENTO DIGITAL DA MASSEY FERGUSON através da
            plataforma virtual da divisão organizadora da AGCO SAS, o
            participante automaticamente integra o concurso, pois ele é a base
            do evento. O participante concorda em dar acesso a suas informações
            pessoais para que a AGCO SAS possa entrar em contato por meio de
            e-mail, telefone ou rede social.
          </Text>
          <Text color="red.500" fontWeight="bold" mt="1%">
            Artigo 4 – Definição dos vencedores
          </Text>
          <Text color="white" mt="1%">
            O resultado do concurso será anunciado entre os dias 6 de setembro e
            6 de outubro de 2021, na sede da AGCO SAS. - Departamento de
            Marketing da Massey Ferguson para EME em Beauvais – França.
          </Text>
          <Box display="block" mt="1%"></Box>
          <Text color="white">
            O sorteio será realizado entre 6 de setembro e 6 de outubro de 2021,
            na sede da AGCO SAS. - Departamento de Marketing da Massey Ferguson
            para EME em Beauvais – França. O Departamento de Marketing da Massey
            Ferguson para EME em Beauvais conduzirá o sorteio a portas fechadas.
          </Text>
          <Text color="red.500" fontWeight="bold" mt="1%">
            Artigo 5 – Prêmios
          </Text>
          <Text color="white" mt="1%">
            Haverá vencedores em 3 níveis, com 3 prêmios por nível, de modo que
            serão, ao total, 9 vencedores no concurso. Ao responder às perguntas
            após a inscrição, o participante terá a possibilidade de aumentar
            sua pontuação e tentar atingir o mais alto nível de prêmios nesse
            concurso.
          </Text>
          <Text color="white" mt="1%">
            Depois do evento, cada participante será distribuído em um dos 3
            sorteios de prêmios descritos abaixo, dependendo de sua posição
            final na classificação do concurso – A posição final será declarada
            na plataforma virtual logo após o evento (no dia 3 de setembro de
            2021 às 15h). Mesmo que o participante não tenha respondido a
            nenhuma pergunta, ele será incluído automaticamente no nível 3 de
            premiações.
          </Text>

          <Text color="white" fontWeight="bold" mt="1%" fontStyle="italic">
            Nível 1: Valor equivalente a até 20.000 euros - Classificação Geral:
            1 - 1.000
          </Text>
          <Text color="white" mt="1%">
            Os participantes com posição 1 a 1.000 no concurso serão
            direcionados ao sorteio de prêmios do nível 1.
            <br /> Nesse nível, há 3 prêmios, no valor máximo de 20.580 euros.
          </Text>
          <UnorderedList color="white" ml="40px" mt="1%">
            <ListItem>
              O primeiro prêmio do nível 1 é um desconto de até 10.000 euros na
              compra de uma nova máquina da MF.
              <br />
              <br />
              Esse desconto se aplica somente à compra de uma máquina nova:
              ferramentas para fenação, tratores, colheitadeiras, enfardadoras e
              manipuladores telescópicos (levando em conta o estoque de máquinas
              na concessionária) da linha MASSEY FERGUSON, realizada antes de 31
              de março de 2022.
              <br />
              <br />
              Regras em relação ao Desconto (*com base em preços de tabela,
              opções incluídas):
              <br />
              <SimpleGrid
                w={isMobile ? "100%" : "50%"}
                autoFlow=""
                borderColor="white"
                columns={[2, null, 2]}
                border="1px solid white"
                mt="5px"
              >
                <Box bg="red.500" border="1px solid white">
                  <Text
                    color="white"
                    fontWeight="bold"
                    padding="1.5"
                    fontSize={isMobile ? ".7rem" : "1rem"}
                  >
                    Valor da nova máquina*:
                  </Text>
                </Box>
                <Box bg="red.500" border="1px solid white">
                  <Text
                    color="white"
                    fontWeight="bold"
                    padding="1.5"
                    fontSize={isMobile ? ".7rem" : "1rem"}
                  >
                    Desconto a aplicar
                  </Text>
                </Box>
                <Box border="1px solid white">
                  <Text
                    color="white"
                    padding="1.5"
                    fontSize={isMobile ? ".7rem" : "1rem"}
                  >
                    Abaixo de 25.000 euros*
                  </Text>
                </Box>
                <Box border="1px solid white">
                  <Text
                    color="white"
                    padding="1.5"
                    fontSize={isMobile ? ".7rem" : "1rem"}
                  >
                    1500€
                  </Text>
                </Box>
                <Box border="1px solid white">
                  <Text
                    color="white"
                    padding="1.5"
                    fontSize={isMobile ? ".7rem" : "1rem"}
                  >
                    Entre 25.000 euros &gt; 50.000 euros*{" "}
                  </Text>
                </Box>
                <Box border="1px solid white">
                  <Text
                    color="white"
                    padding="1.5"
                    fontSize={isMobile ? ".7rem" : "1rem"}
                  >
                    2500€
                  </Text>
                </Box>
                <Box border="1px solid white">
                  <Text
                    color="white"
                    padding="1.5"
                    fontSize={isMobile ? ".7rem" : "1rem"}
                  >
                    Entre 50.000 euros &gt; 100.000 euros*{" "}
                  </Text>
                </Box>
                <Box border="1px solid white">
                  <Text
                    color="white"
                    padding="1.5"
                    fontSize={isMobile ? ".7rem" : "1rem"}
                  >
                    4000€
                  </Text>
                </Box>
                <Box border="1px solid white">
                  <Text
                    color="white"
                    padding="1.5"
                    fontSize={isMobile ? ".7rem" : "1rem"}
                  >
                    Entre 100.000 euros &gt; 150.000 euros*{" "}
                  </Text>
                </Box>
                <Box border="1px solid white">
                  <Text
                    color="white"
                    padding="1.5"
                    fontSize={isMobile ? ".7rem" : "1rem"}
                  >
                    6000€
                  </Text>
                </Box>
                <Box border="1px solid white">
                  <Text
                    color="white"
                    padding="1.5"
                    fontSize={isMobile ? ".7rem" : "1rem"}
                  >
                    Entre 150.000 euros &gt; 200.000 euros*{" "}
                  </Text>
                </Box>
                <Box border="1px solid white">
                  <Text
                    color="white"
                    padding="1.5"
                    fontSize={isMobile ? ".7rem" : "1rem"}
                  >
                    8000€
                  </Text>
                </Box>
                <Box border="1px solid white">
                  <Text
                    color="white"
                    padding="1.5"
                    fontSize={isMobile ? ".7rem" : "1rem"}
                  >
                    A partir de 200.000 euros*
                  </Text>
                </Box>
                <Box border="1px solid white">
                  <Text
                    color="white"
                    padding="1.5"
                    fontSize={isMobile ? ".7rem" : "1rem"}
                  >
                    10 000 euros
                  </Text>
                </Box>
              </SimpleGrid>
            </ListItem>
            <ListItem mt="26px">
              O segundo prêmio no nível 1 é uma opção de MF Guide com corretor
              RTK gratuita para qualquer trator ou colheitadeira MF compatível
              adquirido antes de 31 de março de 2022.
              <br />
              <br />
              A opção do MF Guide precisa ser escolhida pelo vencedor no momento
              da compra da máquina.
              <br />O valor máximo desse prêmio não excederá 16.060 euros.
              Excluem-se as tarifas de assinatura anuais (cobradas do vencedor).
            </ListItem>
            <ListItem mt="26px">
              O terceiro e último prêmio no nível 1 é um trator MF 1520 A Stage
              5, no valor de 19.890 euros*, incluindo uma opção de pneus
              equivalente a 690 euros* (* Preço de Tabela de 1º de fevereiro de
              2021)
            </ListItem>
          </UnorderedList>

          <Text color="white" mt="1%">
            Cada participante poderá ganhar, ao longo de todo o concurso, dentre
            todos os níveis juntos, somente um prêmio.
          </Text>
          <Text color="white" fontWeight="bold" mt="1%" fontStyle="italic">
            Nível 2: Valor equivalente a até 5.000 euros - Classificação Geral:
            1.001 - 2.000
          </Text>
          <Text color="white" mt="1%">
            Os participantes com posição 1.001 a 2.000 no concurso serão
            direcionados ao sorteio de prêmios do nível 2.
            <br />
            Nesse nível, há 3 prêmios, no valor máximo de 5.000 euros.
          </Text>

          <UnorderedList color="white" ml="40px" mt="1%">
            <ListItem>
              O primeiro prêmio do nível 2 é um MF Summer Pack no valor total de
              1.000 euros, incluindo:
              <UnorderedList color="white">
                <ListItem ml="20px" type="circle">
                  Uma churrasqueira de barril a óleo MF/AGCO Parts e 4 cadeiras
                  dobráveis MF/AGCO, no valor total de 340 euros
                </ListItem>
              </UnorderedList>
              <Text color="white" ml="-40px">
                E
              </Text>
              <UnorderedList color="white">
                <ListItem ml="20px" type="circle">
                  Um iPad Air Grey WIFI 64 GO personalizado no valor de 669
                  euros
                </ListItem>
              </UnorderedList>
            </ListItem>
          </UnorderedList>

          <Text color="white" mt="1%">
            Esses prêmios serão entregues ao vencedor separadamente.
          </Text>

          <UnorderedList color="white" ml="40px">
            <ListItem mt="1%">
              O segundo prêmio no nível 2 é uma lavadora de alta pressão
              profissional, no valor de 2.000 euros.
              <br />
              <br />O modelo da lavadora de alta pressão não é determinado em
              contrato e pode variar em função da região/mercado do vencedor.
              Nossa equipe integrada da AGCO Parts proverá ao vencedor os
              detalhes exatos sobre o modelo depois do sorteio.
            </ListItem>
            <ListItem mt="1%">
              O terceiro e último prêmio no nível 2, no valor total de 5.000
              euros, é um pacote MF Service que inclui:
              <UnorderedList color="white" listStyleType="circle" mt="1%">
                <ListItem>
                  Voucher de 5.000 euros, que pode ser usado na loja MF local da
                  concessionária do vencedor para aquisição de itens AGCO Parts
                  disponíveis.
                </ListItem>
                <ListItem mt="1%">
                  A oportunidade de receber uma visita em sua fazenda de um
                  agente de nosso Centro de Serviços Técnicos AGCO, acompanhado
                  de nosso MF concessionário local, para receber consultoria
                  sobre o uso de equipamentos para tratores e manipuladores
                  telescópicos da Massey Ferguson.
                </ListItem>
              </UnorderedList>
            </ListItem>
          </UnorderedList>

          <Text color="white" mt="1%">
            Cada participante poderá ganhar, ao longo de todo o concurso, dentre
            todos os níveis juntos, somente um prêmio.
          </Text>

          <Text color="white" fontWeight="bold" mt="2%" fontStyle="italic">
            Nível 3: Valor equivalente a até 250 euros - Classificação Geral:
            2.001 - último colocado
          </Text>

          <Text color="white" mt="2%">
            Os participantes a partir da posição 2001 até a última classificação
            serão direcionados ao sorteio de prêmios do nível 3.
            <br /> Nesse nível, há 3 prêmios, no valor similar total de 250
            euros.
          </Text>

          <UnorderedList color="white " mt="1%">
            <ListItem>
              O primeiro prêmio do nível 3 é o Pacote Conectividade, que inclui
              3 presentes exclusivos da MASSEY FERGUSON:
              <UnorderedList color="white">
                <ListItem type="circle">
                  Um MASSEY FERGUSON Smart Watch – Valor unitário: 180 euros
                </ListItem>
                <ListItem type="circle">
                  Um conjunto de EarPods MASSEY FERGUSON – Valor unitário: 39,90
                  euros
                </ListItem>
                <ListItem type="circle">
                  Um carregador portátil Powerbank MASSEY FERGUSON S Collection
                  – Valor unitário: 29,90 euros
                </ListItem>
                <Box display="block" mt="1%"></Box>
              </UnorderedList>
            </ListItem>

            <ListItem mt="1%">
              O segundo prêmio do nível 3 é um E-Voucher no valor de 250 euros,
              que pode ser usado em diversas parcelas na loja online da MASSEY
              FERGUSON:{" "}
              <Link href="https://shop.masseyferguson.com">
                https://shop.masseyferguson.com
              </Link>.{" "}
              Esse e-voucher é válido pelo período de 36 meses após a entrega do
              prêmio.
            </ListItem>

            <ListItem mt="1%">
              O terceiro e último prêmio do nível 3 é um Pacote Game, que
              inclui:
              <UnorderedList color="white" listStyleType="circle">
                <ListItem>
                  Uma miniatura do trator MF 8S – Valor unitário: 70 euros
                </ListItem>
                <ListItem>
                  A exclusiva miniatura do trator MF 5S – Valor unitário: 65
                  euros
                </ListItem>
                <ListItem>
                  Um mouse sem fio para PC da MASSEY FERGUSON – Valor unitário:
                  15 euros
                </ListItem>
                <ListItem>
                  2 jogos Farming Simulator 2022 para PC - Valor unitário: 40
                  euros
                </ListItem>
                <ListItem>
                  O exclusivo boné Farming Simulator 2022 FS – Valor unitário:
                  20 euros
                </ListItem>
              </UnorderedList>
            </ListItem>
          </UnorderedList>
          <Text color="white" mt="1%">
            Cada participante poderá ganhar, ao longo de todo o concurso, dentre
            todos os níveis juntos, somente um prêmio.
          </Text>
          <Text color="red.500" fontWeight="bold" mt="1%">
            Artigo 6 – Anúncio e notificação dos vencedores
          </Text>
          <Text color="white" mt="1%">
            A equipe de Marketing da Massey Ferguson notificará os vencedores
            enviando um e-mail solicitando que entrem em contato com a AGCO.
            <br />
            Se a pessoa sorteada não responder a esse e-mail dentro de um prazo
            de 10 dias, será realizado um segundo e último sorteio, e o primeiro
            vencedor será desqualificado.
            <br />
            O vencedor será contactado pela AGCO SAS Company por e-mail ou por
            outro meio para enviar as informações necessárias para a entrega do
            prêmio.
            <br />
            O participante concorda que, caso seja vencedor do concurso, seu
            nome será anunciado publicamente em propagandas, promoções ou outros
            sites e redes sociais pertinentes.
            <br />
            O vencedor compromete-se a colocar-se à disposição, sem custo,
            dentro de limites razoáveis, para promoção em áudios, fotos, imagens
            e/ou textos através de rádio, internet, televisão ou mídia impressa,
            e concorda com a publicação e o uso para fins publicitários pela
            AGCO e por empresas afiliadas à AGCO.
            <br />
          </Text>
          <Text color="red.500" fontWeight="bold" mt="1%">
            Artigo 7 – Exclusão de participantes
          </Text>

          <Text color="white" mt="1%">
            A AGCO reserva-se o direito de excluir participantes do concurso.
            Isso se aplica particularmente às violações às condições para a
            participação, dados pessoais falsos, manipulação ou suspeita de
            manipulação. A AGCO pode também excluir participantes dos quais
            fundadamente se suspeite que estejam usando os serviços da AGCO.
            <br />
            Se a AGCO, posteriormente, confirmar que o participante criou
            qualquer situação que justifique a exclusão ou que não tivesse
            direito à participação segundo o que determina o Artigo 2, a AGCO
            poderá excluir o participante da distribuição ou entrega dos prêmios
            ou reivindicar a devolução de quaisquer prêmios que já tenham sido
            entregues.
          </Text>
          <Text color="red.500" fontWeight="bold" mt="1%">
            Artigo 8 – Entrega dos prêmios
          </Text>
          <Text color="white" mt="1%">
            Troca financeira, devolução ou compensação não são permitidos, em
            nenhuma circunstância.
            <br />
            A empresa organizadora poderá substituir os prêmios por outros de
            valor equivalente, caso circunstâncias externas impeçam a entrega
            dos prêmios anunciados. Não há negociação em relação a isso e
            dinheiro em espécie não é uma alternativa disponível.
            <br />O prêmio será enviado ao vencedor dentro do prazo de 3 meses
            depois da equipe de Marketing da Massey Ferguson receber todas as
            informações necessárias para a entrega do prêmio.
          </Text>
          <Text color="red.500" fontWeight="bold" mt="1%">
            Artigo 9 – Termos de uso e Garantia
          </Text>
          <Text color="white" mt="1%">
            Os organizadores se isentam de quaisquer responsabilidades por todos
            os incidentes e/ou acidentes que possam ocorrer durante a fruição do
            prêmio entregue e/ou decorrentes de seu uso.
            <br />
            Aos organizadores é reservado o direito de abreviar, estender,
            modificar ou cancelar a presente operação, caso as circunstâncias o
            exijam, sem incorrer em nenhuma responsabilidade.
            <br />
            Os organizadores não poderão ser responsabilizados no caso de uma
            interrupção dos serviços causada por um incidente técnico nas redes
            de comunicação ou de um incidente técnico que esteja além de seu
            controle. Os organizadores não poderão ser responsabilizados se, por
            qualquer motivo, o concurso for parcialmente ou completamente
            modificado, adiado ou cancelado. Não poderá ser exigida nenhuma
            compensação. Não serão incluídos formulários ilegíveis, incompletos
            e falsificados. Portanto, a empresa organizadora não aceita nenhuma
            responsabilidade, se os formulários não forem registrados ou se não
            for possível confirmá-los.
            <br />
          </Text>
          <Text color="red.500" fontWeight="bold" mt="1%">
            Article 10 - Acceptance & Disputes
          </Text>

          <Text color="white" mt="1%">
            Ao participar desse concurso, todos os participantes reconhecem que
            aceitam seus termos e condições.
            <br />
            Todo participante reconhece que o fator sorte não lhe garante nada
            além da expectativa da possibilidade de um ganho aleatório.
            <br />
            Eventuais contestações deverão ser enviadas por carta ao
            Departamento Jurídico da AGCO SAS – 41 avenue Blaise Pascal – BP
            60307 - BEAUVAIS (60026) França, no mais tardar, até 3 de setembro
            (fazendo fé o carimbo postal).
            <br />
            Este regulamento está sujeito às leis da França, que são as únicas
            que se aplicam.
            <br />
            A AGCO SAS. – Departamento de Marketing da Massey Ferguson para EME
            proverá uma resposta a solicitações relacionadas a qualquer
            dificuldade prática, interpretação ou aplicação sobre o presente
            regulamento.
            <br />
          </Text>
          <Text color="red.500" fontWeight="bold" mt="1%">
            Artigo 11 – Divulgação dos termos e condições do concurso
          </Text>
          <Text color="white" mt="1%">
            Os presentes termos e condições estarão disponíveis na plataforma
            online oficial do evento da MF.
            <br />
            O participante com conexão à internet não poderá exigir nenhum
            reembolso, pois a inscrição não implica nenhum custo adicional.
            <br />
            Uma cópia completa dos termos e condições é mantida no departamento
            jurídico da empresa organizadora.
            <br />
          </Text>
          <Text color="red.500" fontWeight="bold" mt="1%">
            Artigo 12 – Proteção dos dados pessoais
          </Text>
          <Text color="white" mt="1%">
            Para participar do concurso, os participantes deverão,
            necessariamente, consentir com o uso de alguns de seus dados
            pessoais pela empresa organizadora para fins de gestão do concurso e
            também para fins de marketing.
            <br />
            Para obter mais informações, você pode consultar nossa Política de
            Privacidade no link <Link href="http://www.agcocorp.com/privacy.html">
              http://www.agcocorp.com/privacy.html
            </Link>
            <br />
            Todo vencedor concorda, sem nenhuma implicação financeira ou outra
            compensação, em dar à empresa organizadora o direito de uso de seu
            nome, sobrenome, uma breve indicação de seu local de domicílio e sua
            fotografia para todas as ações promocionais ou editoriais
            relacionadas.
            <br />
            De acordo com as leis aplicáveis, você tem o direito de revogar tal
            concessão, parcial ou totalmente, a qualquer momento, e sem prover
            qualquer justificativa à AGCO. Você pode exercer seus direitos de
            acesso, retificação, exclusão e contestação por meio do e-mail
            dataprivacy@agcocorp.com. You can exercise your rights of access,
            rectification, deletion and opposition by e-mail to
            dataprivacy@agcocorp.com.  
          </Text>
        </Flex>
      </chakra.div>
    </Container>
  );
};

export default TermsAndConditionsContentPTBR;
