import React, { useState, useRef } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";

import {
  chakra,
  useBreakpointValue,
  useMultiStyleConfig,
  Container,
  Text,
  Link,
  Flex,
  Divider,
  ListItem,
  UnorderedList,
  SimpleGrid,
  Box,
} from "@chakra-ui/react";

import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import axiosHelper from "./../../helpers/axiosHelper";
import bgImage from "./../../assets/prizes/prizes-page-bg@2x.jpg";

import { ChevronLeftIcon, ChevronRightIcon } from "@chakra-ui/icons";

const TermsAndConditionsContentDA = ({ ...props }) => {
  const { t } = useTranslation();
  const history = useHistory();
  const [userProfile, setUserProfile] = useState<UserProfile>(
    axiosHelper.getUserProfile()!
  );
  const styles = useMultiStyleConfig("PrizePage", props);

  const isMobile = useBreakpointValue({ base: true, lg: false });
  const customSlider = useRef<any>();

  return (
    <Container
      maxW="container.xl"
      bgImage={bgImage}
      d="flex"
      flexDir="column"
      flexDirection="column"
      style={{
        overflow: "hidden",
        backgroundPosition: "center",
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
      }}
      alignItems="center"
    >
      <Flex
        flexDir={{ base: "column", lg: "column" }}
        justifyContent="space-between"
        w={isMobile?"100%":"50%"}
        mt="2%"
      >
        <Text color="white" mb="2%" mt={isMobile ? "30px" : "0"} align="center">
          VILKÅR OG BETINGELSER <br />
          MF-SUPERTEST <br />
          Foregår i perioden fra den 2. juli 2021 til den 3. september 2021
          <br />
          Af MASSEY FERGUSON AGCO-brandet
          <br />
        </Text>
      </Flex>
      <chakra.div w="100%" pb="100px">
        <Flex
          flexDir={{ base: "column", lg: "column" }}
          justifyContent="space-between"
          w="100%"
          mt="3%"
        >
          <Text color="red.500" mt="1%" fontWeight="bold">
            Artikel 1 – Tilrettelæggelse generelt
          </Text>
          <Text color="white" mt="1%">
            MASSEY FERGUSON EME markedsføringsafdelingen hos AGCO SAS med
            hovedkontor på Blaise Pascal Avenue nr. 41 i Beauvais arrangerer NEW
            MASSEY FERGUSON DIGITAL EVENT den 3. september, inklusive en gratis
            og uforpligtende konkurrence for offentligheden.
          </Text>

          <Text color="red.500" fontWeight="bold" mt="1%">
            Artikel 2 – Deltagerkvalifikation
          </Text>

          <Text color="white" mt="1%">
            Kun deltagere fra lande i Europa og Mellemøsten (med undtagelse af
            lande, der er underlagt handelsrestriktioner) er kvalificeret til at
            deltage. Kun landmænd, entreprenører og personer relateret til
            landbrugsvirksomheder kan vinde i denne konkurrence.
          </Text>

          <Text color="white" mt="1%">
            Følgende kan ikke deltage:
          </Text>

          <chakra.div>
            <UnorderedList ml="40px" color="white">
              <ListItem>Alle personer under 18 år.</ListItem>
              <ListItem>Medarbejdere i AGCO Group og deres familier</ListItem>
              <ListItem>
                Forhandlere og distributører af AGCO-produkter og tjenester
              </ListItem>
              <ListItem>
                Medarbejdere i virksomheder, der er tilknyttet AGCO
              </ListItem>
              <ListItem>
                Agenturer og leverandører, der har indgået aftale med AGCO, og
                familiemedlemmer til disses medarbejdere
              </ListItem>
              <ListItem>
                Journalister inviteret af MASSEY FERGUSON til at deltage i
                eventet
              </ListItem>
            </UnorderedList>
          </chakra.div>
          <Text color="white" mt="1%">
            Kun én deltagelse pr. person pr. husstand og landbrugsenhed, samme
            navn, samme fornavn, samme telefonnummer og samme e-mailadresse er
            tilladt. Deltagelse i spillet er strengt personlig. Den arrangerende
            virksomhed forbeholder sig ret til at foretage den kontrol, som den
            måtte finde hensigtsmæssig.
          </Text>
          <Text color="red.500" fontWeight="bold" mt="1%">
            Artikel 3 – Vilkår for deltagelse
          </Text>
          <Text color="white" mt="1%">
            Når deltageren tilmelder sig til NEW MASSEY FERGUSON DIGITAL EVENT
            via webplatformen tilhørende den organiserende afdeling i AGCO SAS,
            deltager vedkommende automatisk i konkurrencen, da det er
            hovedelementet i eventet. Deltageren giver sit samtykke til at give
            adgang til vedkommendes personoplysninger for at blive kontaktet af
            AGCO SAS via mail, telefon eller sociale medier.
          </Text>
          <Text color="red.500" fontWeight="bold" mt="1%">
            Artikel 4 – Udvælgelse af vindere
          </Text>
          <Text color="white" mt="1%">
            Resultatet af konkurrencen bliver offentliggjort mellem den 6.
            september 2021 og den 6. oktober 2021 på AGCO SAS' kontor. – Massey
            Ferguson Marketing Department EME i Beauvais – Frankrig.
          </Text>
          <Box display="block" mt="1%"></Box>
          <Text color="white">
            Lodtrækningen finder sted mellem den 6. september 2021 og den 6.
            oktober 2021 på AGCO SAS' kontor. – Massey Ferguson Marketing
            Department EME i Beauvais – Frankrig. Massey Ferguson Marketing
            Department EME i Beauvais foretager lodtrækningen bag lukkede døre.
          </Text>
          <Text color="red.500" fontWeight="bold" mt="1%">
            Artikel 5 – Præmier
          </Text>
          <Text color="white" mt="1%">
            Der vil være 3 niveauer og 3 præmier pr. niveau, dvs. 9 vindere i
            alt i denne konkurrence. Ved at besvare spørgsmål efter tilmeldelsen
            har deltageren mulighed for at forbedre sit resultat i et forsøg på
            at nå det højeste præmieniveau i denne konkurrence.
          </Text>
          <Text color="white" mt="1%">
            Efter eventet bliver deltageren placeret i en af de 3
            præmielodtrækninger herunder, afhængigt af deres endelige ranking i
            konkurrencen – Endelig placering erklæres på webplatformen lige
            efter eventet (den 3. september 2021, kl. 15). Selvom deltageren
            ikke svarer på nogen af spørgsmålene, placeres deltageren automatisk
            på præmieniveau 3.
          </Text>

          <Text color="white" fontWeight="bold" mt="1%" fontStyle="italic">
            Niveau 1: Har en værdi af op til 20.000 euro – Samlet ranking:
            1-1.000
          </Text>
          <Text color="white" mt="1%">
            Deltagerne fra placering 1-1.000 i konkurrencen kommer på listen
            over deltagere i lodtrækningen af niveau 1-præmier.
            <br /> På dette niveau er der 3 præmier med en højeste værdi på
            20.580 euro.
          </Text>
          <UnorderedList color="white" ml="40px" mt="1%">
            <ListItem>
              Førstepræmien på dette niveau er en rabat på op til 10.000 euro på
              køb af en ny MF-maskine.
              <br />
              <br />
              Høbearbejdningsredskaber, traktorer, mejetærskere, ballepressere
              og teleskoplæssere (med forbehold af maskiner på lager hos
              forhandleren), som indgår i MASSEY FERGUSON sortimentet, og som er
              fremstillet før 31. marts 2022.
              <br />
              <br />
              Rabatregler (*på grundlag af listepriser, inklusive ekstraudstyr):
              <br />
              <SimpleGrid
                w={isMobile ? "100%" : "50%"}
                autoFlow=""
                borderColor="white"
                columns={[2, null, 2]}
                border="1px solid white"
                mt="5px"
              >
                <Box bg="red.500" border="1px solid white">
                  <Text
                    color="white"
                    fontWeight="bold"
                    padding="1.5"
                    fontSize={isMobile ? ".7rem" : "1rem"}
                  >
                    Værdi af ny maskine*:{" "}
                  </Text>
                </Box>
                <Box bg="red.500" border="1px solid white">
                  <Text
                    color="white"
                    fontWeight="bold"
                    padding="1.5"
                    fontSize={isMobile ? ".7rem" : "1rem"}
                  >
                    Rabat, som kan anvendes{" "}
                  </Text>
                </Box>
                <Box border="1px solid white">
                  <Text
                    color="white"
                    padding="1.5"
                    fontSize={isMobile ? ".7rem" : "1rem"}
                  >
                    Under 25.000 euro*{" "}
                  </Text>
                </Box>
                <Box border="1px solid white">
                  <Text
                    color="white"
                    padding="1.5"
                    fontSize={isMobile ? ".7rem" : "1rem"}
                  >
                    1500€
                  </Text>
                </Box>
                <Box border="1px solid white">
                  <Text
                    color="white"
                    padding="1.5"
                    fontSize={isMobile ? ".7rem" : "1rem"}
                  >
                    Mellem 25.000 euro &gt; 50.000 euro*{" "}
                  </Text>
                </Box>
                <Box border="1px solid white">
                  <Text
                    color="white"
                    padding="1.5"
                    fontSize={isMobile ? ".7rem" : "1rem"}
                  >
                    2500€
                  </Text>
                </Box>
                <Box border="1px solid white">
                  <Text
                    color="white"
                    padding="1.5"
                    fontSize={isMobile ? ".7rem" : "1rem"}
                  >
                    Mellem 50.000 euro &gt; 100.000 euro*
                  </Text>
                </Box>
                <Box border="1px solid white">
                  <Text
                    color="white"
                    padding="1.5"
                    fontSize={isMobile ? ".7rem" : "1rem"}
                  >
                    4000€
                  </Text>
                </Box>
                <Box border="1px solid white">
                  <Text
                    color="white"
                    padding="1.5"
                    fontSize={isMobile ? ".7rem" : "1rem"}
                  >
                    Mellem 100.000 euro &gt; 150.000 euro*{" "}
                  </Text>
                </Box>
                <Box border="1px solid white">
                  <Text
                    color="white"
                    padding="1.5"
                    fontSize={isMobile ? ".7rem" : "1rem"}
                  >
                    6000€
                  </Text>
                </Box>
                <Box border="1px solid white">
                  <Text
                    color="white"
                    padding="1.5"
                    fontSize={isMobile ? ".7rem" : "1rem"}
                  >
                    Mellem 150.000 euro &gt; 200.000 euro*{" "}
                  </Text>
                </Box>
                <Box border="1px solid white">
                  <Text
                    color="white"
                    padding="1.5"
                    fontSize={isMobile ? ".7rem" : "1rem"}
                  >
                    8000€
                  </Text>
                </Box>
                <Box border="1px solid white">
                  <Text
                    color="white"
                    padding="1.5"
                    fontSize={isMobile ? ".7rem" : "1rem"}
                  >
                    Fra 200.000 euro*{" "}
                  </Text>
                </Box>
                <Box border="1px solid white">
                  <Text
                    color="white"
                    padding="1.5"
                    fontSize={isMobile ? ".7rem" : "1rem"}
                  >
                    10 000€
                  </Text>
                </Box>
              </SimpleGrid>
            </ListItem>
            <ListItem mt="26px">
              Andenpræmien på dette niveau 1 er gratis tilbehør i form af en MF
              Guide med RTK-korrektion til enhver kompatibel
              MF-traktor/-mejetærsker, som købes inden den 31. marts 2022.
              <br />
              <br />
              MF Guide-tilbehøret skal vælges af vinderen, når maskinen købes.
              <br />
              Den maksimale værdi af denne præmie må ikke overstige 16.060 euro.
              Årligt abonnementsgebyr er eksklusiv (debiteres vinderen).
            </ListItem>
            <ListItem mt="26px">
              Tredjepræmien, den sidste præmie som kan vindes på dette niveau 1,
              er en MF 1520 A Stage 5 til en værdi af 19.890 euro, inklusive dæk
              til en værdi af 690 euro* (*prisliste pr. 1. februar 2021).
            </ListItem>
          </UnorderedList>

          <Text color="white" mt="1%">
            En deltager kan kun vinde én præmie i den samlede konkurrence, alle
            niveauer tilsammen. .
          </Text>
          <Text color="white" fontWeight="bold" mt="1%" fontStyle="italic">
            Niveau 2: Værdi op til 5.000 euro - Samlet ranking: 1001-2000
          </Text>
          <Text color="white" mt="1%">
            Deltagerne fra placering 1001-2000 i konkurrencen kommer på listen
            over deltagere i lodtrækningen af niveau 2-præmier.
            <br />
            Der er 3 præmier på dette niveau, med en højeste værdi på 5.000
            euro.
          </Text>

          <UnorderedList color="white" ml="40px" mt="1%">
            <ListItem>
              Førstepræmien på dette niveau 2 er en MF Summer Pack til en samlet
              værdi af 1.000 euro, inklusive:
              <UnorderedList color="white">
                <ListItem ml="20px" type="circle">
                  Et sæt MF/AGCO Parts Oil Barrel Barbecue og 4 x MF/AGCO Parts
                  liggestole til en samlet værdi af 340 euro
                </ListItem>
              </UnorderedList>
              <Text color="white" ml="-40px">
                Og
              </Text>
              <UnorderedList color="white">
                <ListItem ml="20px" type="circle">
                  En personlig iPad Air Grey WIFI 64 GO til en værdi af 669 euro
                </ListItem>
              </UnorderedList>
            </ListItem>
          </UnorderedList>

          <Text color="white" mt="1%">
            Disse præmier leveres separat til vinderen.
          </Text>

          <UnorderedList color="white" ml="40px">
            <ListItem mt="1%">
              Andenpræmien på dette niveau 2 er en professionel højtryksrenser
              til en værdi af 2.000 euro. <br />
              <br />
              Højtryksrensermodellen er ikke aftalt og kan ændres afhængigt af
              vinderens marked. Vores AGCO Parts Complementary Team vil oplyse
              de præcise modeldetaljer til vinderen, når denne er trukket.
            </ListItem>
            <ListItem mt="1%">
              Tredjepræmien, den sidste præmie på dette niveau 2, til en samlet
              værdi af 5.000 euro er en MF-servicepakke bestående af:
              <UnorderedList color="white" listStyleType="circle" mt="1%">
                <ListItem>
                  En værdikupon på 5.000 euro, der kan bruges i vinderens lokale
                  MF forhandlerbutik, og som gælder alle tilgængelige AGCO Parts
                  produkter.
                </ListItem>
                <ListItem mt="1%">
                  Mulighed for at få besøg fra AGCO's tekniske servicecenter på
                  din bedrift, ledsaget af den lokale forhandler, med henblik på
                  rådgivning i brugen af udstyr til Massey Ferguson traktorer og
                  teleskoplæssere.
                </ListItem>
              </UnorderedList>
            </ListItem>
          </UnorderedList>

          <Text color="white" mt="1%">
            En deltager kan kun vinde én præmie i den samlede konkurrence, alle
            niveauer tilsammen
          </Text>

          <Text color="white" fontWeight="bold" mt="2%" fontStyle="italic">
            Niveau 3: Værdi op til 250 euro - Samlet ranking: 2001-sidste
          </Text>

          <Text color="white" mt="2%">
            Deltagerne fra placering 2001 til den sidste ranking medtages på
            deltagerlisten til lodtrækningen af niveau 3-præmierne.
            <br /> Der er 3 præmier på dette niveau, alle til en værdi af 250
            euro
          </Text>

          <UnorderedList color="white " mt="1%">
            <ListItem>
              Førstepræmien på dette niveau er en Connectivity Pack, der
              indeholder 3 eksklusive MASSEY FERGUSON gaver:
              <UnorderedList color="white">
                <ListItem type="circle">
                  EN MASSEY FERGUSON smartwatch – enhedspris: 180 euro
                </ListItem>
                <ListItem type="circle">
                  Et sæt MASSEY FERGUSON øresnegle MASSEY FERGUSON – Enhedspris:
                  39,90 euro
                </ListItem>
                <ListItem type="circle">
                  En MASSEY FERGUSON S Collection Powerbank – Enhedspris: 29,90
                  euro
                </ListItem>
                <Box display="block" mt="1%"></Box>
              </UnorderedList>
            </ListItem>

            <ListItem mt="1%">
              Andenpræmien på dette niveau 3 er en værdikupon på 250 euro, som
              kan bruges i MASSEY FERGUSON onlinebutikken{" "}
              <Link href="https://shop.masseyferguson.com">
                https://shop.masseyferguson.com
              </Link>. 
              Denne e-værdikupon er gyldig i 36 måneder efter modtagelse.
            </ListItem>

            <ListItem mt="1%">
              Tredjepræmien, den sidste præmie på dette niveau 3, er en
              spilpakke, som indeholder:
              <UnorderedList color="white" listStyleType="circle">
                <ListItem>
                  En MF 8S-model i målestoksforhold – Enhedspris: 70 euro
                </ListItem>
                <ListItem>
                  Den eksklusive MF 5S model i målestoksforhold – Enhedspris: 65
                  euro
                </ListItem>
                <ListItem>
                  En MASSEY FERGUSON trådløs mus til pc – Enhedspris: 15 euro
                </ListItem>
                <ListItem>
                  2 Farming Simulator 2022-spil til pc – Enhedspris: 40 euro
                </ListItem>
                <ListItem>
                  Den eksklusive Farming Simulator 2022 FS-baseballkasket –
                  Enhedspris: 20 euro
                </ListItem>
              </UnorderedList>
            </ListItem>
          </UnorderedList>
          <Text color="white" mt="1%">
            En deltager kan kun vinde én præmie i den samlede konkurrence, alle
            niveauer tilsammen.
          </Text>
          <Text color="red.500" fontWeight="bold" mt="1%">
            Artikel 6 – Besked til vindere og offentliggørelse
          </Text>
          <Text color="white" mt="1%">
            Massey Ferguson Marketing Team giver vinderne besked via en mail,
            hvori de opfordres til at kontakte AGCO.
            <br />
            Hvis vinderen af lodtrækningen ikke svarer på denne mail i løbet af
            10 dage, foretages en anden, sidste lodtrækning, og den første
            vinder diskvalificeres.
            <br />
            Vinderen bliver kontaktet af AGCO SAS Company via e-mail eller på
            anden måde og bliver bedt om at sende de oplysninger, der er
            nødvendige for at kunne udlevere præmien.
            <br />
            Deltagerne giver deres samtykke til, at deres navne vil blive
            offentliggjort i annoncer, kampagner eller på relevante websider og
            sociale medier, hvis de bliver de heldige vindere.
            <br />
            Vinderen forpligter sig til inden for rimelighedens grænser at
            stille sig gratis til rådighed for lydoptagelser, fotografering,
            kampagner med billeder og/eller tekst via radio, internet, tv eller
            trykte medier, ligesom vedkommende giver sit samtykke til at blive
            brugt til reklameformål for AGCO og virksomheder, der er tilknyttet
            AGCO.
            <br />
          </Text>
          <Text color="red.500" fontWeight="bold" mt="1%">
            Artikel 7 – Udelukkelse af deltagere
          </Text>

          <Text color="white" mt="1%">
            AGCO forbeholder sig ret til at udelukke deltagere fra konkurrencen.
            Dette gælder specielt i tilfælde af overtrædelse af betingelserne
            for deltagelse, falske personoplysninger eller manipulation eller
            mistanke om manipulation. AGCO kan også ekskludere deltagere, som
            med rimelighed mistænkes for at bruge AGCO's serviceydelser.
            <br />
            Hvis AGCO efterfølgende får kendskab til, at deltageren har givet
            grund til eksklusion eller rent faktisk ikke havde ret til at
            deltage i henhold til indholdet i Artikel 2, kan AGCO udelukke
            deltageren fra uddelingen eller overdragelsen af ​​præmierne eller
            kræve allerede overdragede præmier tilbage.
          </Text>
          <Text color="red.500" fontWeight="bold" mt="1%">
            Artikel 8 – Tilbagekaldelse af partier
          </Text>
          <Text color="white" mt="1%">
            Det er under ingen omstændigheder tilladt at ombytte, returnere
            eller blive kompenseret økonomisk.
            <br />
            Den arrangerende virksomhed kan erstatte præmierne med andre præmier
            af tilsvarende værdi, hvis eksterne omstændigheder forhindrer
            tildeling af de annoncerede præmier. Dette er ikke til forhandling,
            og der vil ikke være noget alternativ i form af kontanter.
            <br />
            Præmien vil blive tilsendt vinderen inden for 3 måneder, efter at
            Massey Ferguson Marketing Team har modtaget alle de nødvendige
            oplysninger for at kunne udlevere præmien.
          </Text>
          <Text color="red.500" fontWeight="bold" mt="1%">
            Artikel 9 - Vilkår for anvendelse og garanti
          </Text>
          <Text color="white" mt="1%">
            Arrangørerne frasiger sig ethvert ansvar for alle hændelser og/eller
            ulykker, der kan forekomme under nydelsen af den vundne præmie
            og/eller på grund af dens brug. Arrangørerne forbeholder sig ret til
            at forkorte, forlænge, ændre eller annullere dette arrangement, hvis
            omstændighederne kræver det, uden at pådrage sig noget ansvar.
            <br />
            Arrangørerne kan ikke drages til ansvar i tilfælde af afbrydelse af
            tjenesten som følge af en teknisk hændelse i kommunikationsnetværk
            eller en teknisk hændelse, der ikke er under deres kontrol.
            <br />
            Arrangørerne kan ikke drages til ansvar, hvis konkurrencen af en
            eller anden grund ændres, udsættes eller annulleres helt eller
            delvist. Der kan ikke gøres krav på kompensation. Alle ulæselige,
            ufuldstændige og forfalskede formularer medtages ikke i
            konkurrencen. Derfor påtager den arrangerende virksomhed sig intet
            ansvar, hvis formularerne ikke er registreret eller er umulige at
            kontrollere.
            <br />
          </Text>
          <Text color="red.500" fontWeight="bold" mt="1%">
            Artikel 10 - Accept og tvister
          </Text>

          <Text color="white" mt="1%">
            Ved at deltage i denne konkurrence accepterer alle deltagerne disse
            vilkår og betingelser. Hver deltager ved, at tilfældighedens chance
            ikke kan give vedkommende anden sikkerhed end forventningen om en
            mulig og tilfældig gevinst.
            <br />
            Eventuelle tvister vil blive sendt til AGCO SAS Legal Department –
            avenue Blaise Pascal nr. 41 – BP 60307 – BEAUVAIS (60026) Frankrig,
            senest den 3. september (poststempel som bevis) med brev.
            <br />
            Denne forordning er i henhold til den franske lovgivning den eneste,
            der finder anvendelse.
            <br />
            AGCO SAS. – MASSEY FERGUSON EME-markedsføringsafdelingen giver
            feedback om eventuelle praktiske problemer, fortolkninger eller
            anmodninger om anvendelse af denne forordning.
            <br />
          </Text>
          <Text color="red.500" fontWeight="bold" mt="1%">
            Artikel 11 - Fremlæggelse af konkurrencens vilkår og betingelser
          </Text>
          <Text color="white" mt="1%">
            Disse vilkår og betingelser vil være tilgængelige på den officielle
            MF-begivenhedsplatform på nettet.
            <br />
            Deltagere med en internetforbindelse vil ikke kunne kræve nogen
            refusion, da deltagelsen ikke medfører ekstra omkostninger.
            <br />
            En kopi af disse vilkår og betingelser i deres fulde ordlyd findes i
            den juridiske afdeling i den arrangerende virksomhed.
            <br />
          </Text>
          <Text color="red.500" fontWeight="bold" mt="1%">
            Artikel 12 – Beskyttelse af personlige data
          </Text>
          <Text color="white" mt="1%">
            For at deltage i denne konkurrence er det nødvendigt, at deltagerne
            accepterer, at nogle personoplysninger om dem selv vil blive brugt
            af den arrangerende virksomhed til at administrere konkurrencen, men
            også til markedsføringsformål.
            <br />
            For at få flere oplysninger kan du læse vores politik om beskyttelse
            af personlige oplysninger på følgende link
            {" "}<Link href="http://www.agcocorp.com/privacy.html">
              http://www.agcocorp.com/privacy.html
            </Link>
            <br />
            Hver vinder accepterer uden nogen form for økonomisk eller anden
            kompensation at give den arrangerende virksomhed ret til at bruge
            vedkommendes navn, fornavn, en kort angivelse af bopæl og
            fotografering til al salgsfremmende eller redaktionel støtte.
            <br />I overensstemmelse med gældende bestemmelser kan du til enhver
            tid og uden at give AGCO nogen begrundelse trække dig helt eller
            delvist ud. Du kan udøve dine rettigheder til adgang, rettelse,
            sletning og indsigelse via e-mail til dataprivacy@agcocorp.com
          </Text>
        </Flex>
      </chakra.div>
    </Container>
  );
};

export default TermsAndConditionsContentDA;
