import React, { useState, useRef } from "react";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";

import {
  chakra,
  useBreakpointValue,
  useMultiStyleConfig,
  Container,
  Text,
  Image,
  HStack,
  useDisclosure,
  Flex,
  Divider,
  Box,
  Stack,
} from "@chakra-ui/react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import axiosHelper from "./../../helpers/axiosHelper";
import bgImage from "./../../assets/tutorial/shutterstock_1117734563 (2)/shutterstock_1117734563@2x.png";
import imageforI from "./../../assets/welcomecluster/buttonForI.jpg";
import imageforR from "./../../assets/welcomecluster/buttonForR.jpg";
import imageforS from "./../../assets/welcomecluster/buttonForS.jpg";

import { ChevronLeftIcon, ChevronRightIcon } from "@chakra-ui/icons";
import QuestionModal from "../modal/QuestionModal";
import ClusterBox from "./ClusterBox";

const ClusterContent = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const [userProfile, setUserProfile] = useState<UserProfile>(
    axiosHelper.getUserProfile()!
  );
  const isMobile = useBreakpointValue({ base: true, lg: false });
  const customSlider = useRef<any>();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [rankArea, setRankArea] = useState("1 - 1000");
  const [isSelected, setIsSelected] = useState({
    R: false,
    I: false,
    S: false,
  });

  const refBrave = useRef<any>();
  const refBig = useRef<any>();
  const refSpecialist = useRef<any>();

  //   const styles = useMultiStyleConfig("Hall", props);
  const handleCard = (item: string) => {
    if (item === "brave") {
      // console.log(item);
      // axiosHelper.setCluster("R");
      setIsSelected({ R: true, I: false, S: false });
    } else if (item === "big") {
      // console.log(item);
      // axiosHelper.setCluster("I");
      setIsSelected({ R: false, I: true, S: false });
    } else if (item === "specialist") {
      // console.log(item);
      // axiosHelper.setCluster("S");
      setIsSelected({ R: false, I: false, S: true });
    } else if (item === "") {
      setIsSelected({ R: false, I: false, S: false });
    }
  };
  const saveCluster = () => {
    axiosHelper.setTutorialSeen();
    if (isSelected.R) {
      axiosHelper.setCluster("R");
    } else if (isSelected.I) {
      axiosHelper.setCluster("I");
    } else if (isSelected.S) {
      axiosHelper.setCluster("S");
    }
    history.push("/");
  };

  return (
    <Container
      maxW="container.xl"
      bgImage={bgImage}
      d="flex"
      flexDir="column"
      flexDirection="column"
      py="56px"
      style={{
        overflow: "hidden",
        backgroundPosition: "center",
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
      }}
      alignItems="center"
      pt="20px"
    >
      <Text
        mt="5px"
        fontFamily="Arial"
        fontWeight="normal"
        color="#ffffff"
        fontSize={{ base: "16px" }}
        mb="50px"
      >
        {t("clusterchoice.text." + userProfile.userType)}
      </Text>
      <Stack
        direction={{ base: "column", lg: "row" }}
        justify="center"
        spacing="20px"
        h={{ lg: "129px" }}
      >
        <ClusterBox
          refBox={refBrave}
          img={imageforR}
          title={t("registercard.title.R")}
          subtitle={t("registercard.description.R")}
          type="brave"
          handleClick={handleCard}
          selected={isSelected.R}
          width={{ base: "100%", lg: "270px" }}
          height={{ base: "129px", lg: "129px" }}
        />
        <ClusterBox
          refBox={refBig}
          img={imageforI}
          title={t("registercard.title.I")}
          subtitle={t("registercard.description.I")}
          type="big"
          handleClick={handleCard}
          selected={isSelected.I}
          width={{ base: "100%", lg: "270px" }}
          height={{ base: "129px", lg: "129px" }}
        />
        <ClusterBox
          refBox={refSpecialist}
          img={imageforS}
          title={t("registercard.title.S")}
          subtitle={t("registercard.description.S")}
          type="specialist"
          handleClick={handleCard}
          selected={isSelected.S}
          width={{ base: "100%", lg: "270px" }}
          height={{ base: "129px", lg: "129px" }}
        />
      </Stack>
      <Text fontFamily="Arial" fontSize="12px" color="#707070" my="16px">
        {t("clusterchoice.explanation")}
      </Text>
      <Flex w="100%" my="20px" justifyContent="center" alignItems="center">
        <Flex
          fontSize="18px"
          fontFamily="Arial"
          fontWeight="bold"
          textTransform="uppercase"
          color="white"
          bgColor="red.500"
          px="55px"
          py="7px"
          w={{ base: "100%", lg: "auto" }}
          justifyContent="center"
          cursor="pointer"
          _hover={{ opacity: 0.8 }}
          onClick={saveCluster}
        >
          {t("activate.okbtn")}
        </Flex>
      </Flex>
      {/* <QuestionModal clickOpen={isOpen} clickClose={onClose} questionId={'921820ed-d074-4310-aa65-a38d68683213'} /> */}
    </Container>
  );
};

export default ClusterContent;
