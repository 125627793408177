import React, { FC, useState, useEffect } from "react";

import {
  chakra,
  useBreakpointValue,
  useMultiStyleConfig,
  Container,
  Text,
  Image,
  Flex,
  Button,
  IconButton,
  useDisclosure,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
} from "@chakra-ui/react";
import axiosHelper from "./../helpers/axiosHelper";
import { ChevronDownIcon, CloseIcon, HamburgerIcon } from "@chakra-ui/icons";
import { useTranslation } from "react-i18next";
import logo from "./../assets/logo/MFlogo RGB187_K_S.png";
import LanguageSwitcher from "../components/header/LanguageSwitcher";
import { useHistory, Link } from "react-router-dom";
import LogInModal from "../components/modal/LogInModal";
import ClusterModal from "../components/modal/ClusterModal";
import MenuModal from "../components/modal/MenuModal";

import homeOff from "./../assets/home-icon-off.svg";
import homeOn from "./../assets/home-icon-on.svg";
import coppettaIcon from "./../assets/coppetta.svg";
import ChangePasswordModal from "../components/modal/ChangePasswordModal";

export type LayoutHeaderProps = {
  home?: boolean;
  coppetta?: number;
  reloadUserProfile: () => void;
};

const LayoutHeader: FC<LayoutHeaderProps> = (
  { home, coppetta, reloadUserProfile }: LayoutHeaderProps,
  ...props
) => {
  const { t } = useTranslation();
  const history = useHistory();
  const [userProfile, setUserProfile] = useState<UserProfile>(
    axiosHelper.getUserProfile()!
  );
  const [usertype, setUsertype] = useState("");
  const [name, setName] = useState("");

  // const styles = useMultiStyleConfig("LayoutHeader", props);

  const isMobile = useBreakpointValue({ base: true, lg: false });
  const {
    isOpen: isOpenMenu,
    onOpen: onOpenMenu,
    onClose: onCloseMenu,
  } = useDisclosure();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const {
    isOpen: isOpenCluster,
    onOpen: onOpenCluster,
    onClose: onCloseCluster,
  } = useDisclosure();

  const {
    isOpen: isOpenChangePassword,
    onOpen: onOpenChangePassword,
    onClose: onCloseChangePassword,
  } = useDisclosure();

  const [tutorialTabs, setTutorialTabs] = useState(false);
  const [prizesTabs, setPrizesTabs] = useState(false);
  const [gameTabs, setGameTabs] = useState(false);
  const [kitTabs, setKitTabs] = useState(false);
  const [isHoverHome, setIsHoverHome] = useState(false);

  const goToLogin = () => {
    history.push("/login");
  };

  const refreshCluster = () => {
    setUserProfile(axiosHelper.getUserProfile()!);
    reloadUserProfile();
  };

  const performLogout = async () => {
    try {
      const result = await axiosHelper.logout();
    } catch (e) {
      console.error(e);
    } finally {
      axiosHelper.clearUserProfile();
      history.push("/login");
    }
  };

  useEffect(() => {
    if (userProfile) {
      switch (userProfile.userType) {
        case "R":
          setUsertype(t("hallhero.title.R"));
          setTutorialTabs(true);
          setPrizesTabs(true);
          setGameTabs(true);
          setKitTabs(false);
          break;
        case "S":
          setUsertype(t("hallhero.title.S"));
          setTutorialTabs(true);
          setPrizesTabs(true);
          setGameTabs(true);
          setKitTabs(false);
          break;
        case "I":
          setUsertype(t("hallhero.title.I"));
          setTutorialTabs(true);
          setPrizesTabs(true);
          setGameTabs(true);
          setKitTabs(false);
          break;
        case "P":
          setUsertype(t("hallhero.title.P"));
          setTutorialTabs(false);
          setPrizesTabs(false);
          setGameTabs(false);
          // PRESS KIT available from Aug 12
          // setKitTabs(true);
          setKitTabs(false);
          break;
        case "D":
          setUsertype(t("hallhero.title.D"));
          setTutorialTabs(false);
          setPrizesTabs(false);
          setGameTabs(false);
          // PRESS KIT available from Aug 12
          // setKitTabs(true);
          setKitTabs(false);
          break;
        case "V":
          setUsertype(t("hallhero.title.V"));
          setTutorialTabs(false);
          setPrizesTabs(false);
          setGameTabs(false);
          // PRESS KIT available from Aug 12
          // setKitTabs(true);
          setKitTabs(false);
          break;
        case "E":
          setUsertype(t("hallhero.title.E"));
          setTutorialTabs(false);
          setPrizesTabs(false);
          setGameTabs(false);
          // PRESS KIT available from Aug 12
          // setKitTabs(true);
          setKitTabs(false);
          break;
        default:
          setUsertype("");
          break;
      }
      setName(userProfile.firstName);
    } else {
      setTutorialTabs(false);
      setPrizesTabs(true);
      setGameTabs(true);
      setKitTabs(false);
    }
  }, [t("LNG"), userProfile]);

  return (
    <>
      <chakra.div
        h="121px"
        position="sticky"
        top="0px"
        zIndex="100"
        flexShrink={0}
      >
        <Flex h={{ base: "48px", lg: "52px" }} bgColor="white">
          <Container maxW="container.xl" d="flex" flexDir="row">
            <Flex
              boxShadow="0px 1px 45px rgba(63, 63, 68, 0.35)"
              bgColor="white"
              h={{ base: "70px", lg: "119px" }}
              w={{ base: "100px", lg: "170px" }}
              justifyContent="center"
              alignItems="center"
              onClick={() => {
                history.push("/");
              }}
              cursor="pointer"
              zIndex="1000"
            >
              <Image
                objectFit="cover"
                objectPosition="center"
                bgRepeat="no-repeat"
                overflow="hidden"
                alignItems="center"
                justifyContent="center"
                src={logo}
                h={{ base: "40px", lg: "68px" }}
                w={{ base: "70px", lg: "120px" }}
              />
            </Flex>

            <Flex
              w={{ base: "100%", lg: "100%" }}
              justifyContent={{ base: "flex-end" }}
              alignItems="center"
            >
              {home && (
                <Link
                  to="/"
                  style={{
                    color: "gray",
                    textDecoration: "none",
                    padding: "5px",
                  }}
                  onMouseEnter={() => setIsHoverHome(true)}
                  onMouseLeave={() => setIsHoverHome(false)}
                >
                  <Image src={isHoverHome ? homeOn : homeOff} w="20px" />
                </Link>
              )}

              <LanguageSwitcher />
            </Flex>
          </Container>
        </Flex>
        <Flex h={{ base: "48px", lg: "69px" }} bgColor="rgb(141,141,141,0.95)">
          <Container
            maxW="container.xl"
            d="flex"
            flexDir="row"
            justifyContent="flex-end"
            alignItems="center"
            color="white"
            fontWeight="bold"
          >
            {isMobile ? (
              <>
                {name && usertype ? (
                  <>
                    <Flex flexDir="column" alignItems="flex-end">
                      <Text color="red.500">
                        {t("tutorial.welcome.title")} {name}
                      </Text>
                      <Flex flexDir="row" alignItems="flex-start">
                        {(coppetta != null && coppetta > 0) ? (
                          <Flex
                            flexDir="row"
                            alignItems="center"
                            className="coppetta-wrapper"
                          >
                            <Image src={coppettaIcon} animation="shake 0.5s" />
                            <Text color="red.500">&nbsp;{coppetta}&nbsp;</Text>
                          </Flex>
                        ) : null}
                        <Text color="white">{usertype}</Text>
                      </Flex>
                    </Flex>
                  </>
                ) : (
                  <Text color="red" onClick={onOpen} cursor="pointer">
                    {t("header.login").toUpperCase()}
                  </Text>
                )}
                <IconButton
                  size={"lg"}
                  icon={isOpen ? <CloseIcon /> : <HamburgerIcon />}
                  aria-label={"Open Menu"}
                  display={{ lg: "none" }}
                  onClick={isOpen ? onClose : onOpenMenu}
                  bgColor="transparent"
                />
              </>
            ) : (
              <>
                {tutorialTabs && (
                  <Text mr="3rem">
                    <Link to="/tutorial" className="menu-link">
                      {t("header.tutorial").toUpperCase()}
                    </Link>
                  </Text>
                )}
                {gameTabs && (
                  <Text>
                    <Link to="/termsandconditions" className="menu-link">
                      {t("header.game").toUpperCase()}
                    </Link>
                  </Text>
                )}
                {prizesTabs && (
                  <Text mx="50px">
                    <Link to="/prizes" className="menu-link">
                      {t("header.prizes").toUpperCase()}
                    </Link>
                  </Text>
                )}
                {coppetta ? (
                  <>
                    <Image src={coppettaIcon} animation="shake 0.5s" />
                    <Text color="red.500">&nbsp;{coppetta}</Text>
                  </>
                ) : null}

                {kitTabs && <Text>pressKit.Layout</Text>}
                {name && usertype ? (
                  <Menu>
                    <MenuButton
                      as={Button}
                      rightIcon={<ChevronDownIcon />}
                      bgColor="transparent"
                      color="white"
                      _active={{
                        bgColor: "transparent",
                        border: "none",
                        color: "white",
                      }}
                      _hover={{ bgColor: "transparent", border: "none" }}
                      _focus={{ bgColor: "transparent", border: "none" }}
                      _selected={{ bgColor: "transparent", border: "none" }}
                      borderRadius="0px"
                      borderColor="transparent"
                    >
                      <Flex flexDir="column" alignItems="flex-end">
                        <Text color="red.500">
                          {t("tutorial.welcome.title")} {name}
                        </Text>
                        {userProfile.userType === "I" ||
                          userProfile.userType === "S" ||
                          userProfile.userType === "R" ? (
                          <>
                            <Text>{usertype.toUpperCase()}</Text>
                          </>
                        ) : userProfile.cluster === "I" ? (
                          t("hallhero.title.I").toUpperCase()
                        ) : userProfile.cluster === "R" ? (
                          t("hallhero.title.R").toUpperCase()
                        ) : userProfile.cluster === "S" ? (
                          t("hallhero.title.S").toUpperCase()
                        ) : (
                          ""
                        )}
                      </Flex>
                    </MenuButton>

                    <MenuList
                      textTransform="uppercase"
                      px="0"
                      borderRadius="0px"
                      mt="5px"
                    >
                      <MenuItem
                        key="Change cluster"
                        textTransform="uppercase"
                        justifyContent="center"
                        color="greyDark.500"
                        fontFamily="Arial"
                        fontWeight="bold"
                        fontSize="14px"
                        borderBottom="1px solid #EEEEEE"
                        onClick={onOpenCluster}
                      >
                        {t("header.changecluster").toUpperCase()}
                      </MenuItem>

                      <MenuItem
                        key="change password"
                        textTransform="uppercase"
                        justifyContent="center"
                        color="greyDark.500"
                        fontFamily="Arial"
                        fontWeight="bold"
                        fontSize="14px"
                        borderBottom="1px solid #EEEEEE"
                        onClick={onOpenChangePassword}
                      >
                        {t("header.changepassword").toUpperCase()}
                      </MenuItem>
                      <MenuItem
                        key="logout"
                        textTransform="uppercase"
                        justifyContent="center"
                        color="red.500"
                        fontFamily="Arial"
                        fontWeight="bold"
                        fontSize="14px"
                        onClick={() => {
                          history.push("/logout");
                        }}
                      >
                        {t("header.logout").toUpperCase()}
                      </MenuItem>
                    </MenuList>
                  </Menu>
                ) : (
                  <Text
                    color="red"
                    // onClick={goToLogin}
                    onClick={onOpen}
                    cursor="pointer"
                  >
                    {t("header.login").toUpperCase()}
                  </Text>
                )}
              </>
            )}
          </Container>
        </Flex>
      </chakra.div>
      <MenuModal
        clickOpen={isOpenMenu}
        clickClose={onCloseMenu}
        prizesTabs={prizesTabs}
        gameTabs={gameTabs}
        tutorialTabs={tutorialTabs}
        userTabs={userProfile != null}
        refreshCluster={refreshCluster}
      />
      <LogInModal clickOpen={isOpen} clickClose={onClose} />
      <ClusterModal
        clickOpen={isOpenCluster}
        clickClose={onCloseCluster}
        refreshCluster={refreshCluster}
      />
      <ChangePasswordModal
        clickOpen={isOpenChangePassword}
        clickClose={onCloseChangePassword}
      />
    </>
  );
};

const LayoutHeaderProps = {
  home: false,
};

export default LayoutHeader;
