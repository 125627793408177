
import {
  LightstreamerClient,
  Subscription,
  ClientListener,
} from "lightstreamer-client-web";
import axiosHelper from "./axiosHelper";
import { useHistory } from 'react-router-dom';
import Constants from "./constants";
// import storageHelper from "./UNUSEDstorageHelper";
class LSClient {
  private static instance: LightstreamerClient;
  public static hasAutomatics: boolean;

  static getInstance(): LightstreamerClient {
    // console.log("sono get instance");
    const pwd = axiosHelper?.getUserProfile()?.jntToken;
    const uid = axiosHelper?.getUserProfile()?.id;

    if (!LSClient.instance) {
      // console.log("LSClient instantiation");
      if (uid !== undefined && pwd !== undefined) {
        const ls = new LightstreamerClient(
          Constants.LS_ENDPOINT,
          Constants.LS_ADAPTOR
        );
        LSClient.instance = ls;
        LSClient.hasAutomatics = false;
        ls.addListener({
          onStatusChange: function (newStatus) {
            console.log("GOT NEW STATUS: " + newStatus);
          },
        });

        ls.connectionOptions.setFirstRetryMaxDelay(3000);
        ls.connectionOptions.setStalledTimeout(10000);

        ls.connectionDetails.setUser(`${uid}`);
        ls.connectionDetails.setPassword(`${pwd}`);
        // console.log("connecting to lightstreamer");
        if (Constants.LS_ACTIVE) {
          ls.connect()
        }
        // ls.connect(); //FIXME
      }
    } else {
      // console.log("LSClient recycling");
      if (LSClient.instance.getStatus() === "DISCONNECTED") {
        // console.log('LSClient disconnected');
        if (pwd) {
          LSClient.instance.connectionOptions.setFirstRetryMaxDelay(3000);
          LSClient.instance.connectionOptions.setStalledTimeout(10000);

          LSClient.instance.connectionDetails.setUser(`${uid}`);
          LSClient.instance.connectionDetails.setPassword(`${pwd}`);
          // console.log("LSClient reconnecting");
          // LSClient.instance.connect(); //FIXME
          if (Constants.LS_ACTIVE) {
            LSClient.instance.connect();
          }
        } else {
          // console.log('LSClient not reconnecting. User not logged');
        }
      } else {
        // console.log('LSClient already connected');
      }
    }
    return LSClient.instance;
  }
  // addListener(listener: ClientListener) {
  //   LSClient.instance.addListener(listener);
  // }
  // subscribe(subscription: Subscription) {
  //   LSClient.instance.subscribe(subscription);
  // }
  // unsubscribe(subscription: Subscription) {
  //   LSClient.instance.unsubscribe(subscription);
  // }
}

const setupLs = () => {
  const ls = LSClient.getInstance();
  return ls;
};
export const sendMessage = (message: string) => {
  console.log("SENDING MESSAGE " + message);
  LSClient.getInstance().sendMessage(message);
};
//new Subscription("RAW", ["live"], ["question", "results"]);
export const getSubscription = (
  // channel: string[],
  topic: string,
  onMessageReceived: (message: string) => void
) => {
  const subscription = new Subscription("MERGE", ["live"], [topic]);
  // console.log("sub " + topic);
  subscription.addListener({
    onSubscriptionError: function (errorCode, errorMessage) {
      console.log("error code " + errorCode);
      console.log(errorMessage);
    },
    onCommandSecondLevelSubscriptionError: function (
      errorCode,
      errorMessage,
      relatedkey
    ) {
      //this one can only be fired in case a two-level subscription is created
      //here you can consume the error
      console.log(errorMessage);
    },
    onItemUpdate: function (info: any) {
      info.forEachField(function (
        fieldName: string,
        fieldPos: string,
        url: string
      ) {
        // console.log(
        //   "##### onItemUpdate " +
        //   topic +
        //   " " +
        //   fieldName +
        //   " " +
        //   fieldPos +
        //   " " +
        //   url
        // );
        if (fieldName === topic) {
          onMessageReceived(url);
        } else {
          // console.log("NON CHIAMO LA CALLBACK");
        }
      });
    },
  });
  // console.log("subscribo la subscription ");
  LSClient.getInstance()?.subscribe(subscription);
  // console.log('SUBS=' + LSClient.getInstance().getSubscriptions().length);
  checkAutomatics();


  return subscription;
};
export const removeSubscription = (subscription: Subscription) => {
  // console.log("UNSUBSCRIBO la subscription ");
  LSClient.getInstance()?.unsubscribe(subscription);
  // console.log('SUBS=' + LSClient.getInstance().getSubscriptions().length);
}

export const unsubscribeAll = () => {
  LSClient.getInstance().getSubscriptions().forEach(element => {
    // console.log(element);
    var elsub: Subscription = element as unknown as Subscription;
    LSClient.getInstance()?.unsubscribe(elsub);
  });
}

export const disconnectLS = () => {

  unsubscribeAll();
  setHasAutomatics(false);
  LSClient.getInstance()?.disconnect();
}
export const checkAutomatics = () => {
  // console.log('CHECK AUTOMATICS... ');
  if (LSClient.hasAutomatics) {
    // console.log('HAS THEM');
  } else {
    // console.log('DOESNT HAVE THEM');
    subscribeAutomatics();
  }
}
export const hasAutomatics = () => {
  return LSClient.hasAutomatics;
}
export const setHasAutomatics = (value: boolean) => {
  LSClient.hasAutomatics = value;
}


const subscribeAutomatics = () => {
  setHasAutomatics(true);
  let koSubscription = getSubscription('KO_' + axiosHelper.getUserProfile()?.jntToken, (message: string) => {
    parseKoMessage(message)
  });

  let seppukuSubscription = getSubscription('SEPPUKU', (message: string) => {
    parseSpkMessage(message)
  });

  let refreshSubscription = getSubscription('REFRESH', (message: string) => {
    parseRefreshMessage(message)
  });

}

const parseKoMessage = (koMessage: string | null) => {
  console.log('PARSE KO MESSAGE');
  console.log(koMessage);
  if (koMessage !== null) {
    // console.log('KICKOUT');
    if (koMessage == 'KO') {
      console.log('KICKOUT disconnect');
      disconnectLS();
      console.log('KICKOUT clear');
      axiosHelper.clearUserProfile()
      console.log(window.location);
      const firstPart = window.location.origin;
      window.location.replace(firstPart + "/ko");
      //history.push('/ko');
    }
  }
}


const parseRefreshMessage = async (rfshMessage: string | null) => {
  if (rfshMessage != null) {
    var splitted = rfshMessage.split("|");

    let rfshId = splitted[0];
    let oldRfshId = localStorage.getItem('rfshId');
    if (rfshId != oldRfshId) {
      let seconds = parseInt(splitted[1]);
      let rndTimeout = Math.random() * 1000 * seconds;
      localStorage.setItem('rfshId', rfshId);
      const result = await axiosHelper.loginWithToken<UserProfile>();
      if (result.data !== null) {
        axiosHelper.saveUserProfile(result.data);
        console.log('SETTO LOGGED IN');
      }


    }
  }
}
const parseSpkMessage = (spkMessage: string | null) => {
  if (spkMessage != null) {
    var splitted = spkMessage.split("|");

    let seppId = splitted[0];
    let oldSeppId = localStorage.getItem('seppId');
    // console.log('SEPP ID ' + seppId);
    // console.log('OLD SEPP ID ' + oldSeppId);
    if (seppId != oldSeppId) {
      let seconds = parseInt(splitted[1]);
      let rndTimeout = Math.random() * 1000 * seconds;
      // console.log('Received Seppuku, reloading window in ' + rndTimeout);
      localStorage.setItem('seppId', seppId);
      setTimeout(() => { window.location.reload(); }, rndTimeout);

    }
  }
}


export default setupLs;
