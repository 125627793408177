import React, { useState, useRef } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";

import {
  chakra,
  useBreakpointValue,
  useMultiStyleConfig,
  Container,
  Text,
  Link,
  Flex,
  Divider,
  ListItem,
  UnorderedList,
  SimpleGrid,
  Box,
} from "@chakra-ui/react";

import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import axiosHelper from "./../../helpers/axiosHelper";
import bgImage from "./../../assets/prizes/prizes-page-bg@2x.jpg";

import { ChevronLeftIcon, ChevronRightIcon } from "@chakra-ui/icons";

const TermsAndConditionsContentNO = ({ ...props }) => {
  const { t } = useTranslation();
  const history = useHistory();
  const [userProfile, setUserProfile] = useState<UserProfile>(
    axiosHelper.getUserProfile()!
  );
  const styles = useMultiStyleConfig("PrizePage", props);

  const isMobile = useBreakpointValue({ base: true, lg: false });
  const customSlider = useRef<any>();

  return (
    <Container
      maxW="container.xl"
      bgImage={bgImage}
      d="flex"
      flexDir="column"
      flexDirection="column"
      style={{
        overflow: "hidden",
        backgroundPosition: "center",
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
      }}
      alignItems="center"
    >
      <Flex
        flexDir={{ base: "column", lg: "column" }}
        justifyContent="space-between"
        w={isMobile?"100%":"50%"}
        mt="2%"
      >
        <Text color="white" mb="2%" mt={isMobile ? "30px" : "0"} align="center">
          VILKÅR OG BETINGELSER
          <br />
          MF SUPERKONKURRANSE
          <br />
          Løper fra 2. juli 2021 til 3. september 2021
          <br />
          av AGCO-varemerket MASSEY FERGUSON
          <br />
        </Text>
      </Flex>
      <chakra.div w="100%" pb="100px">
        <Flex
          flexDir={{ base: "column", lg: "column" }}
          justifyContent="space-between"
          w="100%"
          mt="3%"
        >
          <Text color="red.500" mt="1%" fontWeight="bold">
            Artikkel 1 – Generell organisering
          </Text>
          <Text color="white" mt="1%">
            MASSEY FERGUSON markedsavdeling for EME i AGCO SAS., med hovedkontor
            ved 41 Blaise Pascal Avenue i Beauvais, organiserer NEW MASSEY
            FERGUSON DIGITAL EVENT fra og med 3. september, en gratis og
            uforpliktende konkurranse som er åpen for alle.
          </Text>

          <Text color="red.500" fontWeight="bold" mt="1%">
            Artikkel 2 – Hvem kan delta
          </Text>

          <Text color="white" mt="1%">
            Bare deltakerne land i Europa og Midtøsten (unntatt land som er
            underlagt handelsbegrensende tiltak) er kvalifiserte til å delta.
            Kun bønder, entreprenører og andre bedrifter som har en tilknytning
            til landbruk kan vinne i denne konkurransen.
          </Text>

          <Text color="white" mt="1%">
            Disse kan ikke delta:
          </Text>

          <chakra.div>
            <UnorderedList ml="40px" color="white">
              <ListItem>Alle personer under 18 år.</ListItem>
              <ListItem>Ansatte i AGCO Group og deres familier</ListItem>
              <ListItem>
                Forhandlere og distributører av AGCO-produkter og –tjenester
              </ListItem>
              <ListItem>Ansatte i selskaper tilknyttet AGCO</ListItem>
              <ListItem>
                Byråer og leverandører engasjert av AGCO og slektninger av de
                respektive ansatte
              </ListItem>
              <ListItem>
                Journalister som er invitert av MASSEY FERGUSON til å delta på
                arrangementet
              </ListItem>
            </UnorderedList>
          </chakra.div>
          <Text color="white" mt="1%">
            Det er kun tillatt med én deltakelse per person, husstand og
            landbruksenhet, med samme fornavn og etternavn, samme adresse, samme
            telefonnummer og samme e-postadresse. Deltakelse i konkurransen er
            personlig. Det organiserende selskapet forbeholder seg retten til å
            gjennomføre eventuelle kontroller ved behov.
          </Text>
          <Text color="red.500" fontWeight="bold" mt="1%">
            Artikkel 3 – Vilkår for deltakelse
          </Text>
          <Text color="white" mt="1%">
            Ved å registrere deg for MASSEY FERGUSON DIGITALE ARRANGEMENT via
            nettplattformen til arrangøren hos AGCO SAS, deltar deltakeren
            automatisk i konkurransen, da det er selve grunnlaget for
            arrangementet. Deltakeren samtykker i å gi tilgang til personlige
            informasjon for å bli kontaktet av AGCO SAS via e-post, telefon
            eller sosiale medier.
          </Text>
          <Text color="red.500" fontWeight="bold" mt="1%">
            Artikkel 4 – Trekning av vinnere
          </Text>
          <Text color="white" mt="1%">
            Konkurranseresultatet vil bli kunngjort mellom 6. september 2021 og
            6. oktober 2021 på kontoret til AGCO SAS. - Massey Ferguson
            markedsavdeling for EME i Beauvais – Frankrike.
          </Text>
          <Box display="block" mt="1%"></Box>
          <Text color="white">
            Konkurranseresultatet vil bli kunngjort mellom 6. september 2021 og
            6. oktober 2021 på kontoret til AGCO SAS. - Massey Ferguson
            markedsavdeling for EME i Beauvais – Frankrike. Massey Fergusons
            markedsavdeling for EME i Beauvais vil gjennomføre trekningen bak
            lukkede dører.
          </Text>
          <Text color="red.500" fontWeight="bold" mt="1%">
            Artikkel 5 – Premiene
          </Text>
          <Text color="white" mt="1%">
            Det vil være tre nivåer og tre priser per nivå, slik at det i alt
            vil være ni vinnere i konkurransen. Ved å svare på spørsmål etter
            påmeldingen, vil deltakeren ha mulighet til å øke poengsummen og
            prøve å nå det høyeste nivået i konkurransen.
          </Text>
          <Text color="white" mt="1%">
            Etter arrangementet vil hver deltaker delta i en av de tre
            premietrekningene nedenfor, avhengig av sin endelige rangering –
            Endelig rangering vises på nettplattformen rett etter arrangementet
            (3. september 2021, kl. 15.00). Selv om deltakeren ikke svarer på
            noen av spørsmålene, vil han eller hun automatisk inngå i nivå 3 og
            kunne vinne.
          </Text>

          <Text color="white" fontWeight="bold" mt="1%" fontStyle="italic">
            Nivå 1: Verdi opptil 20 000 euro - Total rangering: 1 – 10002C
          </Text>
          <Text color="white" mt="1%">
            Deltakere fra plass 1 til 1000 i konkurransen vil være med i
            trekningen av premier på nivå 1.
            <br /> På dette nivået er det tre premier, med en høyeste verdi på
            20 580 euro.
          </Text>
          <UnorderedList color="white" ml="40px" mt="1%">
            <ListItem>
              Førstepremien på nivå 1 er en rabatt på opptil 10 000 euro ved
              kjøp av en ny maskin fra MF.
              <br />
              <br />
              Rabatten gjelder kun ved kjøp av ny maskin: Slåmaskiner,
              traktorer, skurtreskere, pressere og teleskoplastere (med
              utgangspunkt i lagerbeholdningen hos forhandler) fra MASSEY
              FERGUSON-serien, og før 31. mars 2022.
              <br />
              <br />
              Rabattregler (* basert på listepriser, inkludert tilvalg):
              <br />
              <SimpleGrid
                w={isMobile ? "100%" : "50%"}
                autoFlow=""
                borderColor="white"
                columns={[2, null, 2]}
                border="1px solid white"
                mt="5px"
              >
                <Box bg="red.500" border="1px solid white">
                  <Text
                    color="white"
                    fontWeight="bold"
                    padding="1.5"
                    fontSize={isMobile ? ".7rem" : "1rem"}
                  >
                    Verdi på ny maskin*:{" "}
                  </Text>
                </Box>
                <Box bg="red.500" border="1px solid white">
                  <Text
                    color="white"
                    fontWeight="bold"
                    padding="1.5"
                    fontSize={isMobile ? ".7rem" : "1rem"}
                  >
                    Gjeldende rabatt{" "}
                  </Text>
                </Box>
                <Box border="1px solid white">
                  <Text
                    color="white"
                    padding="1.5"
                    fontSize={isMobile ? ".7rem" : "1rem"}
                  >
                    Under 25 000 euro*{" "}
                  </Text>
                </Box>
                <Box border="1px solid white">
                  <Text
                    color="white"
                    padding="1.5"
                    fontSize={isMobile ? ".7rem" : "1rem"}
                  >
                    1500€
                  </Text>
                </Box>
                <Box border="1px solid white">
                  <Text
                    color="white"
                    padding="1.5"
                    fontSize={isMobile ? ".7rem" : "1rem"}
                  >
                    Mellom 25 000€ &gt; 50 000€*{" "}
                  </Text>
                </Box>
                <Box border="1px solid white">
                  <Text
                    color="white"
                    padding="1.5"
                    fontSize={isMobile ? ".7rem" : "1rem"}
                  >
                    2500€
                  </Text>
                </Box>
                <Box border="1px solid white">
                  <Text
                    color="white"
                    padding="1.5"
                    fontSize={isMobile ? ".7rem" : "1rem"}
                  >
                    Mellom 50 000€ &gt; 100 000€*{" "}
                  </Text>
                </Box>
                <Box border="1px solid white">
                  <Text
                    color="white"
                    padding="1.5"
                    fontSize={isMobile ? ".7rem" : "1rem"}
                  >
                    4000€
                  </Text>
                </Box>
                <Box border="1px solid white">
                  <Text
                    color="white"
                    padding="1.5"
                    fontSize={isMobile ? ".7rem" : "1rem"}
                  >
                    Mellom 100 000€ &gt; 150 000€*{" "}
                  </Text>
                </Box>
                <Box border="1px solid white">
                  <Text
                    color="white"
                    padding="1.5"
                    fontSize={isMobile ? ".7rem" : "1rem"}
                  >
                    6000€
                  </Text>
                </Box>
                <Box border="1px solid white">
                  <Text
                    color="white"
                    padding="1.5"
                    fontSize={isMobile ? ".7rem" : "1rem"}
                  >
                    Mellom 150 000€ &gt; 200 000€*{" "}
                  </Text>
                </Box>
                <Box border="1px solid white">
                  <Text
                    color="white"
                    padding="1.5"
                    fontSize={isMobile ? ".7rem" : "1rem"}
                  >
                    8000€
                  </Text>
                </Box>
                <Box border="1px solid white">
                  <Text
                    color="white"
                    padding="1.5"
                    fontSize={isMobile ? ".7rem" : "1rem"}
                  >
                    Fra 200 000 euro*{" "}
                  </Text>
                </Box>
                <Box border="1px solid white">
                  <Text
                    color="white"
                    padding="1.5"
                    fontSize={isMobile ? ".7rem" : "1rem"}
                  >
                    10 000€
                  </Text>
                </Box>
              </SimpleGrid>
            </ListItem>
            <ListItem mt="26px">
              Andrepremien på nivå 1 er et MF Guide-korrigering RTK-tilvalg
              gratis for alle kompatible MF-traktorer/skurtreskere, kjøpt før
              31. mars 2022.
              <br />
              <br />
              MF Guide-tilvalget må velges av vinneren når maskinen kjøpes.
              <br />
              Maksimum verdi for denne premien kan ikke overstige 16 060 euro.
              Årlige abonnementsavgifter inngår ikke (betales av vinneren).
            </ListItem>
            <ListItem mt="26px">
              Det tredje og siste premien på nivå 1 er en MF 1520 A Stage 5 til
              en verdi av 19 890 euro*, inklusiv dekktilvalg verdt 690 euro* (*
              prisliste 1 februar 2021)
            </ListItem>
          </UnorderedList>

          <Text color="white" mt="1%">
            En deltaker kan kun vinne en premie i konkurransen totalt, uansett
            nivå.
          </Text>
          <Text color="white" fontWeight="bold" mt="1%" fontStyle="italic">
            Nivå 2: Verdi opptil 5000 euro - Total rangering: 1001 – 2000
          </Text>
          <Text color="white" mt="1%">
            Deltakere fra plass 1001 til 2000 i konkurransen vil være med i
            trekningen av premier på nivå 2.
            <br />
            På dette nivået er det tre premier, med en høyeste verdi på 5 000
            euro
          </Text>

          <UnorderedList color="white" ml="40px" mt="1%">
            <ListItem>
              Førstepremien på nivå 2 er en MF sommerpakke til en verdi av 1000
              euro som inkluderer:
              <UnorderedList color="white">
                <ListItem ml="20px" type="circle">
                  En MF/AGCO tønnegrill og 4 x MF/AGCO Parts dekkstoler til en
                  total verdi av 340 euro
                </ListItem>
              </UnorderedList>
              <Text color="white" ml="-40px">
                Og
              </Text>
              <UnorderedList color="white">
                <ListItem ml="20px" type="circle">
                  En persontilpasset iPad Air Grey WIFI 64 GO til en verdi av
                  669 euro
                </ListItem>
              </UnorderedList>
            </ListItem>
          </UnorderedList>

          <Text color="white" mt="1%">
            Disse premiene vil bli levert separat til vinneren.
          </Text>

          <UnorderedList color="white" ml="40px">
            <ListItem mt="1%">
              Andrepremien på nivå 2 er en profesjonell trykkspyler til en verdi
              av 2000 euro.
              <br />
              <br />
              Den trykkspylermodellen som er vist er kun en illustrasjon, og
              denne kan endres avhengig av hvilket land vinneren befinner seg i.
              Vår AGCO Parts team vil informere vinneren om den eksakte modellen
              etter at trekningen er gjennomført.
            </ListItem>
            <ListItem mt="1%">
              Tredjepremien på nivå 2 har en verdi av 5000 euro og en er MF
              servicepakke som inneholder:
              <UnorderedList color="white" listStyleType="circle" mt="1%">
                <ListItem>
                  Et gavekort på 5000 euro som kan brukes på varer fra AGCO
                  Parts hos lokal MF forhandler.
                </ListItem>
                <ListItem mt="1%">
                  Mulighet for besøk på gården av vårt AGCO tekniske
                  servicesenter, sammen med lokal forhandler, for å få råd om
                  bruk av utstyr til Massey Ferguson traktorer og
                  teleskoplastere.
                </ListItem>
              </UnorderedList>
            </ListItem>
          </UnorderedList>

          <Text color="white" mt="1%">
            En deltaker kan kun vinne en premie i konkurransen totalt, uansett
            nivå.
          </Text>

          <Text color="white" fontWeight="bold" mt="2%" fontStyle="italic">
            Nivå 3: Verdi opptil 250 euro - Total rangering: 2001 - siste plass
          </Text>

          <Text color="white" mt="2%">
            Deltakere fra plass 2001 til sisteplass i konkurransen vil være med
            i trekningen av premier på nivå 3.
            <br /> På dette nivået er det tre premier, med en høyeste verdi på
            250 euro
          </Text>

          <UnorderedList color="white " mt="1%">
            <ListItem>
              Førsteprisen på nivå 3 er en Connectivity-pakke med tre eksklusive
              MASSEY FERGUSON-gaver:
              <UnorderedList color="white">
                <ListItem type="circle">
                  En MASSEY FERGUSON Smart Watch – Enhetspris: 180 euro
                </ListItem>
                <ListItem type="circle">
                  MASSEY FERGUSON ørepropper MASSEY FERGUSON – Enhetspris:
                  39,90 euro
                </ListItem>
                <ListItem type="circle">
                  MASSEY FERGUSON S Collection Powerbank – Enhetspris:
                  29,90 euro
                </ListItem>
                <Box display="block" mt="1%"></Box>
              </UnorderedList>
            </ListItem>

            <ListItem mt="1%">
              Andrepremien på nivå 3 er et e-gavekort til en verdi av 250 euro
              som kan brukes i flere omganger i MASSEY FERGUSONs nettbutikk:{" "}
              <Link href="https://shop.masseyferguson.com">
                https://shop.masseyferguson.com
              </Link>.{" "}
              E-gavekortet er gyldig i 36 måneder etter mottak.
            </ListItem>

            <ListItem mt="1%">
              Tredjepremien på nivå 3 er en gamingpakke som inneholder:
              <UnorderedList color="white" listStyleType="circle">
                <ListItem>
                  En MF 8S modellkjøretøy – Enhetspris: 70 euro
                </ListItem>
                <ListItem>
                  Eksklusiv MF 5S modellkjøretøy – Enhetspris: 65 euro
                </ListItem>
                <ListItem>
                  MASSEY FERGUSON trådløs mus til pc – Enhetspris: 15 euro
                </ListItem>
                <ListItem>
                  2 Farming Simulator 2022 spill til pc - Enhetspris: 40 euro
                </ListItem>
                <ListItem>
                  Eksklusiv Farming Simulator 2022 FS baseballcaps – Enhetspris:
                  20 euro
                </ListItem>
              </UnorderedList>
            </ListItem>
          </UnorderedList>
          <Text color="white" mt="1%">
            En deltaker kan kun vinne en premie i konkurransen totalt, uansett
            nivå.
          </Text>
          <Text color="red.500" fontWeight="bold" mt="1%">
            Artikkel 6 – Informasjon til og annonsering av vinner
          </Text>
          <Text color="white" mt="1%">
            Massey Fergusons markedsføringstem vil informere vinnere ved å sende
            dem en e-post der de blir bedt om å kontakte AGCO.
            <br />
            Hvis personen som er valgt ut i trekningen ikke svarer på denne
            e-posten innen 10 dager, vil en andre og siste trekning finne sted,
            og den første vinneren blir da diskvalifisert.
            <br />
            Vinnerne blir kontaktet av AGCO SAS. via e-post eller en annen
            metode, slik at de kan oppgi informasjonen som trengs for å levere
            premien.
            <br />
            Deltakeren samtykker i at dersom han/hun vinner konkurransen vil
            navnet deres bli offentliggjort i annonser, kampanjer eller på
            relevante nettsteder og i sosiale medier.
            <br />
            Vinneren forplikter seg til å gjøre seg tilgjengelig uten
            vederlaginnen rimelige grenser for lyd-, foto-, bilde- og/eller
            tekstkampanje via radio, internett, TV eller trykte medier og godtar
            publisering og bruk til reklameformål av AGCO og av selskaper
            tilknyttet AGCO .
            <br />
          </Text>
          <Text color="red.500" fontWeight="bold" mt="1%">
            Artikkel 7 – Ekskludering av deltakere
          </Text>

          <Text color="white" mt="1%">
            AGCO forbeholder seg retten til å ekskludere deltakere fra
            konkurransenDette gjelder spesielt brudd på vilkårene for
            deltakelse, falske personopplysninger eller manipulasjon eller
            mistenkt manipulasjon. AGCO kan også ekskludere deltakere som det er
            rimelig grunn til å mistenke at bruker AGCOs tjenester.
            <br />
            Hvis AGCO i ettertid fastslår at deltakeren ikke har rett til å
            delta i henhold til artikkel 2, kan AGCO ekskludere deltakeren fra
            utdeling eller overlevering av premie eller kreve tilbake eventuelle
            premier som allerede er overlevert.
          </Text>
          <Text color="red.500" fontWeight="bold" mt="1%">
            Artikkel 8 – Tilbaketrekking av premier
          </Text>
          <Text color="white" mt="1%">
            Du kan ikke under noen omstendighet bytte inn eller returnere
            premien eller få noen form for kompensasjon.
            <br />
            Det organiserende selskapet kan erstatte premiene med andre premier
            til tilsvarende verdi dersom eksterne omstendigheter gjør det umulig
            å tildele de annonserte premiene. Dette vilkåret er absolutt, og det
            er ikke et alternativ å få premien utbetalt i penger.
            <br />
            Premien vil bli sendt til vinneren innen 3 måneder etter at Massey
            Ferguson markedsføringsteam har mottatt all informasjonen som trengs
            for levering av premien.
          </Text>
          <Text color="red.500" fontWeight="bold" mt="1%">
            Artikkel 9 – Bruksvilkår og garanti
          </Text>
          <Text color="white" mt="1%">
            Organisatorene frasier seg alt ansvar for enhver hendelse og/eller
            ulykke som kan oppstå ved bruk av premiene og/eller som følge av
            bruk av disse.
            <br />
            Organisatorene forbeholder seg retten til å forkorte, forlenge,
            endre eller avbryte konkurransen, uten å pådra seg noen form for
            ansvar.
            <br />
            Organisatorene kan ikke holdes ansvarlig for eventuelle
            tjenesteavbrudd som følge av tekniske problemer i
            kommunikasjonsnettverkene eller tekniske problemer som er utenfor
            deres kontroll. Organisatorene kan ikke holdes ansvarlig dersom
            konkurransen helt eller delvis endres, utsettes eller avbrytes,
            uansett årsak. Det vil ikke være mulig å kreve kompensasjon for
            dette. Eventuelle uleselige, ufullstendige eller forfalskede
            skjemaer vil ikke bli med i trekningen. Det organiserende selskapet
            kan derfor ikke holdes ansvarlig dersom et skjema ikke registreres
            eller er umulig å kontrollere.
            <br />
          </Text>
          <Text color="red.500" fontWeight="bold" mt="1%">
            Artikkel 10 – Aksept og tvister
          </Text>

          <Text color="white" mt="1%">
            Ved å delta i denne konkurransen godtar deltakeren disse vilkårene
            og betingelsene.
            <br />
            Deltakerne forstår at konkurransen er basert på flaks, og at
            vedkommende ikke har noen garanti for annet enn en mulighet til å
            vinne en premie.
            <br />
            Eventuelle tvister vil bli sendt til juridisk avdeling AGCO SAS - 41
            avenue Blaise Pascal - BP 60307 - BEAUVAIS (60026) Frankrike, senest
            3. september (poststempel som bevis) per brev.
            <br />
            Denne forskriften er underlagt fransk lov, hvilket er det eneste
            lovverket som skal anvendes.
            <br />
            MASSEY FERGUSON markedsavdeling for EME i AGCO SAS. vil gi
            tilbakemelding dersom det oppstår praktiske problemer eller spørsmål
            om tolkning eller håndhevelse av denne forskriften.
            <br />
          </Text>
          <Text color="red.500" fontWeight="bold" mt="1%">
            Artikkel 11 – Offentliggjøring av vilkår og betingelser for
            konkurransen
          </Text>
          <Text color="white" mt="1%">
            Disse vilkårene vil være tilgjengelige på nett på MFs offisielle
            arrangementsplattform.
            <br />
            Deltakeren kan ikke kreve refusjon for internettkostnader i
            forbindelse med deltakelsen, ettersom det ikke koster noe å delta.
            <br />
            En fullstendig kopi av disse vilkårene finnes hos juridisk avdeling
            hos det organiserende selskapet.
            <br />
          </Text>
          <Text color="red.500" fontWeight="bold" mt="1%">
            Artikkel 12 – Personvern
          </Text>
          <Text color="white" mt="1%">
            For å delta i konkurransen må deltakerne samtykke til at enkelte av
            deres personopplysninger brukes av det organiserende selskapet i den
            hensikt å administrere konkurransen, men også i
            markedsføringsøyemed.
            <br />
            Du finner mer informasjon i personvernerklæringen vår, som er
            tilgjengelig via følgende lenke{" "}
            <Link href="http://www.agcocorp.com/privacy.html">
              http://www.agcocorp.com/privacy.html
            </Link>
            <br />
            Hver vinner gir det organiserende selskapet rettigheten til å bruke
            vedkommendes fornavn og etternavn, generell informasjon om
            vedkommende bosted og et bilde av vedkommende, i all
            markedsføringsrelatert og redaksjonell kommunikasjon.
            <br />I henhold til gjeldende forskrifter kan du helt eller delvis
            trekke deg fra konkurransen når som helst, uten å gi AGCO noen
            begrunnelse for dette. Du kan benytte deg av rettigheten til tilgang
            til, korrigering og sletting av personopplysningene dine samt til å
            motsette deg behandling av personopplysningene, ved å sende en
            e-post til dataprivacy@agcocorp.com.
          </Text>
        </Flex>
      </chakra.div>
    </Container>
  );
};

export default TermsAndConditionsContentNO;
