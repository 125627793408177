import React, { useState, useRef } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";

import {
  chakra,
  useBreakpointValue,
  useMultiStyleConfig,
  Container,
  Text,
  Link,
  Flex,
  Divider,
  ListItem,
  UnorderedList,
  SimpleGrid,
  Box,
} from "@chakra-ui/react";

import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import axiosHelper from "./../../helpers/axiosHelper";
import bgImage from "./../../assets/prizes/prizes-page-bg@2x.jpg";

import { ChevronLeftIcon, ChevronRightIcon } from "@chakra-ui/icons";

const TermsAndConditionsContentFR = ({ ...props }) => {
  const { t } = useTranslation();
  const history = useHistory();
  const [userProfile, setUserProfile] = useState<UserProfile>(
    axiosHelper.getUserProfile()!
  );
  const styles = useMultiStyleConfig("PrizePage", props);

  const isMobile = useBreakpointValue({ base: true, lg: false });
  const customSlider = useRef<any>();

  return (
    <Container
      maxW="container.xl"
      bgImage={bgImage}
      d="flex"
      flexDir="column"
      flexDirection="column"
      style={{
        overflow: "hidden",
        backgroundPosition: "center",
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
      }}
      alignItems="center"
    >
      <Flex
        flexDir={{ base: "column", lg: "column" }}
        justifyContent="space-between"
        w={isMobile?"100%":"50%"}
        mt="2%"
      >
        <Text color="white" mb="2%" mt={isMobile ? "30px" : "0"} align="center">
          CONDITIONS GÉNÉRALES
          <br />
          SUPER JEU-CONCOURS MF
          <br />
          Organisé du 2 juillet au 3 septembre 2021
          <br />
          Par MASSEY FERGUSON, marque du groupe AGCO
          <br />
        </Text>
      </Flex>
      <chakra.div w="100%" pb="100px">
        <Flex
          flexDir={{ base: "column", lg: "column" }}
          justifyContent="space-between"
          w="100%"
          mt="3%"
        >
          <Text color="red.500" mt="1%" fontWeight="bold">
            Article 1 - General Organization
          </Text>
          <Text color="white" mt="1%">
            Le service marketing MASSEY FERGUSON Europe et Moyen-Orient
            d’AGCO SAS, dont le siège social est situé au 41 avenue Blaise
            Pascal à Beauvais, organisera le 3 septembre un NOUVEAU LANCEMENT
            DIGITAL MASSEY FERGUSON qui comprendra un jeu-concours gratuit et
            sans engagement ouvert au grand public.
          </Text>

          <Text color="red.500" fontWeight="bold" mt="1%">
            Article 2 – Critères de participation
          </Text>

          <Text color="white" mt="1%">
            Seuls les participants résidant dans les pays d’Europe et du
            Moyen-Orient (à l’exception des pays soumis à des mesures de
            restriction des échanges commerciaux) peuvent participer. Seuls les
            agriculteurs, entrepreneurs et membres d’entreprises agricoles sont
            autorisés à participer à ce jeu-concours.
          </Text>

          <Text color="white" mt="1%">
            En sont exclus :
          </Text>

          <chakra.div>
            <UnorderedList ml="40px" color="white">
              <ListItem>toute personne âgée de moins de 18 ans ;</ListItem>
              <ListItem>
                les employés du groupe AGCO et les membres de leur famille ;
              </ListItem>
              <ListItem>
                les concessionnaires et distributeurs de produits et services
                AGCO ;
              </ListItem>
              <ListItem>
                les employés des entreprises affiliées à AGCO ;
              </ListItem>
              <ListItem>
                les agences et fournisseurs engagés par AGCO et les membres de
                la famille de leurs employés ;
              </ListItem>
              <ListItem>
                les journalistes invités par MASSEY FERGUSON à assister à
                l’événement.
              </ListItem>
            </UnorderedList>
          </chakra.div>
          <Text color="white" mt="1%">
            Une seule participation par personne, par foyer et par entité
            agricole (mêmes nom, prénom, numéro de téléphone et adresse e-mail)
            est autorisée. La participation au jeu est strictement personnelle.
            La société organisatrice se réserve le droit d’effectuer tout
            contrôle qu’elle juge approprié.
          </Text>
          <Text color="red.500" fontWeight="bold" mt="1%">
            Article 3 - Conditions de participation
          </Text>
          <Text color="white" mt="1%">
            En s’inscrivant au NOUVEL ÉVÉNEMENT DIGITAL MASSEY FERGUSON sur la
            plateforme Internet de la division organisatrice d’AGCO SAS, le
            participant participe automatiquement au jeu-concours sur lequel
            repose l’événement. Le participant consent à donner accès à ses
            informations personnelles afin d’être contacté par AGCO SAS par
            e-mail, téléphone ou sur les réseaux sociaux.
          </Text>
          <Text color="red.500" fontWeight="bold" mt="1%">
            Article 4 - Désignation des gagnants
          </Text>
          <Text color="white" mt="1%">
            Les résultats du jeu-concours seront annoncés entre le 6 septembre
            et le 6 octobre 2021 dans les bureaux d’AGCO SAS. - Service
            marketing de Massey Ferguson Europe et Moyen-Orient à Beauvais,
            France.
          </Text>
          <Box display="block" mt="1%"></Box>
          <Text color="white">
            Le tirage au sort aura lieu entre le 6 septembre et le
            6 octobre 2021 dans les bureaux d’AGCO SAS. - Service marketing de
            Massey Ferguson Europe et Moyen-Orient à Beauvais, France. Le
            service marketing de Massey Ferguson Europe et Moyen-Orient de
            Beauvais effectuera le tirage au sort à huis clos.
          </Text>
          <Text color="red.500" fontWeight="bold" mt="1%">
            Article 5 – Prix
          </Text>
          <Text color="white" mt="1%">
            Ce jeu-concours comprendra 3 niveaux de récompense comportant chacun
            3 prix, pour un total de 9 gagnants. En répondant à des questions
            après son inscription, le participant aura la possibilité
            d’augmenter son score et d’essayer d’atteindre le niveau le plus
            élevé de gain du jeu-concours.
          </Text>
          <Text color="white" mt="1%">
            Après l’événement, chaque participant sera affecté à l’un des
            3 tirages au sort ci-dessous, selon son classement final au jeu – le
            classement final étant annoncé sur la plateforme Internet juste
            après l’événement (le 3 septembre 2021, à 15 h). Même si le
            participant ne répond à aucune question, il sera automatiquement
            inclus dans le tirage au sort de niveau 3.
          </Text>

          <Text color="white" fontWeight="bold" mt="1%" fontStyle="italic">
            Niveau 1 : Valeur maximale de 20 000 € - Classement général : 1 à
            1 000
          </Text>
          <Text color="white" mt="1%">
            Les participants classés de 1 à 1 000 au jeu-concours seront inclus
            dans la liste des participants du tirage au sort du niveau 1.
            <br />
            Ce niveau met en jeu 3 prix d’une valeur maximale de 20 580 €.
          </Text>
          <UnorderedList color="white" ml="40px" mt="1%">
            <ListItem>
              Le premier prix de ce niveau 1 est une remise maximale de 10 000 €
              sur l’achat d’une machine MF neuve.
              <br />
              <br />
              Cette remise s’applique uniquement sur l’achat d’une machine neuve
              parmi la gamme MASSEY FERGUSON de matériel de fenaison, tracteurs,
              moissonneuses-batteuses, presses à balles et chargeurs
              télescopiques (selon le stock de machines de la concession),
              effectué avant le 31 mars 2022.
              <br />
              <br />
              Conditions de remise (*sur la base des listes de prix, options
              incluses) :
              <br />
              <SimpleGrid
                w={isMobile ? "100%" : "50%"}
                autoFlow=""
                borderColor="white"
                columns={[2, null, 2]}
                border="1px solid white"
                mt="5px"
              >
                <Box bg="red.500" border="1px solid white">
                  <Text
                    color="white"
                    fontWeight="bold"
                    padding="1.5"
                    fontSize={isMobile ? ".7rem" : "1rem"}
                  >
                    Valeur de la machine neuve* :{" "}
                  </Text>
                </Box>
                <Box bg="red.500" border="1px solid white">
                  <Text
                    color="white"
                    fontWeight="bold"
                    padding="1.5"
                    fontSize={isMobile ? ".7rem" : "1rem"}
                  >
                    Remise appliquée :{" "}
                  </Text>
                </Box>
                <Box border="1px solid white">
                  <Text
                    color="white"
                    padding="1.5"
                    fontSize={isMobile ? ".7rem" : "1rem"}
                  >
                    Inférieure à 25 000 €*{" "}
                  </Text>
                </Box>
                <Box border="1px solid white">
                  <Text
                    color="white"
                    padding="1.5"
                    fontSize={isMobile ? ".7rem" : "1rem"}
                  >
                    1500€
                  </Text>
                </Box>
                <Box border="1px solid white">
                  <Text
                    color="white"
                    padding="1.5"
                    fontSize={isMobile ? ".7rem" : "1rem"}
                  >
                    De 25 000 € à moins de 50 000 €*{" "}
                  </Text>
                </Box>
                <Box border="1px solid white">
                  <Text
                    color="white"
                    padding="1.5"
                    fontSize={isMobile ? ".7rem" : "1rem"}
                  >
                    2500€
                  </Text>
                </Box>
                <Box border="1px solid white">
                  <Text
                    color="white"
                    padding="1.5"
                    fontSize={isMobile ? ".7rem" : "1rem"}
                  >
                    De 50 000 € à moins de 100 000 €*{" "}
                  </Text>
                </Box>
                <Box border="1px solid white">
                  <Text
                    color="white"
                    padding="1.5"
                    fontSize={isMobile ? ".7rem" : "1rem"}
                  >
                    4000€
                  </Text>
                </Box>
                <Box border="1px solid white">
                  <Text
                    color="white"
                    padding="1.5"
                    fontSize={isMobile ? ".7rem" : "1rem"}
                  >
                    De 100 000 € à moins de 150 000 €*{" "}
                  </Text>
                </Box>
                <Box border="1px solid white">
                  <Text
                    color="white"
                    padding="1.5"
                    fontSize={isMobile ? ".7rem" : "1rem"}
                  >
                    6000€
                  </Text>
                </Box>
                <Box border="1px solid white">
                  <Text
                    color="white"
                    padding="1.5"
                    fontSize={isMobile ? ".7rem" : "1rem"}
                  >
                    De 150 000 € à moins de 200 000 €*{" "}
                  </Text>
                </Box>
                <Box border="1px solid white">
                  <Text
                    color="white"
                    padding="1.5"
                    fontSize={isMobile ? ".7rem" : "1rem"}
                  >
                    8000€
                  </Text>
                </Box>
                <Box border="1px solid white">
                  <Text
                    color="white"
                    padding="1.5"
                    fontSize={isMobile ? ".7rem" : "1rem"}
                  >
                    Supérieure ou égale à 200 000 €*{" "}
                  </Text>
                </Box>
                <Box border="1px solid white">
                  <Text
                    color="white"
                    padding="1.5"
                    fontSize={isMobile ? ".7rem" : "1rem"}
                  >
                    10 000€
                  </Text>
                </Box>
              </SimpleGrid>
            </ListItem>
            <ListItem mt="26px">
              Le deuxième prix du niveau 1 est une option MF Guide gratuite avec
              correction RTK pour tout tracteur/moissonneuse-batteuse MF
              compatible acheté(e) avant le 31 mars 2022.
              <br />
              <br />
              L’option MF Guide doit être sélectionnée par le gagnant à l’achat
              de la machine.
              <br />
              La valeur maximale de ce prix n’excède pas 16 060 €. Les frais
              d’abonnement annuels ne sont pas compris (à la charge du gagnant).
            </ListItem>
            <ListItem mt="26px">
              Le troisième et dernier prix du niveau 1 est un MF 1520 A Stage 5
              d’une valeur de 19 890 €*, comprenant des pneus en option d’une
              valeur de 690 €* (*Liste de prix du 1er février 2021)
            </ListItem>
          </UnorderedList>

          <Text color="white" mt="1%">
            Un participant ne peut remporter qu’un seul prix dans le cadre du
            jeu, tous niveaux confondus.
          </Text>
          <Text color="white" fontWeight="bold" mt="1%" fontStyle="italic">
            Niveau 2 : Valeur maximale de 5 000 € - Classement général : 1 001 à
            2 000
          </Text>
          <Text color="white" mt="1%">
            Les participants classés de 1 001 à 2 000 au jeu-concours seront
            inclus dans la liste des participants du tirage au sort du niveau 2.
            <br />
            Ce niveau met en jeu 3 prix d’une valeur maximale de 5 000 €.
          </Text>

          <UnorderedList color="white" ml="40px" mt="1%">
            <ListItem>
              Le premier prix du niveau 2 est un MF Summer Pack d’une valeur
              totale de 1 000 € comprenant :
              <UnorderedList color="white">
                <ListItem ml="20px" type="circle">
                  un barbecue tonneau MF/AGCO Parts et 4 transats MF/AGCO Parts,
                  d’une valeur totale de 340 €
                </ListItem>
              </UnorderedList>
              <Text color="white" ml="-40px">
                et
              </Text>
              <UnorderedList color="white">
                <ListItem ml="20px" type="circle">
                  un iPad Air gris 64 Go WIFI personnalisé d’une valeur de 669 €
                </ListItem>
              </UnorderedList>
            </ListItem>
          </UnorderedList>

          <Text color="white" mt="1%">
            Ces prix seront remis séparément au gagnant.
          </Text>

          <UnorderedList color="white" ml="40px">
            <ListItem mt="1%">
              Le deuxième prix du niveau 2 est un nettoyeur haute pression
              professionnel d’une valeur de 2 000 €.
              <br />
              <br />
              Le modèle du nettoyeur haute pression présenté n’est pas
              contractuel et peut être modifié selon le pays du gagnant. Notre
              équipe Produits complémentaires AGCO Parts fournira au gagnant les
              informations relatives au modèle exact après sa sélection.
            </ListItem>
            <ListItem mt="1%">
              Le troisième et dernier prix du niveau 2 est un pack
              d’entretien MF d’une valeur totale de 5 000 € comprenant :
              <UnorderedList color="white" listStyleType="circle" mt="1%">
                <ListItem>
                  un bon d’achat de 5 000 € utilisable dans la boutique de la
                  concession MF locale du gagnant sur tous les articles
                  AGCO Parts disponibles ;
                </ListItem>
                <ListItem mt="1%">
                  la possibilité de bénéficier sur son exploitation d’une visite
                  de notre service technique AGCO accompagné du concessionnaire
                  MF local pour bénéficier de conseils sur l’utilisation de son
                  tracteur ou de son télescopique Massey Ferguson.
                </ListItem>
              </UnorderedList>
            </ListItem>
          </UnorderedList>

          <Text color="white" mt="1%">
            Un participant ne peut remporter qu’un seul prix dans le cadre du
            jeu, tous niveaux confondus.
          </Text>

          <Text color="white" fontWeight="bold" mt="2%" fontStyle="italic">
            Niveau 3 : Valeur maximale de 250 € - Classement général : 2 001 à
            la fin du classement
          </Text>

          <Text color="white" mt="2%">
            Les participants classés de 2 001 à la fin du classement seront
            inclus dans la liste des participants du tirage au sort du niveau 3.
            <br /> Ce niveau met en jeu 3 prix d’une valeur totale de 250 €
            chacun.
          </Text>

          <UnorderedList color="white " mt="1%">
            <ListItem>
              Le premier prix du niveau 3 est un pack Connectivité comprenant
              3 cadeaux MASSEY FERGUSON exclusifs :
              <UnorderedList color="white">
                <ListItem type="circle">
                  une montre connectée MASSEY FERGUSON – Prix unitaire : 180 €
                </ListItem>
                <ListItem type="circle">
                  une paire d’écouteurs MASSEY FERGUSON – Prix unitaire :
                  39,90 €
                </ListItem>
                <ListItem type="circle">
                  un chargeur Collection S MASSEY FERGUSON – Prix unitaire :
                  29,90 €
                </ListItem>
                <Box display="block" mt="1%"></Box>
              </UnorderedList>
            </ListItem>

            <ListItem mt="1%">
              Le deuxième prix du niveau 3 est un bon d’achat d’une valeur de
              250 € utilisable en plusieurs fois sur la boutique en ligne
              MASSEY FERGUSON:{" "}
              <Link href="https://shop.masseyferguson.com">
                https://shop.masseyferguson.com
              </Link>.{" "}
              Ce bon d’achat en ligne est valable pendant 36 mois à compter de
              sa réception.
            </ListItem>

            <ListItem mt="1%">
              Le troisième et dernier prix du niveau 3 est un pack jeu
              comprenant :
              <UnorderedList color="white" listStyleType="circle">
                <ListItem>
                  un modèle réduit MF 8S – Prix unitaire : 70 €
                </ListItem>
                <ListItem>
                  le modèle réduit exclusif MF 5S – Prix unitaire : 65 €
                </ListItem>
                <ListItem>
                  une souris sans fil MASSEY FERGUSON pour PC – Prix unitaire :
                  15 €
                </ListItem>
                <ListItem>
                  2 jeux Farming Simulator 2022 pour PC - Prix unitaire : 40 €
                </ListItem>
                <ListItem>
                  la casquette exclusive Farming Simulator 2022 – Prix
                  unitaire : 20 €
                </ListItem>
              </UnorderedList>
            </ListItem>
          </UnorderedList>
          <Text color="white" mt="1%">
            Un participant ne peut remporter qu’un seul prix dans le cadre du
            jeu, tous niveaux confondus.
          </Text>
          <Text color="red.500" fontWeight="bold" mt="1%">
            Article 6 – Annonce des gagnants et notification
          </Text>
          <Text color="white" mt="1%">
            L’équipe marketing de Massey Ferguson enverra un e-mail aux gagnants
            pour les inviter à contacter AGCO.
            <br />
            Si la personne tirée au sort ne répond pas à l’e-mail sous 10 jours,
            un second et dernier tirage au sort a lieu et le premier gagnant est
            disqualifié.
            <br />
            Chaque gagnant est contacté par la société AGCO SAS par e-mail ou
            par tout autre moyen afin de recevoir les informations requises pour
            la remise du prix.
            <br />
            Le participant accepte qu’en cas de gain, son nom soit annoncé
            publiquement dans des publicités, des promotions ou sur des sites
            Internet ou pages de réseaux sociaux pertinentes.
            <br />
            Le gagnant s’engage à se rendre disponible gratuitement dans la
            limite du raisonnable pour toute promotion par audio, photo, image
            et/ou texte sur radio, Internet, télévision ou presse écrite, et
            autorise leur publication et leur utilisation à des fins
            publicitaires par AGCO et les sociétés affiliées à AGCO.
            <br />
          </Text>
          <Text color="red.500" fontWeight="bold" mt="1%">
            Article 7 – Exclusion de participants
          </Text>

          <Text color="white" mt="1%">
            AGCO se réserve le droit d’exclure des participants du jeu-concours.
            Cela s’applique en particulier en cas de violation des conditions de
            participation, de communication de données personnelles incorrectes
            et de manipulation ou suspicion de manipulation. AGCO peut également
            exclure des participants raisonnablement suspectés d’utiliser les
            services d’AGCO de manière abusive.
            <br />
            Si AGCO établit que les agissements du participant justifient son
            exclusion ou qu’il n’était pas habilité à participer au jeu-concours
            au titre de l’article 2, AGCO peut décider de ne pas lui transmettre
            ou lui remettre le prix ou exiger que le prix remis soit restitué.
          </Text>
          <Text color="red.500" fontWeight="bold" mt="1%">
            Article 8 – Retrait des lots
          </Text>
          <Text color="white" mt="1%">
            Aucun échange financier, retour ou indemnisation n’est autorisé.
            <br />
            La société organisatrice peut remplacer les prix par d’autres prix
            de valeur équivalente, dans le cas où des circonstances extérieures
            empêchent la remise des prix annoncés. Ladite remise de prix ne peut
            faire l’objet d’aucune négociation ni versement en espèces.
            <br />
            Le prix est envoyé au gagnant dans un délai de 3 mois à compter de
            la réception par l’équipe marketing de Massey Ferguson de toutes les
            informations requises pour l’envoi du prix.
          </Text>
          <Text color="red.500" fontWeight="bold" mt="1%">
            Article 9 - Conditions d’utilisation et garantie
          </Text>
          <Text color="white" mt="1%">
            Les organisateurs déclinent toute responsabilité en cas d’incident
            ou d’accident susceptible de survenir pendant ou du fait de la
            jouissance des prix attribués.
            <br />
            Les organisateurs se réservent le droit d’écourter, prolonger,
            modifier ou annuler cette opération si les circonstances l’exigent,
            sans que leur responsabilité puisse être engagée.
            <br />
            Les organisateurs ne peuvent être tenus pour responsables en cas
            d’interruption de service due à un incident technique sur les
            réseaux de communication ou à un incident technique qui échappe à
            leur contrôle. Les organisateurs ne peuvent être tenus pour
            responsables en cas de modification, report ou annulation partielle
            ou complète du concours, quel qu’en soit le motif. Aucune
            indemnisation ne peut être exigée. Les formulaires inintelligibles,
            incomplets ou falsifiés ne sont pas pris en compte. La
            responsabilité de la société organisatrice ne peut par conséquent
            pas être engagée en cas de non-enregistrement des formulaires ou
            d’impossibilité de les consulter.
            <br />
          </Text>
          <Text color="red.500" fontWeight="bold" mt="1%">
            Article 10 - Acceptation et litiges
          </Text>

          <Text color="white" mt="1%">
            En participant à ce jeu-concours, tous les participants acceptent
            les présentes conditions générales.
            <br />
            Chaque participant reconnaît que la nature aléatoire du jeu-concours
            n’offre aucune garantie de gain.
            <br />
            Les éventuels litiges sont à adresser par courrier au Service
            juridique d’AGCO SAS, 41 avenue Blaise Pascal, BP 60307,
            60026 BEAUVAIS, France, au plus tard le 3 septembre 2021 (le cachet
            de la poste faisant foi).
            <br />
            Le présent règlement est uniquement régi par le droit français.
            <br />
            AGCO SAS - Service marketing MASSEY FERGUSON Europe et Moyen-Orient
            - statue concernant toute difficulté pratique d’interprétation ou
            d’application liée au présent règlement.
            <br />
          </Text>
          <Text color="red.500" fontWeight="bold" mt="1%">
            Article 11 – Communication des conditions générales du jeu-concours
          </Text>
          <Text color="white" mt="1%">
            Les présentes conditions générales seront disponibles en ligne sur
            la plateforme officielle de l’événement MF.
            <br />
            Les participants disposant d’une connexion Internet ne peuvent
            prétendre à aucun remboursement, la participation n’impliquant aucun
            coût additionnel.
            <br />
            Une copie intégrale des présentes conditions générales est conservée
            au service juridique de la société organisatrice.
            <br />
          </Text>
          <Text color="red.500" fontWeight="bold" mt="1%">
            Article 12 – Protection des données à caractère personnel
          </Text>
          <Text color="white" mt="1%">
            Pour participer à ce jeu-concours, les participants doivent
            obligatoirement accepter que certaines informations à caractère
            personnel les concernant soient utilisées par la société
            organisatrice à des fins marketing et de gestion du jeu-concours.
            Pour plus d’informations, vous pouvez consulter notre politique de
            confidentialité en suivant le lien suivant:{" "}
            <Link href="http://www.agcocorp.com/privacy.html">
              http://www.agcocorp.com/privacy.html
            </Link>
            <br />
            Chaque gagnant autorise la société organisatrice, sans aucune
            contrepartie financière ou autre, à utiliser son nom, prénom,
            indication sommaire du lieu de résidence et photographie sur tous
            les supports promotionnels ou éditoriaux.
            <br />
            Conformément aux réglementations en vigueur, vous pouvez retirer
            votre participation en tout ou partie à tout moment sans vous
            justifier auprès d’AGCO. Vous pouvez exercer vos droits d’accès, de
            rectification, de suppression et d’opposition par e-mail à l’adresse
            dataprivacy@agcocorp.com
          </Text>
        </Flex>
      </chakra.div>
    </Container>
  );
};

export default TermsAndConditionsContentFR;
