import {
  Flex,
  Text,
  useMultiStyleConfig,
  useBreakpointValue,
} from "@chakra-ui/react";
import React, { FC, useEffect } from "react";
import { ReactComponent as Spinner } from "./../../../assets/clock/spinner.svg";

export type Props = {
  digit: number;
  maxNum: number;
  id: string;
  label?: string;
  width: string;
};

const Clock: FC<Props> = (
  { label, digit, id, maxNum, width }: Props,
  ...props
) => {
  const styles = useMultiStyleConfig("Hall", props);
  const isMobile = useBreakpointValue({ base: true, lg: false });

  useEffect(() => {
    if (digit != null && maxNum != null) {
      const count = Math.round((62 * digit) / maxNum);
      let svg = document.getElementById(id + "svg");

      if (svg != null) {
        let index = 0;
        svg.childNodes.forEach((el) => {
          if (index > 0) {
            const child = el.childNodes[0] as HTMLElement;
            if (index < count + 1) {
              child.classList.add("on");
            } else {
              child.classList.remove("on");
            }
          }
          index++;
        });
      } else {
        console.log("was null");
      }
    }

    return () => {};
  }, [digit]);

  const addLeadingZero = (value: number) => {
    let valueString = String(value);
    while (valueString.length < 2) {
      valueString = "0" + valueString;
    }
    return valueString;
  };

  return (
    <Flex
      flexDir="column"
      w={{ base: "70px", lg: width }}
      alignItems="center"
      className="clockItem"
    >
      <Spinner
        id={id + "svg"}
        width={isMobile ? "70px" : width}
        height={isMobile ? "70px" : "170px"}
        className="clockSVG"
      />
      <Text sx={styles.clockTime} className="clockNum">{addLeadingZero(Math.floor(digit))}</Text>
      <Text sx={styles.clockLabel} id={id} className="clockLabel">
        {label}
      </Text>
    </Flex>
  );
};

export default Clock;
