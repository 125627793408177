import { Subscription } from "lightstreamer-client-web";
import React, { useEffect, useState, useRef } from "react";
import axiosHelper from "../helpers/axiosHelper";
import { useCall } from "../helpers/useCall";
import { getSubscription, removeSubscription } from "../helpers/lsHelper";
import { OneToOneTile } from "./onetoone/OneToOneTile";
import DailyIframe, {
  DailyCall,
  DailyEvent,
  DailyParticipant,
} from "@daily-co/daily-js";

//Media
import liveIcon from "../img/icon-live-white.svg";
import { SSL_OP_NO_TLSv1_2 } from "constants";

interface Props {
  onUser: (user: null | DailyParticipant) => void;
  switchToHLS: () => void;
  onClose: () => void;
}

const OneToOneBlock = (props: Props) => {
  const [userProfile] = useState(axiosHelper.getUserProfile());
  const {
    enableStartButton,
    startJoiningCall,
    startLeavingCall,
    callState,
    remoteUser,
    localUser,
    devices,

    selectedAudio,
    setSelectedAudio,
    selectedVideo,
    setSelectedVideo,

    setIsVideo,
    isVideo,
  } = useCall(
    "ID" +
      userProfile?.id +
      "__" +
      userProfile?.firstName +
      " " +
      userProfile?.lastName
  );

  const [firstStepMessage, setFirstStepMessage] = useState(
    "Please allow webcam access"
  );
  const [liveStatus, setLiveStatus] = useState<string>("not-requested");
  const [broadcastingStatus, setBroadcastingStatus] = useState("waiting");
  const [posInQueue, setPosInQueue] = useState<number>();
  const [controlRoomMessage, setControlRoomMessage] = useState(
    "Please wait for instructions in this box."
  );
  const [showInstructions, setShowInstructions] = useState(true);
  // const videoRef = useRef<Webcam>(null);
  const [oneToOne, setOneToOne] = useState<OneToOneRequest>();
  const lsSubscription = useRef<any>(null);
  const o2o = useRef<any>(null);

  useEffect(() => {
    console.log("USE EFFECT #################");
    return () => {
      console.log("CLEANUP");
      if (lsSubscription.current != null) {
        console.log("removing ls subscription");
        removeSubscription(lsSubscription.current);
      } else {
        console.log("no LS Subscription");
      }
      if (o2o.current != null) {
        console.log("removing request");
        cancelRequest(false, o2o.current);
      } else {
        console.log("no onetoone");
      }
      props.switchToHLS();
    };
  }, []);

  useEffect(() => {
    console.log("USE EFFECT REMOTE USER");
    console.log(remoteUser);
    props.onUser(remoteUser);
  }, [remoteUser]);

  useEffect(() => {
    console.log("USE EFFECT ONETOONE");
    console.log(oneToOne);
    // let lsSubscription: Subscription | null = null;
    if (oneToOne != null && lsSubscription.current === null) {
      lsSubscription.current = getSubscription(
        "ONETOONE_" + oneToOne.randomID,
        (message: string) => {
          console.log(
            "#####################################################################"
          );
          console.log(message);
          if (message === "LIVE_ON") {
            // props.switchToWebRtc();
            setBroadcastingStatus("screening");
          } else if (message === "LIVE_ROOM") {
            refreshOneToOne();
          } else if (message === "LIVE_LIVE") {
            setBroadcastingStatus("live");
          } else if (message === "LIVE_OFF") {
            o2o.current = null;
            setBroadcastingStatus("waiting");
            setLiveStatus("ended");
            startLeavingCall();
            props.switchToHLS();
          } else if (message === "LIVE_DENIEDT") {
            o2o.current = null;
            setBroadcastingStatus("waiting");
            setLiveStatus("denied-t");
            props.switchToHLS();
            startLeavingCall();
          } else if (message === "LIVE_DENIEDQ") {
            o2o.current = null;
            setBroadcastingStatus("waiting");
            setLiveStatus("denied-q");
            props.switchToHLS();
          } else if (message.includes("LIVE_QUEUE")) {
            setPosInQueue(Number(message.split("|")[1]));
          } else if (message.includes("LIVE_CHAT")) {
            setControlRoomMessage(message.split("|")[1]);
          }
        }
      );
    } else {
      console.log("non subscribo...");
    }
    return () => {};
  }, [oneToOne]);

  const cancelRequest = async (shouldUpdateUI: boolean, id?: number) => {
    // console.log('###########################################################');
    console.log("CANCEL REQUEST " + shouldUpdateUI);
    console.log("cancel " + id);
    console.log(oneToOne);
    // console.log('###########################################################');

    try {
      const result = await axiosHelper.cancelOneToOne(id || oneToOne!.id);
      if (shouldUpdateUI) {
        setLiveStatus("canceled");
      }
      o2o.current = null;
    } catch (e) {
      props.onClose();
    }
  };
  const refreshOneToOne = async () => {
    try {
      if (oneToOne != null) {
        const result = await axiosHelper.getOneToOne(oneToOne.id);
        setOneToOne(result.data);
        setPosInQueue(result.data.posInQueue);
        setShowInstructions(false);
        if (result.data.roomUrl != null) {
          startJoiningCall(result.data.roomUrl);
          setBroadcastingStatus("screening");
        } else {
          setLiveStatus("requested");
        }
      }
    } catch {
      props.onClose();
    }
  };

  const performRequest = async () => {
    try {
      const result = await axiosHelper.createOneToOne();
      setOneToOne(result.data);
      o2o.current = result.data.id;
      setPosInQueue(result.data.posInQueue);
      setShowInstructions(false);
      if (result.data.roomUrl != null) {
        startJoiningCall(result.data.roomUrl);
        setBroadcastingStatus("screening");
      } else {
        setLiveStatus("requested");
      }
    } catch {
      props.onClose();
    }
  };

  // <Webcam width="100%" screenshotFormat="image/jpeg" onUserMedia={didAllow} onUserMediaError={didNotAllow} />}
  return (
    <div className="content">
      <div className="box col-6 nopadding left">
        <div className="columns-wrapper">
          {showInstructions || localUser == null ? (
            <>
              <div className="box col-4 icon-container">
                <img src={liveIcon} className="icon" alt="Q&A" />
              </div>
              <div className="box col-8 info-container">
                <div className="title">Live questions</div>
              </div>
            </>
          ) : (
            <div className="box col-12">
              <div className="myVideoCamera">
                <OneToOneTile
                  videoTrackState={localUser?.tracks.video ?? null}
                  audioTrackState={null}
                />
              </div>
            </div>
          )}
        </div>
      </div>

      {liveStatus === "not-requested" ? (
        <div className="box col-6">
          <div className="help center">
            By clicking the button below, you submit your request for a live
            question.
          </div>
          <div className="help center">
            When asked (pop-up), please allow the access to your webcam and mic,
            in order to proceed.
          </div>
          <button
            className="button--submit_small"
            onClick={() => {
              performRequest();
            }}
          >
            Click here to Continue
          </button>
        </div>
      ) : null}
      {liveStatus === "requested" ? (
        <div className="box col-6">
          {broadcastingStatus === "waiting" ? (
            <>
              <div className="title center">Waiting</div>
              <div className="help center">
                GET READY TO GIVE WEBCAM PERMISSIONS
              </div>
              {posInQueue! < 2 ? (
                <div className="queue">
                  <span>You are in line</span>
                </div>
              ) : (
                <div className="queue">
                  There are <span>{posInQueue}</span> people ahead of you
                </div>
              )}
              <div className="help bigger">
                Once given permissions, the control room will be able to see
                you, to check the quality of your connection and finally air
                you.
              </div>
              {/* <div className="help center"><br />GET READY TO GIVE WEBCAM PERMISSIONS</div> */}
              <button
                className="button--submit_small reverse"
                onClick={() => {
                  setLiveStatus("discard-request");
                }}
              >
                Cancel your Request
              </button>
            </>
          ) : null}
          {broadcastingStatus === "screening" ? (
            <>
              <div className="title center">Control Room</div>
              <div className="help center">YOU ARE STILL NOT LIVE.</div>
              <div className="help">
                The control room is able to see you, to check the quality of
                your connection and finally air you.
              </div>
              <div className="help crLabel">
                <br />
                Message from Control Room:
              </div>
              {controlRoomMessage.length > 0 ? (
                <div className="help crMessage">{controlRoomMessage}</div>
              ) : null}
              <div className="help"></div>
              <button
                className="button--submit_small reverse"
                onClick={() => {
                  setLiveStatus("discard-request");
                }}
              >
                Cancel your Request
              </button>
            </>
          ) : null}
          {broadcastingStatus === "live" ? (
            <>
              <div className="title center">YOU ARE LIVE!</div>
              {/* <div className="help crLabel"><br />Message from Control Room:</div>
                        {controlRoomMessage.length > 0 ? <div className="help crMessage">{controlRoomMessage}</div> : null} */}
              <div className="help bigger">
                Once the presenters talk to you, you can ask your question!
              </div>

              <div className="help center"></div>
            </>
          ) : null}
        </div>
      ) : null}

      {liveStatus === "discard-request" ? (
        <div className="box col-6">
          <div className="cancelBox">
            <p className="center">
              Are you sure you want to cancel your request?
            </p>
            <button
              className="button--submit_small gray"
              onClick={() => {
                cancelRequest(true);
              }}
            >
              YES, cancel my request
            </button>
            <button
              className="button--submit_small"
              onClick={() => {
                setLiveStatus("requested");
              }}
            >
              NO, keep me on the waiting list
            </button>
          </div>
        </div>
      ) : null}
      {liveStatus === "denied-t" ? (
        <div className="box col-6">
          <div className="title center">Request denied</div>
          <div className="help center">SORRY!</div>
          <div className="help">
            <br />
            Due to limited time we are unable to answer your question live.
            <br />
            Please send you question to{" "}
            <a href="mailto:masseyfergusonpress@agcocorp.com">
              masseyfergusonpress@agcocorp.com
            </a>
            <br /> <br />
          </div>
          <button
            className="button--submit_small reverse"
            onClick={() => {
              props.onClose();
            }}
          >
            Close
          </button>
        </div>
      ) : null}
      {liveStatus === "denied-q" ? (
        <div className="box col-6">
          <div className="title center">Request denied</div>
          <div className="help center">SORRY!</div>
          <div className="help">
            <br />
            Due to poor video quality, we cannot take your question. <br />{" "}
            Please send us a written question, or email us at{" "}
            <a href="mailto:masseyfergusonpress@agcocorp.com">
              masseyfergusonpress@agcocorp.com
            </a>
            <br />
            <br />
          </div>
          <button
            className="button--submit_small reverse"
            onClick={() => {
              props.onClose();
            }}
          >
            Close
          </button>
        </div>
      ) : null}
      {liveStatus === "ended" ? (
        <div className="box col-6">
          <div className="title">Thank you</div>
          <div className="help">
            Thank you for your participation, enjoy the rest of the event.
          </div>
          <button
            className="button--submit_small reverse"
            onClick={() => {
              props.onClose();
            }}
          >
            Close
          </button>
        </div>
      ) : null}

      {liveStatus === "canceled" ? (
        <div className="box col-6">
          <div className="title center">Request canceled</div>
          <div className="help center">
            As per your request, your participation has been cancelled.
          </div>
          <button
            className="button--submit_small reverse"
            onClick={() => {
              props.onClose();
            }}
          >
            Close
          </button>
        </div>
      ) : null}
    </div>
  );
};

export default OneToOneBlock;
