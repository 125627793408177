import React, { FC, useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import axiosHelper from "../../helpers/axiosHelper";
import { ErrorMessage } from "@hookform/error-message";
import { useTranslation } from "react-i18next";
import {
  Flex,
  Modal,
  ModalBody,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Input,
  InputGroup,
  InputRightElement,
  Text,
  useMultiStyleConfig,

} from "@chakra-ui/react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";


type Props = {
  clickOpen?: boolean;
  clickClose?: () => void;
};

const ChangePassword: FC<Props> = ({
  clickOpen = false,
  clickClose = () => { },
  ...props
}) => {
  const [error, setError] = useState<boolean>(false);
  const [loginErrorMessage, setLoginErrorMessage] = useState<string>();
  const [successful, setSuccessful] = useState<boolean>(false);
  const [passwordShown, setPasswordShown] = useState(false);
  const { t } = useTranslation();
  const [userProfile, setUserProfile] = useState<UserProfile>(axiosHelper.getUserProfile()!);


  const styles = useMultiStyleConfig("Login", props);

  const { register, handleSubmit, errors, watch } = useForm<ChangePassData>({
    criteriaMode: "all",
  });

  const eye = <FontAwesomeIcon icon={faEye} />;
  const eyeSlash = <FontAwesomeIcon icon={faEyeSlash} />;

  type ChangePassData = {
    email: string;
    currentPassword: string;
    password: string;
    passwordAgain: string;
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const togglePasswordVisiblity = () => {
    setPasswordShown(passwordShown ? false : true);
  };

  const onSubmit = (values: ChangePassData) => {
    // console.log(values);
    setSuccessful(false);
    if (values.password === values.passwordAgain) {
      changePassword(values.currentPassword, values.password);

    }
  };

  const changePassword = async (oldPassword: string, newPassword: string) => {
    // console.log(data);
    try {
      const result = await axiosHelper.changePassword<UserProfile>(
        oldPassword,
        newPassword
      );
      console.log(result);
      setSuccessful(true);
      setError(false);
      setTimeout(clickClose, 1200);
    } catch (error) {
      if (error.response == null) {
        setLoginErrorMessage(t("err.backendcomm"));
        setError(true);
      } else {
        switch (error.response.status) {
          case 417:
            setLoginErrorMessage(t("err.logindisabled"));
            setError(true);
            break;
          case 401:
            setLoginErrorMessage(t("err.login"));
            setError(true);
            break;
          default:
            setLoginErrorMessage(t("err.login"));
            setError(true);
            break;
        }
      }
    }
  };



  return (
    <Modal isOpen={clickOpen} onClose={clickClose} size="xl">
      <ModalOverlay backgroundColor="rgba(0, 0, 0, 0.8)" />
      <ModalContent
        borderRadius="0px"
        bgColor="white"
        boxShadow="0px 5px 15px rgba(225,225,225,0.15)"
      >
        <ModalHeader>
          <Flex justifyContent="space-between" alignItems="center">
            <Text as="h1" textStyle="h1" color="red.500">
              {t("header.changepassword")}
            </Text>
          </Flex>
        </ModalHeader>
        {/* <ModalCloseButton /> */}
        <ModalBody>


          <Flex>
            <Flex flexDir="column" w="100%">
              <form onSubmit={handleSubmit(onSubmit)}>
                <Flex flexDir="column">
                  <Flex mb="30px">
                  {userProfile!=null &&  <input
                      autoComplete="username"
                      type="hidden"
                      name="email"
                      value={userProfile.email}
                    ></input>}

                    <Input
                      ref={register({
                        required: t("err.required") as string,
                      })}
                      type={passwordShown ? "text" : "password"}
                      autoComplete="current-password"
                      placeholder={t("changepass.currentpass")}
                      name="currentPassword"
                      h="50px"
                      borderRadius="0px"
                      bgColor="white"
                      variant="outline"
                    />

                    <ErrorMessage as="div" errors={errors} name="currentPassword" className="error-message" />
                  </Flex>

                  <Flex w="100%">
                    <InputGroup>
                      <Input
                        ref={register({
                          required: t("err.required") as string,
                          pattern: {
                            // value: /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z]).{8,}$/gm,
                            value:
                              /^(?=.*[a-z])(?=.*[A-Z])(?=.*[|\-_\\0123456789!@#&()\[{}\]:;',?/*~$^+=<>]).{8,}$/gm,
                            message: t("err.passtooweak") as string,
                          },
                        })}
                        type={passwordShown ? "text" : "password"}
                        autoComplete="new-password"
                        placeholder={t("activate.passwordph")}
                        name="password"
                        h="50px"
                        borderRadius="0px"
                        bgColor="white"
                        variant="outline"
                      />
                      <InputRightElement h="100%" mr="10px">
                        <i onClick={togglePasswordVisiblity}>
                          {!passwordShown ? eyeSlash : eye}
                        </i>
                      </InputRightElement>
                    </InputGroup>


                    <ErrorMessage as="div" errors={errors} name="password" className="error-message" />
                  </Flex>
                  <Flex w="100%" mt="30px">
                    <InputGroup>
                      <Input
                        ref={register({
                          required: t("err.required") as string,
                          validate: (value) =>
                            value === watch("password") ||
                            (t("err.mismatch") as string),
                        })}
                        type={passwordShown ? "text" : "password"}
                        autoComplete="new-password"
                        placeholder={t("activate.passwordph")}
                        name="passwordAgain"
                        h="50px"
                        borderRadius="0px"
                        bgColor="white"
                        variant="outline"
                      />
                      <InputRightElement h="100%" mr="10px">
                        <i onClick={togglePasswordVisiblity}>
                          {!passwordShown ? eyeSlash : eye}
                        </i>
                      </InputRightElement>
                    </InputGroup>


                    <ErrorMessage as="div" errors={errors} name="passwordAgain" className="error-message" />
                  </Flex>
                </Flex>

                <Flex justifyContent="space-between" alignItems="center" mt="30px">
                  <button
                    className="button--submit"
                    style={{
                      backgroundColor: "#C41230",
                      paddingTop: "10px",
                      paddingBottom: "10px",
                      paddingLeft: "30px",
                      paddingRight: "30px",
                      border: "none",
                      color: "white",
                      width: "35%",
                    }}
                  >
                    {t("login.okbtn")}
                  </button>
                </Flex>

                {error ? (
                  <div className="modal-text error-message">
                    {loginErrorMessage}
                  </div>
                ) : null}
                {successful ? (
                  <div className="modal-text">
                    {t("changepass.success")}
                  </div>
                ) : null}

              </form>
            </Flex>
          </Flex>


        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default ChangePassword;
