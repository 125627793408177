import React, { FC } from "react";
import {
  chakra,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
} from "@chakra-ui/react";

import PasswordReset from "./PasswordReset";

type Props = {
  clickOpen?: boolean;
  clickClose?: () => void;
};

const LostPasswordModal: FC<Props> = ({
  clickOpen = false,
  clickClose = () => {},
}) => {
  return (
    <Modal isOpen={clickOpen} onClose={clickClose} size="xl">
      <ModalOverlay backgroundColor="rgba(0, 0, 0, 0.8)" />
      <ModalContent
        borderRadius="0px"
        bgColor="white"
        boxShadow="0px 5px 15px rgba(225,225,225,0.15)"
        p="0px"
      >
        <ModalCloseButton />

        <ModalBody p="15px">
          <PasswordReset />
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default LostPasswordModal;
