import React, { FC, useState, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import Constants from "../../helpers/constants";

import {
  chakra,
  useBreakpointValue,
  useMultiStyleConfig,
  Container,
  Text,
  Flex,
  Divider,
  HStack,
  Image,
  calc,
} from "@chakra-ui/react";

import triangle from "./../../assets/Tracciato 167.svg";

//export type Props = {};
interface Props {
  ranking: number;
}

const Leaderboard: FC<Props> = ({ ranking: ranking }: Props, ...props) => {
  const styles = useMultiStyleConfig("Hall", props);
  const { t } = useTranslation();
  const [imgSequenceNo, setImgSequenceNo] = useState(1);
  const [image1, setImage1] = useState('');
  const [image2, setImage2] = useState('');
  const [image3, setImage3] = useState('');
  const [redIn, setRedIn] = useState(0);
  const isMobile = useBreakpointValue({ base: true, lg: false });
  const [currentBox, setCurrentBox] = useState("flex-start");
  const [fixing, setFixing] = useState({ f1: 0, f2: 0, f3: 0 });

  const ref1 = useRef<any>();
  const ref2 = useRef<any>();
  const ref3 = useRef<any>();
  useEffect(() => {
    calculateImages();
    return function cleanup() { };
  }, [ranking, imgSequenceNo]);

  const calculateImages = () => {

    // "leaderboard/active/Level-1-Gift-1.png";
    if (ranking > 0 && ranking <= 1000) {
      setRedIn(1);
      setImage1(Constants.LEADERBOARD_PATH + 'active/Level-1-Gift-' + imgSequenceNo + '.png');
      setImage2(Constants.LEADERBOARD_PATH + 'inactive/Level-2-Gift-' + imgSequenceNo + '.png');
      setImage3(Constants.LEADERBOARD_PATH + 'inactive/Level-3-Gift-' + imgSequenceNo + '.png');
    } else if (ranking > 1000 && ranking <= 2000) {
      setRedIn(2);
      setImage1(Constants.LEADERBOARD_PATH + 'inactive/Level-1-Gift-' + imgSequenceNo + '.png');
      setImage2(Constants.LEADERBOARD_PATH + 'active/Level-2-Gift-' + imgSequenceNo + '.png');
      setImage3(Constants.LEADERBOARD_PATH + 'inactive/Level-3-Gift-' + imgSequenceNo + '.png');
    } else if (ranking > 2000) {
      setRedIn(3);
      setImage1(Constants.LEADERBOARD_PATH + 'inactive/Level-1-Gift-' + imgSequenceNo + '.png');
      setImage2(Constants.LEADERBOARD_PATH + 'inactive/Level-2-Gift-' + imgSequenceNo + '.png');
      setImage3(Constants.LEADERBOARD_PATH + 'active/Level-3-Gift-' + imgSequenceNo + '.png');
    }
    setTimeout(() => {
      changeSequenceNo()
    }, 10000);
  }

  const nextSequenceNo = () => {
    if (imgSequenceNo == 3) {
      return 1;
    } else {
      return imgSequenceNo + 1;
    }
  }
  const changeSequenceNo = () => {
    setImgSequenceNo(nextSequenceNo);
  }

  useEffect(() => {
    if (ranking > 0 && ranking <= 1000) {
      setCurrentBox("flex-start");
      const pos1 = ref1.current.offsetWidth / 2 - 11;
      setFixing({ f1: pos1, f2: 0, f3: 0 });
    } else if (ranking > 1000 && ranking <= 2000) {
      setCurrentBox("center");
      const pos2 = ref2.current.offsetWidth / 2;
      setFixing({ f1: 0, f2: pos2, f3: 0 });
    } else if (ranking > 2000) {
      setCurrentBox("flex-end");
      const pos3 = ref3.current.offsetWidth / 2 + 11;
      setFixing({ f1: 0, f2: 0, f3: pos3 });
    }
  }, [ranking, window.innerWidth]);

  return (
    <Flex
      ml={isMobile ? "0" : "15px"}
      mt={isMobile ? "30px" : ""}
      //   h="100%"
      h="auto"
      w={isMobile ? "full" : "35%"}
      px="15px"
      borderLeft={isMobile ? "0" : "1px solid #707070"}
      flexDir="column"
      alignItems="center"
    >
      <Text
        fontFamily="Arial Black, Arial-BoldMT, Arial"
        fontSize="16px"
        color="#707070"
        textTransform="uppercase"
        textAlign="center"
      >
        {t("counterhall.leaderboard")}
      </Text>
      {ranking > 0 && (
        <Text
          fontFamily="Arial Black, Arial-BoldMT, Arial"
          fontSize={isMobile ? "50px" : "72px"}
          color="red.500"
        >
          {ranking}
        </Text>
      )}

      <Divider orientation="horizontal" borderColor="red.500" mt="44px" />
      <Flex
        w="100%"
        justifyContent={currentBox}
        px={
          currentBox === "flex-start"
            ? fixing.f1
            : currentBox === "center"
              ? fixing.f2
              : fixing.f3
        }
      >
        <Image src={triangle} />
      </Flex>

      <HStack spacing={{ base: "15px", lg: "35px" }} mb="30px" justify={isMobile ? "space-between" : "center"} width="100%">
        <Flex flexDir="column" ref={ref1}>
          <Text
            fontFamily="Arial Black, Arial-BoldMT, Arial"
            fontSize={isMobile ? "11px" : "13px"}
            color={redIn === 1 ? "red.500" : "#707070"}
            // mb="15px"
            h="45px"
            d="flex"
            alignItems="center"
          >
            1 - 1.000
          </Text>
          <Image src={image1} width={isMobile ? "100%" : "100%"} />
        </Flex>
        <Flex flexDir="column" ref={ref2}>
          <Text
            fontFamily="Arial Black, Arial-BoldMT, Arial"
            fontSize={isMobile ? "11px" : "13px"}
            color={redIn === 2 ? "red.500" : "#707070"}
            h="45px"
            d="flex"
            alignItems="center"
          >
            1.001 - 2.000
          </Text>
          <Image src={image2} width={isMobile ? "100%" : "100%"} />
        </Flex>
        <Flex flexDir="column" ref={ref3}>
          <Text
            fontFamily="Arial Black, Arial-BoldMT, Arial"
            fontSize={isMobile ? "11px" : "13px"}
            color={redIn === 3 ? "red.500" : "#707070"}
            h="45px"
            d="flex"
            alignItems="center"
          >
            2001 +
          </Text>
          <Image src={image3} width={isMobile ? "100%" : "100%"} />
        </Flex>
      </HStack>
    </Flex>
  );
};

export default Leaderboard;
