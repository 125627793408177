import React, { useState, useRef } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";

import {
  chakra,
  useBreakpointValue,
  useMultiStyleConfig,
  Container,
  Text,
  Link,
  Flex,
  Divider,
  ListItem,
  UnorderedList,
  SimpleGrid,
  Box,
} from "@chakra-ui/react";

import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import axiosHelper from "./../../helpers/axiosHelper";
import bgImage from "./../../assets/prizes/prizes-page-bg@2x.jpg";

import { ChevronLeftIcon, ChevronRightIcon } from "@chakra-ui/icons";

const TermsAndConditionsContentUK = ({ ...props }) => {
  const { t } = useTranslation();
  const history = useHistory();
  const [userProfile, setUserProfile] = useState<UserProfile>(
    axiosHelper.getUserProfile()!
  );
  const styles = useMultiStyleConfig("PrizePage", props);

  const isMobile = useBreakpointValue({ base: true, lg: false });
  const customSlider = useRef<any>();

  return (
    <Container
      maxW="container.xl"
      bgImage={bgImage}
      d="flex"
      flexDir="column"
      flexDirection="column"
      style={{
        overflow: "hidden",
        backgroundPosition: "center",
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
      }}
      alignItems="center"
    >
      <Flex
        flexDir={{ base: "column", lg: "column" }}
        justifyContent="space-between"
        w={isMobile?"100%":"50%"}
        mt="2%"
      >
        <Text color="white" mb="2%" mt={isMobile ? "30px" : "0"} align="center">
          УМОВИ Й ПОЛОЖЕННЯ
          <br />
          СУПЕРКОНКУРС MF
          <br />
          Проводиться з 2 липня до 3 вересня 2021 року
          <br />
          A брендом MASSEY FERGUSON AGCO
          <br />
        </Text>
      </Flex>
      <chakra.div w="100%" pb="100px">
        <Flex
          flexDir={{ base: "column", lg: "column" }}
          justifyContent="space-between"
          w="100%"
          mt="3%"
        >
          <Text color="red.500" mt="1%" fontWeight="bold">
            Пункт 1. Основні організаційні положення
          </Text>
          <Text color="white" mt="1%">
            Відділ маркетингу MASSEY FERGUSON — Європа та Близький Схід компанії
            AGCO SAS, головний офіс якої розташований за адресою: 41 Blaise
            Pascal Avenue, Beauvais (Бове), 3 вересня проводить ОНЛАЙН-ПРЕМ'ЄРУ
            MASSEY FERGUSON, що включає участь у безкоштовному конкурсі, який ні
            до чого не зобов'язує і доступний для всіх бажаючих.
          </Text>

          <Text color="red.500" fontWeight="bold" mt="1%">
            Пункт 2. Хто може брати участь
          </Text>

          <Text color="white" mt="1%">
            Брати участь можуть лише претенденти з країн Європи та Близького
            Сходу (за винятком країн, до яких застосовуються обмежувальні заходи
            у торгівлі). У цьому конкурсі можуть брати участь тільки фермери,
            підрядники та особи, пов'язані з сільськогосподарськими
            підприємствами.
          </Text>

          <Text color="white" mt="1%">
            Не допускаються до участі:
          </Text>

          <chakra.div>
            <UnorderedList ml="40px" color="white">
              <ListItem>Особи віком до 18 років</ListItem>
              <ListItem>Співробітники AGCO Group і члени їх сімей</ListItem>
              <ListItem>
                Дилери та дистриб'ютори продукції та послуг AGCO
              </ListItem>
              <ListItem>Співробітники компаній, афілійованих з AGCO</ListItem>
              <ListItem>
                Агентства та постачальники, залучені AGCO, та родичі відповідних
                співробітників
              </ListItem>
              <ListItem>
                Журналісти, запрошені MASSEY FERGUSON для участі в заході
              </ListItem>
            </UnorderedList>
          </chakra.div>
          <Text color="white" mt="1%">
            На одні й ті ж самі ім'я, прізвище, номер телефону та адресу
            електронної пошти може зареєструватися лише один учасник — фізична
            особа, домашнє господарство або сільськогосподарське підприємство.
            Участь у ігровому конкурсі є суто особистою. Компанія-організатор
            залишає за собою право здійснювати будь-який контроль, який вона
            вважатиме за необхідне.
          </Text>
          <Text color="red.500" fontWeight="bold" mt="1%">
            Пункт 3. Умови участі
          </Text>
          <Text color="white" mt="1%">
            Реєстрація на ОНЛАЙН-ПРЕМ'ЄРУ MASSEY FERGUSON через веб-платформу
            організаційного підрозділу AGCO SAS означає автоматичну участь у
            ігровому конкурсі, оскільки він являє собою основу заходу. Учасник
            погоджується надати свої особисті дані AGCO SAS для зв'язку
            електронною поштою, телефоном або в соціальних мережах.
          </Text>
          <Text color="red.500" fontWeight="bold" mt="1%">
            Пункт 4. Визначення переможців
          </Text>
          <Text color="white" mt="1%">
            Результати конкурсу будуть оголошені у період з 6 вересня до 6
            жовтня 2021 року в офісах AGCO SAS. - Відділ маркетингу Massey
            Ferguson — Європа та Близький Схід, розташований у Бове, Франція.
          </Text>
          <Box display="block" mt="1%"></Box>
          <Text color="white">
            Результати конкурсу будуть оголошені в період з 6 вересня до 6
            жовтня 2021 року в офісах AGCO SAS. - Відділ маркетингу Massey
            Ferguson — Європа та Близький Схід, розташований у Бове, Франція.
            Відділ маркетингу Massey Ferguson — Європа та Близький Схід,
            розташований у Бове, проведе розіграш у закритому режимі.
          </Text>
          <Text color="red.500" fontWeight="bold" mt="1%">
            Пункт 5. Призи
          </Text>
          <Text color="white" mt="1%">
            У цьому ігровому конкурсі буде 3 рівня та 3 призи на кожен рівень,
            тож загалом 9 переможців. Відповідаючи на питання після реєстрації,
            учасник отримає можливість збільшити кількість своїх балів і
            отримати в цьому конкурсі подарунки вищого рівня.
          </Text>
          <Text color="white" mt="1%">
            Після заходу кожному учаснику буде надано можливість участі в 1 з 3
            розіграшів призів, описаних нижче, залежно від його кінцевого місця
            в ігровому рейтингу - Кінцеві місця буде оголошено на веб-платформі
            відразу після заходу (3 вересня 2021 року, о 15:00). Навіть якщо
            учасник не відповість на жодне запитання, його автоматично буде
            включено до списку на розіграш призів 3-го рівня.
          </Text>

          <Text color="white" fontWeight="bold" mt="1%" fontStyle="italic">
            Рівень 1: Вартість до 20 000 євро — Кінцеве місце: 1–1000
          </Text>
          <Text color="white" mt="1%">
            For Серед учасників, які посядуть у конкурсі місця з 1 до 1000, буде
            розіграно призи 1-го рівня.
            <br /> На цьому рівні доступні 3 призи, максимальна вартість яких
            становить 20 580 євро.
          </Text>
          <UnorderedList color="white" ml="40px" mt="1%">
            <ListItem>
              Перший приз на рівні 1 — знижка до 10 000 євро на придбання нової
              машини MF.
              <br />
              <br />
              Ця знижка поширюється тільки на придбання нової техніки:
              інструменти для заготівлі сіна, трактори, комбайни, прес-підбирачі
              та телескопічні навантажувачі (з урахуванням наявності машин у
              дилерському центрі) з асортименту MASSEY FERGUSON, здійснене до 31
              березня 2022 року.
              <br />
              <br />
              Правила застосування знижки (*на основі прайс-листів, включаючи
              додаткове обладнання):
              <br />
              <SimpleGrid
                w={isMobile ? "100%" : "50%"}
                autoFlow=""
                borderColor="white"
                columns={[2, null, 2]}
                border="1px solid white"
                mt="5px"
              >
                <Box bg="red.500" border="1px solid white">
                  <Text
                    color="white"
                    fontWeight="bold"
                    padding="1.5"
                    fontSize={isMobile ? ".7rem" : "1rem"}
                  >
                    Вартість нової машини*:{" "}
                  </Text>
                </Box>
                <Box bg="red.500" border="1px solid white">
                  <Text
                    color="white"
                    fontWeight="bold"
                    padding="1.5"
                    fontSize={isMobile ? ".7rem" : "1rem"}
                  >
                    "Знижка, що застосовується"{" "}
                  </Text>
                </Box>
                <Box border="1px solid white">
                  <Text
                    color="white"
                    padding="1.5"
                    fontSize={isMobile ? ".7rem" : "1rem"}
                  >
                    До 25 000 євро*{" "}
                  </Text>
                </Box>
                <Box border="1px solid white">
                  <Text
                    color="white"
                    padding="1.5"
                    fontSize={isMobile ? ".7rem" : "1rem"}
                  >
                    1500€
                  </Text>
                </Box>
                <Box border="1px solid white">
                  <Text
                    color="white"
                    padding="1.5"
                    fontSize={isMobile ? ".7rem" : "1rem"}
                  >
                    Від 25 000 до 50 000 євро*{" "}
                  </Text>
                </Box>
                <Box border="1px solid white">
                  <Text
                    color="white"
                    padding="1.5"
                    fontSize={isMobile ? ".7rem" : "1rem"}
                  >
                    2500€
                  </Text>
                </Box>
                <Box border="1px solid white">
                  <Text
                    color="white"
                    padding="1.5"
                    fontSize={isMobile ? ".7rem" : "1rem"}
                  >
                    Від 50 000 до 100 000 євро*{" "}
                  </Text>
                </Box>
                <Box border="1px solid white">
                  <Text
                    color="white"
                    padding="1.5"
                    fontSize={isMobile ? ".7rem" : "1rem"}
                  >
                    4000€
                  </Text>
                </Box>
                <Box border="1px solid white">
                  <Text
                    color="white"
                    padding="1.5"
                    fontSize={isMobile ? ".7rem" : "1rem"}
                  >
                    Від 100 000 до 150 000 євро*{" "}
                  </Text>
                </Box>
                <Box border="1px solid white">
                  <Text
                    color="white"
                    padding="1.5"
                    fontSize={isMobile ? ".7rem" : "1rem"}
                  >
                    6000€
                  </Text>
                </Box>
                <Box border="1px solid white">
                  <Text
                    color="white"
                    padding="1.5"
                    fontSize={isMobile ? ".7rem" : "1rem"}
                  >
                    Від 150 000 до 200 000 євро*{" "}
                  </Text>
                </Box>
                <Box border="1px solid white">
                  <Text
                    color="white"
                    padding="1.5"
                    fontSize={isMobile ? ".7rem" : "1rem"}
                  >
                    8000€
                  </Text>
                </Box>
                <Box border="1px solid white">
                  <Text
                    color="white"
                    padding="1.5"
                    fontSize={isMobile ? ".7rem" : "1rem"}
                  >
                    Від 200 000 євро*{" "}
                  </Text>
                </Box>
                <Box border="1px solid white">
                  <Text
                    color="white"
                    padding="1.5"
                    fontSize={isMobile ? ".7rem" : "1rem"}
                  >
                    10 000 євро{" "}
                  </Text>
                </Box>
              </SimpleGrid>
            </ListItem>
            <ListItem mt="26px">
              Другий приз на рівні 1 — безкоштовна опціональна система MF Guide
              з корекцією RTK для будь-якого сумісного трактора/комбайна MF,
              придбаного до 31 березня 2022 року.
              <br />
              <br />
              Переможець повинен вибрати опціональну систему MF Guide при
              придбанні машини..
              <br />
              Максимальна вартість цього призу не перевищує 16 060 євро. Річна
              абонентська плата не включена (стягується з переможця).
            </ListItem>
            <ListItem mt="26px">
              Третій і останній приз на рівні 1 — трактор MF 1520 A стандарту
              Stage 5 вартістю 19 890 євро*, включаючи шини вартістю 690 євро*
              (*Прайс-лист станом на 1 лютого 2021 року).
            </ListItem>
          </UnorderedList>

          <Text color="white" mt="1%">
            E Учасник розіграшу за всю гру може виграти тільки один приз на всіх
            рівнях.
          </Text>
          <Text color="white" fontWeight="bold" mt="1%" fontStyle="italic">
            Niveau 2: Værdi op til 5.000 euro - Samlet ranking: 1001-2000
          </Text>
          <Text color="white" mt="1%">
            Серед учасників, які посядуть у конкурсі місця з 1001 до 2000, буде
            розіграно призи 2-го рівня.
            <br />
            На цьому рівні доступні 3 призи, максимальна вартість яких становить
            5000 євро.
          </Text>

          <UnorderedList color="white" ml="40px" mt="1%">
            <ListItem>
              Перший приз на рівні 2 — пакет MF Summer Pack загальною вартістю
              1000 євро, який включає:
              <UnorderedList color="white">
                <ListItem ml="20px" type="circle">
                  Набір для барбекю MF/AGCO Parts Oil Barrel і 4 шезлонги
                  MF/AGCO Parts загальною вартістю 340 євро
                </ListItem>
              </UnorderedList>
              <Text color="white" ml="-40px">
                Та
              </Text>
              <UnorderedList color="white">
                <ListItem ml="20px" type="circle">
                  Персоналізований iPad Air Grey WIFI 64 Гб вартістю 669 євро
                </ListItem>
              </UnorderedList>
            </ListItem>
          </UnorderedList>

          <Text color="white" mt="1%">
            Ці подарунки будуть доставлені переможцю окремо.
          </Text>

          <UnorderedList color="white" ml="40px">
            <ListItem mt="1%">
              Другий приз на рівні 2 — професійна мийка високого тиску вартістю
              2000 євро.
              <br />
              <br />
              Представлена модель мийки високого тиску не є предметом
              обговорення та може бути змінена залежно від країни переможця.
              Наша команда відділу додаткового обладнання AGCO Parts
              Complementary надасть точні дані про модель після вибору
              переможця.
            </ListItem>
            <ListItem mt="1%">
              Третій і останній приз на рівні 2 загальною вартістю 5000 євро —
              пакет послуг MF, який включає:
              <UnorderedList color="white" listStyleType="circle" mt="1%">
                <ListItem>
                  Ваучер на 5000 євро, який можна використати у магазині
                  місцевого MF дилера переможця для придбання будь-яких наявних
                  запчастин AGCO Parts.
                </ListItem>
                <ListItem mt="1%">
                  Можливість замовити виїзну консультацію фахівця нашого Центра
                  технічного обслуговування AGCO в супроводі місцевого дилера
                  для отримання рекомендацій з використання трактора та
                  телескопічного навантажувача Massey Ferguson.
                </ListItem>
              </UnorderedList>
            </ListItem>
          </UnorderedList>

          <Text color="white" mt="1%">
            Учасник розіграшу за всю гру може виграти тільки один приз на всіх
            рівнях.
          </Text>

          <Text color="white" fontWeight="bold" mt="2%" fontStyle="italic">
            Рівень 3: Вартість до 250 євро — Кінцеве місце: 2001 – останнє
          </Text>

          <Text color="white" mt="2%">
            Серед учасників, які посядуть у конкурсі місця з 2001 до останнього,
            буде розіграно призи 3-го рівня.
            <br /> На цьому рівні доступні 3 призи з однаковою загальною
            вартістю 250 євро
          </Text>

          <UnorderedList color="white " mt="1%">
            <ListItem>
              Перший приз на рівні 3 — пакет Connectivity Pack, який включає 3
              ексклюзивні подарунки MASSEY FERGUSON:
              <UnorderedList color="white">
                <ListItem type="circle">
                  Розумний годинник MASSEY FERGUSON — Ціна за одиницю: 180 євро
                </ListItem>
                <ListItem type="circle">
                  Комплект навушників MASSEY FERGUSON — Ціна за одиницю: 39,90
                  євро
                </ListItem>
                <ListItem type="circle">
                  Зовнішній акумулятор MASSEY FERGUSON S Collection — Ціна за
                  одиницю: 29,90 євро
                </ListItem>
                <Box display="block" mt="1%"></Box>
              </UnorderedList>
            </ListItem>

            <ListItem mt="1%">
              Другий приз на рівні 3 — електронний ваучер на 250 євро, який
              можна використовувати частинами кілька разів у онлайн-магазині
              MASSEY FERGUSON{" "}
              <Link href="https://shop.masseyferguson.com">
                https://shop.masseyferguson.com
              </Link>.{" "}
              Цей електронний ваучер дійсний протягом 36 місяців з моменту його
              отримання.
            </ListItem>

            <ListItem mt="1%">
              Третій і останній приз на рівні 3 — ігровий пакет, що включає:
              <UnorderedList color="white" listStyleType="circle">
                <ListItem>
                  Масштабну модель MF 8S — Ціна за одиницю: 70 євро
                </ListItem>
                <ListItem>
                  Ексклюзивну масштабну модель MF 5S — Ціна за одиницю: 65 євро
                </ListItem>
                <ListItem>
                  Бездротову мишу MASSEY FERGUSON для ПК — Ціна за одиницю: 15
                  євро
                </ListItem>
                <ListItem>
                  2 гри для ПК Farming Simulator 2022 (симулятор ферми) — Ціна
                  за одиницю: 40 євро
                </ListItem>
                <ListItem>
                  Ексклюзивна бейсболка Farming Simulator 2022 FS — Ціна за
                  одиницю: 20 євро
                </ListItem>
              </UnorderedList>
            </ListItem>
          </UnorderedList>
          <Text color="white" mt="1%">
            Учасник розіграшу за всю гру може виграти тільки один приз на всіх
            рівнях.
          </Text>
          <Text color="red.500" fontWeight="bold" mt="1%">
            Пункт 6. Інформування та оголошення переможців
          </Text>
          <Text color="white" mt="1%">
            Співробітники відділу маркетингу Massey Ferguson поінформують
            переможців, надіславши їм електронного листа із запрошенням
            звернутися до AGCO. Якщо особа, вибрана в процесі розіграшу, не
            відповість на це електронне повідомлення протягом 10 днів, буде
            проведено другий і останній розіграш, а перший переможець буде
            дискваліфікований.
            <br />
            Співробітники компанії AGCO SAS зв'яжуться з переможцями електронною
            поштою або іншим способом, щоб надіслати інформацію, необхідну для
            отримання призів.
            <br />
            Учасник погоджується, що в разі його перемоги у конкурсі його ім'я
            буде публічно оголошено у рекламі, акціях або на відповідних
            веб-сайтах і в соціальних мережах.
            <br />
            Переможець зобов'язується безкоштовно в розумних межах брати участь
            у рекламних кампаніях, що проводяться на радіо, в Інтернеті, на
            телебаченні або в друкованих ЗМІ, і погоджується на публікацію та
            використання в рекламних цілях компанією AGCO і компаніями,
            афільованими з AGCO, аудіоматеріалів, фотографій, зображень і/або
            тексту зі своєю участю.
            <br />
          </Text>
          <Text color="red.500" fontWeight="bold" mt="1%">
            Пункт 7. Виключення учасників
          </Text>

          <Text color="white" mt="1%">
            AGCO залишає за собою право виключати учасників із конкурсу. Це
            стосується, зокрема, порушень умов участі, неправдивих персональних
            даних чи маніпуляцій або підозр у маніпуляціях. AGCO може також
            виключити учасників, які обґрунтовано підозрюються у використанні
            послуг AGCO.
            <br />
            Якщо компанія AGCO згодом встановить, що учасник створив будь-яку
            причину, що веде до виключення його з числа учасників, або фактично
            не мав права брати участь на підставі критеріїв, викладених у пункті
            2, AGCO може виключити учасника з розподілу або передачі призів або
            повернути вже видані призи.
          </Text>
          <Text color="red.500" fontWeight="bold" mt="1%">
            Пункт 8. Видача призів
          </Text>
          <Text color="white" mt="1%">
            Обмін на гроші, повернення або компенсація не допускаються за жодних
            обставин.
            <br />
            Компанія-організатор може замінити призи на інші, що мають
            еквівалентну цінність, якщо зовнішні обставини не дозволяють видати
            переможцям заявлені призи. Призи не обговорюються та не підлягають
            заміні на грошовий еквівалент.
            <br />
            Приз буде відправлений переможцю протягом 3 місяців після того, як
            відділ маркетингу Massey Ferguson отримає всю інформацію, необхідну
            для доставки цього призу.
          </Text>
          <Text color="red.500" fontWeight="bold" mt="1%">
            Пункт 9. Умови надання й гарантія
          </Text>
          <Text color="white" mt="1%">
            Організатори конкурсу не несуть жодної відповідальності за будь-які
            випадки та/або аварії, які можуть статися під час отримання призів
            і/або внаслідок їх використання.
            <br />
            Організатори залишають за собою право скорочувати, продовжувати,
            змінювати або скасовувати цю операцію, якщо того вимагають
            обставини, не беручи на себе жодної відповідальності.
            <br />
            Організатори не можуть нести відповідальність у разі переривання
            обслуговування, спричиненого технічною несправністю у мережах
            зв'язку або технічною неполадкою з незалежних від організаторів
            причин. Організатори не можуть нести відповідальність, якщо з
            якихось причин конкурс буде частково або повністю змінено,
            перенесено або скасовано. Вимоги щодо компенсації не будуть
            прийматися. Будь-які нерозбірливі, неповні та підроблені форми
            заявок не розглядатимуться. Таким чином, компанія-організатор не
            несе відповідальності в разі, якщо форми заявок не зареєстровані або
            їх неможливо перевірити.
            <br />
          </Text>
          <Text color="red.500" fontWeight="bold" mt="1%">
            Пункт 10. Прийняття умов і суперечки
          </Text>

          <Text color="white" mt="1%">
            Беручи участь у цьому ігровому конкурсі, усі учасники підтверджують
            свою згоду з даними умовами й положеннями. Кожен учасник визнає, що
            випадковий характер змагань не дає жодних гарантій перемоги.
            <br />
            Будь-які суперечки, що можуть виникнути, повинні спрямовуватися в
            письмовій формі до юридичного відділу AGCO SAS за адресою: 41 avenue
            Blaise Pascal – BP 60307 - BEAUVAIS (60026) France (Бове, Франція)
            не пізніше 3 вересня (за поштовим штемпелем).
            <br />
            Цей регламент регулюється відповідно до законодавства Франції, яке є
            єдиним застосовуваним.
            <br />
            Відділ маркетингу компанії AGCO SAS Massey Ferguson — Європа та
            Близький Схід надаватиме роз'яснення щодо будь-яких практичних
            труднощів, тлумачення чи запитів щодо застосування цього Регламенту.
            <br />
          </Text>
          <Text color="red.500" fontWeight="bold" mt="1%">
            Пункт 11. Надання інформації про положення й умови конкурсу
          </Text>
          <Text color="white" mt="1%">
            Ці положення й умови будуть доступні онлайн на офіційній платформі
            заходів MF.
            <br />
            Учасник розіграшу через Інтернет не може вимагати відшкодування,
            оскільки його участь не несе жодних додаткових витрат.
            <br />
            Повна версія положень і умов знаходиться в юридичному відділі
            компанії-організатора.
            <br />
          </Text>
          <Text color="red.500" fontWeight="bold" mt="1%">
            Пункт 12. Захист персональних даних
          </Text>
          <Text color="white" mt="1%">
            Для участі у цьому конкурсі учасники повинні обов'язково погодитися
            з тим, що деяка особиста інформація про них буде використовуватися
            компанією-організатором з метою управління конкурсом, а також у
            маркетингових цілях.
            <br />
            Для отримання додаткової інформації ви можете ознайомитися з нашою
            Політикою конфіденційності за посиланням:{" "}
            <Link href="http://www.agcocorp.com/privacy.html">
              http://www.agcocorp.com/privacy.html
            </Link>
            <br />
            Кожен переможець погоджується надати компанії-організатору право
            використовувати своє ім'я, прізвище, коротку адресу та фотографії
            для будь-яких рекламних або редакційних матеріалів без будь-якої
            фінансової або іншої компенсації.
            <br />
            Згідно з чинними правилами ви можете припинити свою участь в
            розіграші повністю або частково в будь-який час, не надаючи AGCO
            жодних обґрунтувань. Ви можете скористатися правом доступу,
            виправлення, видалення та заперечення щодо обробки ваших
            персональних даних, звернувшись до нас за адресою електронної пошти:
            dataprivacy@agcocorp.com
          </Text>
        </Flex>
      </chakra.div>
    </Container>
  );
};

export default TermsAndConditionsContentUK;
