import React, { useState, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import { Flex, Text, useBreakpointValue } from "@chakra-ui/react";
import Clock from "../home/counter/Clock";

type Props = {
    endDateString: string;
    callback: () => void;
};
const PlenaryChatCountdown = (props: Props) => {
    const { endDateString, callback } = props;
    const endDate = new Date(endDateString);

    const { t } = useTranslation();
    const isMobile = useBreakpointValue({ base: true, lg: false });

    const [time, setNewTime] = useState({
        days: 0,
        hours: 0,
        mins: 0,
        secs: 0,
    });

    useEffect(() => {
        const interval = setInterval(() => {
            const date = calculateCountDown(endDate);
            setNewTime(date);
        }, 1000);
        return function cleanup() {
            clearInterval(interval);
        };
    }, [endDate]);
    const calculateCountDown = (endDate: Date) => {
        let startDate = new Date();
        let diff = (endDate.getTime() - startDate.getTime()) / 1000;

        const timeLeft = {
            days: 0,
            hours: 0,
            mins: 0,
            secs: 0,
        };

        if (diff <= 0) {
            callback();
            return timeLeft;
        }
        if (diff >= 86400) {
            // 24 * 60 * 60
            timeLeft.days = Math.floor(diff / 86400);
            diff -= timeLeft.days * 86400;
        }
        if (diff >= 3600) {
            // 60 * 60
            timeLeft.hours = Math.floor(diff / 3600);
            diff -= timeLeft.hours * 3600;
        }
        if (diff >= 60) {
            timeLeft.mins = Math.floor(diff / 60);
            diff -= timeLeft.mins * 60;
        }
        timeLeft.secs = Math.floor(diff);

        return timeLeft;
    };

    return (
        <>
            <Flex
                maxW="container.xl"
                d="flex"
                flexFlow="row nowrap"
                justifyContent="center"
                alignItems="stretch"
                mx={isMobile ? "0" : "0"}
                bgColor="rgba(255, 255, 255, 0.1)"
                boxShadow="0px 3px 6px #00000029"
                flexGrow={1}
            >
                <Flex
                    d="flex"
                    flexFlow="column nowrap"
                    justifyContent="center"
                    alignItems="center"
                    w="100%"
                >
                    <Text
                        d="flex"
                        justifyContent="center"
                        my="2rem"
                        fontWeight="bold"
                        flexFlow="column nowrap"
                        alignItems="center"
                        color="red.500"
                        fontSize="30px"
                    >
                        {t("plenary.button.chat")}:
                    </Text>
                    <Flex
                        d="flex"
                        justifyContent="space-around"
                        alignItems="center"
                        m="1rem 0 3rem 0"
                        w={isMobile ? "100%" : "60%"}
                    >
                        <Clock
                            digit={time.mins}
                            label={t("countdown.minutes")}
                            maxNum={60}
                            id={"hmins"}
                            width="150px"
                        />
                        <Clock
                            digit={time.secs}
                            label={t("countdown.seconds")}
                            maxNum={60}
                            id={"hsecs"}
                            width="150px"
                        />
                    </Flex>
                </Flex>
            </Flex>
        </>
    );
};

export default PlenaryChatCountdown;
