import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { chakra, Container } from "@chakra-ui/react";

import axiosHelper from "../helpers/axiosHelper";

import Header from "../components/Header";

import TutorialContent from "../components/tutorial/TutorialContent";
import MainLayout from "../layouts/MainLayout";

const Tutorial = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const [userProfile, setUserProfile] = useState<UserProfile>(
    axiosHelper.getUserProfile()!
  );
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [userProfile]);


  return (
    <MainLayout home reloadUserProfile={()=>{}}>
      <Container
        maxW="container.xl"
        d="flex"
        flexDir="column"
        p="0"
        minH="100vh"
        flexShrink={0}
      >
        {/* {userProfile.firstLogin ? ( */}
        <chakra.div ml="2rem" mr={{ base: "2rem", lg: "0" }}>
          {userProfile != null && userProfile.firstLogin ? (
            <Header
              title={t(`header.welcome`)}
              logo
              date="03/09/2021 - 00:00"
              subtitle1={t(`tutorial.welcome.subtitle`)}
              subtitle2={t(`tutorial.welcome.text`)}
            />
          ) : (
            <Header title={t(`tutorial.title`)} mainLayoutLogin={true} />
          )}
        </chakra.div>
        {/* ) : (
          <chakra.div ml="2rem" mr={{ base: "2rem", lg: "0" }}>
            <Header
              title={t(`tutorial.title`)}
              logo
              date="03/09/2021 - 00:00"
            />
          </chakra.div>
        )} */}
        <TutorialContent />
      </Container>
    </MainLayout>
  );
};

export default Tutorial;
