import React, { FC } from "react";

import LayoutHeaderLogin from "./LayoutHeaderLogin";
import Footer from "./Footer";
import { chakra } from "@chakra-ui/react";

type Props = { home?: boolean };

const MainLayoutLogin: FC<Props> = ({ home, children }) => {
  return (
    <chakra.div d="flex" flexDir="column" bgColor="#000000" minH="100vh">
      <LayoutHeaderLogin home={home} />
      <chakra.div flex="1 0 auto">{children}</chakra.div>
      <Footer />
    </chakra.div>
  );
};
const Props = {
  home: false,
};
export default MainLayoutLogin;
