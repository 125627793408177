import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import {
  chakra,
  useBreakpointValue,
  useMultiStyleConfig,
  Container,
  Text,
  Image,
  Button,
  HStack,
  Wrap,
  Flex,
} from "@chakra-ui/react";

import background from "./../../assets/home/Visuel-Sans-Textes-Degrade.png";
import borntoFarm from "./../../assets/home/BornToFarm/BornToFarm@2x.png";
import { localizedDateFromString } from "../../helpers/utils";
import Constants from "../../helpers/constants";
export type LayoutHeaderProps = {};

const Hero: FC<LayoutHeaderProps> = ({}: LayoutHeaderProps, ...props) => {
  const styles = useMultiStyleConfig("Hall", props);
  const { t } = useTranslation();

  const isMobile = useBreakpointValue({ base: true, lg: false });

  const sharePressed = () => {
    var addthisScript = document.createElement("script");
    addthisScript.setAttribute(
      "src",
      "https://s7.addthis.com/js/300/addthis_widget.js#pubid=ra-5eff4643c2d344d1"
    );
    if (document.body) document.body.appendChild(addthisScript);
  };

  return (
    <chakra.div w="100%" marginTop="-69px" bgColor="#000000">
      <Container
        maxW="container.xl"
        bgImage={background}
        sx={styles.heroContainer}
      >
        <Flex flexDir="column">
          <Image src={borntoFarm} sx={styles.heroTitleImg} />
          <Text
            as="h3"
            textStyle="h3"
            mt="5px"
            mb={{ base: "20px", md: "16px" }}
          >
            {localizedDateFromString(Constants.EVENT_DATE, t("LNG"))}
          </Text>
          {!isMobile && (
            <>
              <HStack
                spacing={{ base: "10px", lg: "15px" }}
                style={{
                  font: "normal normal bold 16px/24px Arial",
                  letterSpacing: "0px",
                  opacity: 1,
                }}
                justify={{ base: "left" }}
                fontSize="16px"
              >
                <Wrap>
                  <Text color="red.500" pr="30px" m="0!important">
                    {t("hero.red_subtitle")}
                  </Text>
                  <Text color="white" pr="30px" m="0!important">
                    {t("hero.white_left_subtitle")}
                  </Text>
                  <Text color="white" m="0!important">
                    {t("hero.white_right_subtitle")}
                  </Text>
                </Wrap>
              </HStack>
              <Text mt="10px" sx={styles.heroLabel}>
                {t("hero.subtitle")}
              </Text>
              <Text sx={styles.heroDesc}>{t("hero.description")}</Text>
              <Button sx={styles.heroBtn} onClick={sharePressed}>
                {t("hero.sharebtn")}
              </Button>
            </>
          )}
        </Flex>
      </Container>
      {isMobile && (
        <Container maxW="container.xl">
          <HStack
            spacing={{ base: "10px", lg: "15px" }}
            style={{
              font: "normal normal bold 16px/24px Arial",
              letterSpacing: "0px",
              opacity: 1,
            }}
            justify={{ base: "left" }}
            fontSize="16px"
            mt="10px"
          >
            <Wrap>
              <Text color="white" pr="30px" m="0!important">
                {t("hero.red_subtitle")}
              </Text>
              <Text color="white" pr="30px" m="0!important">
                {t("hero.white_left_subtitle")}
              </Text>
              <Text color="white" m="0!important">
                {t("hero.white_right_subtitle")}
              </Text>
            </Wrap>
          </HStack>
          <Text mt="10px" sx={styles.heroLabel}>
            {t("hero.subtitle")}
          </Text>{" "}
          <Text sx={styles.heroDesc}>{t("hero.description")}</Text>
          <Button sx={styles.heroBtn} mb="94px" onClick={sharePressed}>
            {t("hero.sharebtn")}
          </Button>
        </Container>
      )}
    </chakra.div>
  );
};

export default Hero;
