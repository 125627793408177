import React, { FC, useState, useEffect } from "react";
import axiosHelper from "../helpers/axiosHelper";
import { useHistory } from "react-router-dom";
import { localizedDateFromString } from "../helpers/utils";
import Constants from "../helpers/constants";
import { Flex, Image, Text, useBreakpointValue } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import logo from "../assets/logo/MFlogo RGB187_K_S@2x.png";
import titleImg from "../assets/home/BornToFarm/BornToFarm@2x.png";
import background from "../assets/header/Raggruppa 460/Raggruppa 460@2x.png";
import backgroundLogin from "../assets/header/headerBg.jpg";
import backgroundMobile from "../assets/home/Visuel-Sans-Textes-Degrade@2x.png";

type Props = {
  logo?: boolean;
  date?: string;
  header?: string;
  title?: string;
  subtitle1?: string;
  subtitle2?: string;
  mainLayoutLogin?: boolean;
};

const Header: FC<Props> = ({
  title,
  date,
  header,
  logo,
  subtitle1,
  subtitle2,
  mainLayoutLogin,
}) => {
  const history = useHistory();
  const { t } = useTranslation();
  const isMobile = useBreakpointValue({ base: true, lg: false });

  const [userProfile, setUserProfile] = useState<UserProfile>(
    axiosHelper.getUserProfile()!
  );
  const [eventDate, setEventDate] = useState<string>(Constants.EVENT_DATE);

  useEffect(() => {
    var interval: any;
    if (userProfile != null) {
      setEventDate(axiosHelper.getDefaultEvent().showDate);
      // interval = setInterval(() => {
      //   const now = new Date();
      //   var date = new Date(Date.parse(axiosHelper.getDefaultEvent().fromDate));
      //   //var date = new Date(Date.parse('2021-06-03T11:20:00.000Z'));

      //   if (now > date) {
      //     console.log('OK lets go');
      //     history.push("/plenary");
      //   } else {
      //     console.log('not yet');
      //   }
      // }, 30000);
    } else {
      setEventDate(Constants.EVENT_DATE);
    }
    return () => {
      clearInterval(interval);
    };
  }, []);

  return (
    <Flex
      d="flex"
      flexDir="column"
      bgColor="black"
      h={{ base: mainLayoutLogin ? (isMobile ? "460px":"307px") : "357px" }}
      pb={{ base: "30px", lg: "0px" }}
      mt={mainLayoutLogin ? "-119px" : "0"}
    >
      <Image
        objectFit="cover"
        objectPosition={{ base: "center", lg: "right" }}
        bgRepeat="no-repeat"
        overflow="hidden"
        alignItems="center"
        justifyContent="center"
        src={
          !isMobile
            ? mainLayoutLogin
              ? backgroundLogin
              : background
            : backgroundMobile
        }
        h={{ base: "427px", lg: mainLayoutLogin ? "307px" : "427px" }}
        // w={!isMobile ? "1280px" : "100%"}
        w={"1280px"}
        ml={{ lg: "-2rem" }}
        left={!isMobile ? "none" : "0px"}
        pos="absolute"
        zIndex="5"
        top={isMobile ? "50px" : mainLayoutLogin ? "0" : "50px"}
      />

      <Flex zIndex="10" flexDir={{ base: "column", lg: "row" }}>
        <Flex
          flexDir="column"
          ml={{ lg: mainLayoutLogin ? "220px" : "0" }}
          justifyContent={{ base: "center", lg: "flex-end" }}
          position="relative"
          // left={{ lg: "150px" }}
        >
          {logo && (
            <Image
              objectFit="contain"
              objectPosition="center"
              bgRepeat="no-repeat"
              overflow="hidden"
              alignItems="center"
              justifyContent="center"
              src={titleImg}
              h={{ base: "auto", lg: "62px" }}
              w={{ base: "100%", lg: "604px" }}
              // mt={{ base: "60px", lg: "100px" }}
              mt={{ base: mainLayoutLogin ? "12vh" : "60px", lg: "0px" }}
            />
          )}

          {date && (
            <Text as="h3" textStyle="h3" mt="5px" pl={isMobile?'0':"12px"}>
              {localizedDateFromString(eventDate, t("LNG"))}
            </Text>
          )}
        </Flex>
      </Flex>
      <Flex
        flexDir="column"
        color="white"
        mt="30px"
        zIndex="10"
        justifyContent="center"
        h="100%"
      >
        {title && (
          <Text
            as={mainLayoutLogin ? "h1" : "h3"}
            textStyle={mainLayoutLogin ? "h1" : "h3"}
            color={mainLayoutLogin ? "white" : "red.500"}
            fontFamily={mainLayoutLogin ? "Arial Black, Arial-BoldMT, Arial" : "inherit"}
            paddingTop={mainLayoutLogin ? "80px" : "inherit"}
          >
            {title}
          </Text>
        )}
        {subtitle1 && (
          <Text
            w={{ lg: "40%" }}
            mt="5px"
            fontFamily="Arial"
            fontWeight="normal"
            fontSize={{ base: "16px" }}
          >
            {subtitle1}
          </Text>
        )}

        {subtitle2 && (
          <Text
            w={{ lg: "40%" }}
            mt="5px"
            fontFamily="Arial"
            fontWeight="normal"
            fontSize={{ base: "16px" }}
          >
            {subtitle2}
          </Text>
        )}
      </Flex>
    </Flex>
  );
};

export default Header;
