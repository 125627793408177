import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { chakra, Container, Text, Flex } from "@chakra-ui/react";

import axiosHelper from "../helpers/axiosHelper";

import Header from "../components/Header";

import MainLayout from "../layouts/MainLayout";
import bgImage from "./../assets/prizes/prizes-page-bg.jpg";
import PrizesContent from "./../components/prizes/PrizesContent";
import PrizesCard from "./../components/home/prizesSection/PrizesCard";

const PrizesPage = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const [userProfile, setUserProfile] = useState<UserProfile>(
    axiosHelper.getUserProfile()!
  );

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <MainLayout home reloadUserProfile={()=>{}}>
      <Container
        maxW="container.xl"
        d="flex"
        flexDir="column"
        // border="2px solid red"
      >
        <Header title={t("prizepage.title")} mainLayoutLogin={true} />
      </Container>
      <PrizesContent />
    </MainLayout>
  );
};

export default PrizesPage;
