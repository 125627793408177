import React, { useState, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import { Flex, Text, useBreakpointValue } from "@chakra-ui/react";
import Clock from "../home/counter/Clock";


type Props = {
  callback: () => void;
  countdown?: number;
  maxNum?: number;
};
const PlenaryCountdown = (props: Props) => {
  let { callback, countdown = 8, maxNum = 8 } = props
  const { t } = useTranslation();
  const isMobile = useBreakpointValue({ base: true, lg: false });

  const [cd, setCd] = useState(8); //TODO 8 secondi che non so se vanno messi qua?
  const [max, setMaxNum] = useState(8); //TODO 8 secondi che non so se vanno messi qua?
  const interval = useRef<any>(null);
  const timerHalted = useRef(false);
  const counter = useRef(80);


  useEffect(() => {
    setCd(countdown);
    counter.current = countdown * 10;
    startTimer();
    return () => {
      if (!timerHalted.current) {
        timerHalted.current = true;
      }
    }
  }, []);

  const startTimer = () => {
    if (interval.current != null) {
      clearInterval(interval.current);
    }
    interval.current = setInterval(() => {
      if (!timerHalted.current) {
        const oldCount = counter.current;
        counter.current = oldCount - 1;
        if (counter.current <= 0) {
          callback();
          stopTimer();
        }
        setCd(counter.current / 10);
      }
    }, 100);
  }
  const stopTimer = () => {
    if (interval.current != null) {

      clearInterval(interval.current);
    }
  }

  return (
    <>
      <Flex
        maxW="container.xl"
        d="flex"
        flexFlow="row nowrap"
        justifyContent="center"
        alignItems="stretch"
        m={isMobile ? "0" : "1rem 2rem 2rem 2rem"}
        bgColor="rgba(255, 255, 255, 0.1)"
        boxShadow="0px 3px 6px #00000029"
      >
        <Flex
          d="flex"
          flexFlow={isMobile ? "column nowrap" : "row nowrap"}
          justifyContent="center"
          alignItems="stretch"
          w="100%"
        >
          <Flex
            d="flex"
            justifyContent="center"
            alignItems="baseline"
            w={isMobile ? "100%" : "70%"}
          >
            <Text
              alignSelf="center"
              fontSize={isMobile ? "1.2rem" : "2rem"}
              color="white"
              fontFamily="Arial Black, Arial-BoldMT, Arial"
              p={isMobile ? "1.2rem" : "auto"}
              textAlign="center"
            >
              {t("plenary.countdown.text")}
            </Text>
          </Flex>
          <Flex
            d="flex"
            justifyContent="center"
            alignItems="stretch"
            w={isMobile ? "100%" : "30%"}
            p={isMobile ? "1rem" : "4rem 3rem 3rem 3rem"}
          >
            <Clock
              digit={cd}
              label=""
              maxNum={max}
              id={"cds"}
              width={isMobile ? "200px" : "150px"}
            />
          </Flex>
        </Flex>
      </Flex>
    </>
  );
};

export default PlenaryCountdown;
