import React, { FC, useRef, useState } from "react";
import {
  Container,
  useMultiStyleConfig,
  Box,
  Text,
  VStack,
  Flex,
  Collapse,
  useOutsideClick,
  Button,
  Slide,
  CloseButton,
  IconButton,
  useBreakpointValue,
} from "@chakra-ui/react";

import imageforR from "./../../assets/home/registerCard/registerR.jpg";
import imageforS from "./../../assets/home/registerCard/registerS.jpg";
import imageforI from "./../../assets/home/registerCard/registerI.jpg";

import mobileimageforR from "./../../assets/home/registerCard/registerR-m.jpg";
import mobileimageforS from "./../../assets/home/registerCard/registerS-m.jpg";
import mobileimageforI from "./../../assets/home/registerCard/registerI-m.jpg";

import RegisterCard from "./RegisterCard";
import { CloseIcon } from "@chakra-ui/icons";
import { useTranslation } from "react-i18next";
import axiosHelper from "../../helpers/axiosHelper";

type Props = {};

const Register: FC<Props> = ({}: Props, ...props) => {
  const utmData: UtmData | null = axiosHelper.getUtmData();

  const isMobile = useBreakpointValue({ base: true, lg: false });

  const styles = useMultiStyleConfig("Hall", props);
  const { t } = useTranslation();
  const ref = useRef<any>();

  const refBrave = useRef<any>();
  const refBig = useRef<any>();
  const refSpecialist = useRef<any>();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isModalOpenMobile, setIsModalOpenMobile] = useState(false);
  useOutsideClick({
    ref: ref,
    handler: () => {
      setIsModalOpen(false);
      setIsModalOpenMobile(false);
    },
  });
  const [link, setLink] = useState("");

  const handleCard = (item: string) => {
    if (item === "brave") {
      setLink(
        `${t("register.iframe.url.R")}&utm_source=${
          utmData?.utm_source
        }&utm_medium=${utmData?.utm_medium}&utm_campaign=${
          utmData?.utm_campaign
        }&utm_term=${utmData?.utm_term}&utm_content=${utmData?.utm_content}`
      );
      setIsModalOpen(true);
      setIsModalOpenMobile(true);
    } else if (item === "big") {
      setLink(
        `${t("register.iframe.url.I")}&utm_source=${
          utmData?.utm_source
        }&utm_medium=${utmData?.utm_medium}&utm_campaign=${
          utmData?.utm_campaign
        }&utm_term=${utmData?.utm_term}&utm_content=${utmData?.utm_content}`
      );
      setIsModalOpen(true);
      setIsModalOpenMobile(true);
    } else if (item === "specialist") {
      setLink(
        `${t("register.iframe.url.S")}&utm_source=${
          utmData?.utm_source
        }&utm_medium=${utmData?.utm_medium}&utm_campaign=${
          utmData?.utm_campaign
        }&utm_term=${utmData?.utm_term}&utm_content=${utmData?.utm_content}`
      );
      setIsModalOpen(true);
      setIsModalOpenMobile(true);
    }
  };

  const listOfCard = (
    <VStack spacing="10px" mt="20px">
      <Box onClick={() => handleCard("brave")} ref={refBrave} w="100%">
        <RegisterCard
          image={isMobile ? mobileimageforR : imageforR}
          title={t("registercard.title.R")}
          label={t("registercard.description.R")}
        />
      </Box>
      <Box onClick={() => handleCard("big")} ref={refBig} w="100%">
        <RegisterCard
          image={isMobile ? mobileimageforI : imageforI}
          title={t("registercard.title.I")}
          label={t("registercard.description.I")}
        />
      </Box>
      <Box
        onClick={() => handleCard("specialist")}
        ref={refSpecialist}
        w="100%"
      >
        <RegisterCard
          image={isMobile ? mobileimageforS : imageforS}
          title={t("registercard.title.S")}
          label={t("registercard.description.S")}
        />
      </Box>
    </VStack>
  );

  const iframeBox = (
    <Box px="0" mt="20px">
      <iframe
        id="iframe"
        src={link}
        style={{
          width: "100%",
          height: "60vh",
          paddingRight: "0px",
          paddingLeft: "0px",
        }}
      />
    </Box>
  );

  return (
    <>
      <Flex w="100%" justifyContent="center">
        <Container maxW="container.xl" sx={styles.registerContainer}>
          <Box sx={styles.registerWrapper} w={isModalOpen ? "600px" : "270px"}>
            <Flex justifyContent="space-between" alignItems="center" mb="20px">
              <Text sx={styles.registerTitle}>{t("register.title")}</Text>
              {isModalOpen && (
                <IconButton
                  variant="outline"
                  colorScheme="blackAlpha"
                  aria-label="Call Sage"
                  fontSize="15px"
                  icon={<CloseIcon />}
                  onClick={() => {
                    setIsModalOpen(false);
                  }}
                />
              )}
            </Flex>
            {!isModalOpen && (
              <Text sx={styles.registerSubtitle}>{t("register.subtitle")}</Text>
            )}
            {!isModalOpen && listOfCard}
            <Collapse in={isModalOpen} animateOpacity>
              {iframeBox}
            </Collapse>
          </Box>
        </Container>
      </Flex>

      <Flex sx={styles.counterRegisterWrapper} ref={ref}>
        <Button
          sx={styles.counterRegisterBtn}
          onClick={() => setIsModalOpen(true)}
        >
          {t("register.title")}
        </Button>
        <Slide direction="bottom" in={isModalOpen} style={{ zIndex: 10 }}>
          <Box p="20px" color="white" mt="4" bg="white" shadow="md">
            <Flex justifyContent="space-between" alignItems="center" mb="20px">
              <Text sx={styles.registerTitle}>{t("register.title")}</Text>

              <IconButton
                variant="outline"
                colorScheme="blackAlpha"
                aria-label="Call Sage"
                fontSize="15px"
                icon={<CloseIcon />}
                onClick={() => {
                  setIsModalOpen(false);
                  setIsModalOpenMobile(false);
                }}
              />
            </Flex>
            <Text sx={styles.registerSubtitle}></Text>

            {!isModalOpenMobile && listOfCard}

            <Collapse in={isModalOpenMobile} animateOpacity>
              {iframeBox}
            </Collapse>
          </Box>
        </Slide>
      </Flex>
    </>
  );
};

export default Register;
