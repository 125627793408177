const Hall = {
  parts: [
    "registerContainer",
    "registerWrapper",
    "registerTitle",
    "registerSubtitle",
    "registerCardWrapper",
    "registerCardContainer",
    "registerCardTitle",
    "registerCardLabel",

    "heroContainer",
    "heroTitleImg",
    "heroLabel",
    "heroBtn",

    "counterContainer",
    "counterRegisterWrapper",
    "counterRegisterBtn",

    "clockTime",
    "clockLabel",

    "sliderSectionContainer",
    "sliderSectionWrapper",
    "sliderCardContainer",
    "sliderCardTitle",
    "sliderCardLabel",

    "prizesSectionContainer",
    "prizesSectionLabel",
    "prizesSectionWrapper",
    "prizesCardImg",
    "prizesCardTitle",
    "prizesCardSubtitle",
    "prizesCardLabel",
  ],

  baseStyle: ({ colorMode }: any) => ({
    registerContainer: {
      d: { base: "none", lg: "flex" },
      justifyContent: "flex-end",
      top: "200px",
      position: "fixed",
      zIndex: "15",
    },

    registerWrapper: {
      // width: "270px",
      boxShadow: "0px 3px 10px #0000007F",
      background: "#FFFFFF 0% 0% no-repeat padding-box",
      padding: "10px",
      position: "absolute",
    },
    registerTitle: {
      color: "#C41230",
      font: "normal normal 900 25px/18px Arial",
      letterSpacing: "0px",
      textTransform: "uppercase",
      opacity: 1,
      mb: "10px",
    },
    registerSubtitle: {
      font: "normal normal normal 16px/24px ",
      letterSpacing: "0px",
      color: "#000000",
      opacity: 1,
    },
    registerCardWrapper: {
      h: "90px",
      w: "100% ",
      justifyContent: "flex-start",
      alignItems: "center",
      cursor: "pointer",
      _hover: { opacity: 1 },
    },
    registerCardContainer: {
      position: "fixed",
      flexDir: "column",
      w: { base: "95%", lg: "250px" },
      px: "10px",
      h: "90px",
      justifyContent: "center",
      _hover: { bgColor: "rgba(0,0,0,0.7)" },
      left: { base: "20px", lg: "auto" },
    },
    registerCardTitle: {
      font: "normal normal 900 18px/18px Arial",
      letterSpacing: "0px",
      color: "#FFFFFF",
      textTransform: "uppercase",
      opacity: 1,
    },
    registerCardLabel: {
      font: "normal normal normal 12px/14px Arial",
      letterSpacing: "0px",
      color: "#FFFFFF",
      textTransform: "uppercase",
      opacity: 1,
    },
    heroContainer: {
      overflow: "hidden",
      backgroundPosition: "center",
      backgroundSize: "cover",
      backgroundRepeat: "no-repeat",
      flexDir: "column",
      h: { base: "416px", lg: "640px" },
      d: "flex",
      flexDirection: "column",
      justifyContent: "flex-end",
      pb: { lg: "5%" },
    },
    heroTitleImg: {
      h: { base: "auto", lg: "62px" },
      w: { base: "fit-content", lg: "604px" },
      marginLeft:"-5px",
      objectFit: "cover",
      objectPosition: "center",
      bgRepeat: "no-repeat",
      overflow: "hidden",
      alignItems: "center",
      justifyContent: "center",
    },
    heroLabel: {
      font: "normal normal bold 18px/24px Arial",
      letterSpacing: "0px",
      color: "#FFFFFF",
      opacity: 1,
      // mb: "25px",
    },
    heroDesc: {
      font: "normal normal normal 16px/24px Arial",
      letterSpacing: "0px",
      color: "#FFFFFF",
      opacity: 1,
      maxWidth: { lg: "30vw" },
      my: "29px",
    },
    heroBtn: {
      bgColor: "red.500",
      width: { base: "100%", lg: "270px" },
      height: "32px",
      borderRadius: "0px",
      font: "normal normal bold 18px/24px Arial",
      letterSpacing: "0px",
      color: "#FFFFFF",
      textTransform: "uppercase",
      opacity: 1,
      _hover: { opacity: 0.5 },
    },
    counterContainer: {
      overflow: "hidden",
      backgroundPosition: "center",
      backgroundSize: "cover",
      backgroundRepeat: "no-repeat",
      flexDir: "column",
      h: { lg: "400px" },
      d: "flex",
      flexDirection: "column",
      pt: { lg: "56px" },
    },
    counterRegisterWrapper: {
      display: { base: "flex", lg: "none" },
      position: "fixed",
      h: "82px",
      w: "100%",
      bgColor: "white",
      zIndex: "20",
      bottom: "0px",
      justifyContent: "center",
      alignItems: "center",
    },
    counterRegisterBtn: {
      h: "32px",
      bgColor: "#C41230",
      w: "100%",
      mx: "2rem",
      borderRadius: "0px",
      color: "white",
      textTransform: "uppercase",
      _hover: { opacity: 0.3 },
    },
    clockTime: {
      letterSpacing: "0px",
      color: "#FFFFFF",
      opacity: 1,
      fontFamily: "Arial",
      fontSize: { base: "24px", lg: "70px" },
      // fontSize: { base: "24px", lg: "60px" },
      fontWeight: "normal",
      position: "absolute",
      w: { base: "70px", lg: "170px" },
      h: { base: "70px", lg: "170px" },
      d: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
  timerTime: {
      letterSpacing: "0px",
      color: "#FFFFFF",
      opacity: 1,
      fontFamily: "Arial",
      fontSize: { base: "24px", lg: "50px" },
      // fontSize: { base: "24px", lg: "60px" },
      fontWeight: "normal",
      position: "absolute",
      w: { base: "100px", lg: "100px" },
      h: { base: "100px", lg: "100px" },
      d: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    clockLabel: {
      letterSpacing: "0px",
      textTransform: "uppercase",
      color: "#FFFFFF",
      opacity: 1,
      fontFamily: "Arial",
      fontSize: { base: "12px", lg: "25px" },
      fontWeight: "normal",
      textAlign: "center",
      mt: "20px",
    },
    sliderSectionContainer: {
      overflow: "hidden",
      backgroundPosition: "center",
      backgroundSize: "cover",
      backgroundRepeat: "no-repeat",
      flexDir: "column",
      d: "flex",
      flexDirection: "column",
      py: "60px",
    },
    sliderSectionWrapper: {
      w: { base: "100vw", lg: "75%" },
      flexDir: "column",
      ml: { base: "-2rem", lg: "0px" },
    },
    sliderCardContainer: {
      // w: { base: "95%", lg: "280px" },
      w: { base: "95%", lg: "auto" },
      minH: "320px",
      // h: "370px",
      bgColor: "white",
      opacity: "0.8",
      boxShadow: " 0px 5px 10px #0000007F",
      p: "10px",
      mx: "10px",
    },
    sliderCardTitle: {
      textAlign: "center",
      // h: "50px",
      d: "flex",
      justifyContent: "center",
      alignItems: "flex-end",
      lineHeight: { base: "25px", md: "28px" },
    },
    sliderCardLabel: {
      fontFamily: "Arial",
      fontSize: { base: "16px", lg: "16px" },
      fontWeight: "normal",
      letterSpacing: "0px",
      color: "#000000",
      opacity: "1",
      mt: "15px",
      textAlign: "center",
    },
    prizesSectionContainer: {
      overflow: "hidden",
      backgroundPosition: "center",
      backgroundSize: "cover",
      backgroundRepeat: "no-repeat",
      flexDir: "column",
      d: "flex",
      flexDirection: "column",
      py: "60px",
    },
    prizesSectionLabel: {
      fontSize: { base: "18px", lg: "26px" },
      fontWeight: "normal",
      fontFamily: "Arial Black, Arial-BoldMT, Arial",
      color: "red.500",
      textTransform: "uppercase",
      opacity: "1",
    },
    prizesSectionWrapper: {
      w: { base: "100vw", lg: "75%" },
      flexDir: "column",
      ml: { base: "-2rem", lg: "0px" },
      mt: "15px",
    },
    prizesCardImg: {
      size: "cover",
      bgRepeat: "no-repeat",
      h: { base: "auto", lg: "150px" },
      w: { base: "100%", lg: "270px" },
      _hover: { cursor: "pointer" },
    },
    prizesCardTitle: {
      fontFamily: "Arial",
      fontSize: { base: "16px", lg: "16px" },
      fontWeight: "bold",
      letterSpacing: "0px",
      color: "red.500",
      textTransform: "uppercase",
      opacity: "1",
      d: "flex",
      mt: "15px",
      _hover: { cursor: "pointer" },
    },
    prizesCardSubtitle: {
      fontFamily: "Arial",
      fontSize: { base: "12px", lg: "12px" },
      fontStyle: "italic",
      fontWeight: "normal",
      letterSpacing: "0px",
      color: "white",
      _hover: { cursor: "pointer" },
    },
    prizesCardLabel: {
      fontFamily: "Arial",
      fontSize: { base: "14px", lg: "14px" },
      fontWeight: "normal",
      letterSpacing: "0px",
      color: "white",
      opacity: "1",
      mt: "15px",
      _hover: { cursor: "pointer" },
    },
  }),
};

export default Hall;
