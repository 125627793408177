import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";
import { useHistory } from "react-router-dom";
import axiosHelper from "../../helpers/axiosHelper";
import ModalBox from "../ModalBox";
import Constants from "../../helpers/constants";
import Header from "../Header";
import { useTranslation } from "react-i18next";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";

// Media

import {
  chakra,
  Checkbox,
  Flex,
  Input,
  InputGroup,
  InputRightElement,
  Text,
  useMultiStyleConfig,
  useDisclosure,
} from "@chakra-ui/react";
import LostPasswordModal from "./LostPasswordModal";

const eye = <FontAwesomeIcon icon={faEye} />;
const eyeSlash = <FontAwesomeIcon icon={faEyeSlash} />;

type LoginData = {
  username: string;
  password: string;
  rememberme: boolean;
};

const LoginContent = ({ ...props }) => {
  const history = useHistory();
  const [error, setError] = useState<boolean>(false);
  const [loginErrorMessage, setLoginErrorMessage] = useState<string>();
  const [modalVisible, setModalVisible] = useState<boolean>(false);
  const [passwordShown, setPasswordShown] = useState(false);
  const { t } = useTranslation();

  const { isOpen, onOpen, onClose } = useDisclosure();

  const styles = useMultiStyleConfig("Login", props);

  const { register, handleSubmit, errors } = useForm<LoginData>({
    criteriaMode: "all",
  });

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const togglePasswordVisiblity = () => {
    setPasswordShown(passwordShown ? false : true);
  };

  const onSubmit = async (data: LoginData) => {
    try {
      const result = await axiosHelper.login<UserProfile>(
        data.username,
        data.password
      );
      axiosHelper.setJntToken(result.data.jntToken, data.rememberme);
      axiosHelper.saveUserProfile(result.data);
      if (result.data.firstLogin) {
        if (
          result.data.userType === "R" ||
          result.data.userType === "S" ||
          result.data.userType === "I"
        ) {
          history.push("/tutorial");
        } else {
          history.push("/cluster");
        }
      } else {
        history.push("/");
      }
    } catch (error) {
      if (error.response == null) {
        setLoginErrorMessage(t("err.backendcomm"));
        setError(true);
      } else {
        switch (error.response.status) {
          case 417:
            setLoginErrorMessage(t("err.logindisabled"));
            setError(true);
            break;
          case 401:
            setLoginErrorMessage(t("err.login"));
            setError(true);

            break;
          default:
            setLoginErrorMessage(t("err.login"));
            setError(true);
            break;
        }
      }
    }
  };

  // if (storageHelper.userIsLogged()) {
  //     history.push("/");
  // }
  return (
    <>
      <Flex>
        <Flex flexDir="column" w="100%">
          <form onSubmit={handleSubmit(onSubmit)}>
            <Flex flexDir="column">
              <Flex mb="30px">
                <Input
                  autoComplete="username"
                  type="email"
                  placeholder="E-mail"
                  name="username"
                  ref={register({
                    required: t("err.required") as string,
                    max: 100,
                    min: 4,
                    maxLength: 100,
                  })}
                  h="50px"
                  borderRadius="0px"
                  bgColor="white"
                  variant="outline"
                />

                <ErrorMessage as="div" errors={errors} name="username" />
              </Flex>
              <Flex w="100%">
                <InputGroup>
                  <Input
                    ref={register({
                      required: t("err.required") as string,
                    })}
                    type={passwordShown ? "text" : "password"}
                    autoComplete="current-password"
                    placeholder={t("login.passwordph")}
                    name="password"
                    h="50px"
                    borderRadius="0px"
                    bgColor="white"
                    variant="outline"
                  />
                  <InputRightElement h="100%" mr="10px">
                    <i onClick={togglePasswordVisiblity}>
                      {!passwordShown ? eyeSlash : eye}
                    </i>
                  </InputRightElement>
                </InputGroup>

                <ErrorMessage as="div" errors={errors} name="password" />
              </Flex>
            </Flex>

            <Flex justifyContent="space-between" alignItems="center" mt="30px">
              <Checkbox
                colorScheme="red"
                type="checkbox"
                defaultIsChecked
                placeholder="rememberme"
                name="rememberme"
                ref={register}
                fontFamily="Arial"
                fontWeight="normal"
                fontSize={{ base: "16px", lg: "24px" }}
                color="#707070"
                isInvalid
              >
                {t("login.remember")}
              </Checkbox>

              <Text
                color="red.500"
                textDecor="underline"
                onClick={onOpen}
                cursor="pointer"
              >
                {t("login.lostpass")}
              </Text>

              <button
                className="button--submit"
                style={{
                  backgroundColor: "#C41230",
                  paddingTop: "10px",
                  paddingBottom: "10px",
                  paddingLeft: "30px",
                  paddingRight: "30px",
                  border: "none",
                  color: "white",
                  width: "35%",
                }}
              >
                {t("login.okbtn")}
              </button>
            </Flex>

            {error ? (
              <div className="modal-text error-message">
                {loginErrorMessage}
              </div>
            ) : null}
          </form>
        </Flex>
      </Flex>
      <LostPasswordModal clickOpen={isOpen} clickClose={onClose} />
    </>
  );
};

export default LoginContent;
