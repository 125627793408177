import React, { useState, useRef } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";

import {
  chakra,
  useBreakpointValue,
  useMultiStyleConfig,
  Container,
  Text,
  Link,
  Flex,
  Divider,
  ListItem,
  UnorderedList,
  SimpleGrid,
  Box,
} from "@chakra-ui/react";

import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import axiosHelper from "./../../helpers/axiosHelper";
import bgImage from "./../../assets/prizes/prizes-page-bg@2x.jpg";

import { ChevronLeftIcon, ChevronRightIcon } from "@chakra-ui/icons";

const TermsAndConditionsContentES = ({ ...props }) => {
  const { t } = useTranslation();
  const history = useHistory();
  const [userProfile, setUserProfile] = useState<UserProfile>(
    axiosHelper.getUserProfile()!
  );
  const styles = useMultiStyleConfig("PrizePage", props);

  const isMobile = useBreakpointValue({ base: true, lg: false });
  const customSlider = useRef<any>();

  return (
    <Container
      maxW="container.xl"
      bgImage={bgImage}
      d="flex"
      flexDir="column"
      flexDirection="column"
      style={{
        overflow: "hidden",
        backgroundPosition: "center",
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
      }}
      alignItems="center"
    >
      <Flex
        flexDir={{ base: "column", lg: "column" }}
        justifyContent="space-between"
        w={isMobile?"100%":"50%"}
        mt="2%"
      >
        <Text color="white" mb="2%" mt={isMobile ? "30px" : "0"} align="center">
          TÉRMINOS Y CONDICIONES
          <br />
          SUPERCONCURSO DE MF
          <br />
          Celebrado entre el 2 de julio y el 3 de septiembre de 2021
          <br />
          Por la marca AGCO MASSEY FERGUSON
          <br />
        </Text>
      </Flex>
      <chakra.div w="100%" pb="100px">
        <Flex
          flexDir={{ base: "column", lg: "column" }}
          justifyContent="space-between"
          w="100%"
          mt="3%"
        >
          <Text color="red.500" mt="1%" fontWeight="bold">
            Artículo 1: Organización general
          </Text>
          <Text color="white" mt="1%">
            El departamento de marketing de MASSEY FERGUSON para Europa y
            Oriente Medio de AGCO S.A.S., con domicilio social en 41 Avenue
            Blaise Pascal en Beauvais (Francia) organiza el NUEVO EVENTO DIGITAL
            DE MASSEY FERGUSON el 3 de septiembre, el cual incluirá un concurso
            de participación gratuita y sin obligaciones dirigido al público en
            general.
          </Text>

          <Text color="red.500" fontWeight="bold" mt="1%">
            Artículo 2: Condiciones para participar
          </Text>

          <Text color="white" mt="1%">
            Solo los participantes de países europeos y de Oriente Medio (salvo
            países sujetos a medidas comerciales restrictivas) pueden tomar
            parte. Solo pueden participar en este concurso agricultores,
            contratistas y otras personas relacionadas con empresas agrícolas.
          </Text>

          <Text color="white" mt="1%">
            Quedan excluidos:
          </Text>

          <chakra.div>
            <UnorderedList ml="40px" color="white">
              <ListItem>Todas las personas menores de 18 años</ListItem>
              <ListItem>Trabajadores del Grupo AGCO y sus familiares</ListItem>
              <ListItem>
                Trabajadores de concesionarios y distribuidores de productos y
                servicios AGCO
              </ListItem>
              <ListItem>Trabajadores de empresas asociadas a AGCO</ListItem>
              <ListItem>
                Agencias y proveedores relacionados con AGCO y los familiares de
                sus trabajadores
              </ListItem>
              <ListItem>
                Periodistas invitados por MASSEY FERGUSON para asistir al evento
              </ListItem>
            </UnorderedList>
          </chakra.div>
          <Text color="white" mt="1%">
            Solo se permite una participación por individuo, domicilio o entidad
            agrícola con un mismo nombre, mismos apellidos, mismo número de
            teléfono y misma dirección de correo electrónico. La participación
            en el juego es estrictamente personal. La empresa organizadora se
            reserva el derecho a llevar a cabo cuantos controles estime
            adecuados.
          </Text>
          <Text color="red.500" fontWeight="bold" mt="1%">
            Artículo 3: Condiciones de participación
          </Text>
          <Text color="white" mt="1%">
            Cuando se registra en el NUEVO EVENTO DIGITAL DE MASSEY FERGUSON a
            través de la plataforma web de la división de AGCO SAS que lo
            organiza, el participante entra de forma automática en el concurso,
            ya que el evento gira en torno a este. El participante da su
            consentimiento al acceso de sus datos personales para que AGCO SAS
            se ponga en contacto con él por correo electrónico, teléfono o redes
            sociales.
          </Text>
          <Text color="red.500" fontWeight="bold" mt="1%">
            Artículo 4: Selección de los ganadores
          </Text>
          <Text color="white" mt="1%">
            Se anunciará el resultado del concurso entre el 6 de septiembre de
            2021 y el 6 de octubre de 2021 en las oficinas de AGCO SAS. -
            Departamento de marketing de Massey Ferguson de Europa y Oriente
            Medio en Beauvais (Francia).
          </Text>
          <Box display="block" mt="1%"></Box>
          <Text color="white">
            El sorteo tendrá lugar entre el 6 de septiembre de 2021 y el 6 de
            octubre de 2021 en las oficinas de AGCO SAS. - Departamento de
            marketing de Massey Ferguson de Europa y Oriente Medio en Beauvais
            (Francia). El departamento de marketing de Massey Ferguson de Europa
            y Oriente Medio en Beauvais realizará el sorteo a puerta cerrada.
          </Text>
          <Text color="red.500" fontWeight="bold" mt="1%">
            Artículo 5: Premios
          </Text>
          <Text color="white" mt="1%">
            Habrá 3 niveles, con 3 premios en cada nivel, por lo que habrá 9
            ganadores en total en este concurso. Al responder a las preguntas
            después de registrarse, el participante tendrá la posibilidad de
            sumar más puntos alcanzar el nivel más alto de premios en este
            concurso.
          </Text>
          <Text color="white" mt="1%">
            Tras el evento, se le asignará a cada participante 1 de los 3
            sorteos de premios según el puesto que ocupe en la clasificación
            final del concurso – Puesto final anunciado en la plataforma web
            justo después del evento (3 de septiembre de 2021, 15:00). Aunque un
            participante no responda a ninguna pregunta, entrará de forma
            automática en el nivel 3 de premios.
          </Text>

          <Text color="white" fontWeight="bold" mt="1%" fontStyle="italic">
            Nivel 1: Valorado en hasta 20.000 euros - Clasificación general:
            1-1.000
          </Text>
          <Text color="white" mt="1%">
            Los participantes que ocupen del puesto 1 al puesto 1.000 en el
            concurso entrarán en la lista de participantes del sorteo de premios
            del nivel 1.
            <br /> Se puede ganar 3 premios en este nivel, valorados en hasta
            20.580 €.*
          </Text>
          <UnorderedList color="white" ml="40px" mt="1%">
            <ListItem>
              El primer premio del nivel 1 es un descuento de hasta 10.000 €
              canjeable en la compra de maquinaria MF nueva.
              <br />
              <br />
              Este descuento solo se puede canjear en la compra de maquinaria
              nueva: maquinaria de forraje, cosechadoras, empacadoras y
              cargadoras telescópicas (según existencias de máquinas en el
              concesionario) de la gama MASSEY FERGUSON, REALIZADA ANTES DEL 31
              DE MARZO DEL 2022.
              <br />
              <br />
              Condiciones del descuento (*aplicadas a los precios, incluidos los
              equipos opcionales):
              <br />
              <SimpleGrid
                w={isMobile ? "100%" : "50%"}
                autoFlow=""
                borderColor="white"
                columns={[2, null, 2]}
                border="1px solid white"
                mt="5px"
              >
                <Box bg="red.500" border="1px solid white">
                  <Text
                    color="white"
                    fontWeight="bold"
                    padding="1.5"
                    fontSize={isMobile ? ".7rem" : "1rem"}
                  >
                    Valor de la máquina nueva*:{" "}
                  </Text>
                </Box>
                <Box bg="red.500" border="1px solid white">
                  <Text
                    color="white"
                    fontWeight="bold"
                    padding="1.5"
                    fontSize={isMobile ? ".7rem" : "1rem"}
                  >
                    Descuento aplicable{" "}
                  </Text>
                </Box>
                <Box border="1px solid white">
                  <Text
                    color="white"
                    padding="1.5"
                    fontSize={isMobile ? ".7rem" : "1rem"}
                  >
                    Inferior a 25.000 €*{" "}
                  </Text>
                </Box>
                <Box border="1px solid white">
                  <Text
                    color="white"
                    padding="1.5"
                    fontSize={isMobile ? ".7rem" : "1rem"}
                  >
                    1500€
                  </Text>
                </Box>
                <Box border="1px solid white">
                  <Text
                    color="white"
                    padding="1.5"
                    fontSize={isMobile ? ".7rem" : "1rem"}
                  >
                    Entre 25.000 € y 50.000 €*{" "}
                  </Text>
                </Box>
                <Box border="1px solid white">
                  <Text
                    color="white"
                    padding="1.5"
                    fontSize={isMobile ? ".7rem" : "1rem"}
                  >
                    2500€
                  </Text>
                </Box>
                <Box border="1px solid white">
                  <Text
                    color="white"
                    padding="1.5"
                    fontSize={isMobile ? ".7rem" : "1rem"}
                  >
                    Entre 50.000€ y 100.000 €*{" "}
                  </Text>
                </Box>
                <Box border="1px solid white">
                  <Text
                    color="white"
                    padding="1.5"
                    fontSize={isMobile ? ".7rem" : "1rem"}
                  >
                    4000€
                  </Text>
                </Box>
                <Box border="1px solid white">
                  <Text
                    color="white"
                    padding="1.5"
                    fontSize={isMobile ? ".7rem" : "1rem"}
                  >
                    Entre 100.000€ y 150.000 €*{" "}
                  </Text>
                </Box>
                <Box border="1px solid white">
                  <Text
                    color="white"
                    padding="1.5"
                    fontSize={isMobile ? ".7rem" : "1rem"}
                  >
                    6000€
                  </Text>
                </Box>
                <Box border="1px solid white">
                  <Text
                    color="white"
                    padding="1.5"
                    fontSize={isMobile ? ".7rem" : "1rem"}
                  >
                    Entre 150.000€ y 200.000 €*{" "}
                  </Text>
                </Box>
                <Box border="1px solid white">
                  <Text
                    color="white"
                    padding="1.5"
                    fontSize={isMobile ? ".7rem" : "1rem"}
                  >
                    8000€
                  </Text>
                </Box>
                <Box border="1px solid white">
                  <Text
                    color="white"
                    padding="1.5"
                    fontSize={isMobile ? ".7rem" : "1rem"}
                  >
                    Desde 200.000 €*{" "}
                  </Text>
                </Box>
                <Box border="1px solid white">
                  <Text
                    color="white"
                    padding="1.5"
                    fontSize={isMobile ? ".7rem" : "1rem"}
                  >
                    10 000€
                  </Text>
                </Box>
              </SimpleGrid>
            </ListItem>
            <ListItem mt="26px">
              TEl segundo premio de este nivel 1 es un MF Guide con corrección
              RTK gratuita compatible con cualquier tractor o cosechadora de MF
              que se haya comprado antes del 31 de marzo del 2022.
              <br />
              <br />
              La función opcional MF Guide debe ser seleccionada por el ganador
              cuando compre la máquina.
              <br />
              El valor máximo de este premio no puede superar los 16.060 €. Se
              excluye la tarifa de la suscripción anual (que deberá abonar el
              ganador).
            </ListItem>
            <ListItem mt="26px">
              El tercer y último premio de este nivel 1 es un MF 1520 A de Fase
              V valorado en 19.890 €*, incluidos unos neumáticos opcionales
              valorados en 690 €* (*precio del 1 de febrero del 2021).
            </ListItem>
          </UnorderedList>

          <Text color="white" mt="1%">
            1 participante solo puede ganar un único premio en todo el juego, lo
            que incluye todos los niveles.
          </Text>
          <Text color="white" fontWeight="bold" mt="1%" fontStyle="italic">
            Nivel 2: Valorado en hasta 5.000 euros - Clasificación general:
            1.001-2.000
          </Text>
          <Text color="white" mt="1%">
            Los participantes que ocupen del puesto 1.001 al puesto 2.000 en el
            concurso entrarán en la lista de participantes del sorteo de premios
            del nivel 2.
            <br />
            Se puede ganar 3 premios en este nivel, valorados en hasta 5.000 €.
          </Text>

          <UnorderedList color="white" ml="40px" mt="1%">
            <ListItem>
              El primer premio de este nivel 2 es un MF Summer Pack valorado en
              1.000 € en total, que incluye:
              <UnorderedList color="white">
                <ListItem ml="20px" type="circle">
                  Un set de una barbacoa de barril de aceite de MF/AGCO Parts y
                  4 x tumbonas de MF/AGCO Parts, todo valorado en 340 €.
                </ListItem>
              </UnorderedList>
              <Text color="white" ml="-40px">
                Y{" "}
              </Text>
              <UnorderedList color="white">
                <ListItem ml="20px" type="circle">
                  Un iPad Air gris con Wi-Fi 64 GO personalizado y valorado en
                  669 €
                </ListItem>
              </UnorderedList>
            </ListItem>
          </UnorderedList>

          <Text color="white" mt="1%">
            Se entregarán estos premios por separado al ganador.
          </Text>

          <UnorderedList color="white" ml="40px">
            <ListItem mt="1%">
              El segundo premio de este nivel 2 es una hidrolimpiadora a presión
              profesional valorada en 2.000 €.
              <br />
              <br />
              El modelo mostrado de esta hidrolimpiadora a presión no forma
              parte de ningún contrato, por lo que podrá ser diferente según
              cuál sea el mercado del ganador. Nuestro equipo de productos
              complementarios de AGCO Parts podrá facilitarle información más
              precisa sobre el modelo después de que se elija al ganador.
            </ListItem>
            <ListItem mt="1%">
              El tercer y último premio de este nivel 2 valorado en 5.000 € es
              un paquete de MF Service, que incluye:
              <UnorderedList color="white" listStyleType="circle" mt="1%">
                <ListItem>
                  Un vale de 5.000 € válido para artículos de AGCO Parts
                  disponibles en la tienda del MF concesionario más cercano del
                  ganador.
                </ListItem>
                <ListItem mt="1%">
                  La oportunidad de recibir en la explotación agrícola del
                  ganador 1 visita de nuestro Centro de Servicio Técnico de
                  AGCO, junto con personal del concesionario local, para recibir
                  asesoramiento sobre el uso de tractores y cargadoras
                  telescópicas de Massey Ferguson.
                </ListItem>
              </UnorderedList>
            </ListItem>
          </UnorderedList>

          <Text color="white" mt="1%">
            1 participante solo puede ganar un único premio en todo el juego, lo
            que incluye todos los niveles.
          </Text>

          <Text color="white" fontWeight="bold" mt="2%" fontStyle="italic">
            Nivel 3: Valorado en hasta 250 euros - Clasificación general:
            2001-hasta el último
          </Text>

          <Text color="white" mt="2%">
            Los participantes que ocupen del puesto 2001 al último puesto de la
            clasificación entrarán en la lista de participantes del sorteo de
            premios del nivel 3.
            <br /> Se puede ganar 3 premios en este nivel, valorados todos en
            250 €.
          </Text>

          <UnorderedList color="white " mt="1%">
            <ListItem>
              El primer premio de este nivel 3 es un Connectivity Pack, que
              incluye 3 regalos exclusivos de MASSEY FERGUSON :
              <UnorderedList color="white">
                <ListItem type="circle">
                  Un reloj inteligente de MASSEY FERGUSON – Precio: 180 €
                </ListItem>
                <ListItem type="circle">
                  Un juego de auriculares de botón de MASSEY FERGUSON – Precio:
                  39,90 €
                </ListItem>
                <ListItem type="circle">
                  Una batería externa de la colección MASSEY FERGUSON S –
                  Precio: 29,90 €
                </ListItem>
                <Box display="block" mt="1%"></Box>
              </UnorderedList>
            </ListItem>

            <ListItem mt="1%">
              El segundo premio de este nivel 3 es un vale electrónico por valor
              de 250 €, válido para varias secciones de la tienda online de
              MASSEY FERGUSON:{" "}
              <Link href="https://shop.masseyferguson.com">
                https://shop.masseyferguson.com
              </Link>.
              El vale electrónico tendrá validez durante 36 meses desde su
              recepción.
            </ListItem>

            <ListItem mt="1%">
              El tercer y último premio de este nivel 3 es un pack de juego, que
              incluye:
              <UnorderedList color="white" listStyleType="circle">
                <ListItem>Un modelo del MF 8S a escala – Precio: 70 €</ListItem>
                <ListItem>
                  El modelo exclusivo del MF 5S a escala – Precio: 65 €
                </ListItem>
                <ListItem>
                  Un ratón inalámbrico para ordenador de MASSEY FERGUSON –
                  Precio: 15 €
                </ListItem>
                <ListItem>
                  2 juegos para ordenador de Farming Simulator 2022 - Precio: 40
                  €
                </ListItem>
                <ListItem>
                  Gorra exclusiva de Farming Simulator 2022 FS – Precio: 20 €
                </ListItem>
              </UnorderedList>
            </ListItem>
          </UnorderedList>
          <Text color="white" mt="1%">
            1 participante solo puede ganar un único premio en todo el juego, lo
            que incluye todos los niveles.
          </Text>
          <Text color="red.500" fontWeight="bold" mt="1%">
            Artículo 6: Anuncio y notificación de ganadores
          </Text>
          <Text color="white" mt="1%">
            El equipo de marketing de Massey Ferguson notificará a los ganadores
            con un correo electrónico en el que se les pedirá que se pongan en
            contacto con AGCO.
            <br />
            Si la persona seleccionada en el sorteo no responde a este correo
            electrónico en el plazo de 10 días, se procederá a un segundo y
            último sorteo, con el que el primer ganador quedará descalificado.
            <br />
            La compañía AGCO SAS se pondrá en contacto con el ganador por correo
            electrónico o por otros medios para solicitar la información
            obligatoria para la entrega del premio.
            <br />
            El participante está de acuerdo con que, en el caso de ganar el
            concurso, se anuncie su nombre públicamente en publicidad,
            promociones y en sitios web y redes sociales relacionados.
            <br />
            El ganador accede a estar disponible de forma gratuita y dentro de
            lo razonable para participar en la promoción con textos, audios,
            fotografías y/o imágenes en radio, Internet, televisión o prensa
            escrita. Además, acepta su publicación y su uso con fines
            publicitarios por parte de AGCO y otras empresas asociadas con AGCO.
            <br />
          </Text>
          <Text color="red.500" fontWeight="bold" mt="1%">
            Artículo 7: Exclusión de participantes
          </Text>

          <Text color="white" mt="1%">
            AGCO se reserva el derecho a excluir participantes del concurso.
            Este derecho se aplica, sobre todo, a la infracción de las
            condiciones para participar, la falsificación de datos personales,
            así como su manipulación o presunta manipulación. AGCO podrá excluir
            participantes de los que haya sospechas razonables que utilicen los
            servicios de AGCO. <br />
            Por tanto, si AGCO sospecha que el participante ha dado motivos para
            que se le excluya y no tiene derecho a participar según lo
            estipulado en el Artículo 2, AGCO podrá excluir al participante de
            la distribución o entrega de premios, así como del derecho a
            reclamar premios que ya hayan sido entregados.
          </Text>
          <Text color="red.500" fontWeight="bold" mt="1%">
            Artículo 8: Sorteo de lotes
          </Text>
          <Text color="white" mt="1%">
            No se permite bajo ninguna circunstancia ninguna permuta financiera,
            devolución o compensación.
            <br />
            La empresa organizadora podrá sustituir los premios por otros de
            valor similar si circunstancias externas impiden la entrega de los
            premios anunciados. El premio es innegociable y no se pondrá canjear
            por dinero en metálico.
            <br />
            Se enviará el premio al ganador en un plazo de 3 meses a partir de
            que el equipo de marketing de Massey Ferguson reciba toda la
            información que necesita para la entrega de dicho premio.
          </Text>
          <Text color="red.500" fontWeight="bold" mt="1%">
            Artículo 9: Condiciones de uso y garantía
          </Text>
          <Text color="white" mt="1%">
            Los organizadores no asumen ninguna responsabilidad por cualquier
            incidente y/o accidente que pueda suceder durante el disfrute del
            premio otorgado o por causa de su uso.
            <br />
            Los organizadores se reservan el derecho a acortar, ampliar o
            cancelar esta actividad si las circunstancias así lo requieren, sin
            incurrir por ello en responsabilidad alguna.
            <br />
            Los organizadores no asumen ninguna responsabilidad por
            interrupciones de servicio en las redes de comunicaciones u otros
            incidentes técnicos fuera de su alcance. Los organizadores no asumen
            ninguna responsabilidad si, por cualquier motivo, el concurso sufre
            modificaciones parciales o totales, o bien se pospone o cancela. No
            se aceptará reclamación alguna. No se incluirá ningún formulario
            ilegible, incompleto o falsificado. Por lo tanto, la empresa
            organizadora no asume ninguna responsabilidad si los formularios no
            quedan registrados o no pueden comprobarse. <br />
          </Text>
          <Text color="red.500" fontWeight="bold" mt="1%">
            Artículo 10: Aceptación y litigios
          </Text>

          <Text color="white" mt="1%">
            La participación en el concurso implica la aceptación de estas
            condiciones.
            <br />
            Cada participante sabe que la intervención del azar no les puede
            ofrecer más certeza que la de la posibilidad de ganar.
            <br />
            Las posibles reclamaciones se remitirán por carta al departamento
            jurídico de AGCO SAS, 41 avenue Blaise Pascal – BP 60307 - BEAUVAIS
            (60026) Francia, en un plazo que finalizará el 3 de septiembre (se
            tomará el matasellos como prueba).
            <br />
            Este reglamento queda supeditado a la legalidad vigente en Francia,
            que es la única aplicable.
            <br />
            El departamento de marketing de MASSEY FERGUSON para Europa y
            Oriente Medio de AGCO S.A.S. proporcionará información sobre
            cualquier dificultad práctica, interpretación o solicitud de
            aplicación relativa a este reglamento.
            <br />
          </Text>
          <Text color="red.500" fontWeight="bold" mt="1%">
            Artículo 11: Divulgación de las condiciones del concurso
          </Text>
          <Text color="white" mt="1%">
            Estas condiciones estarán disponibles en Internet en la plataforma
            oficial del evento de MF.
            <br />
            El participante con una conexión a Internet no podrá reclamar ningún
            reembolso en metálico, ya que la participación no le supone coste
            adicional alguno.
            <br />
            El departamento jurídico de la empresa organizadora dispone de una
            copia completa de las condiciones.
            <br />
          </Text>
          <Text color="red.500" fontWeight="bold" mt="1%">
            Artículo 12: Protección de datos personales
          </Text>
          <Text color="white" mt="1%">
            Para tomar parte en el concurso, los participantes deben aceptar
            obligatoriamente que la empresa organizadora use algunos de sus
            datos personales con el objetivo de gestionar el concurso, pero
            también con fines de marketing.
            <br />
            Para obtener más información, puede consultar nuestra política de
            privacidad en el siguiente enlace:{" "}
            <Link href="http://www.agcocorp.com/privacy.html">
              http://www.agcocorp.com/privacy.html
            </Link>
            <br />
            Los ganadores aceptan ceder a la empresa organizadora el derecho a
            usar su nombre, sus apellidos, su área de residencia y su fotografía
            en todas las comunicaciones promocionales o editoriales, sin esperar
            por ello compensación alguna, financiera o de cualquier otra índole.
            <br />
            De acuerdo con las normativas aplicables, puede cancelar su
            participación en el concurso total o parcialmente en cualquier
            momento y sin justificación alguna ante AGCO. Puede ejercer sus
            derechos de acceso, rectificación, supresión y oposición por correo
            electrónico a dataprivacy@agcocorp.com
          </Text>
        </Flex>
      </chakra.div>
    </Container>
  );
};

export default TermsAndConditionsContentES;
