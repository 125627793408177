import { useTranslation } from "react-i18next";

import {
  Container,
} from "@chakra-ui/react";
import "slick-carousel/slick/slick.css";

import MainLayout from "../layouts/MainLayout";
import TermsAndConditionsContent from "../components/termsandconditions/TermsAndConditionsContent";
import Header from "../components/Header";


const TutorialContent = () => {
  const { t } = useTranslation();


  return (
    <MainLayout home reloadUserProfile={()=>{}}>
      <Container
        maxW="container.xl"
        d="flex"
        flexDir="column"
      >
        <Header title={t("header.game").toUpperCase()} mainLayoutLogin={true} />
      </Container>
      <TermsAndConditionsContent />
    </MainLayout>
  );

  
};

export default TutorialContent;
