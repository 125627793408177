import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import Constants from "../../helpers/constants";
import { localizedDateFromString } from "../../helpers/utils";

import {
  chakra,
  useMultiStyleConfig,
  Container,
  Text,
  Image,
  HStack,
  Wrap,
  Flex,
} from "@chakra-ui/react";

import borntoFarm from "./../../assets/home/BornToFarm/BornToFarm@2x.png";
import VideoSection from "./VideoSection";

export type LayoutHeaderProps = {
  usertype: string;
  videoList: LibraryContent[];
  dateString: string;
};

const HallHero: FC<LayoutHeaderProps> = (
  { usertype, videoList, dateString }: LayoutHeaderProps,

  ...props
) => {
  const styles = useMultiStyleConfig("Hall", props);
  const { t } = useTranslation();

  return (
    <chakra.div w="100%" marginTop={{ base: "0vh", lg: "-69px" }}>
      <Container maxW="container.xl" d="flex" flexDir="column">
        <Image
          src={Constants.WR_BG_PATH + usertype + ".jpg"}
          overflow="hidden"
          objectFit="cover"
          objectPosition="right"
          bgRepeat="no-repeat"
          flexDir="column"
          h={{ base: "416px", lg: "640px" }}
          d="flex"
          flexDirection="column"
          justifyContent="flex-end"
          // pb={{ lg: "95px" }}
          w={{ base: "100vw", lg: "100%" }}
          //maxW={{ base: "max-content", lg: "auto" }}
          maxW={{ base: "100vw", lg: "100%" }}
          position="relative"
          mt={{ base: "-75px", lg: "0" }}
          ml={{ base: "-2rem", lg: "0" }}
        />

        <Flex
          position="relative"
          mt={{ base: "-350px", lg: "-550px" }}
          flexDir="column"
          justifyContent={{ base: "flex-end", lg: "space-between" }}
          w={{ lg: "65%" }}
        >
          <Image src={borntoFarm} sx={styles.heroTitleImg} />
          <Text
            as="h3"
            textStyle="h3"
            mt="5px"
            mb={{ base: "60px", lg: "16px" }}
          >
            {dateString}
          </Text>
          <Text
            style={{
              fontSize: "40px",
              fontFamily: "Arial Black, Arial-BoldMT, Arial",
              color: "white",
            }}
          >
            {t(`hallhero.title.${usertype}`)}
          </Text>
          <Text
            style={{
              fontSize: "25px",
              fontFamily: "Arial",
              color: "white",
              textTransform: "uppercase",
            }}
            mb="15px"
          >
            {t(`hallhero.subtitle.${usertype}`)}
          </Text>
          <HStack
            spacing={{ base: "10px", lg: "15px" }}
            style={{
              font: "normal normal bold 16px/24px Arial",
              letterSpacing: "0px",
              opacity: 1,
            }}
            justify={{ base: "left" }}
            fontSize="16px"
          >
            <Wrap>
              <Text color="red.500" w={{ base: "100%", lg: "auto" }} pr="30px" m="0!important">
                {t("hallhero.red.dontmiss")}
              </Text>
              <Text color="white" whiteSpace={{ md: "nowrap" }} pr="30px" m="0!important">
                {t("hallhero.opportunity")}
              </Text>
              <Text color="white" whiteSpace={{ md: "nowrap" }} m="0!important">
                {t(`hallhero.personalised.${usertype}`)}
              </Text>
            </Wrap>
          </HStack>
          <Text pb="29px" mt="10px" sx={styles.heroLabel}>
            {t("hallhero.iscoming")}
          </Text>

          <Text
            style={{
              font: "normal normal normal 16px/24px Arial",
              letterSpacing: "0px",
              color: "#FFFFFF",
              opacity: 1,
            }}
          >
            {t(`hallhero.description.${usertype}`)}
          </Text>
        </Flex>
        {/* <chakra.div
          w={{ base: "100vw", lg: "auto" }}
          padding="0px!important"
          zIndex="20"
        > */}
        {videoList.length > 0 &&
          <VideoSection
            videoList={videoList}
            title={t("hallhero.newest")}
            showWatchAllButton={true}
            padding="0"
          />
        }
        {/* </chakra.div> */}
      </Container>
    </chakra.div>
  );
};

export default HallHero;
