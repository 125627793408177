import React, { useState, useRef } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";

import {
  chakra,
  useBreakpointValue,
  useMultiStyleConfig,
  Container,
  Text,
  Link,
  Flex,
  Divider,
  ListItem,
  UnorderedList,
  SimpleGrid,
  Box,
} from "@chakra-ui/react";

import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import axiosHelper from "./../../helpers/axiosHelper";
import bgImage from "./../../assets/prizes/prizes-page-bg@2x.jpg";

import { ChevronLeftIcon, ChevronRightIcon } from "@chakra-ui/icons";

const TermsAndConditionsContentRU = ({ ...props }) => {
  const { t } = useTranslation();
  const history = useHistory();
  const [userProfile, setUserProfile] = useState<UserProfile>(
    axiosHelper.getUserProfile()!
  );
  const styles = useMultiStyleConfig("PrizePage", props);

  const isMobile = useBreakpointValue({ base: true, lg: false });
  const customSlider = useRef<any>();

  return (
    <Container
      maxW="container.xl"
      bgImage={bgImage}
      d="flex"
      flexDir="column"
      flexDirection="column"
      style={{
        overflow: "hidden",
        backgroundPosition: "center",
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
      }}
      alignItems="center"
    >
      <Flex
        flexDir={{ base: "column", lg: "column" }}
        justifyContent="space-between"
        w={isMobile?"100%":"50%"}
        mt="2%"
      >
        <Text color="white" mb="2%" mt={isMobile ? "30px" : "0"} align="center">
          ПОЛОЖЕНИЯ И УСЛОВИЯ
          <br />
          СУПЕРКОНКУРС MF
          <br />
          Конкурс проводится со 2 июля по 3 сентября 2021 года
          <br />
          брендом MASSEY FERGUSON корпорации AGCO
          <br />
        </Text>
      </Flex>
      <chakra.div w="100%" pb="100px">
        <Flex
          flexDir={{ base: "column", lg: "column" }}
          justifyContent="space-between"
          w="100%"
          mt="3%"
        >
          <Text color="red.500" mt="1%" fontWeight="bold">
            1. Основные организационные положения
          </Text>
          <Text color="white" mt="1%">
            Отдел маркетинга компании AGCO SAS по бренду Massey Ferguson на
            территории Европы и Ближнего Востока, головной офис которого
            находится по адресу: 41 Blaise Pascal Avenue, Бове, 3 сентября
            проводит ОНЛАЙН-ПРЕМЬЕРУ НОВОГО MASSEY FERGUSON, включающую в себя
            участие в конкурсе, которое ни к чему не обязывает и бесплатно для
            всех желающих.
          </Text>

          <Text color="red.500" fontWeight="bold" mt="1%">
            2. Кто может участвовать
          </Text>

          <Text color="white" mt="1%">
            К участию допускаются только претенденты из стран Европы и Ближнего
            Востока (за исключением стран, в отношении которых действуют
            ограничительные меры в торговле). В этом конкурсе могут участвовать
            только сельхозпроизводители, подрядчики и лица, связанные с
            сельскохозяйственными предприятиями.
          </Text>

          <Text color="white" mt="1%">
            Не допускаются к участию:
          </Text>

          <chakra.div>
            <UnorderedList ml="40px" color="white">
              <ListItem>Лица моложе 18 лет</ListItem>
              <ListItem>Сотрудники AGCO Group и члены их семей</ListItem>
              <ListItem>Дилеры и дистрибьюторы продукции и услуг AGCO</ListItem>
              <ListItem>Сотрудники компаний, аффилированных с AGCO</ListItem>
              <ListItem>
                Агентства и поставщики, привлеченные AGCO, и родственники
                соответствующих сотрудников
              </ListItem>
              <ListItem>
                Журналисты, приглашенные MASSEY FERGUSON для участия в
                мероприятии
              </ListItem>
            </UnorderedList>
          </chakra.div>
          <Text color="white" mt="1%">
            На одно и то же название организации, имя, один и тот же номер
            телефона и адрес электронной почты можно зарегистрировать только
            одного участника — физическое лицо, домашнее хозяйство или
            сельскохозяйственное предприятие. Участие в игре строго
            индивидуальное. Компания-организатор оставляет за собой право
            осуществлять любой контроль, который она сочтет необходимым.
          </Text>
          <Text color="red.500" fontWeight="bold" mt="1%">
            3. Правила участия
          </Text>
          <Text color="white" mt="1%">
            Регистрация на НОВОЕ ОНЛАЙН-МЕРОПРИЯТИЕ MASSEY FERGUSON через
            веб-платформу организационного подразделения AGCO SAS автоматически
            подразумевает участие в игровом конкурсе, поскольку он составляет
            основу мероприятия. Участник соглашается предоставить AGCO SAS свои
            личные данные для связи по электронной почте, телефону или в
            социальных сетях
          </Text>
          <Text color="red.500" fontWeight="bold" mt="1%">
            4. Определение победителей
          </Text>
          <Text color="white" mt="1%">
            Результаты конкурса будут объявлены в период с 6 сентября 2021 года
            по 6 октября 2021 года в офисах AGCO SAS. - Отдел маркетинга по
            бренду Massey Ferguson на территории Европы и Ближнего Востока,
            расположенный в Бове (Франция).
          </Text>
          <Box display="block" mt="1%"></Box>
          <Text color="white">
            Результаты конкурса будут объявлены в период с 6 сентября 2021 года
            по 6 октября 2021 года в офисах AGCO SAS. - Отдел маркетинга по
            бренду Massey Ferguson на территории Европы и Ближнего Востока,
            расположенный в Бове (Франция). Отдел маркетинга по бренду Massey
            Ferguson на территории Европы и Ближнего Востока, расположенный в
            Бове, проведет розыгрыш за закрытыми дверями.
          </Text>
          <Text color="red.500" fontWeight="bold" mt="1%">
            5. Призы
          </Text>
          <Text color="white" mt="1%">
            В этом игровом конкурсе будет 3 уровня и 3 приза на каждый уровень,
            так что всего будет 9 победителей. Ответив на вопросы после
            регистрации, участник получит возможность увеличить свое количество
            баллов и получить в этом конкурсе подарки высшего уровня.
          </Text>
          <Text color="white" mt="1%">
            После мероприятия каждому участнику будет присужден 1 из 3 призов,
            описанных ниже, в зависимости от его итогового места в игровом
            рейтинге – которое будет объявлено на веб-платформе сразу после
            мероприятия (3 сентября 2021 года, в 15:00). Даже если участник не
            ответит ни на один вопрос, он автоматически будет включен в список
            на розыгрыш призов третьего уровня.
          </Text>

          <Text color="white" fontWeight="bold" mt="1%" fontStyle="italic">
            Уровень 1: стоимость до 20 000 евро — итоговое место: 1–1000
          </Text>
          <Text color="white" mt="1%">
            Участники, занявшие в конкурсе с 1 по 1000 места, разыграют призы
            первого уровня.
            <br /> На этом уровне доступны 3 приза, максимальная стоимость
            которых составляет 20 580 евро.
          </Text>
          <UnorderedList color="white" ml="40px" mt="1%">
            <ListItem>
              Первый приз этого уровня — скидка до 10 000 евро при покупке новой
              машины MF.
              <br />
              <br />
              Эта скидка распространяется только на новую технику: инструменты
              для уборки сена, тракторы, комбайны, пресс-подборщики и
              телескопические погрузчики (с учетом наличия машин в дилерском
              центре) из ассортимента MASSEY FERGUSON, приобретенную до 31 марта
              2022 года.
              <br />
              <br />
              Правила применения скидки (* на основе прайс-листов, включая
              дополнительное оборудование):
              <br />
              <SimpleGrid
                w={isMobile ? "100%" : "50%"}
                autoFlow=""
                borderColor="white"
                columns={[2, null, 2]}
                border="1px solid white"
                mt="5px"
              >
                <Box bg="red.500" border="1px solid white">
                  <Text
                    color="white"
                    fontWeight="bold"
                    padding="1.5"
                    fontSize={isMobile ? ".7rem" : "1rem"}
                  >
                    Стоимость новой машины *:{" "}
                  </Text>
                </Box>
                <Box bg="red.500" border="1px solid white">
                  <Text
                    color="white"
                    fontWeight="bold"
                    padding="1.5"
                    fontSize={isMobile ? ".7rem" : "1rem"}
                  >
                    Применяемая скидка{" "}
                  </Text>
                </Box>
                <Box border="1px solid white">
                  <Text
                    color="white"
                    padding="1.5"
                    fontSize={isMobile ? ".7rem" : "1rem"}
                  >
                    до 25 000 евро*{" "}
                  </Text>
                </Box>
                <Box border="1px solid white">
                  <Text
                    color="white"
                    padding="1.5"
                    fontSize={isMobile ? ".7rem" : "1rem"}
                  >
                    1500€
                  </Text>
                </Box>
                <Box border="1px solid white">
                  <Text
                    color="white"
                    padding="1.5"
                    fontSize={isMobile ? ".7rem" : "1rem"}
                  >
                    от 25 000 до 50 000 евро*{" "}
                  </Text>
                </Box>
                <Box border="1px solid white">
                  <Text
                    color="white"
                    padding="1.5"
                    fontSize={isMobile ? ".7rem" : "1rem"}
                  >
                    2500€
                  </Text>
                </Box>
                <Box border="1px solid white">
                  <Text
                    color="white"
                    padding="1.5"
                    fontSize={isMobile ? ".7rem" : "1rem"}
                  >
                    от 50 000 до 100 000 евро*{" "}
                  </Text>
                </Box>
                <Box border="1px solid white">
                  <Text
                    color="white"
                    padding="1.5"
                    fontSize={isMobile ? ".7rem" : "1rem"}
                  >
                    4000€
                  </Text>
                </Box>
                <Box border="1px solid white">
                  <Text
                    color="white"
                    padding="1.5"
                    fontSize={isMobile ? ".7rem" : "1rem"}
                  >
                    от 100 000 до 150 000 евро*{" "}
                  </Text>
                </Box>
                <Box border="1px solid white">
                  <Text
                    color="white"
                    padding="1.5"
                    fontSize={isMobile ? ".7rem" : "1rem"}
                  >
                    6000€
                  </Text>
                </Box>
                <Box border="1px solid white">
                  <Text
                    color="white"
                    padding="1.5"
                    fontSize={isMobile ? ".7rem" : "1rem"}
                  >
                    от 150 000 до 200 000 евро*{" "}
                  </Text>
                </Box>
                <Box border="1px solid white">
                  <Text
                    color="white"
                    padding="1.5"
                    fontSize={isMobile ? ".7rem" : "1rem"}
                  >
                    8000€
                  </Text>
                </Box>
                <Box border="1px solid white">
                  <Text
                    color="white"
                    padding="1.5"
                    fontSize={isMobile ? ".7rem" : "1rem"}
                  >
                    свыше 200 000 евро*{" "}
                  </Text>
                </Box>
                <Box border="1px solid white">
                  <Text
                    color="white"
                    padding="1.5"
                    fontSize={isMobile ? ".7rem" : "1rem"}
                  >
                    10 000€
                  </Text>
                </Box>
              </SimpleGrid>
            </ListItem>
            <ListItem mt="26px">
              Второй приз первого уровня — бесплатная опциональная система MF
              Guide с коррекцией RTK для любого совместимого трактора / комбайна
              MF, купленного до 31 марта 2022 года.
              <br />
              <br />
              Победитель должен выбрать опциональную систему MF Guide при
              покупке машины.
              <br />
              Максимальная стоимость этого приза не превышает 16 060 евро.
              Годовая абонентская плата не включена (взимается с победителя).
            </ListItem>
            <ListItem mt="26px">
              Третий и последний приз на первом уровне — трактор MF 1520 A
              стандарта Stage 5 стоимостью 19 890 евро*, включая шины стоимостью
              690 евро * (* Прайс-лист на 1 февраля 2021 года)
            </ListItem>
          </UnorderedList>

          <Text color="white" mt="1%">
            Участник розыгрыша может выиграть только один приз за всю игру на
            всех уровнях.
          </Text>
          <Text color="white" fontWeight="bold" mt="1%" fontStyle="italic">
            Уровень 2: стоимость до 5000 евро — итоговое место: 1001–2000
          </Text>
          <Text color="white" mt="1%">
            Участники, занявшие в конкурсе с 1001 по 2000 места, разыграют призы
            второго уровня.
            <br />
            На этом уровне доступны 3 приза, максимальная стоимость которых
            составляет 5000 евро
          </Text>

          <UnorderedList color="white" ml="40px" mt="1%">
            <ListItem>
              Первый приз этого уровня — пакет MF Summer Pack общей стоимостью
              1000 евро, который включает в себя:
              <UnorderedList color="white">
                <ListItem ml="20px" type="circle">
                  Набор для барбекю MF / AGCO Parts Oil Barrel Barbecue и 4
                  шезлонга MF / AGCO Parts общей стоимостью 340 евро
                </ListItem>
              </UnorderedList>
              <Text color="white" ml="-40px">
                И{" "}
              </Text>
              <UnorderedList color="white">
                <ListItem ml="20px" type="circle">
                  Персонализированный iPad Air Grey WIFI 64 Гб стоимостью 669
                  евро
                </ListItem>
              </UnorderedList>
            </ListItem>
          </UnorderedList>

          <Text color="white" mt="1%">
            Эти подарки будут доставлены победителю по отдельности.
          </Text>

          <UnorderedList color="white" ml="40px">
            <ListItem mt="1%">
              Второй приз второго уровня — профессиональная мойка высокого
              давления стоимостью 2000 евро.
              <br />
              <br />
              Конкретная модель предоставляемой мойки высокого давления не
              оговаривается и может меняться в зависимости от страны-победителя.
              Сотрудники отдела дополнительного оборудования AGCO Parts
              Complementary предоставят точную информацию о модели после выбора
              победителя.
            </ListItem>
            <ListItem mt="1%">
              Третий и последний приз второго уровня общей стоимостью 5000
              евро — это пакет услуг MF, который включает в себя:
              <UnorderedList color="white" listStyleType="circle" mt="1%">
                <ListItem>
                  Ваучер на 5000 евро, который можно использовать в магазине
                  местного MF дилера победителя для приобретения любых имеющихся
                  запчастей AGCO Parts.
                </ListItem>
                <ListItem mt="1%">
                  Возможность заказать одну выездную консультацию специалиста
                  нашего центра технического обслуживания AGCO в сопровождении
                  местного дилера для получения рекомендаций по использованию MF
                  трактора и телескопического погрузчика Massey Ferguson.
                </ListItem>
              </UnorderedList>
            </ListItem>
          </UnorderedList>

          <Text color="white" mt="1%">
            Участник розыгрыша может выиграть только один приз за всю игру на
            всех уровнях.
          </Text>

          <Text color="white" fontWeight="bold" mt="2%" fontStyle="italic">
            Уровень 3: стоимость до 250 евро — итоговое место: 2001–последнее
          </Text>

          <Text color="white" mt="2%">
            Участники, занявшие в конкурсе места с 2001 до последнего, разыграют
            призы третьего уровня.
            <br /> На этом уровне доступны 3 приза с одинаковой общей стоимостью
            в 250 евро
          </Text>

          <UnorderedList color="white " mt="1%">
            <ListItem>
              Первый приз третьего уровня — это пакет Connectivity Pack, который
              включает в себя три эксклюзивных подарка MASSEY FERGUSON:
              <UnorderedList color="white">
                <ListItem type="circle">
                  Умные часы MASSEY FERGUSON — цена за единицу: 180 евро
                </ListItem>
                <ListItem type="circle">
                  Комплект наушников MASSEY FERGUSON MASSEY FERGUSON — цена за
                  единицу: 39,90 евро
                </ListItem>
                <ListItem type="circle">
                  Внешний аккумулятор MASSEY FERGUSON S Collection — цена за
                  единицу: 29,90 евро
                </ListItem>
                <Box display="block" mt="1%"></Box>
              </UnorderedList>
            </ListItem>

            <ListItem mt="1%">
              Второй приз третьего уровня — электронный ваучер на 250 евро,
              который можно использовать частями несколько раз в онлайн-магазине
              MASSEY FERGUSON{" "}
              <Link href="https://shop.masseyferguson.com">
                https://shop.masseyferguson.com
              </Link>.{" "}
              Этот электронный ваучер действителен 36 месяцев с момента его
              получения.
            </ListItem>

            <ListItem mt="1%">
              • Третий и последний приз третьего уровня — игровой пакет,
              включающий в себя:
              <UnorderedList color="white" listStyleType="circle">
                <ListItem>
                  Масштабную модель MF 8S — цена за единицу: 70 евро
                </ListItem>
                <ListItem>
                  Эксклюзивную масштабную модель MF 5S — цена за единицу: 65
                  евро
                </ListItem>
                <ListItem>
                  Беспроводную мышь MASSEY FERGUSON для ПК — цена за единицу: 15
                  евро
                </ListItem>
                <ListItem>
                  2 игры для ПК Farming Simulator 2022 (симулятор фермы) — цена
                  за единицу: 40 евро
                </ListItem>
                <ListItem>
                  Эксклюзивную бейсболку Farming Simulator 2022 FS — цена за
                  единицу: 20 евро
                </ListItem>
              </UnorderedList>
            </ListItem>
          </UnorderedList>
          <Text color="white" mt="1%">
            Участник розыгрыша может выиграть только один приз за всю игру на
            всех уровнях.
          </Text>
          <Text color="red.500" fontWeight="bold" mt="1%">
            6. Уведомление и объявление победителей
          </Text>
          <Text color="white" mt="1%">
            Сотрудники отдела маркетинга Massey Ferguson уведомят победителей,
            отправив им электронное письмо с приглашением связаться с AGCO.
            <br />
            Если человек, выбранный в розыгрыше, не ответит на это электронное
            письмо в течение 10 дней, будет проведен второй и последний
            розыгрыш, а первый победитель будет дисквалифицирован.
            <br />
            Сотрудники компании AGCO SAS свяжутся с победителями по электронной
            почте или другим способом и отправят информацию по получению призов.
            <br />
            Участник соглашается, что в случае его победы в конкурсе его имя
            будет публично объявлено в рекламе, промоакциях или на
            соответствующих веб-сайтах и в социальных сетях.
            <br />
            Победитель обязуется бесплатно в разумных пределах участвовать в
            рекламных кампаниях, проводимых через радио, Интернет, телевидение
            или печатные СМИ, и соглашается на публикацию и использование в
            рекламных целях компанией AGCO и компаниями, аффилированными с AGCO,
            аудиоматериалов, фотографий, изображений и/или текста с его/ее
            участием.
            <br />
          </Text>
          <Text color="red.500" fontWeight="bold" mt="1%">
            7. Исключение участников
          </Text>

          <Text color="white" mt="1%">
            AGCO оставляет за собой право исключать участников из конкурса. Это,
            в частности, относится к нарушениям условий участия, ложным
            персональным данным, манипуляциям или подозрениям в манипуляциях.
            <br /> AGCO также может исключать участников, которые обоснованно
            подозреваются в использовании услуг AGCO.
            <br />
            Если компания AGCO впоследствии установит, что участник создал
            какую-либо причину, ведущую к исключению его из числа участников,
            или фактически не имел права участвовать на основании критериев,
            изложенных в пункте 2, AGCO может исключить участника из
            распределения или передачи призов или отозвать любые призы, которые
            уже были вручены.
          </Text>
          <Text color="red.500" fontWeight="bold" mt="1%">
            8. Выдача призов
          </Text>
          <Text color="white" mt="1%">
            Обмен на деньги, возврат или компенсация не допускаются ни при каких
            обстоятельствах.
            <br />
            Компания-организатор может заменить призы на другие, имеющие
            эквивалентную ценность, если внешние обстоятельства не позволяют
            предоставить победителям заявленные призы. Призы не обсуждаются и не
            подлежат замене на денежный эквивалент.
            <br />
            Приз будет отправлен победителю в течение трех месяцев после того,
            как отдел маркетинга Massey Ferguson получит всю информацию,
            необходимую для доставки этого приза.
          </Text>
          <Text color="red.500" fontWeight="bold" mt="1%">
            9. Условия оказания услуг и гарантия
          </Text>
          <Text color="white" mt="1%">
            Организаторы конкурса не несут никакой ответственности за любые
            происшествия, которые могут возникнуть в период использования призов
            и/или вследствие их использования.
            <br />
            Организаторы оставляют за собой право сокращать, продлевать,
            изменять или отменять этот процесс, если того требуют
            обстоятельства, не принимая на себя никакой ответственности.
            <br />
            Организаторы не могут нести ответственность в случае прерывания
            услуги, если оно вызвано технической неполадкой в сетях связи или
            технической неполадкой по независящим от организаторов причинам.
            Организаторы не могут нести ответственность, если по какой-либо
            причине конкурс будет частично или полностью изменен, отложен или
            отменен. Компенсация не подлежит истребованию. Любые неразборчивые,
            неполные и подделанные формы заявок не будут рассматриваться. Таким
            образом, компания-организатор не несет ответственности в случае,
            если формы заявок не зарегистрированы или их невозможно проверить.
            <br />
          </Text>
          <Text color="red.500" fontWeight="bold" mt="1%">
            10. Акцепт и разногласия
          </Text>

          <Text color="white" mt="1%">
            Принимая участие в этом игровом конкурсе, все участники подтверждают
            свое согласие с данными положениями и условиями.
            <br />
            Каждый участник знает, что вмешательство случая не может ему ничего
            гарантировать, кроме надежды на возможный и случайный выигрыш.
            <br />
            Любые возникшие разногласия будут переданы в письменной форме в
            юридический отдел компании AGCO SAS, расположенный по адресу: 41
            avenue Blaise Pascal, BP 60307, БОВЕ (60026), ФРАНЦИЯ, не позднее
            чем 3 сентября (по почтовому штемпелю).
            <br />
            'Этот регламент регулируется в соответствии с законодательством
            Франции, которое является единственным применимым.
            <br />
            Отдел маркетинга компании AGCO SAS по бренду Massey Ferguson на
            территории Европы и Ближнего Востока предоставит разъяснения по
            любым трудностям практического характера, толкованию или вопросам
            применения настоящего Регламента.
            <br />
          </Text>
          <Text color="red.500" fontWeight="bold" mt="1%">
            11. Предоставление информации о положениях и условиях конкурса
          </Text>
          <Text color="white" mt="1%">
            Настоящие положения и условия будут доступны онлайн на официальной
            платформе мероприятий MF.
            <br />
            Участник розыгрыша через Интернет не может требовать возврата денег,
            так как его участие не несет каких-либо дополнительных затрат.
            <br />
            Полная версия положений и условий находится в юридическом отделе
            компании-организатора.
            <br />
          </Text>
          <Text color="red.500" fontWeight="bold" mt="1%">
            12. Защита персональных данных
          </Text>
          <Text color="white" mt="1%">
            Чтобы принять участие в этом конкурсе, участники должны обязательно
            согласиться с тем, что некоторая личная информация о них будет
            использоваться компанией-организатором в целях управления конкурсом,
            а также в маркетинговых целях.
            <br />
            Для получения дополнительной информации, вы можете ознакомиться с
            нашей Политикой конфиденциальности по следующей ссылке:{" "}
            <Link href="http://www.agcocorp.com/privacy.html">
              http://www.agcocorp.com/privacy.html
            </Link>
            <br />
            Каждый победитель соглашается предоставить компании-организатору
            право использовать свое название, имя, краткий адрес и фотографии
            для любых рекламных или редакционных материалов без какой-либо
            финансовой или иной компенсации.
            <br />В соответствии с применимыми правилами, вы можете прекратить
            свое участие в розыгрыше полностью или частично в любое время и без
            объяснения причин концерну AGCO. Вы можете воспользоваться правом
            доступа к вашим персональным данным, правом на исправление или
            удаление персональных данных и правом на возражение в отношении
            обработки ваших персональных данных, связавшись с нами по адресу
            электронной почты: dataprivacy@agcocorp.com.
          </Text>
        </Flex>
      </chakra.div>
    </Container>
  );
};

export default TermsAndConditionsContentRU;
