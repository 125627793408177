import React, { FC, useState, useEffect } from "react";
import { useTranslation } from "react-i18next";

import {
  chakra,
  useBreakpointValue,
  useMultiStyleConfig,
  Container,
  Text,
  Flex,
  useDisclosure,
  Divider,
  HStack,
  Button,
} from "@chakra-ui/react";

import bgImage from "./../../../assets/home/counter/shutterstock_1117734563/shutterstock_1117734563@2x.png";
import Clock from "./Clock";

//export type Props = {};
interface Props {
  endDateString: string;
}

const Counter: FC<Props> = ({ endDateString }: Props, ...props) => {
  const endDate = new Date(endDateString);

  const styles = useMultiStyleConfig("Hall", props);
  const { t } = useTranslation();

  const isMobile = useBreakpointValue({ base: true, lg: false });

  const [time, setNewTime] = useState({
    days: 0,
    hours: 0,
    mins: 0,
    secs: 0,
  });

  useEffect(() => {
    const interval = setInterval(() => {
      const date = calculateCountDown(endDate);
      setNewTime(date);
    }, 1000);
    return function cleanup() {
      clearInterval(interval);
    };
  }, [endDate]);

  const calculateCountDown = (endDate: Date) => {
    let startDate = new Date();
    let diff = (endDate.getTime() - startDate.getTime()) / 1000;

    const timeLeft = {
      days: 0,
      hours: 0,
      mins: 0,
      secs: 0,
    };

    if (diff <= 0) return timeLeft;

    if (diff >= 86400) {
      // 24 * 60 * 60
      timeLeft.days = Math.floor(diff / 86400);
      diff -= timeLeft.days * 86400;
    }
    if (diff >= 3600) {
      // 60 * 60
      timeLeft.hours = Math.floor(diff / 3600);
      diff -= timeLeft.hours * 3600;
    }
    if (diff >= 60) {
      timeLeft.mins = Math.floor(diff / 60);
      diff -= timeLeft.mins * 60;
    }
    timeLeft.secs = Math.floor(diff);

    return timeLeft;
  };

  return (
    <chakra.div w="100%" bgColor="#000000">
      <Container
        maxW="container.xl"
        bgImage={bgImage}
        sx={styles.counterContainer}
      >
        <Flex alignItems="center" mb={isMobile?"30px":"60px"} w={{ base:"100%", lg: "75%" }}>
          <Text
            as="h2"
            textStyle="h2"
            textAlign="center"
            w="100%"
          >
            {t("counter.title")}
          </Text>
        </Flex>
        {/* {isMobile && (
          <Flex sx={styles.counterRegisterWrapper}>
            <Button sx={styles.counterRegisterBtn}> {t("")}Register now</Button>
          </Flex>
        )} */}
        <HStack
          spacing={{ base: "auto", lg: "30px" }}
          mb="30px"
          pl={{ base: "0", lg: "50px" }}
        >
          <Clock
            digit={time.days}
            label={t("countdown.days")}
            maxNum={60}
            id={"days"}
            width={"170px"}
          />
          <Clock
            digit={time.hours}
            label={t("countdown.hours")}
            maxNum={24}
            id={"hours"}
            width={"170px"}
          />
          <Clock
            digit={time.mins}
            label={t("countdown.minutes")}
            maxNum={60}
            id={"mins"}
            width={"170px"}
          />
          <Clock
            digit={time.secs}
            label={t("countdown.seconds")}
            maxNum={60}
            id={"sec"}
            width={"170px"}
          />
        </HStack>
      </Container>
    </chakra.div>
  );
};

export default Counter;
