import React, { FC, useRef } from "react";
import { useTranslation } from "react-i18next";
import {
  chakra,
  useBreakpointValue,
  useMultiStyleConfig,
  Container,
  Text,
  Flex,
  Box,
} from "@chakra-ui/react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import background from "./../../../assets/home/sliderSection/shutterstock_1420702181/shutterstock_1420702181@2x.png";
import SliderCard from "./SliderCard";
import { ChevronLeftIcon, ChevronRightIcon } from "@chakra-ui/icons";
import image1 from "./../../../assets/home/sliderSection/Raggruppa 501.svg";
import image2 from "./../../../assets/home/sliderSection/Raggruppa 458.svg";
import image3 from "./../../../assets/home/sliderSection/Raggruppa 457.svg";
import image4 from "./../../../assets/home/sliderSection/Raggruppa 491.svg";

export type Props = {};

const SliderSection: FC<Props> = ({}: Props, ...props) => {
  const styles = useMultiStyleConfig("Hall", props);
  const customSlider = useRef<any>();
  const { t } = useTranslation();
  const isMobile = useBreakpointValue({ base: true, lg: false });
  const renderArrows = () => {
    return (
      <Flex justifyContent="space-between" my={{ base: "20px", lg: "5px" }}>
        <Flex
          onClick={() => customSlider.current.slickPrev()}
          cursor="pointer"
          zIndex="10"
        >
          <ChevronLeftIcon color="white" w="40px" h="40px" />
        </Flex>

        <Flex
          onClick={() => customSlider.current.slickNext()}
          cursor="pointer"
          zIndex="10"
        >
          <ChevronRightIcon color="white" w="40px" h="40px" />
        </Flex>
      </Flex>
    );
  };

  return (
    <chakra.div w="100%" bgColor="#000000">
      <Container
        maxW="container.xl"
        bgImage={background}
        sx={styles.sliderSectionContainer}
      >
        <Text
          marginBottom="60px"
          maxW={{ base: "100%", lg: "470px" }}
          as="h3"
          textStyle="h3"
          lineHeight="35px"
        >
          {t("slidersection.title")}
        </Text>
        <Flex sx={styles.sliderSectionWrapper}>
          <Slider
            ref={(slider: any) => (customSlider.current = slider)}
            dots={true}
            infinite={true}
            speed={500}
            slidesToShow={isMobile ? 1 : 3}
            accessibility={true}
            autoplay
            autoplaySpeed={5000}
            arrows={false}
            centerMode={true}
            centerPadding={isMobile ? "30px" : "0px"}
            slidesToScroll={isMobile ? 1 : 5}
          >
            <SliderCard
              logo={image1}
              title={t("slidercard_1.title")}
              label={t("slidercard_1.description")}
            />
            <SliderCard
              logo={image2}
              title={t("slidercard_2.title")}
              label={t("slidercard_2.description")}
            />
            <SliderCard
              logo={image3}
              title={t("slidercard_3.title")}
              label={t("slidercard_3.description")}
            />
            <SliderCard
              logo={image4}
              title={t("slidercard_4.title")}
              label={t("slidercard_4.description")}
            />
          </Slider>
          <Box display="block" mt="1rem">
            {renderArrows()}
          </Box>
        </Flex>
      </Container>
    </chakra.div>
  );
};

export default SliderSection;
