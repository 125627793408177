const Login = {
  parts: ["container", "wrapper", "label"],

  baseStyle: ({ colorMode }: any) => ({
    container: {
      overflow: "hidden",
      backgroundPosition: "center",
      backgroundSize: "cover",
      backgroundRepeat: "no-repeat",
      w: "100%",
      minH: { lg: "646px" },
      py: { base: "30px", lg: "0px" },
    },
    wrapper: {
      w: "100%",
      justifyContent: "center",
      alignItems: "center",
      h: { base: "fit-content", lg: "auto" },
    },
    label: {
      mt: "15px",
      color: "greyDark.500",
      fontFamily: "Arial",
      fontWeight: "normal",
      fontSize: { base: "12px", lg: "14px" },
    },
  }),
};

export default Login;
