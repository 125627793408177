import React, { FC } from "react";

import { useTranslation } from "react-i18next";

import {
  Flex,
  Text,
  DrawerFooter,
  Button,
  DrawerContent,
  Drawer,
  DrawerBody,
  DrawerHeader,
  DrawerOverlay,
  DrawerCloseButton,
  useDisclosure,
  VStack,
} from "@chakra-ui/react";

import ClusterModal from "./ClusterModal";
import ChangePasswordModal from "./ChangePasswordModal";

import { useHistory, Link } from "react-router-dom";

type Props = {
  refreshCluster: () => void;
  clickOpen?: boolean;
  clickClose?: () => void;
  tutorialTabs?: boolean;
  gameTabs?: boolean;
  prizesTabs?: boolean;
  userTabs?: boolean;
};

const MenuModal: FC<Props> = ({
  refreshCluster,
  clickOpen = false,
  clickClose = () => { },
  tutorialTabs,
  gameTabs,
  prizesTabs,
  userTabs,
}) => {
  const { t } = useTranslation();
  const history = useHistory();
  const {
    isOpen: isOpenCluster,
    onOpen: onOpenCluster,
    onClose: onCloseCluster,
  } = useDisclosure();

  const {
    isOpen: isOpenChangePassword,
    onOpen: onOpenChangePassword,
    onClose: onCloseChangePassword,
  } = useDisclosure();

  return (
    <>
      <Drawer isOpen={clickOpen} onClose={clickClose}>
        <DrawerOverlay />
        <DrawerContent>
          <DrawerCloseButton />
          <DrawerHeader></DrawerHeader>

          <DrawerBody>
            <VStack
              spacing="10px"
              h="100%"
              textTransform="uppercase"
              alignItems="flex-start"
              fontFamily="Arial"
              color="black"
            >
              {tutorialTabs && (
                <Text cursor="pointer" p="5px 0">
                  <Link
                    to="/tutorial"
                    style={{ color: "black", textDecoration: "none" }}
                  >
                    {t("header.tutorial")}
                  </Link>
                </Text>
              )}
              {gameTabs && (
                <Text color="black" cursor="pointer" p="5px 0">
                  <Link
                    to="/termsandconditions"
                    style={{ color: "black", textDecoration: "none" }}
                  >
                    {t("header.game")}
                  </Link>
                </Text>
              )}
              {prizesTabs && (
                <Text cursor="pointer" p="5px 0">
                  <Link
                    to="/prizes"
                    style={{ color: "black", textDecoration: "none" }}
                  >
                    {t("header.prizes")}
                  </Link>
                </Text>
              )}
              {userTabs && (
                <>
                  <Text onClick={onOpenCluster} cursor="pointer" p="5px 0">
                    {t("header.changecluster")}
                  </Text>
                  <Text onClick={onOpenChangePassword} cursor="pointer" p="5px 0">
                    {t("header.changepassword")}
                  </Text>
                  <Text
                    color="red.500"
                    onClick={() => {
                      history.push("/logout");
                    }}
                    cursor="pointer"
                    p="5px 0"
                  >
                    {t("header.logout")}
                  </Text>
                </>
              )}
            </VStack>
          </DrawerBody>

          <DrawerFooter>
            {/* <Button variant="outline" mr={3} onClick={clickClose}>
              Cancel.menuModal
            </Button> */}
          </DrawerFooter>
        </DrawerContent>
      </Drawer>
      <ClusterModal clickOpen={isOpenCluster} clickClose={onCloseCluster} refreshCluster={refreshCluster} />
      <ChangePasswordModal clickOpen={isOpenChangePassword} clickClose={onCloseChangePassword} /> 
    </>
  );
};

export default MenuModal;
