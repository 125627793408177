import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";
import { useTranslation } from "react-i18next";
import axiosHelper from "../../helpers/axiosHelper";
import { Flex, Text, Input, useDisclosure } from "@chakra-ui/react";

type ResetData = {
  email: string;
};

const PasswordReset = () => {
  const { t } = useTranslation();
  const [passwordChangeRequested, setPasswordChangeRequested] =
    useState<boolean>(false);

  const [error, setError] = useState<boolean>(false);
  const { register, handleSubmit, errors } = useForm<ResetData>({
    criteriaMode: "all",
  });

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const onSubmit = async (data: ResetData) => {
    // console.log(data);

    try {
      await axiosHelper.resetPassword(data.email);
      setPasswordChangeRequested(true);
    } catch (error) {
      setError(true);
    }
  };

  return (
    <Flex w="100%" flexDir="column">
      {!passwordChangeRequested ? (
        <>
          <Text as="h2" textStyle="h2" color="red.500">
            {t("retrieve.title")}
          </Text>
          <Text
            style={{
              font: "normal normal normal 16px/24px Arial",
              letterSpacing: "0px",
              color: "#000000",
              opacity: "1",
            }}
            mt="10px"
            mb="30px"
          >
            {t("retrieve.instructions")}
          </Text>

          <form onSubmit={handleSubmit(onSubmit)}>
            <Flex mb="30px">
              <Input
                type="email"
                placeholder={t("retrieve.emailph")}
                name="email"
                ref={register({
                  required: t("err.required") as string,
                  max: 100,
                  min: 4,
                  maxLength: 100,
                })}
                variant="outline"
                h="50px"
                borderRadius="0px"
              />
              <ErrorMessage errors={errors} name="email" />
            </Flex>

            <Flex justifyContent="flex-end">
              <button
                className="button--submit"
                style={{
                  backgroundColor: "#C41230",
                  paddingTop: "10px",
                  paddingBottom: "10px",
                  paddingLeft: "30px",
                  paddingRight: "30px",
                  border: "none",
                  color: "white",
                  width: "40%",
                }}
              >
                {t("retrieve.okbtn")}
              </button>
              {error ? (
                <div className="modal-text error-message">
                  <br />
                  {"An error occurred."}
                </div>
              ) : null}
            </Flex>
          </form>
        </>
      ) : (
        <>
          <Text as="h2" textStyle="h2" color="red.500">
            {t("retrieve.success.title")}
          </Text>
          <Text
            style={{
              font: "normal normal normal 16px/24px Arial",
              letterSpacing: "0px",
              color: "#000000",
              opacity: "1",
            }}
            mt="10px"
          >
            {t("retrieve.success.message1")}
          </Text>
          <Text
            style={{
              font: "normal normal normal 16px/24px Arial",
              letterSpacing: "0px",
              color: "#000000",
              opacity: "1",
            }}
            mb="30px"
          >
            {t("retrieve.success.message2")}
          </Text>
          <Flex justifyContent="flex-end">
            <button
              style={{
                backgroundColor: "#C41230",
                paddingTop: "10px",
                paddingBottom: "10px",
                paddingLeft: "30px",
                paddingRight: "30px",
                border: "none",
                color: "white",
                width: "40%",
              }}
              onClick={() => {
                window.location.reload();
              }}
            >
              OK
            </button>
          </Flex>
        </>
      )}
    </Flex>
  );
};

export default PasswordReset;
