import React, { useState, useEffect, useRef } from "react";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import {
  Container,
  Flex,
  Stack,
  Text,
  useBreakpointValue,
} from "@chakra-ui/react";

import axiosHelper from "../helpers/axiosHelper";
import bgImage from "../assets/tutorial/shutterstock_1117734563 (2)/shutterstock_1117734563@2x.png";
import imageforI from "../assets/welcomecluster/buttonForI.jpg";
import imageforR from "../assets/welcomecluster/buttonForR.jpg";
import imageforS from "../assets/welcomecluster/buttonForS.jpg";

import Header from "../components/Header";

import MainLayoutLogin from "../layouts/MainLayoutLogin";
import ClusterBox from "../components/cluster/ClusterBox";

const PageCluster = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const [userProfile, setUserProfile] = useState<UserProfile>();
  const [showSkip, setShowSkip] = useState(false);

  const isMobile = useBreakpointValue({ base: true, lg: false });
  const [isSelected, setIsSelected] = useState({
    R: false,
    I: false,
    S: false,
  });

  const refBrave = useRef<any>();
  const refBig = useRef<any>();
  const refSpecialist = useRef<any>();

  const isCustomer = () => {
    return "RSI".includes(userProfile!.userType);
  }

  useEffect(() => {
    console.log('USE EFFECT 1');
    setUserProfile(axiosHelper.getUserProfile()!);

  }, []);

  useEffect(() => {
    if (userProfile) {
      console.log('USE EFFECT 2');
      if (isCustomer()) {
        if (userProfile.userType === 'R') {
          setIsSelected({ R: true, I: false, S: false });
          setShowSkip(true);
        } else if (userProfile.userType === 'S') {
          setIsSelected({ R: false, I: false, S: true });
          setShowSkip(true);
        } else if (userProfile.userType === 'I') {
          setIsSelected({ R: false, I: true, S: false });
          setShowSkip(true);
        } else {
          console.log('NOT REALLY');
          setShowSkip(false);
        }
      } else {
        if (userProfile.cluster === 'R') {
          setIsSelected({ R: true, I: false, S: false });
          setShowSkip(true);
        } else if (userProfile.cluster === 'I') {
          setIsSelected({ R: false, I: true, S: false });
          setShowSkip(true);
        } else if (userProfile.cluster === 'S') {
          setIsSelected({ R: false, I: false, S: true });
          setShowSkip(true);
        } else {
          console.log('NOT REALLY GUEST' + userProfile.cluster);
          setShowSkip(false);
        }
      }

    }
  }, [userProfile]);

  //   const styles = useMultiStyleConfig("Hall", props);
  const handleCard = (item: string) => {
    if (item === "brave") {
      setIsSelected({ R: true, I: false, S: false });
      setShowSkip(true);
    } else if (item === "big") {
      setIsSelected({ R: false, I: true, S: false });
      setShowSkip(true);
    } else if (item === "specialist") {
      setIsSelected({ R: false, I: false, S: true });
      setShowSkip(true);
    } else if (item === "") {
      setIsSelected({ R: false, I: false, S: false });
    }
  };

  const skip = () => {
    // history.push("/");

  }

  const saveCluster = () => {
    // axiosHelper.setTutorialSeen();
    if (isSelected.R) {
      axiosHelper.setCluster("R");
      history.push("/");
    } else if (isSelected.I) {
      axiosHelper.setCluster("I");
      history.push("/");
    } else if (isSelected.S) {
      axiosHelper.setCluster("S");
      history.push("/");
    } else {
      console.log('DEVE SCEGLIERE');
    }

  };

  return (
    <MainLayoutLogin>
      <Container maxW="container.xl" d="flex" flexDir="column">
        <Header
          title={t("clusterselection.title")}
          logo
          date="02/09/2021 - 12:00"
          mainLayoutLogin
        />
      </Container>

      <Container
        maxW="container.xl"
        bgImage={bgImage}
        d="flex"
        flexDir="column"
        flexDirection="column"
        py="56px"
        style={{
          overflow: "hidden",
          backgroundPosition: "center",
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
        }}
        alignItems="center"
        pt="20px"
      >
        <Text
          mt="5px"
          fontFamily="Arial"
          fontWeight="normal"
          color="#ffffff"
          fontSize={{ base: "16px" }}
          mb="50px"
          w="100%"
        >
          {t("clusterselection.text")}
        </Text>
        <Stack
          direction={{ base: "column", lg: "row" }}
          justify="center"
          spacing="20px"
          h={{ lg: "129px" }}
        >
          <ClusterBox
            refBox={refBrave}
            img={imageforR}
            title={t("registercard.title.R")}
            subtitle={t("registercard.description.R")}
            type="brave"
            handleClick={handleCard}
            selected={isSelected.R}
            width={{ base: "100%", lg: "270px" }}
            height={{ base: "129px", lg: "129px" }}
          />
          <ClusterBox
            refBox={refBig}
            img={imageforI}
            title={t("registercard.title.I")}
            subtitle={t("registercard.description.I")}
            type="big"
            handleClick={handleCard}
            selected={isSelected.I}
            width={{ base: "100%", lg: "270px" }}
            height={{ base: "129px", lg: "129px" }}
          />
          <ClusterBox
            refBox={refSpecialist}
            img={imageforS}
            title={t("registercard.title.S")}
            subtitle={t("registercard.description.S")}
            type="specialist"
            handleClick={handleCard}
            selected={isSelected.S}
            width={{ base: "100%", lg: "270px" }}
            height={{ base: "129px", lg: "129px" }}
          />
        </Stack>
        <Flex w="100%" my="2rem" justifyContent="center" alignItems="center">
          {showSkip ? <>
            <Flex
              fontSize="18px"
              fontFamily="Arial"
              fontWeight="bold"
              textTransform="uppercase"
              color="white"
              bgColor="#707070"
              px="55px"
              py="7px"
              w={{ base: "100%", lg: "auto" }}
              justifyContent="center"
              cursor="pointer"
              _hover={{ opacity: 0.8 }}
              onClick={saveCluster}
              mr="5px"
            >
              {t("question.skip")}
            </Flex>
            <Flex
              fontSize="18px"
              fontFamily="Arial"
              fontWeight="bold"
              textTransform="uppercase"
              color="white"
              bgColor="red.500"
              px="55px"
              py="7px"
              w={{ base: "100%", lg: "auto" }}
              justifyContent="center"
              cursor="pointer"
              _hover={{ opacity: 0.8 }}
              onClick={saveCluster}
              ml="5px"
            >
              {t("retrieve.okbtn")}
            </Flex>
            </> : null}
        </Flex>
      </Container>
    </MainLayoutLogin>
  );
};

export default PageCluster;
