import React, { useEffect, useState, useRef } from "react";
import logoChat from "../../assets/plenary/mf-chat-logo.png";
import { chatTimeString } from '../../helpers/utils';

import { chakra, Text } from "@chakra-ui/react";

interface Props { message: ChatMessage, isMine: boolean}

const PlenaryChatMessage = (props: Props) => {
  useEffect(() => {}, []);

  return (
    <>
      <chakra.div
        className={`message ${
          props.message.isFromSpeaker ? " mf-message" : props.isMine ? " my-message" : ""
        }`}
      >
        <Text className="user">
          {props.message.isFromSpeaker && (
            <span className="speaker">
              <img src={logoChat} alt="Massey Ferguson" /> MASSEY FERGUSON -{" "}
            </span>
          )}

          {props.message?.aux1}
        </Text>
        <Text className="time">{chatTimeString(props.message.utcCreationDate)}</Text>
        {props.message?.originalMessage && (
          <chakra.div className="replied-message">
            <Text className="user">{props.message.originalMessage.aux1}</Text>
            <Text className="time">{chatTimeString(props.message.originalMessage.utcCreationDate)}</Text>
            {props.message.originalMessage.message}
          </chakra.div>
        )}
        <Text className="text">{props.message?.message}</Text>
      </chakra.div>
    </>
  );
};

export default PlenaryChatMessage;
