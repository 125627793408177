import React, { FC, useState } from "react";
import { useForm } from "react-hook-form";
import axiosHelper from "./../../helpers/axiosHelper";
import { useHistory } from "react-router-dom";
import { ErrorMessage } from "@hookform/error-message";
import { useTranslation } from "react-i18next";
import {
  chakra,
  Checkbox,
  Flex,
  Modal,
  ModalBody,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Text,
} from "@chakra-ui/react";

import LoginContent from "./LoginContent";

type Props = {
  clickOpen?: boolean;
  clickClose?: () => void;
};

const LogInModal: FC<Props> = ({
  clickOpen = false,
  clickClose = () => {},
}) => {
  const { t } = useTranslation();
  return (
    <Modal isOpen={clickOpen} onClose={clickClose} size="xl">
      <ModalOverlay backgroundColor="rgba(0, 0, 0, 0.8)" />
      <ModalContent
        borderRadius="0px"
        bgColor="white"
        boxShadow="0px 5px 15px rgba(225,225,225,0.15)"
      >
        <ModalHeader>
          <Flex justifyContent="space-between" alignItems="center">
            <Text as="h1" textStyle="h1" color="red.500">
              {t("login.title")}
            </Text>
          </Flex>
          <Text
            style={{
              font: "normal normal normal 16px/24px Arial",
              letterSpacing: "0px",
              color: "#000000",
              opacity: 1,
            }}
            mt="5px"
          >
            {t("login.instructions")}
          </Text>
        </ModalHeader>
        {/* <ModalCloseButton /> */}
        <ModalBody>
          <LoginContent />
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default LogInModal;
