const reducer: (state: AppState, action: Action) => AppState = (
  state: AppState,
  action: Action
) => {
  switch (action.type) {
    case "login_request":
      return { ...state, isLoading: true };
    case "login_success":
      return { ...state, isLoading: false, isLoggedIn: true };
    case "reset":
      return init();
    default:
      throw new Error();
  }
};
const init: () => AppState = () => {
  return { isLoggedIn: false, isLoading: false };
};

export default reducer;
