import React, { useState, useEffect } from "react";
import axiosHelper from "../helpers/axiosHelper";
import { sendMessage } from "../helpers/lsHelper";
import closeIcon from "../img/close-button.svg"

interface Props {
  questionId: string;
  modal: any;
  callback: any
}

const PollOverlay = (props: Props) => {
  const [question, setQuestion] = useState<Question>();
  const [loading, setLoading] = useState<Boolean>();
  useEffect(() => {
    setQuestion(undefined);
    // fetch dei dati basandosi su props.questionId
    const fetchData = async () => {
      setLoading(true);
      const question = await axiosHelper.getQuestion(props.questionId, 'it');
      // console.log(question);
      setQuestion(question.data);
      setLoading(false);
    };
    fetchData();
  }, [props]);
  return (
    loading ? null :
      <div className="poll-overlay-container">
        <div className="poll-overlay-content">
          <div className="container poll-container">
            <div className="box col-12 poll-question">
              <div className="poll-close-button">
                <a
                  onClick={() => {
                    // console.log("clicked");
                    sendMessage(`${props.questionId}/0/0`);
                    axiosHelper.sendVote(0,0);
                    props.callback();
                    props.modal.close();
                  }}
                >
                  <img src={closeIcon} alt="Close" />
                </a>
              </div>

              <div className="text-wrapper" dangerouslySetInnerHTML={{ __html: question?.t ?? "" }}></div>
            </div>
            {question?.o.map((value, index) => (
              <div
                key={value.r}
                className="box col-6 poll-answer"
                onClick={() => {
                  // console.log(`chosen id: ${value.r}`);
                  sendMessage(`${props.questionId}/${value.p}/${value.r}`);
                  axiosHelper.sendVote(value.p,value.r);
                  props.callback();
                  props.modal.close();
                }}
              >
                <a className="text-wrapper" dangerouslySetInnerHTML={{ __html: value.t }}></a>
              </div>
            ))}
          </div>
        </div>
      </div>
  );
};

export default PollOverlay;
