import React, { FC, useState, useRef, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Flex, Text, Button, useBreakpointValue } from "@chakra-ui/react";
import Timer from "./Timer";
import Clock from "../home/counter/Clock";
import axiosHelper from "../../helpers/axiosHelper";

import grayMark from "../../assets/question/graymark.svg";
import greenMark from "../../assets/question/greenmark.svg";
import redCross from "../../assets/question/redcross.svg";
import { resultsAriaMessage } from "react-select/src/accessibility";

type Props = {
  question: Question;
  doubleCount: number;
  quizQuestion: QuizQuestion;
  closeCallback: (didUseDouble: boolean, didWin: boolean, didLoose: boolean, didTimeOut: boolean) => void;
  quizIndex: number;
};

const PlenaryQuiz = (props: Props) => {
  let { question, doubleCount, quizQuestion, closeCallback, quizIndex } = props;
  const isMobile = useBreakpointValue({ base: true, lg: false });
  const { t } = useTranslation();
  const [selectedIndex, setSelectedIndex] = useState<number>(-1);
  const [correctIndex, setCorrectIndex] = useState<number>(-1);
  const [wrongIndex, setWrongIndex] = useState<number>(-1);

  // const [doubleSelected, setDoubleSelected] = useState(false);
  const [canStillAnswer, setCanStillAnswer] = useState(true);
  const [didTimeOut, setDidTimeOut] = useState(false);
  const [didWin, setDidWin] = useState(false);
  const [didLose, setDidLose] = useState(false);

  const [countdown, setCountdown] = useState(question.d);
  const [maxCountdown, setMaxCountdown] = useState(question.d);
  const interval = useRef<any>(null);
  const timerHalted = useRef(false);
  const counter = useRef(question.d * 10);
  const doubleSelected = useRef(false);

  useEffect(() => {
    // console.log('USE EFFECT DI PLENARY QUIZ ' + countdown + ' ' + counter.current);
    startTimer();
    return () => {
      if (!timerHalted.current) {
        closeCallback(false, false, false, false);
        timerHalted.current = true;
      }
    };
  }, [])


  const startTimer = () => {
    if (interval.current != null) {
      clearInterval(interval.current);
    }
    interval.current = setInterval(() => {
      if (!timerHalted.current) {
        const oldCount = counter.current;
        counter.current = oldCount - 1;
        if (counter.current === 0) {
          if (canStillAnswer) {
            verifyAnswer(-1, false);
          }
          // setShowTimeOutLabel(true);
          stopTimer();
        }
        setCountdown(counter.current / 10);
      }
    }, 100);
  }

  const stopTimer = () => {
    if (interval.current != null) {
      clearInterval(interval.current);
    }
    setCanStillAnswer(false);
    setTimeout(() => { closeCallback(doubleSelected.current, didWin, didLose, didTimeOut) }, 3000);
  }
  const sendData = async (quid: string, answId: number, time: number, index: number, isDbl: boolean) => {
    if (question != null) {
      const reply = await axiosHelper.sendAnswer(quid, answId, time, isDbl);
      if (reply != null) {
        // console.log(reply);
        if (reply.data.wasCorrect) {
          setCorrectIndex(index);
          setDidWin(true);
        } else {
          setDidLose(true);
          setWrongIndex(index);
          let winidx = -1;
          for (let ans of question.o) {
            winidx++;
            // console.log(ans.r, reply.data.correctAnswer, winidx);
            if (ans.r === reply.data.correctAnswer) {
              setTimeout(() => {
                setCorrectIndex(winidx);
              }, 1000);
              break;
            }
          }
        }
      }
    } else {
      console.log('QUESTION WAS NULL');
    }
  };

  function verifyAnswer(index: number, wantDouble: boolean) {
    const time = counter.current;
    timerHalted.current = true;
    if (interval.current != null) {
      clearInterval(interval.current);
    } else {
      // console.log('interval was null');
    }
    stopTimer();
    if (index >= 0) {
      const answer = question.o[index];
      sendData(quizQuestion.id, answer.r, time, index, wantDouble);
    } else {
      setDidTimeOut(true);
      sendData(quizQuestion.id, 0, time, index, wantDouble);
    }
  };


  const selectAnswer = (index: number, answer: Option) => {
    setSelectedIndex(index);
    // setTimeout(() => {
    //   setCorrectIndex(1);
    //   setWrongIndex(2);
    // }, 1000)
  }

  const skipPressed = () => {
    setCanStillAnswer(false);
    timerHalted.current = true;
    closeCallback(false, false, false, false);
  }

  const doublePressed = () => {
    if (selectedIndex >= 0) {
      doubleSelected.current = true;
      verifyAnswer(selectedIndex, true);
    }
  }

  const votePressed = () => {
    if (selectedIndex >= 0) {
      doubleSelected.current = false;
      verifyAnswer(selectedIndex, false);
    }
  }

  const classForIndex = (index: number) => {
    let result = "answer";
    if (index === selectedIndex) {
      result += " selected"
    }
    if (index === correctIndex) {
      result = "answer correct"
    }
    if (index === wrongIndex) {
      result = "answer wrong"
    }
    return result;
  }

  const indicatorForAnswer = (index: number) => {
    let result = <span>{index + 1}</span>
    if (index === selectedIndex) {
      result = <img src={grayMark} alt="selected" />
    }
    if (index === correctIndex) {
      result = <img src={greenMark} alt="correct" />
    }
    if (index === wrongIndex) {
      result = <img src={redCross} alt="wrong" />
    }
    return result;
  }
  return (
    <>
      <Flex
        maxW="container.xl"
        d="flex"
        flexFlow="column nowrap"
        justifyContent="center"
        alignItems="stretch"
        width={isMobile ? "100%" : "auto"}
        m={isMobile ? "0" : "1rem 2rem 2rem 2rem"}
        p="1rem"
        bgColor="rgba(255, 255, 255, 0.1)"
        boxShadow="0px 3px 6px #00000029"
        color="white"
      >
        <Flex
          d="flex"
          flexFlow="row nowrap"
          justifyContent="space-between"
          alignItems="stretch"
        >
          <Flex flexGrow={1} d="flex" flexFlow="column nowrap">
            <Flex justifyContent="space-between">
              <Text fontWeight="bold" fontSize={isMobile ? "35px" : "25px"}>
                {t('question.name')} {quizIndex + 1}
              </Text>
              {isMobile && (
                <Text fontWeight="bold" fontSize={isMobile ? "35px" : "25px"}>
                  {Math.floor(countdown)}''
                </Text>
              )}
            </Flex>
            <Text
              fontFamily={
                isMobile
                  ? "Arial-BoldMT, Arial"
                  : "Arial Black, Arial-BoldMT, Arial"
              }
              fontSize={isMobile ? "14px" : "21px"}
              lineHeight={isMobile ? "18px" : "25px"}
              color="red.500"
              pr={isMobile ? "0" : "2rem"}
              flexGrow={1}
              alignItems="center"
              d="flex"
            >
              {question.t}
            </Text>
          </Flex>
          {!isMobile && (
            <Flex>
              <Timer digit={countdown} maxNum={maxCountdown} id={"cds"} width="100px" />
            </Flex>
          )}
        </Flex>
        <Flex
          flexDirection="row"
          justifyContent="space-between"
          flexWrap="wrap"
          mt="1rem"
        >
          {question.o.map((answ, index) => (
            <Flex key={index} className={classForIndex(index)} flexBasis={isMobile ? "100%" : "49%"} onClick={() => selectAnswer(index, answ)}>
              <Text className="answer-index">
                {indicatorForAnswer(index)}
              </Text>
              <Text className="answer-text" fontSize={isMobile ? "14px" : "16px"}>
                {answ.t}
              </Text>
            </Flex>
          ))}


        </Flex>
        {/*  TODO x WOLF  LE PROSSIME 2 RIGHE SONO OPZIONALI: NE VEDI SOLO UNA DELLE 2 */}
        {canStillAnswer ?
          <Flex
            d="flex"
            flexFlow={isMobile ? "column-reverse" : "row"}
            justifyContent="space-between"
          >
            <Flex
              d="flex"
              justifyContent={isMobile ? "space-between" : "flex-start"}
              mt={isMobile ? "1rem" : "0"}
            >
              <Button onClick={skipPressed} className="question-button-skip" alignSelf="flex-start" flexGrow={isMobile ? 1 : 0}>
                {t("question.skip")}
              </Button>
            </Flex>
            <Flex
              d="flex"
              justifyContent={isMobile ? "space-between" : "flex-end"}
            >
              {doubleCount > 0 &&
                <Button className={doubleSelected ? "question-button-double double-selected" : "question-button-double"} onClick={doublePressed}>
                  <span className="double-text">{t("question.double")}</span>{" "}
                  <span className="double-count">{doubleCount}</span>
                </Button>
              }
              <Button className="question-button-select" onClick={votePressed}>
                {t("question.select")}
              </Button>
            </Flex>
          </Flex>
          :
          <Flex d="flex" alignItems="stretch" alignContent="stretch">
            {didTimeOut ? (
              <Text
                color="white"
                fontSize={isMobile ? "20" : "30"}
                fontWeight="bold"
                flexGrow={1}
                textAlign="center"
              >
                {t("plenary.countdown.timeout")}
              </Text>
            ) : (
              <>
                {didLose && (
                  <Text
                    color="#c41230"
                    fontSize={isMobile ? "20" : "30"}
                    fontWeight="bold"
                    flexGrow={1}
                    textAlign="center"
                  >
                    {t("question.wronganswer")}
                  </Text>
                )}
                {didWin && (
                  <Text
                    color="#10954a"
                    fontSize={isMobile ? "20" : "30"}
                    fontWeight="bold"
                    flexGrow={1}
                    textAlign="center"
                  >
                    {t("question.correctanswer")}
                  </Text>
                )}
              </>
            )}
          </Flex>
        }
        {/*  TODO x WOLF FINE */}
      </Flex>
    </>
  );
};

export default PlenaryQuiz;
