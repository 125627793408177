import React, { FC } from "react";
import { useHistory } from "react-router-dom";
import {
  chakra,
  useBreakpointValue,
  useMultiStyleConfig,
  Text,
  Image,
  useDisclosure,
} from "@chakra-ui/react";

export type Props = {
  title?: string;
  subtitle?: string;
  label?: string;
  image?: any;
};

const PrizesCard: FC<Props> = (
  { subtitle, title, label, image }: Props,
  ...props
) => {
  const styles = useMultiStyleConfig("Hall", props);
  const history = useHistory();

  const isMobile = useBreakpointValue({ base: true, lg: false });

  return (
    <chakra.div p="10px">
      <Image
        src={image}
        sx={styles.prizesCardImg}
        // mx="auto"
        onClick={() => history.push("/prizes")}
      />

      {title && (
        <Text
          sx={styles.prizesCardTitle}
          onClick={() => history.push("/prizes")}
        >
          {title}
        </Text>
      )}
      {subtitle && (
        <Text
          sx={styles.prizesCardSubtitle}
          onClick={() => history.push("/prizes")}
        >
          {subtitle}
        </Text>
      )}
      {label && (
        <Text
          sx={styles.prizesCardLabel}
          onClick={() => history.push("/prizes")}
        >
          {label}
        </Text>
      )}
    </chakra.div>
  );
};

export default PrizesCard;
