import React, { useState, useEffect } from "react";
import { Switch, Route, withRouter } from "react-router-dom";

import axiosHelper from "./helpers/axiosHelper";

import Activate from "./screens/Activate";
import Error404Page from "./screens/Error404Page";
import Logout from "./screens/Logout";
import ThankYou from "./screens/ThankYou";
import Home from "./screens/Home";
import WaitingRoom from "./screens/WaitingRoom";
import Tutorial from "./screens/Tutorial";
import PrizesPage from "./screens/PrizesPage";
import Cluster from "./screens/Cluster";
import ComingSoon from "./screens/ComingSoon";
import ChangePassword from "./components/modal/ChangePasswordModal";
import TermsAndConditions from "./screens/TermsAndConditions";
import Plenary from "./screens/Plenary";
import PlenaryCluster from "./screens/PlenaryCluster";
import RedirectThanks from './screens/RedirectThanks';

const Container = ({ location }: any) => {
  const [isLoggedIn, setIsLoggedIn] = useState<boolean | null>(null);
  // console.log("isLoggedInisLoggedInisLoggedIn:", isLoggedIn);

  useEffect(() => {
    // console.log('USE EFFECT DI CONTAINER');
    const retrieveUserProfile = async () => {
      try {
        const result = await axiosHelper.loginWithToken<UserProfile>();
        if (result.data !== null) {
          axiosHelper.saveUserProfile(result.data);
          //   console.log("SETTO LOGGED IN");
          setIsLoggedIn(true);
        } else {
          // console.log('LOGN ERR ');
          setIsLoggedIn(false);
        }
      } catch (e) {
        console.error(e);
        setIsLoggedIn(false);
      }
    };
    if (axiosHelper.isLoggedIn) {
      // console.log('LOGGATO IN');
      setIsLoggedIn(true);
    } else if (axiosHelper.hasToken) {
      // console.log('HA IL TOKEN RETRIEVO');
      retrieveUserProfile();
    } else {
      // console.log('NON HO IL TOKEN NE SONO LOGGATO');
      setIsLoggedIn(false);
    }
    return () => {};
  });

  if (isLoggedIn == null) {
    return <div />;
  } else {
    return (
      <Switch>
        <Route exact path="/activate/:token" component={Activate} />
        <Route exact path="/resetpass/:token" component={Activate} />
        {/*FIXME Login should go to Home but with login modal opened */}
        <Route exact path="/prizes" component={PrizesPage} />
        <Route exact path="/login" component={Home} />
        <Route exact path="/comingsoon" component={ComingSoon} />
        <Route exact path="/termsandconditions" component={TermsAndConditions}/>
        <Route exact path="/ko" component={Home} />
        {isLoggedIn ? (
          <>
            <Route exact path="/" component={Plenary} />
            <Route exact path="/tutorial" component={Tutorial} />
            <Route exact path="/thankyou" component={ThankYou} />
            <Route exact path="/logout" component={Logout} />
            <Route exact path="/cluster" component={Cluster} />
            <Route exact path="/welcome" component={Tutorial} />
            <Route exact path="/changepwd" component={ChangePassword}/>
            <Route exact path="/plenary123" component={Plenary}/>
            <Route exact path="/plenaryCluster" component={PlenaryCluster}/>
            <Route exact path="/redirectThanks" component={RedirectThanks}/>
          </>
        ) : (
          <>
            <Route exact path="/" component={Home} />
            <Route exact path="/thankyou" component={ThankYou} />
          </>
        )}
        <Route path="/*" component={Error404Page} />
      </Switch>
    );
  }
};

export default withRouter(Container);
