import { useCallback, useEffect, useState } from 'react'

import DailyIframe, {
  DailyCall,
  DailyEvent,
  DailyParticipant,
} from '@daily-co/daily-js'

const STATE_IDLE = 'STATE_IDLE'
const STATE_JOINING = 'STATE_JOINING'
const STATE_LEAVING = 'STATE_LEAVING'
const STATE_ERROR = 'STATE_ERROR'
// const STATE_CREATING = 'STATE_CREATING'
// const STATE_JOINED = 'STATE_JOINED'


const events: DailyEvent[] = [
  'participant-joined',
  'participant-updated',
  'participant-left',
]

export const useCall = (username: string) => {
  const [callState, setAppState] = useState(STATE_IDLE)
  const [callObject, setCallObject] = useState<null | DailyCall>(null)
  const [localUser, setLocalUser] = useState<null | DailyParticipant>(null)
  const [remoteUser, setRemoteUser] = useState<null | DailyParticipant>(null)
  const [devices, setDevices] = useState<MediaDeviceInfo[]>([])
  const [selectedAudio, setSelectedAudio] = useState<null | MediaDeviceInfo>(null,)
  const [selectedVideo, setSelectedVideo] = useState<null | MediaDeviceInfo>(null,)
  const [isVideo, setIsVideo] = useState(true)
  const [isLive, setIsLive] = useState(false);

  /**
   * Starts joining an existing call.
   *
   * NOTE: In this demo we show how to completely clean up a call with destroy(),
   * which requires creating a new call object before you can join() again.
   * This isn't strictly necessary, but is good practice when you know you'll
   * be done with the call object for a while and you're no longer listening to its
   * events.
   */
  const startJoiningCall = useCallback(

    (url: string) => {
      // console.log('startJoiningCall ' + url);
      // console.log('startJoiningCall ' + useCall);
      async function start() {
        await newCallObject.join({ url })

        await newCallObject.setUserName(username)
      }

      const newCallObject = DailyIframe.createCallObject()
      setCallObject(newCallObject)
      setAppState(STATE_JOINING)

      start()
    },
    [username],
  )

  /**
   * Starts leaving the current call.
   */
  const startLeavingCall = useCallback(() => {
    if (!callObject) return
    // If we're in the error state, we've already "left", so just clean up
    if (callState === STATE_ERROR) {
      callObject.destroy().then(() => {
        setCallObject(null)
        setAppState(STATE_IDLE)
        setLocalUser(null)
      })
    } else {
      setAppState(STATE_LEAVING)
      setLocalUser(null)
      callObject.leave()
    }
  }, [callObject, callState])

  useEffect(() => {
    if (!callObject) {
      return
    }

    function handleNewParticipantsState(event?: DailyEvent) {
      const participants = callObject!.participants()

      let liveUsers = Object.values(participants).filter((user) => !user.local).filter((user => user.user_name === 'LIVE'));
      if (liveUsers.length > 0) {
        setRemoteUser(liveUsers[0]);
        //setIsLive(true);
      } else {
        //setIsLive(false);
        // setRemoteUser(Object.values(participants).filter((user) => !user.local)[0]);
      }
      setLocalUser(participants.local)
    }

    handleNewParticipantsState()

    for (const event of events) {
      callObject.on(event, handleNewParticipantsState)
    }

    return function cleanup() {
      setLocalUser(null)

      for (const event of events) {
        callObject.off(event, handleNewParticipantsState)
      }

      callObject.leave().then(() => {
        callObject.destroy()
      })
    }
  }, [callObject])

  useEffect(() => {
    async function retrieve() {
      try {
        const devicesResult = await navigator.mediaDevices.enumerateDevices()
        setDevices(devicesResult)

        const defaultAudio = devicesResult
          .filter((device) => device.kind === 'audioinput')
          .filter((device) => device.deviceId === 'default')[0]
        if (defaultAudio) {
          setSelectedAudio(defaultAudio)
        }

        const defaultVideo = devicesResult
          .filter((device) => device.kind === 'videoinput')
          .filter((device) => device.deviceId === 'default')[0]
        if (defaultVideo) {
          setSelectedVideo(defaultVideo)
        }
      } catch (err) {
        console.error(`Can not retrieve media devices `)
        setAppState(STATE_ERROR)

        return
      }
    }

    retrieve()
  }, [callObject])

  useEffect(() => {
    callObject?.setInputDevices({
      audioDeviceId: selectedAudio?.deviceId,
      videoDeviceId: selectedVideo?.deviceId,
    })
  }, [callObject, selectedAudio?.deviceId, selectedVideo?.deviceId])

  /**
   * Only enable the start button if we're in an idle state (i.e. not creating,
   * joining, etc.).
   *
   * !!!
   * IMPORTANT: only one call object is meant to be used at a time. Creating a
   * new call object with DailyIframe.createCallObject() *before* your previous
   * callObject.destroy() completely finishes can result in unexpected behavior.
   * Disabling the start button until then avoids that scenario.
   * !!!
   */
  const enableStartButton =
    callState === STATE_IDLE || callState === STATE_LEAVING

  useEffect(() => {
    callObject?.setLocalVideo(isVideo)
  }, [callObject, isVideo])

  return {
    enableStartButton,
    startJoiningCall,
    startLeavingCall,
    callState,
    localUser,
    remoteUser,
    devices,
    selectedAudio,
    setSelectedAudio,
    selectedVideo,
    setSelectedVideo,

    isVideo,
    setIsVideo,
  }
}
