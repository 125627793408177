import { fas } from "@fortawesome/free-solid-svg-icons";
import axios from "axios";
import { isThisTypeNode } from "typescript";
import Constants from "./constants";
import { isOlderThanSeconds } from './utils';
import { generateUniqueID } from "./utils";

const JNT_TOKEN = "jntToken";
const UNIQUE_ID = "uniqueID";
const UTM_DATA = "utmData";

interface WebAppLog {
  pageName: string,
  idLibraryContent: number,
  event: string
}

class AxiosHelper {
  isLoggedIn = false;
  hasToken = false;
  hasAttendeesCache = false;
  attendeesCache: Attendee[] = [];
  attendeesCacheDate = new Date();
  hasEventStatusCache = false;
  eventStatusCache: EventStatus | null = null;
  eventStatusCacheDate = new Date();
  // hasRoomsCache = false;
  // roomsCache: ChatRoom[] = [];
  // roomsCacheDate = new Date();
  duringEvent = false;

  constructor() {
    this.getUniqueID();
    let token = localStorage.getItem(JNT_TOKEN);
    if (token !== null) {
      this.setJntToken(token, true);
      // this.retrieveUserProfile();
      // this.isLoggedIn = true;
    }
  }
  saveUtmData(data: UtmData) {
    localStorage.setItem(UTM_DATA, JSON.stringify(data));
  }
  getUtmData<UtmData>(): UtmData | null {
    let result = localStorage.getItem(UTM_DATA);
    if (result !== null) {
      return JSON.parse(result) as UtmData;
    }
    return null;
  }

  login<UserProfile>(username: string, password: string) {
    localStorage.removeItem(JNT_TOKEN);
    var bodyFormData = new FormData();
    bodyFormData.append('e', username);
    bodyFormData.append('p', password);
    return axios.post<UserProfile>(`${Constants.API_ENDPOINT}/Attendee/login`, bodyFormData)
      .then((response) => {
        return response;
      })
      .catch((error) => {
        throw error;
      });
  }

  changePassword<UserProfile>(oldPassword: string, newPassword: string) {
    var bodyFormData = new FormData();
    bodyFormData.append('email', this.profileCache!.email);
    bodyFormData.append('password', oldPassword);
    bodyFormData.append('newPassword', newPassword);
    return axios.post<UserProfile>(`${Constants.API_ENDPOINT}/Attendee/changePassword`, bodyFormData)
      .then((response) => {
        return response;
      })
      .catch((error) => {
        throw error;
      });
  }

  requestSignature<RequestSignatureResult>(fileName: string, mimeType: string, type: string) {
    var bodyFormData = new FormData();
    bodyFormData.append("fileName", fileName);
    bodyFormData.append("mimeType", mimeType);
    bodyFormData.append("type", type);
    return axios.post<RequestSignatureResult>(`${Constants.API_ENDPOINT}/Attendee/requestSignature`, bodyFormData)
      .then((response) => {
        return response;
      })
      .catch((error) => {
        throw error;
      });
  }

  setUploadSuccessful(uploadId: string) {
    const finishAndUpdateProfile = async (bodyFormData: FormData) => {
      let res = await axios.post<UserProfile>(`${Constants.API_ENDPOINT}/Attendee/uploadFinished`, bodyFormData);
      // console.log('occhio a res');
      // console.log(res);
      this.saveUserProfile(res.data);
    }
    var bodyFormData = new FormData();
    bodyFormData.append('uploadId', uploadId);
    finishAndUpdateProfile(bodyFormData);
  }

  logout() {
    return axios(`${Constants.API_ENDPOINT}/Attendee/logout`);
  }

  activate(token: string) {
    return axios(`${Constants.API_ENDPOINT}/Attendee/activate?tmpToken=${token}`);
  }

  reset(token: string) {
    return axios(`${Constants.API_ENDPOINT}/Attendee/reset?tmpToken=${token}`);
  }

  resetPassword(email: string) {
    return axios(`${Constants.API_ENDPOINT}/Attendee/resetPassword?email=${email}`);
  }

  checkAccount<UserProfile>() {
    return axios.get<UserProfile>(`${Constants.API_ENDPOINT}/Attendee/check`);
  }

  setTutorialSeen() {
    if (this.profileCache != null) {
      var profile = this.profileCache;
      profile.firstLogin = false;
      this.updateAccount(profile);
      this.saveUserProfile(profile);
    }
  }

  setCluster(type: string) {
    const retrieveUserProfile = async () => {
      try {
        if (this.profileCache != null) {
          var profile: UserProfile = this.profileCache;
          this.profileCache.field2=false;
          localStorage.setItem(JNT_TOKEN, profile.jntToken);
          if (
            profile.userType === 'R'
            || profile.userType === 'S'
            || profile.userType === 'I'
          ) {
            profile.userType = type;
          } else {
            profile.cluster = type;
          }
          const result = await this.updateAccount(profile);
          if (result.data !== null) {
            this.saveUserProfile(result.data);
          } else {
            console.error('USET CLUSTER ERROR');
          }
        }
      } catch (e) {
        console.error(e);
      }
    }
    retrieveUserProfile();
  }

  loginWithToken<UserProfile>() {
    if (axios.defaults.headers.common["jntToken"]) {
      return axios.get<UserProfile>(`${Constants.API_ENDPOINT}/Attendee/tokLogin`)
        .then((response) => {
          return response;
        })
        .catch((error) => {
          if (error.response.status === 401) {
            this.clearUserProfile();
          }
          throw error;
        });
    } else {
      throw "no token";
    }
  }

  getRank() {
    return axios.get<Rank>(`${Constants.API_ENDPOINT}/Attendee/rank`)
      .then(response => {
        return response.data;
      })
      .catch((err) => {
        throw (err);
      })
  }

  getQuiz() {
    return axios.get<Quiz>(`${Constants.API_ENDPOINT}/Quiz`)
      .then(response => {
        return response.data;
      })
      .catch((err) => {
        throw (err);
      })

  }

  sendAnswer(qqUuid: string, answer: number, responseTime: number, isDbl = false) {
    return axios.put<QuizQuestionResponse>(`${Constants.API_ENDPOINT}/QuizQuestion/${qqUuid}`,
      {
        answer: answer,
        responseTime: responseTime,
        isDbl: isDbl
      }
    ).then((response) => {
      return response;
    })
      .catch((error) => {
        throw error;
      });
    ;
  }

  sendImage(id: number, img: string, style: string) {
    const dataURLtoFile = (dataurl: string, filename: string) => {
      var arr = dataurl.split(','),
        mime = arr[0].match(/:(.*?);/)![1],
        bstr = atob(arr[1]),
        n = bstr.length,
        u8arr = new Uint8Array(n);
      while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
      }
      return new File([u8arr], filename, { type: mime });
    };

    var bodyFormData = new FormData();
    bodyFormData.append('file', dataURLtoFile(img, style + '.jpg'));
    return axios.put<UserAvatar>(
      `${Constants.API_ENDPOINT}/Attendee/${id}/image`,
      bodyFormData
    )
  }

  createOneToOne() {
    return axios.post<OneToOneRequest>(
      `${Constants.API_ENDPOINT}/OneToOne`,
      {
        objecttype: 'OneToOne'
      }
    );
  }

  cancelOneToOne(id: number) {
    return axios.put<OneToOneRequest>(
      `${Constants.API_ENDPOINT}/OneToOne/${id}`,
      {
        state: 'C'
      }
    );
  }

  getOneToOne(id: number) {
    return axios.get<OneToOneRequest>(
      `${Constants.API_ENDPOINT}/OneToOne/${id}`
    );
  }

  createRaiseHand() {
    return axios.post<RaiseHand>(
      `${Constants.API_ENDPOINT}/RaiseHand`,
      {
        objecttype: 'RaiseHand'
      }
    );
  }


  cancelRaiseHand(id: number) {
    return axios.put<RaiseHand>(
      `${Constants.API_ENDPOINT}/RaiseHand/${id}`,
      {
        state: 'C'
      }
    );
  }

  getRaiseHand(id: number) {
    return axios.get<RaiseHand>(
      `${Constants.API_ENDPOINT}/RaiseHand/${id}`
    );
  }

  sendQuestion(msg: string) {
    return axios.post(
      `${Constants.API_ENDPOINT}/Feedback`,
      {
        feedbackText: msg,
      }
    );
  }

  getAttendees() {
    if (this.hasAttendeesCache && !isOlderThanSeconds(this.attendeesCacheDate, 30)) {
      // console.log('getAttendees hit');
      return Promise.resolve(this.attendeesCache);
    } else {
      // console.log('getAttendees miss');
      return axios.get<Attendee[]>(`${Constants.API_ENDPOINT}/Attendee`)
        .then(response => {
          this.attendeesCacheDate = new Date();
          this.attendeesCache = response.data;
          this.hasAttendeesCache = true;
          return response.data;
        })
        .catch((err) => {
          throw (err);
        })
    }
  }
  getEventStatus() {
    // console.log('##############getEventStatus');
    if (this.hasEventStatusCache && !isOlderThanSeconds(this.eventStatusCacheDate, 10)) {
      // console.log('################# torno cache');
      return Promise.resolve(this.eventStatusCache);
    } else {
      // console.log('################# torno acchiappo');
      return axios.get<EventStatusResult>(`${Constants.API_ENDPOINT}/Attendee/eventStatus`)
        .then(response => {
          this.eventStatusCacheDate = new Date();
          this.eventStatusCache = response.data.eventStatus;
          this.hasEventStatusCache = true;
          return response.data.eventStatus;
        })
        .catch((err) => {
          throw (err);
        })
    }
  }

  getChatRooms() {
    // console.log('getChatrooms');
    return axios.get<ChatRoom[]>(`${Constants.API_ENDPOINT}/ChatRoom`)
      .then(response => {
        return response.data;
      })
      .catch((err) => {
        throw (err);
      })
  }

  getChatMessages(chatroomId: number, lastId: number = 0) {
    // console.log('getChatroomMessages ' + chatroomId);
    return axios.get<ChatMessage[]>(`${Constants.API_ENDPOINT}/ChatRoom/${chatroomId}?qualifier=id%3E${lastId}`)
      .then(response => {
        return response.data;
      })
      .catch((err) => {
        throw (err);
      })
  }


  getRedflixCategories() {
    return axios.get<LibraryCategory[]>(`${Constants.API_ENDPOINT}/LibraryCategory`)
      .then(response => {
        return response.data;
      })
      .catch((err) => {
        throw (err);
      })

    // return require('./21b95eb5-543f-48a0-9cdf-ea3f90a21c78.json');
  }

  logWebAppEvent(pageName: string | null, idLibraryContent: number | null, event: string | null) {
    return axios.post(`${Constants.API_ENDPOINT}/WebAppLog`, { pageName, idLibraryContent, event });
  }

  sendVote(p: number, r: number) {
    return axios.post(`${Constants.API_ENDPOINT}/WebAppVote`, { voteP: p, voteR: r })
  }

  sendAppointmentRequest() {
    return axios.post(`${Constants.API_ENDPOINT}/AppointmentRequest`, {});
  }

  sendMessage(message: string, recipientId: number | null) {
    return axios.post<ChatMessage>(
      `${Constants.API_ENDPOINT}/ChatMessage`,
      {
        message: message,
        idrecipient: recipientId
      }
    )
      .then((response) => {
        // if (response.data.chatroomId === 0) {
        //   // console.log('ATTENZIONE!!! RICARICARE LE CHATROOM SVUOTO LA CACHE');
        //   this.hasRoomsCache = false;
        // }
        return response;
      })
      .catch((err) => {
        throw err;
      });
  }

  sendMessageToRoom(message: string, roomId: number) {
    return axios.post<ChatMessage>(
      `${Constants.API_ENDPOINT}/ChatMessage`,
      {
        message: message,
        chatRoom: { id: roomId }
      }
    )
      .then((response) => {
        // if (response.data.chatroomId === 0) {
        //   // console.log('ATTENZIONE!!! RICARICARE LE CHATROOM SVUOTO LA CACHE');
        //   this.hasRoomsCache = false;
        // }
        return response;
      })
      .catch((err) => {
        throw err;
      });
  }

  savePassword<UserProfile>(
    id: number,
    password: string,
    token: string
  ) {
    return axios.put<UserProfile>(
      `${Constants.API_ENDPOINT}/Attendee/${id}`,
      {
        cleartextPassword: password,
      },
      {
        headers: { tmpToken: token },
      }
    );
  }

  updateAccount(profile: UserProfile) {
    return axios.put<UserProfile>(
      `${Constants.API_ENDPOINT}/Attendee/${profile.id}`,
      profile
    );
  }

  changeLanguage(lang: string) {
    const retrieveUserProfile = async () => {
      try {
        if (this.profileCache != null) {
          var pippo: UserProfile = this.profileCache;
          localStorage.setItem(JNT_TOKEN, pippo.jntToken);
          pippo.language = lang;
          const result = await this.updateAccount(pippo);
          if (result.data !== null) {
            this.saveUserProfile(result.data);
            // console.log('CORRETTO');
          } else {
            console.error('changeLanguage ERR ERR ');
          }
        }
      } catch (e) {
        console.log(e);
      }
    }
    retrieveUserProfile();
  }

  switchToAziende() {
    const retrieveUserProfile = async () => {
      try {
        if (this.profileCache != null) {
          var pippo: UserProfile = this.profileCache;
          localStorage.setItem(JNT_TOKEN, pippo.jntToken);
          pippo.userType = 'Q';
          const result = await this.updateAccount(pippo);
          if (result.data !== null) {
            this.saveUserProfile(result.data);
            // console.log('CORRETTO');
            window.location.reload();

            // setIsLoggedIn(true);
          } else {
            // console.log('UPDATE ERR ERR ');
            // setIsLoggedIn(false);
          }
        }
      } catch (e) {
        console.log(e);
      }
    }
    retrieveUserProfile();
  }

  switchToProfessionisti() {
    const retrieveUserProfile = async () => {
      try {
        if (this.profileCache != null) {
          var pippo: UserProfile = this.profileCache;
          localStorage.setItem(JNT_TOKEN, pippo.jntToken);
          pippo.userType = 'R';
          const result = await this.updateAccount(pippo);
          if (result.data !== null) {
            this.saveUserProfile(result.data);
            // console.log('CORRETTO');
            window.location.reload();
            // setIsLoggedIn(true);
          } else {
            // console.log('UPDATE ERR ERR ');
            // setIsLoggedIn(false);
          }
        }
      } catch (e) {
        console.log(e);
      }
    }
    retrieveUserProfile();
  }

  setJntToken(token: string, permanently: boolean) {
    this.hasToken = true;
    axios.defaults.headers.common["jntToken"] = token;
    if (permanently) {
      localStorage.setItem(JNT_TOKEN, token);
    }
  }
  setClientId(id: string) {
    axios.defaults.headers.common["clientId"] = id;
  }

  getQuestion(id: string, lang: string) {
    //  return require('./'+id+'-'+lang+'.json');
    return axios.get<Question>(
      `${Constants.QUESTIONS_ENDPOINT}/${id}-${lang}.json`
    );
  }

  registerClientData(data: UserProfile, clientId: string) {
    // console.log('AXIOS registerClientData ' + data);
    let { jntToken } = data;
    axios.defaults.headers.common["jntToken"] = jntToken;
    axios.defaults.headers.common["clientId"] = clientId;
  }

  savePermanently = false;
  profileCache: UserProfile | null = null;

  cachedStatusForHall: string | null = null;
  getDefaultStatusForHall(): string | null {
    if (this.cachedStatusForHall != null) {
      return this.cachedStatusForHall
    }
    const evt = this.getDefaultEvent();
    if (evt != null && evt.currentPlatformStatus != null) {
      return evt.currentPlatformStatus.hall;
    }
    return null;
  }
  setCachedStatusForHall(key: string) {
    this.cachedStatusForHall = key;
  }
  //TODO unused
  // getDefaultStatusForNavigation(): string | null {
  //   const evt = this.getDefaultEvent();
  //   if (evt != null && evt.currentPlatformStatus != null) {
  //     return evt.currentPlatformStatus.navigation;
  //   }
  //   return null;
  // }
  cachedStatusForPlenary: string | null = null;
  getDefaultStatusForPlenary(): string | null {
    if (this.cachedStatusForPlenary != null) {
      return this.cachedStatusForPlenary
    }
    const evt = this.getDefaultEvent();
    if (evt != null && evt.currentPlatformStatus != null) {
      return evt.currentPlatformStatus.plenary;
    }
    return null;
  }
  setCachedStatusForPlenary(key: string) {
    this.cachedStatusForPlenary = key;
  }

  cachedStatusForRedflix: string | null = null;
  getDefaultStatusForRedflix(): string | null {
    if (this.cachedStatusForRedflix != null) {
      return this.cachedStatusForRedflix
    }
    const evt = this.getDefaultEvent();
    if (evt != null && evt.currentPlatformStatus != null) {
      return evt.currentPlatformStatus.redflix;
    }
    return null;
  }
  setCachedStatusForRedflix(key: string) {
    this.cachedStatusForRedflix = key;
  }


  // getDefaultEvent(): Event {
  //   console.log(this.getUserProfile());
  //   let result = this.getUserProfile()!.events[0]!;
  //   return result;
  // }
  getDefaultEvent(): Event {
    // console.log(this.getUserProfile());
    // console.log('get default event');
    let result = this.getUserProfile()!.events[0]!;
    let gotOne = false;
    this.getUserProfile()!.events.forEach(event => {
      if (event.isActive && gotOne == false) {
        result = event;
        gotOne = true;
      }
    });
    return result;
  }


  getUserProfile(): UserProfile | null {
    // console.log("##########################GET USER PROFILE");
    if (this.profileCache !== null) {
      return this.profileCache;
    }
    return null;
  }

  updateUserAvatar(data: UserAvatar) {
    let up = this.getUserProfile();
    if (up != null) {
      up.avatarURL = data.avatarURL;
      up.avatarStyle = data.avatarStyle;
      this.saveUserProfile(up);
    }
  }

  saveUserProfile(data: UserProfile) {
    // console.log('saveUserProfile');
    // console.log(data);
    if (data !== null) {
      this.isLoggedIn = true;
    }
    this.profileCache = data;

  }

  clearUserProfile() {
    // localStorage.clear();
    localStorage.removeItem(JNT_TOKEN);

    this.isLoggedIn = false;
    this.profileCache = null;
    this.savePermanently = false;
  }

  getUniqueID(): string {
    let result = localStorage.getItem(UNIQUE_ID);
    if (result !== null) {
      this.setClientId(result);
      return result;
    } else {
      const id = generateUniqueID("jnt-");
      this.saveUniqueID(id);
      return id;
    }
  }
  saveUniqueID(id: string) {
    localStorage.setItem(UNIQUE_ID, id);
    this.setClientId(id);
  }

}

export default new AxiosHelper();
