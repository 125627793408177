import React, { useState, useRef } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";

import {
  chakra,
  useBreakpointValue,
  useMultiStyleConfig,
  Container,
  Text,
  Link,
  Flex,
  Divider,
  ListItem,
  UnorderedList,
  SimpleGrid,
  Box,
} from "@chakra-ui/react";

import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import axiosHelper from "./../../helpers/axiosHelper";
import bgImage from "./../../assets/prizes/prizes-page-bg@2x.jpg";

import { ChevronLeftIcon, ChevronRightIcon } from "@chakra-ui/icons";

const TermsAndConditionsContentPL = ({ ...props }) => {
  const { t } = useTranslation();
  const history = useHistory();
  const [userProfile, setUserProfile] = useState<UserProfile>(
    axiosHelper.getUserProfile()!
  );
  const styles = useMultiStyleConfig("PrizePage", props);

  const isMobile = useBreakpointValue({ base: true, lg: false });
  const customSlider = useRef<any>();

  return (
    <Container
      maxW="container.xl"
      bgImage={bgImage}
      d="flex"
      flexDir="column"
      flexDirection="column"
      style={{
        overflow: "hidden",
        backgroundPosition: "center",
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
      }}
      alignItems="center"
    >
      <Flex
        flexDir={{ base: "column", lg: "column" }}
        justifyContent="space-between"
        w={isMobile?"100%":"50%"}
        mt="2%"
      >
        <Text color="white" mb="2%" mt={isMobile ? "30px" : "0"} align="center">
          WARUNKI I POSTANOWIENIA
          <br />
          SUPER KONKURS MF
          <br />
          Organizowany od 2 lipca 2021 r. do 3 września 2021 r.
          <br />
          Organizator: MASSEY FERGUSON — marka koncernu AGCO
          <br />
        </Text>
      </Flex>
      <chakra.div w="100%" pb="100px">
        <Flex
          flexDir={{ base: "column", lg: "column" }}
          justifyContent="space-between"
          w="100%"
          mt="3%"
        >
          <Text color="red.500" mt="1%" fontWeight="bold">
            Artykuł 1 — Ogólne informacje organizacyjne
          </Text>
          <Text color="white" mt="1%">
            Dział marketingu MASSEY FERGUSON EME koncernu AGCO SAS, z siedzibą
            przy 41 Blaise Pascal Avenue w Beauvais, organizuje 3 sierpnia
            konkurs „NOWE WYDARZENIE CYFROWE MASSEY FERGUSON”, które jest
            bezpłatne, niezobowiązujące i przeznaczone dla wszystkich
            zainteresowanych.
          </Text>

          <Text color="red.500" fontWeight="bold" mt="1%">
            Artykuł 2 — Kwalifikowalność do udziału
          </Text>

          <Text color="white" mt="1%">
            Do udziału kwalifikują się wyłącznie uczestnicy z krajów Europy
            i Bliskiego Wschodu (z wyłączeniem krajów objętych sankcjami
            handlowymi). W konkursie mogą brać udział wyłącznie rolnicy, firmy
            usługowe (podwykonawcy) i osoby związane z branżą rolniczą.
          </Text>

          <Text color="white" mt="1%">
            Osoby wyłączone z udziału:
          </Text>

          <chakra.div>
            <UnorderedList ml="40px" color="white">
              <ListItem>Wszystkie osoby poniżej 18 roku życia</ListItem>
              <ListItem>Pracownicy grupy AGCO i ich rodziny</ListItem>
              <ListItem>
                Dealerzy i dystrybutorzy produktów oraz usług AGCO
              </ListItem>
              <ListItem>Pracownicy firm powiązanych z AGCO</ListItem>
              <ListItem>
                Agencje i dostawcy zatrudnieni przez AGCO oraz krewni takich
                pracowników
              </ListItem>
              <ListItem>
                Dziennikarze zaproszeni przez MASSEY FERGUSON do udziału
                w wydarzeniu.
              </ListItem>
            </UnorderedList>
          </chakra.div>
          <Text color="white" mt="1%">
            Dozwolone jest tylko jedno uczestnictwo na osobę, na gospodarstwo
            domowe i podmiot rolniczy, to samo nazwisko, to samo imię, ten sam
            numer telefonu i ten sam adres e-mail. Udział w konkursie jest
            ściśle indywidualny. Organizator zastrzega sobie prawo do
            przeprowadzenia wszelkich stosownych kontroli, wedle własnego
            uznania.
          </Text>
          <Text color="red.500" fontWeight="bold" mt="1%">
            Artykuł 3 — Warunki uczestnictwa
          </Text>
          <Text color="white" mt="1%">
            Rejestrując się na NOWE WYDARZENIE CYFROWE MASSEY FERGUSON za
            pośrednictwem platformy internetowej oddziału organizującego AGCO
            SAS, uczestnik automatycznie zgłasza się do udziału w konkursie,
            gdyż stanowi on podstawę wydarzenia. Uczestnik wyraża zgodę na
            udostępnienie swoich danych osobowych w celu skontaktowania się
            z nim przez AGCO SAS za pośrednictwem poczty elektronicznej,
            telefonu, mediów społecznościowych.
          </Text>
          <Text color="red.500" fontWeight="bold" mt="1%">
            Artykuł 4 — Wyłonienie zwycięzców
          </Text>
          <Text color="white" mt="1%">
            Wyniki konkursu zostaną ogłoszone w okresie od 6 września do
            6 października 2021 r. w siedzibie AGCO SAS. - Dział Marketingu
            Massey Ferguson EME w Beauvais — Francja.
          </Text>
          <Box display="block" mt="1%"></Box>
          <Text color="white">
            Losowanie odbędzie się między 6 września a 6 października 2021 roku
            w siedzibie AGCO SAS. - Dział Marketingu Massey Ferguson EME
            w Beauvais — Francja. Dział Marketingu Massey Ferguson EME
            w Beauvais przeprowadzi to losowanie za zamkniętymi drzwiami.
          </Text>
          <Text color="red.500" fontWeight="bold" mt="1%">
            Artykuł 5 — Nagroda
          </Text>
          <Text color="white" mt="1%">
            Są dostępne 3 poziomy nagród. Na każdy poziom przypadają 3 nagrody,
            co daje łącznie 9 zwycięzców w konkursie. Odpowiadając na pytania po
            rejestracji, uczestnik może poprawić swój wynik i spróbować osiągnąć
            najwyższy poziom nagród w tym konkursie.
          </Text>
          <Text color="white" mt="1%">
            Po zakończeniu wydarzenia każdy uczestnik zostanie przydzielony do 1
            z 3 poniższych losowań nagród, w zależności od jego końcowej pozycji
            rankingowej w grze. - Ostateczna pozycja zostanie ogłoszona na
            platformie internetowej zaraz po zakończeniu wydarzenia (3 września
            2021 r., godz. 15:00). Nawet jeśli uczestnik nie odpowie na żadne
            pytanie, zostanie automatycznie włączony do losowania nagród
            3 poziomu.
          </Text>

          <Text color="white" fontWeight="bold" mt="1%" fontStyle="italic">
            Poziom 1: Wartość do 20 000 Euro — pozycja w rankingu: 1–1000
          </Text>
          <Text color="white" mt="1%">
            Uczestnicy, którzy zajmą w konkursie miejsca od 1 do 1000, zostaną
            umieszczeni na liście uczestników losowania nagród poziomu 1.
            <br /> Na tym poziomie dostępne są 3 nagrody. Wartość najwyższej
            z nich wynosi 20 580 Euro.
          </Text>
          <UnorderedList color="white" ml="40px" mt="1%">
            <ListItem>
              Pierwszą nagrodą na poziomie 1 jest rabat w wysokości do
              10 000 Euro na zakup nowej maszyny MF.
              <br />
              <br />
              Rabat ten obowiązuje wyłącznie przy zakupie nowej maszyny: maszyny
              zielonkowe, ciągniki, kombajny, prasy i ładowarki teleskopowe
              (zależnie od dostępności maszyn w punkcie dealerskim) z oferty
              MASSEY FERGUSON, do dnia 31 marca 2022 r.
              <br />
              <br />
              Zasady udzielania rabatów (*na podstawie cen katalogowych,
              z uwzględnieniem opcji):
              <br />
              <SimpleGrid
                w={isMobile ? "100%" : "50%"}
                autoFlow=""
                borderColor="white"
                columns={[2, null, 2]}
                border="1px solid white"
                mt="5px"
              >
                <Box bg="red.500" border="1px solid white">
                  <Text
                    color="white"
                    fontWeight="bold"
                    padding="1.5"
                    fontSize={isMobile ? ".7rem" : "1rem"}
                  >
                    Wartość nowej maszyny*:{" "}
                  </Text>
                </Box>
                <Box bg="red.500" border="1px solid white">
                  <Text
                    color="white"
                    fontWeight="bold"
                    padding="1.5"
                    fontSize={isMobile ? ".7rem" : "1rem"}
                  >
                    Rabat do zastosowania{" "}
                  </Text>
                </Box>
                <Box border="1px solid white">
                  <Text
                    color="white"
                    padding="1.5"
                    fontSize={isMobile ? ".7rem" : "1rem"}
                  >
                    Poniżej 25 000 Euro*{" "}
                  </Text>
                </Box>
                <Box border="1px solid white">
                  <Text
                    color="white"
                    padding="1.5"
                    fontSize={isMobile ? ".7rem" : "1rem"}
                  >
                    1500€
                  </Text>
                </Box>
                <Box border="1px solid white">
                  <Text
                    color="white"
                    padding="1.5"
                    fontSize={isMobile ? ".7rem" : "1rem"}
                  >
                    Między 25 000 Euro a 50 000 Euro*{" "}
                  </Text>
                </Box>
                <Box border="1px solid white">
                  <Text
                    color="white"
                    padding="1.5"
                    fontSize={isMobile ? ".7rem" : "1rem"}
                  >
                    2500€
                  </Text>
                </Box>
                <Box border="1px solid white">
                  <Text
                    color="white"
                    padding="1.5"
                    fontSize={isMobile ? ".7rem" : "1rem"}
                  >
                    Między 50 000 Euro a 100 000 Euro*{" "}
                  </Text>
                </Box>
                <Box border="1px solid white">
                  <Text
                    color="white"
                    padding="1.5"
                    fontSize={isMobile ? ".7rem" : "1rem"}
                  >
                    4000€
                  </Text>
                </Box>
                <Box border="1px solid white">
                  <Text
                    color="white"
                    padding="1.5"
                    fontSize={isMobile ? ".7rem" : "1rem"}
                  >
                    Między 100 000 Euro a 150 000 Euro*{" "}
                  </Text>
                </Box>
                <Box border="1px solid white">
                  <Text
                    color="white"
                    padding="1.5"
                    fontSize={isMobile ? ".7rem" : "1rem"}
                  >
                    6000€
                  </Text>
                </Box>
                <Box border="1px solid white">
                  <Text
                    color="white"
                    padding="1.5"
                    fontSize={isMobile ? ".7rem" : "1rem"}
                  >
                    Między 150 000 Euro a 200 000 Euro*{" "}
                  </Text>
                </Box>
                <Box border="1px solid white">
                  <Text
                    color="white"
                    padding="1.5"
                    fontSize={isMobile ? ".7rem" : "1rem"}
                  >
                    8000€
                  </Text>
                </Box>
                <Box border="1px solid white">
                  <Text
                    color="white"
                    padding="1.5"
                    fontSize={isMobile ? ".7rem" : "1rem"}
                  >
                    Powyżej 200 000 Euro*{" "}
                  </Text>
                </Box>
                <Box border="1px solid white">
                  <Text
                    color="white"
                    padding="1.5"
                    fontSize={isMobile ? ".7rem" : "1rem"}
                  >
                    10 000 Euro{" "}
                  </Text>
                </Box>
              </SimpleGrid>
            </ListItem>
            <ListItem mt="26px">
              Drugą nagrodą na poziomie 1 jest system MF Guide z korekcją RTK,
              oferowany bezpłatnie do każdego zgodnego ciągnika/kombajnu MF,
              zakupionego przed 31 marca 2022 roku.
              <br />
              <br />
              Opcja MF Guide musi zostać wybrana przez zwycięzcę podczas zakupu
              maszyny.
              <br />
              Maksymalna wartość tej nagrody nie może przekroczyć 16 060 Euro.
              Roczne subskrypcje są płatne oddzielnie (przez zwycięzcę).
            </ListItem>
            <ListItem mt="26px">
              Trzecią i ostatnią nagrodą na poziomie 1 jest ciągnik MF 1520 A z
              silnikiem Stage 5, o łącznej wartości 19 890 Euro*, w tym opcja
              opon o wartości 690 Euro* (*Cennik z 1 lutego 2021 r.)
            </ListItem>
          </UnorderedList>

          <Text color="white" mt="1%">
            1 uczestnik może wygrać tylko jedną nagrodę w całej grze, na
            wszystkich poziomach łącznie.
          </Text>
          <Text color="white" fontWeight="bold" mt="1%" fontStyle="italic">
            Poziom 2: Wartość do 5000 Euro — pozycja w rankingu: 1001–2000
          </Text>
          <Text color="white" mt="1%">
            Uczestnicy, którzy zajmą w konkursie miejsca od 1001 do 2000,
            zostaną umieszczeni na liście uczestników losowania nagród
            poziomu 2.
            <br />
            Na tym poziomie dostępne są 3 nagrody. Wartość najwyższej z nich
            wynosi 5000 Euro.
          </Text>

          <UnorderedList color="white" ml="40px" mt="1%">
            <ListItem>
              Pierwszą nagrodą na poziomie 2 jest zestaw MF Summer o łącznej
              wartości 1000 Euro; elementy zestawu:
              <UnorderedList color="white">
                <ListItem ml="20px" type="circle">
                  Grill typu barrel MF / AGCO Parts i 4 leżaki MF / AGCO Parts
                  o łącznej wartości 340 Euro
                </ListItem>
              </UnorderedList>
              <Text color="white" ml="-40px">
                oraz
              </Text>
              <UnorderedList color="white">
                <ListItem ml="20px" type="circle">
                  Spersonalizowany iPad Air Grey WIFI 64 GB o wartości 669 Euro
                </ListItem>
              </UnorderedList>
            </ListItem>
          </UnorderedList>

          <Text color="white" mt="1%">
            Nagrody te zostaną dostarczone oddzielnie do zwycięzcy.
          </Text>

          <UnorderedList color="white" ml="40px">
            <ListItem mt="1%">
              Drugą nagrodą na poziomie 2 jest profesjonalna myjka ciśnieniowa
              o wartości 2000 Euro.
              <br />
              <br />
              Przedstawiony model myjki ciśnieniowej ma charakter przykładowy
              i może być inny w zależności od rynku zwycięzcy. Nasz zespół AGCO
              Parts Complementary przekaże zwycięzcy dokładne dane modelu po
              losowaniu.
            </ListItem>
            <ListItem mt="1%">
              Trzecią i ostatnią nagrodą na poziomie 2 jest pakiet MF Serwis
              o łącznej wartości 5000 Euro, który obejmuje:
              <UnorderedList color="white" listStyleType="circle" mt="1%">
                <ListItem>
                  Voucher o wartości 5000 Euro do wykorzystania w lokalnym
                  salonie MF dealerskim na wszystkie dostępne części AGCO Parts.
                </ListItem>
                <ListItem mt="1%">
                  Możliwość zamówienia jednej wizyty w gospodarstwie pracownika
                  z naszego Centrum Obsługi Technicznej AGCO, w towarzystwie MF
                  lokalnego dealera, w celu uzyskania porad dotyczących
                  eksploatacji ciągnika i ładowarki teleskopowej Massey
                  Ferguson.
                </ListItem>
              </UnorderedList>
            </ListItem>
          </UnorderedList>

          <Text color="white" mt="1%">
            1 uczestnik może wygrać tylko jedną nagrodę w całej grze, na
            wszystkich poziomach łącznie.
          </Text>

          <Text color="white" fontWeight="bold" mt="2%" fontStyle="italic">
            Poziom 3: Wartość do 250 Euro — pozycja w rankingu: 2001–ostatnie
          </Text>

          <Text color="white" mt="2%">
            Uczestnicy, którzy zajmą w konkursie miejsca od 2001 do ostatniego,
            zostaną umieszczeni na liście uczestników losowania nagród
            poziomu 3.
            <br /> Na tym poziomie dostępne są 3 nagrody, o podobnej łącznej
            wartości 250 Euro.
          </Text>

          <UnorderedList color="white " mt="1%">
            <ListItem>
              Pierwszą nagrodą na poziomie 3 jest pakiet Connectivity, który
              zawiera 3 ekskluzywne upominki MASSEY FERGUSON:
              <UnorderedList color="white">
                <ListItem type="circle">
                  Smartwatch MASSEY FERGUSON — cena jednostkowa: 180 Euro
                </ListItem>
                <ListItem type="circle">
                  Zestaw słuchawek dousznych MASSEY FERGUSON — cena jednostkowa:
                  39,90 Euro
                </ListItem>
                <ListItem type="circle">
                  Powerbank z kolekcji MASSEY FERGUSON S — cena jednostkowa:
                  29,90 Euro
                </ListItem>
                <Box display="block" mt="1%"></Box>
              </UnorderedList>
            </ListItem>

            <ListItem mt="1%">
              Drugą nagrodą na poziomie 3 jest voucher elektroniczny o wartości
              250 Euro do wykorzystania przy kilku zakupach w sklepie
              internetowym MASSEY FERGUSON:{" "}
              <Link href="https://shop.masseyferguson.com">
                https://shop.masseyferguson.com
              </Link>.{" "}
              Ten e-voucher jest ważny przez 36 miesięcy od momentu otrzymania.
            </ListItem>

            <ListItem mt="1%">
              Trzecią i ostatnią nagrodą na poziomie 3 jest pakiet Game Pack
              zawierający:
              <UnorderedList color="white" listStyleType="circle">
                <ListItem>
                  AModel ciągnika MF 8S — cena jednostkowa: 70 Euro
                </ListItem>
                <ListItem>
                  Ekskluzywny model ciągnika MF 5S — cena jednostkowa: 65 Euro
                </ListItem>
                <ListItem>
                  Komputerowa mysz bezprzewodowa MASSEY FERGUSON — cena
                  jednostkowa: 15 Euro
                </ListItem>
                <ListItem>
                  2 gry komputerowe Farming Simulator 2022 — cena jednostkowa:
                  40 Euro
                </ListItem>
                <ListItem>
                  Ekskluzywna czapka z daszkiem Farming Simulator 2022 FS — cena
                  jednostkowa: 20 Euro
                </ListItem>
              </UnorderedList>
            </ListItem>
          </UnorderedList>
          <Text color="white" mt="1%">
            1 uczestnik może wygrać tylko jedną nagrodę w całej grze, na
            wszystkich poziomach łącznie.
          </Text>
          <Text color="red.500" fontWeight="bold" mt="1%">
            Artykuł 6 — Ogłoszenie zwycięzców i powiadomienie o wygranej
          </Text>
          <Text color="white" mt="1%">
            Zespół marketingowy Massey Ferguson powiadomi zwycięzców, wysyłając
            do nich wiadomość e-mail z zaproszeniem do kontaktu z AGCO.
            <br />
            Jeśli osoba wybrana w losowaniu nie odpowie na wiadomość e-mail
            w ciągu 10 dni, odbędzie się drugie i ostateczne losowanie,
            a pierwszy zwycięzca zostanie zdyskwalifikowany.
            <br />
            Firma AGCO SAS skontaktuje się ze zwycięzcą za pośrednictwem poczty
            elektronicznej lub w inny sposób w celu uzyskania informacji
            niezbędnych do dostarczenia nagrody.
            <br />
            Uczestnik akceptuje fakt, że w przypadku wygranej w konkursie jego
            imię i nazwisko zostaną publicznie ogłoszone w reklamach, promocjach
            i w odpowiednich witrynach internetowych oraz mediach
            społecznościowych.
            <br />
            Zwycięzca zobowiązuje się do nieodpłatnego i mieszczącego się
            w granicach zdrowego rozsądku uczestnictwa w wydarzeniach mających
            na celu promocję dźwiękową, fotograficzną, filmową i/lub tekstową za
            pośrednictwem radia, Internetu, telewizji lub mediów drukowanych
            oraz wyraża zgodę na publikację uzyskanych w ten sposób materiałów
            i wykorzystanie ich do celów reklamowych przez AGCO i firmy
            powiązane z AGCO.
            <br />
          </Text>
          <Text color="red.500" fontWeight="bold" mt="1%">
            Artykuł 7 — Wykluczenie uczestników
          </Text>

          <Text color="white" mt="1%">
            AGCO zastrzega sobie prawo do wykluczenia uczestników z konkursu.
            Dotyczy to w szczególności przypadków naruszenia warunków
            uczestnictwa, podania nieprawdziwych danych osobowych, manipulacji
            lub podejrzenia manipulacji. AGCO może również wykluczyć
            uczestników, co do których istnieje uzasadnione podejrzenie, że
            korzystają z usług AGCO.
            <br />
            Jeśli AGCO w późniejszym czasie ustali, że uczestnik w jakikolwiek
            sposób dostarczył powód wykluczenia albo nie był faktycznie
            uprawniony do udziału w konkursie w rozumieniu art. 2, AGCO może
            anulować przypisanie nagrody lub odebrać wszelkie już przekazane
            nagrody.
          </Text>
          <Text color="red.500" fontWeight="bold" mt="1%">
            Artykuł 8 — Wycofanie nagród
          </Text>
          <Text color="white" mt="1%">
            Wymiana finansowa, zwrot lub rekompensata nie są dozwolone w żadnych
            okolicznościach.
            <br />
            Jeżeli niezależne okoliczności uniemożliwią przyznanie
            zadeklarowanych nagród, organizator może je zastąpić innymi
            nagrodami o równoważnej wartości. Termin ten nie podlega negocjacjom
            oraz nie obowiązuje alternatywna nagroda gotówkowa.
            <br />
            Nagroda zostanie wysłana do zwycięzcy w ciągu 3 miesięcy od
            otrzymania przez zespół marketingu Massey Ferguson wszystkich
            informacji niezbędnych do jej dostarczenia.
          </Text>
          <Text color="red.500" fontWeight="bold" mt="1%">
            Artykuł 9 — Warunki użytkowania i gwarancja
          </Text>
          <Text color="white" mt="1%">
            Organizatorzy zrzekają się wszelkiej odpowiedzialności za
            jakiekolwiek incydenty i/lub wypadki, które mogą wystąpić w trakcie
            korzystania z przyznanej nagrody i/lub w wyniku jej wykorzystania.
            <br />
            Organizatorzy zastrzegają sobie prawo, bez ponoszenia jakiejkolwiek
            odpowiedzialności, do skrócenia, rozszerzenia, zmodyfikowania lub
            odwołania tego wydarzenia w razie zaistnienia stosownych
            okoliczności.
            <br />
            Organizatorzy nie odpowiadają za przerwy w świadczeniu usług,
            spowodowane incydentem technicznym w sieciach łączności lub
            niezależnym od nich incydentem technicznym. Bez względu na
            przyczynę, organizatorzy nie odpowiadają za częściową ani całkowitą
            zmianę warunków konkursu, jego przełożenie ani odwołanie. Uczestnicy
            nie mogą żądać z tego tytułu żadnego odszkodowania. Wszelkie
            nieczytelne, niekompletne i sfałszowane formularze nie zostaną
            uwzględnione. Oznacza to, że organizator nie ponosi żadnej
            odpowiedzialności w sytuacji, gdy formularze nie zostaną
            zarejestrowane lub nie będą możliwe do sprawdzenia.
            <br />
          </Text>
          <Text color="red.500" fontWeight="bold" mt="1%">
            Artykuł 10 — Akceptacja i spory
          </Text>

          <Text color="white" mt="1%">
            Przystępując do tego konkursu, wszyscy uczestnicy potwierdzają, że
            akceptują niniejsze warunki i postanowienia.
            <br />
            Każdy z uczestników przyjmuje do wiadomości, że działania losowe nie
            mogą dać mu żadnej innej pewności, niż pod postacią nadziei
            odniesienia potencjalnych i zależnych od przypadku korzyści.
            <br />
            Ewentualne roszczenia należy przesłać listownie do AGCO SAS. Dział
            prawny, 41 Avenue Blaise Pascal, BP 60307 — BEAUVAIS (60026)
            FRANCJA, najpóźniej do 3 września (decyduje data stempla
            pocztowego).
            <br />
            Niniejsze warunki podlegają prawu francuskiemu, które jako jedyne ma
            zastosowanie.
            <br />
            AGCO SAS. — Dział Marketingu EME, MASSEY FERGUSON, przekaże
            informacje zwrotne dotyczące wszelkich trudności praktycznych,
            interpretacji lub wniosków o zastosowanie niniejszych warunków.
            <br />
          </Text>
          <Text color="red.500" fontWeight="bold" mt="1%">
            Artykuł 11 — Ujawnienie warunków i postanowień konkursu
          </Text>
          <Text color="white" mt="1%">
            Regulamin ten będzie dostępny online na oficjalnej platformie
            wydarzeń MF.
            <br />
            Uczestnik internetowy nie będzie mógł domagać się zwrotu pieniędzy
            od organizatora, ponieważ udział nie pociąga za sobą żadnych
            dodatkowych kosztów.
            <br />
            Pełna kopia tych warunków i postanowień znajduje się w dziale
            prawnym organizatora.
            <br />
          </Text>
          <Text color="red.500" fontWeight="bold" mt="1%">
            Artykuł 12 — Ochrona danych osobowych
          </Text>
          <Text color="white" mt="1%">
            Aby wziąć udział w konkursie, uczestnicy muszą koniecznie wyrazić
            zgodę na to, aby niektóre ich dane osobowe mogły być wykorzystywane
            przez organizatora w celu zarządzania konkursem — również w celach
            marketingowych.
            <br />
            Więcej informacji można znaleźć w naszej Polityce ochrony
            prywatności pod adresem:{" "}
            <Link href="http://www.agcocorp.com/privacy.html">
              http://www.agcocorp.com/privacy.html
            </Link>
            <br />
            Każdy zwycięzca zgadza się bez żadnego finansowego ani innego
            wynagrodzenia przyznać organizatorowi prawo do posługiwania się
            swoim nazwiskiem, imieniem, skróconym wskazaniem swojego miejsca
            zamieszkania oraz fotografią w zakresie wszelkich promocji
            i wsparcia redakcyjnego.
            <br />
            Zgodnie z obowiązującymi przepisami użytkownik może wycofać się
            z udziału, w całości lub w części, w dowolnym momencie i bez podania
            AGCO jakiegokolwiek uzasadnienia. W kwestii danych osobowych
            uczestnik może skorzystać z prawa dostępu, sprostowania, usunięcia
            i sprzeciwu, wysyłając wiadomość e-mail na adres
            dataprivacy@agcocorp.com
          </Text>
        </Flex>
      </chakra.div>
    </Container>
  );
};

export default TermsAndConditionsContentPL;
