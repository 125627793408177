import React from 'react';
import ReactDOM from 'react-dom';
import 'bootstrap/dist/css/bootstrap.css';
import './index.css';
import App from './App';

import { I18nextProvider } from "react-i18next";
import i18next from "i18next";
import LanguageDetector from 'i18next-browser-languagedetector';
import TagManager from 'react-gtm-module'

import language_da from './assets/i18n/da.json';
import language_de from './assets/i18n/de.json';
import language_en from './assets/i18n/en.json';
import language_es from './assets/i18n/es.json';
import language_fr from './assets/i18n/fr.json';
import language_it from './assets/i18n/it.json';
import language_nl from './assets/i18n/nl.json';
import language_no from './assets/i18n/no.json';
import language_pl from './assets/i18n/pl.json';
import language_ptbr from './assets/i18n/pt-BR.json';
import language_pt from './assets/i18n/pt.json';
import language_ru from './assets/i18n/ru.json';
import language_sv from './assets/i18n/sv.json';
import language_tr from './assets/i18n/tr.json';
import language_uk from './assets/i18n/uk.json';


i18next.use(LanguageDetector).init({
  interpolation: { escapeValue: false },  // React already does escaping
  defaultNS: 'common',
  load: 'all',
  fallbackLng: 'en', //default
  resources: {
    da: {
      common: language_da
    },
    de: {
      common: language_de               // 'common' is our custom namespace
    },
    en: {
      common: language_en               // 'common' is our custom namespace
    },
    es: {
      common: language_es
    },
    fr: {
      common: language_fr
    },
    it: {
      common: language_it
    },
    nl: {
      common: language_nl
    },
    no: {
      common: language_no
    },
    pl: {
      common: language_pl
    },
    pt: {
      common: language_pt
    },
    ru: {
      common: language_ru
    },
    sv: {
      common: language_sv
    },
    tr: {
      common: language_tr
    },
    uk: {
      common: language_uk
    }
  },
});

i18next.addResourceBundle('pt-BR', 'common', language_ptbr, true, true);
const tagManagerArgs = {
  gtmId: 'GTM-PV4PCPM'
}

TagManager.initialize(tagManagerArgs)


ReactDOM.render(
  <React.StrictMode>
    <I18nextProvider i18n={i18next}>
      <App />
    </I18nextProvider>
  </React.StrictMode>,
  document.getElementById('root')
);
