import React, { useState, useRef } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";

import {
  chakra,
  useBreakpointValue,
  useMultiStyleConfig,
  Container,
  Text,
  Link,
  Flex,
  Divider,
  ListItem,
  UnorderedList,
  SimpleGrid,
  Box,
} from "@chakra-ui/react";

import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import axiosHelper from "./../../helpers/axiosHelper";
import bgImage from "./../../assets/prizes/prizes-page-bg@2x.jpg";

import { ChevronLeftIcon, ChevronRightIcon } from "@chakra-ui/icons";

const TermsAndConditionsContentIT = ({ ...props }) => {
  const { t } = useTranslation();
  const history = useHistory();
  const [userProfile, setUserProfile] = useState<UserProfile>(
    axiosHelper.getUserProfile()!
  );
  const styles = useMultiStyleConfig("PrizePage", props);

  const isMobile = useBreakpointValue({ base: true, lg: false });
  const customSlider = useRef<any>();

  return (
    <Container
      maxW="container.xl"
      bgImage={bgImage}
      d="flex"
      flexDir="column"
      flexDirection="column"
      style={{
        overflow: "hidden",
        backgroundPosition: "center",
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
      }}
      alignItems="center"
    >
      <Flex
        flexDir={{ base: "column", lg: "column" }}
        justifyContent="space-between"
        w={isMobile?"100%":"50%"}
        mt="2%"
      >
        <Text color="white" mb="2%" mt={isMobile ? "30px" : "0"} align="center">
          TERMINI E CONDIZIONI
          <br />
          MF SUPER CONTEST
          <br />
          Dal 2 luglio 2021 al 3 settembre 2021
          <br />
          Organizzato da MASSEY FERGUSON, un marchio di AGCO
          <br />
        </Text>
      </Flex>
      <chakra.div w="100%" pb="100px">
        <Flex
          flexDir={{ base: "column", lg: "column" }}
          justifyContent="space-between"
          w="100%"
          mt="3%"
        >
          <Text color="red.500" mt="1%" fontWeight="bold">
            Articolo 1 – Organizzazione generale
          </Text>
          <Text color="white" mt="1%">
            Il giorno 3 settembre, il reparto Marketing MASSEY FERGUSON EME di
            AGCO S.A.S., con sede legale presso 41, Avenue Blaise Pascal a
            Beauvais, organizzerà il NUOVO EVENTO DIGITALE MASSEY FERGUSON, che
            comprenderà un gioco gratuito e senza impegno, aperto a tutti e
            rivolto al grande pubblico.
          </Text>

          <Text color="red.500" fontWeight="bold" mt="1%">
            Articolo 2 – Condizioni di ammissione
          </Text>

          <Text color="white" mt="1%">
            Possono partecipare al gioco esclusivamente i residenti in Paesi di
            Europa e Medio Oriente (ad eccezione dei Paesi sottoposti a misure
            restrittive). Alla competizione sono ammessi solo agricoltori,
            contoterzisti e individui legati ad aziende agricole.
          </Text>

          <Text color="white" mt="1%">
            Sono esclusi:
          </Text>

          <chakra.div>
            <UnorderedList ml="40px" color="white">
              <ListItem>Tutti gli individui sotto i 18 anni</ListItem>
              <ListItem>
                Dipendenti del Gruppo AGCO e rispettive famiglie
              </ListItem>
              <ListItem>
                Concessionari e distributori di prodotti e servizi AGCO
              </ListItem>
              <ListItem>Dipendenti di aziende affiliate con AGCO</ListItem>
              <ListItem>
                Agenzie e fornitori incaricati da AGCO e i familiari dei
                rispettivi dipendenti
              </ListItem>
              <ListItem>
                Giornalisti invitati all’evento da MASSEY FERGUSON
              </ListItem>
            </UnorderedList>
          </chakra.div>
          <Text color="white" mt="1%">
            Sarà accettata una sola partecipazione per persona, famiglia e
            azienda agricola, stesso cognome, nome, numero di telefono,
            indirizzo e-mail. La partecipazione al gioco è strettamente
            personale. L’azienda organizzatrice si riserva il diritto di
            svolgere tutti i controlli che riterrà opportuni.
          </Text>
          <Text color="red.500" fontWeight="bold" mt="1%">
            Articolo 3 – Termini della partecipazione
          </Text>
          <Text color="white" mt="1%">
            Registrandosi al NUOVO EVENTO DIGITALE MASSEY FERGUSON tramite la
            piattaforma web della divisione di AGCO SAS che organizza l’evento,
            il partecipante prende automaticamente parte al gioco, poiché è alla
            base dell’evento stesso. Il partecipante autorizza AGCO SAS ad
            accedere alle proprie informazioni personali per essere contattato
            via e-mail, telefono, social media.
          </Text>
          <Text color="red.500" fontWeight="bold" mt="1%">
            Articolo 4 – Designazione dei vincitori
          </Text>
          <Text color="white" mt="1%">
            Il risultato del gioco sarà comunicato fra il 6 settembre 2021 e il
            6 ottobre 2021 presso la sede di AGCO SAS. - Reparto Marketing
            Massey Ferguson EME a Beauvais, Francia.
          </Text>
          <Box display="block" mt="1%"></Box>
          <Text color="white">
            L’estrazione si svolgerà fra il 6 settembre 2021 e il 6 ottobre 2021
            presso la sede di AGCO SAS.- Reparto Marketing Massey Ferguson EME a
            Beauvais, Francia. Il reparto Marketing Massey Ferguson EME di
            Beauvais condurrà l’estrazione a porte chiuse.
          </Text>
          <Text color="red.500" fontWeight="bold" mt="1%">
            Articolo 5 – Premi
          </Text>
          <Text color="white" mt="1%">
            Sono previsti tre livelli e tre premi per livello, per un totale di
            nove vincitori del gioco. Rispondendo alle domande dopo la
            registrazione, il partecipante avrà la possibilità di aumentare il
            suo punteggio e provare a raggiungere il livello più alto di regali
            in questa competizione.
          </Text>
          <Text color="white" mt="1%">
            Dopo l’evento, ogni partecipante sarà assegnato a una delle tre
            estrazioni seguenti, a seconda della posizione finale in classifica
            - Posizione finale dichiarata sulla piattaforma web subito dopo
            l’evento (3 settembre 2021 alle ore 15:00). Anche se il partecipante
            non risponde alle domande, sarà automaticamente incluso nel livello
            3.
          </Text>

          <Text color="white" fontWeight="bold" mt="1%" fontStyle="italic">
            Livello 1: Valore fino a € 20.000 - Classifica generale: 1 - 1.000
          </Text>
          <Text color="white" mt="1%">
            I partecipanti dalla posizione 1 alla posizione 1.000 nella
            competizione saranno inclusi nell’elenco dell’estrazione dei regali
            di livello 1.
            <br /> Questo livello comprende tre premi, con un valore massimo di
            € 20.580.
          </Text>
          <UnorderedList color="white" ml="40px" mt="1%">
            <ListItem>
              Il primo premio del livello 1 è uno sconto fino a € 10.000
              sull’acquisto di una nuova macchina MF.
              <br />
              <br />
              Questo sconto si applica esclusivamente all’acquisto di una
              macchina nuova: attrezzature per fienagione, trattori,
              mietitrebbie, presse e telescopici (tenendo conto dell’inventario
              di macchine nella concessionaria) della gamma MASSEY FERGUSON,
              entro il 31 marzo 2022
              <br />
              <br />
              Regole dello sconto (*basate sui listini prezzi; optional
              inclusi):
              <br />
              <SimpleGrid
                w={isMobile ? "100%" : "50%"}
                autoFlow=""
                borderColor="white"
                columns={[2, null, 2]}
                border="1px solid white"
                mt="5px"
              >
                <Box bg="red.500" border="1px solid white">
                  <Text
                    color="white"
                    fontWeight="bold"
                    padding="1.5"
                    fontSize={isMobile ? ".7rem" : "1rem"}
                  >
                    Valore della macchina nuova*:{" "}
                  </Text>
                </Box>
                <Box bg="red.500" border="1px solid white">
                  <Text
                    color="white"
                    fontWeight="bold"
                    padding="1.5"
                    fontSize={isMobile ? ".7rem" : "1rem"}
                  >
                    Sconto da applicare{" "}
                  </Text>
                </Box>
                <Box border="1px solid white">
                  <Text
                    color="white"
                    padding="1.5"
                    fontSize={isMobile ? ".7rem" : "1rem"}
                  >
                    Inferiore a € 25.000*{" "}
                  </Text>
                </Box>
                <Box border="1px solid white">
                  <Text
                    color="white"
                    padding="1.5"
                    fontSize={isMobile ? ".7rem" : "1rem"}
                  >
                    1500€
                  </Text>
                </Box>
                <Box border="1px solid white">
                  <Text
                    color="white"
                    padding="1.5"
                    fontSize={isMobile ? ".7rem" : "1rem"}
                  >
                    Tra € 25.000 e € 50.000*{" "}
                  </Text>
                </Box>
                <Box border="1px solid white">
                  <Text
                    color="white"
                    padding="1.5"
                    fontSize={isMobile ? ".7rem" : "1rem"}
                  >
                    2500€
                  </Text>
                </Box>
                <Box border="1px solid white">
                  <Text
                    color="white"
                    padding="1.5"
                    fontSize={isMobile ? ".7rem" : "1rem"}
                  >
                    Tra € 50.000 e € 100.000*{" "}
                  </Text>
                </Box>
                <Box border="1px solid white">
                  <Text
                    color="white"
                    padding="1.5"
                    fontSize={isMobile ? ".7rem" : "1rem"}
                  >
                    4000€
                  </Text>
                </Box>
                <Box border="1px solid white">
                  <Text
                    color="white"
                    padding="1.5"
                    fontSize={isMobile ? ".7rem" : "1rem"}
                  >
                    Tra € 100.000 e € 150.000*{" "}
                  </Text>
                </Box>
                <Box border="1px solid white">
                  <Text
                    color="white"
                    padding="1.5"
                    fontSize={isMobile ? ".7rem" : "1rem"}
                  >
                    6000€
                  </Text>
                </Box>
                <Box border="1px solid white">
                  <Text
                    color="white"
                    padding="1.5"
                    fontSize={isMobile ? ".7rem" : "1rem"}
                  >
                    Tra € 150.000 e € 200.000*{" "}
                  </Text>
                </Box>
                <Box border="1px solid white">
                  <Text
                    color="white"
                    padding="1.5"
                    fontSize={isMobile ? ".7rem" : "1rem"}
                  >
                    8000€
                  </Text>
                </Box>
                <Box border="1px solid white">
                  <Text
                    color="white"
                    padding="1.5"
                    fontSize={isMobile ? ".7rem" : "1rem"}
                  >
                    Superiore a € 200.000*{" "}
                  </Text>
                </Box>
                <Box border="1px solid white">
                  <Text
                    color="white"
                    padding="1.5"
                    fontSize={isMobile ? ".7rem" : "1rem"}
                  >
                    10 000€
                  </Text>
                </Box>
              </SimpleGrid>
            </ListItem>
            <ListItem mt="26px">
              Il secondo premio del livello 1 è l’opzione MF Guide con
              correzione RTK gratuita per qualsiasi trattore o mietitrebbia MF
              compatibile, acquistato entro il 31 marzo 2022.
              <br />
              <br />
              Il servizio MF Guide opzionale dovrà essere selezionato dal
              vincitore al momento dell’acquisto della macchina.
              <br />
              Il valore massimo di questo premio non supererà € 16.060. Sono
              esclusi e a carico del vincitore i costi di abbonamento annuali.
            </ListItem>
            <ListItem mt="26px">
              Il terzo e ultimo premio del livello 1 è un MF 1520 A Stage 5, per
              un valore di € 19.890*, inclusa un’opzione per gli pneumatici del
              valore di € 690* (*Listino prezzi del 1° febbraio 2021).
            </ListItem>
          </UnorderedList>

          <Text color="white" mt="1%">
            Ogni partecipante può vincere un solo premio nell’intero gioco, con
            tutti i livelli combinati.
          </Text>
          <Text color="white" fontWeight="bold" mt="1%" fontStyle="italic">
            Livello 2: Valore fino a € 5.000 - Classifica generale: 1.001 -
            2.000
          </Text>
          <Text color="white" mt="1%">
            I partecipanti dalla posizione 1.001 alla posizione 2.000 nella
            competizione saranno inclusi nell’elenco dell’estrazione dei regali
            di livello 2.
            <br />
            Questo livello comprende tre premi, con un valore massimo di €
            5.000.
          </Text>

          <UnorderedList color="white" ml="40px" mt="1%">
            <ListItem>
              Il primo premio del livello 2 è un pacchetto MF Summer Pack del
              valore complessivo di € 1.000, che comprende:
              <UnorderedList color="white">
                <ListItem ml="20px" type="circle">
                  Un set composto da un barbecue MF/AGCO Parts Oil Barrel
                  Barbecue e quattro sdraio MF/AGCO Parts, per un valore
                  complessivo di € 340
                </ListItem>
              </UnorderedList>
              <Text color="white" ml="-40px">
                e
              </Text>
              <UnorderedList color="white">
                <ListItem ml="20px" type="circle">
                  Un iPad Air Wi-Fi 64GB grigio personalizzato del valore di €
                  669
                </ListItem>
              </UnorderedList>
            </ListItem>
          </UnorderedList>

          <Text color="white" mt="1%">
            Questi premi saranno consegnati separatamente al vincitore.
          </Text>

          <UnorderedList color="white" ml="40px">
            <ListItem mt="1%">
              Il secondo premio del livello 2 è un’idropulitrice professionale
              del valore di € 2.000.
              <br />
              <br />
              Il modello di idropulitrice offerto non è stabilito da contratto e
              può variare a seconda del mercato del vincitore. Il nostro team
              AGCO Parts Prodotti Complementari comunicherà i dettagli del
              modello esatto al vincitore dopo la selezione.
            </ListItem>
            <ListItem mt="1%">
              Il terzo e ultimo premio del livello 2 è un pacchetto MF Service
              del valore di € 5.000, che include:
              <UnorderedList color="white" listStyleType="circle" mt="1%">
                <ListItem>
                  Un voucher da € 5.000 spendibile presso la concessionaria MF
                  locale per qualsiasi articolo AGCO Parts disponibile.
                </ListItem>
                <ListItem mt="1%">
                  - L’opportunità di organizzare una visita presso l’azienda
                  agricola con un rappresentante del nostro AGCO Technical
                  Service Centre accompagnato dal concessionario MF locale, per
                  ricevere consulenza e suggerimenti sull’utilizzo dei trattori
                  e telescopici Massey Ferguson.
                </ListItem>
              </UnorderedList>
            </ListItem>
          </UnorderedList>

          <Text color="white" mt="1%">
            Ogni partecipante può vincere un solo premio nell’intero gioco, con
            tutti i livelli combinati.
          </Text>

          <Text color="white" fontWeight="bold" mt="2%" fontStyle="italic">
            Livello 3: Valore fino a € 250 - Classifica generale: 2.001 - ultimo
            classificato
          </Text>

          <Text color="white" mt="2%">
            I partecipanti dalla posizione 2.001 all’ultimo classificato nella
            competizione saranno inclusi nell’elenco dell’estrazione dei regali
            di livello 3.
            <br /> Questo livello comprende tre premi, con un valore totale
            simile di € 250.
          </Text>

          <UnorderedList color="white " mt="1%">
            <ListItem>
              Il primo premio del livello 3 è un pacchetto Connectivity Pack che
              include tre esclusivi regali MASSEY FERGUSON:
              <UnorderedList color="white">
                <ListItem type="circle">
                  Uno smartwatch MASSEY FERGUSON – Prezzo unitario: € 180
                </ListItem>
                <ListItem type="circle">
                  Un set di auricolari MASSEY FERGUSON – Prezzo unitario: €
                  39,90
                </ListItem>
                <ListItem type="circle">
                  Un powerbank MASSEY FERGUSON S Collection – Prezzo unitario: €
                  29,90
                </ListItem>
                <Box display="block" mt="1%"></Box>
              </UnorderedList>
            </ListItem>

            <ListItem mt="1%">
              Il secondo premio del livello 3 è un e-voucher del valore di €
              250, spendibile in più rate nel negozio online di MASSEY FERGUSON{" "}
              <Link href="https://shop.masseyferguson.com">
                https://shop.masseyferguson.com
              </Link>.{" "}
              L’e-voucher è valido per 36 mesi dal momento della ricezione.
            </ListItem>

            <ListItem mt="1%">
              Il terzo e ultimo premio del livello 3 è un pacchetto Game Pack
              che include:
              <UnorderedList color="white" listStyleType="circle">
                <ListItem>
                  Un modellino in scala del trattore MF 8S – Prezzo unitario: €
                  70
                </ListItem>
                <ListItem>
                  L’esclusivo modellino in scala del trattore MF 5S – Prezzo
                  unitario: € 65
                </ListItem>
                <ListItem>
                  Un mouse wireless per PC MASSEY FERGUSON – Prezzo unitario: €
                  15
                </ListItem>
                <ListItem>
                  Due giochi Farming Simulator 2022 per PC - Prezzo unitario: €
                  40
                </ListItem>
                <ListItem>
                  L’esclusivo cappellino Farming Simulator 2022 FS – Prezzo
                  unitario: € 20
                </ListItem>
              </UnorderedList>
            </ListItem>
          </UnorderedList>
          <Text color="white" mt="1%">
            Ogni partecipante può vincere un solo premio nell’intero gioco, con
            tutti i livelli combinati.
          </Text>
          <Text color="red.500" fontWeight="bold" mt="1%">
            Articolo 6 – Notifica e comunicazione dei vincitori
          </Text>
          <Text color="white" mt="1%">
            Il team Marketing di Massey Ferguson invierà ai vincitori una
            notifica via e-mail in cui li inviterà a contattare AGCO. Se la
            persona selezionata non risponderà a questa e-mail entro 10 giorni
            sarà squalificata e verrà condotta una seconda e ultima estrazione.
            <br />
            Il vincitore sarà contattato da AGCO S.A.S. via e-mail o altro mezzo
            di comunicazione affinché invii agli organizzatori le informazioni
            necessarie per la consegna del premio.
            <br />
            Il partecipante accetta che, in caso di vittoria, il suo nome sarà
            comunicato pubblicamente in materiali pubblicitari o promozionali
            oppure sui siti web e social media rilevanti.
            <br />
            Il vincitore si impegna a rendersi disponibile a titolo gratuito
            entro limiti ragionevoli per promozioni a mezzo audio, foto,
            immagini e/o testi via radio, internet, televisione e stampa e
            accetta la pubblicazione e l’utilizzo per fini pubblicitari da parte
            di AGCO e delle aziende affiliate con AGCO.
            <br />
          </Text>
          <Text color="red.500" fontWeight="bold" mt="1%">
            Articolo 7 – Esclusione di partecipanti
          </Text>

          <Text color="white" mt="1%">
            AGCO si riserva il diritto di escludere partecipanti dalla
            competizione. Ciò si applica in particolare a violazioni dei termini
            e delle condizioni per la partecipazione, a dati personali falsi
            oppure a manipolazione o sospetta manipolazione. AGCO può inoltre
            escludere partecipanti ragionevolmente sospettati di usare servizi
            di AGCO.
            <br />
            Se AGCO stabilisce successivamente che un partecipante poteva per
            qualsiasi ragione essere escluso o non aveva effettivamente diritto
            a partecipare secondo quando stabilito dall’Articolo 2, AGCO può
            escludere tale partecipante dalla distribuzione o consegna dei premi
            o riappropriarsi di eventuali premi già consegnati.
          </Text>
          <Text color="red.500" fontWeight="bold" mt="1%">
            Articolo 8 – Ritiro dei premi
          </Text>
          <Text color="white" mt="1%">
            In nessun caso sono consentiti scambi finanziari, restituzioni o
            rimborsi.
            <br />
            L’azienda organizzatrice può sostituire i premi con altri di valore
            equivalente nel caso in cui risulti impossibile assegnare i premi
            comunicati a causa di circostanze esterne. Questo aspetto non è
            negoziabile e non saranno disponibili alternative in denaro.
            <br />
            Il premio sarà inviato al vincitore entro tre mesi da quando il team
            Marketing di Massey Ferguson avrà ricevuto tutte le informazioni
            necessarie per la consegna del premio.
          </Text>
          <Text color="red.500" fontWeight="bold" mt="1%">
            Articolo 9 – Termini di utilizzo e garanzia
          </Text>
          <Text color="white" mt="1%">
            Gli organizzatori non si assumono alcuna responsabilità per
            eventuali incidenti e/o inconvenienti che potrebbero verificarsi
            durante o a causa dell’utilizzo dei premi assegnati.
            <br />
            Qualora le circostanze lo richiedano, gli organizzatori si riservano
            il diritto di abbreviare, prolungare, modificare o annullare questa
            operazione senza incorrere in alcuna responsabilità.
            <br />
            Gli organizzatori non possono essere ritenuti responsabili in caso
            di interruzione di servizio causata da un inconveniente tecnico
            delle reti di comunicazione o da un inconveniente tecnico fuori dal
            loro controllo. Gli organizzatori non possono essere ritenuti
            responsabili qualora il concorso, per qualsiasi ragione, venisse
            modificato in toto o in parte, posticipato o annullato. Non potrà
            essere richiesto alcun rimborso. Eventuali moduli illeggibili,
            incompleti o contraffatti non saranno ritenuti validi. L’azienda
            organizzatrice non si assume alcuna responsabilità qualora i moduli
            non fossero registrati o fossero impossibili da verificare.
            <br />
          </Text>
          <Text color="red.500" fontWeight="bold" mt="1%">
            Articolo 10 – Accettazione e controversie
          </Text>

          <Text color="white" mt="1%">
            Partecipando a questo concorso, tutti i partecipanti dichiarano di
            accettare i presenti termini e condizioni. I partecipanti sanno che
            è presente una componente di casualità e che non viene fornita
            alcuna assicurazione se non l’aspettativa di una possibile vincita
            casuale.
            <br />
            Eventuali controversie dovranno essere inviate ad AGCO S.A.S. –
            Ufficio Legale – 41, Avenue Blaise Pascal – BP 60307 – BEAUVAIS
            (60026) – Francia, per posta non oltre il 3 settembre (farà fede la
            data del timbro postale).
            <br />
            Eventual disputes will be sent to AGCO SAS Legal department – 41
            avenue Blaise Pascal – BP 60307 - BEAUVAIS (60026) FRANCE, no later
            than September 3 (postmark as proof) directing a letter.
            <br />
            Queste disposizioni sono disciplinate dalla legge francese, che è
            l’unica applicabile in tali circostanze.
            <br />
            Il Reparto Marketing MASSEY FERGUSON EME di AGCO S.A.S. fornirà un
            feedback in relazione a eventuali difficoltà pratiche o richieste
            relative all’interpretazione o all’applicazione di queste
            disposizioni.
            <br />
          </Text>
          <Text color="red.500" fontWeight="bold" mt="1%">
            Articolo 11 – Divulgazione dei termini e delle condizioni del
            concorso
          </Text>
          <Text color="white" mt="1%">
            I presenti termini e condizioni saranno disponibili online sulla
            piattaforma ufficiale dell’evento MF.
            <br />
            Una copia completa dei presenti termini e condizioni è disponibile
            presso l’ufficio legale dell’azienda organizzatrice.
            <br />
          </Text>
          <Text color="red.500" fontWeight="bold" mt="1%">
            Articolo 12 – Protezione dei dati personali
          </Text>
          <Text color="white" mt="1%">
            Per partecipare a questo concorso, i partecipanti devono
            necessariamente accettare che alcune loro informazioni personali
            vengano utilizzate dall’azienda organizzatrice allo scopo di gestire
            il concorso, ma anche per finalità di marketing.
            <br />
            Per maggiori informazioni, i partecipanti possono consultare la
            nostra Informativa sulla privacy al seguente indirizzo:{" "}
            <Link href="http://www.agcocorp.com/privacy.html">
              http://www.agcocorp.com/privacy.html
            </Link>
            <br />
            Ciascun vincitore accetta, senza alcuno scambio finanziario o altro
            tipo di rimborso, di concedere all’azienda organizzatrice il diritto
            di utilizzare il suo nome, il suo cognome, un’indicazione sommaria
            del suo luogo di residenza e le sue foto per tutti i supporti
            editoriali o promozionali.
            <br />
            In conformità con le disposizioni applicabili, i partecipanti
            possono ritirare la propria partecipazione, in toto o in parte, in
            qualsiasi momento e senza fornire alcuna giustificazione ad AGCO. I
            partecipanti possono esercitare i propri diritti di accesso,
            correzione, cancellazione e opposizione scrivendo un’email
            all’indirizzo dataprivacy@agcocorp.com
          </Text>
        </Flex>
      </chakra.div>
    </Container>
  );
};

export default TermsAndConditionsContentIT;
