import React, { FC, useRef, useState } from "react";
import axiosHelper from "../../helpers/axiosHelper";
import { Link } from "react-scroll";
import { Link as RRLink } from "react-router-dom";
import Constants from "../../helpers/constants";

import {
  chakra,
  useBreakpointValue,
  useMultiStyleConfig,
  Text,
  Flex,
  Box,
  Container,
  useOutsideClick,
  CloseButton,
  Image,
} from "@chakra-ui/react";

import { ChevronLeftIcon, ChevronRightIcon, ViewIcon } from "@chakra-ui/icons";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import ReactPlayer from "react-player";
import VideoCard from "./VideoCard";
import { useTranslation } from "react-i18next";

export type LayoutHeaderProps = {
  videoList: LibraryContent[];
  title?: string;
  showWatchAllButton?: boolean;
  padding?: string;
};

const VideoSection: FC<LayoutHeaderProps> = (
  { videoList, title, showWatchAllButton, padding }: LayoutHeaderProps,
  ...props
) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const [userProfile, setUserProfile] = useState<UserProfile>(
    axiosHelper.getUserProfile()!
  );

  const ref = useRef<any>();
  useOutsideClick({
    ref: ref,
    handler: () => {
      setToggled(false);
    },
  });

  const styles = useMultiStyleConfig("Hall", props);
  const customSlider = useRef<any>();
  const isMobile = useBreakpointValue({ base: true, lg: false });
  const [toggled, setToggled] = React.useState(false);
  const [activeIndex, setActiveIndex] = React.useState(-1);
  const [playvideo, setPlayvideo] = React.useState(true);
  const [videoObj, setVideoObj] = React.useState<LibraryContent>();
  const [showVideo, setShowVideo] = useState(true);
  const [hasSentPlay, setHasSentPlay] = useState(false);
  const { t } = useTranslation();

  const logStartPlay = () => {
    if (videoObj!=null){
      axiosHelper.logWebAppEvent("Video", videoObj.id, "PLAY")
      setHasSentPlay(true);
    }
  }
  const logStopPlay = () => {
    if (hasSentPlay) {
      if (videoObj!=null){
        axiosHelper.logWebAppEvent("Video", videoObj.id, "STOP")
      }
      setHasSentPlay(false);
    }
  }
  const logPausePlay = () => {
    logStopPlay();
  }
  const logEndedPlay = () => {
    logStopPlay();
  }

  const shouldDisplay = (video: LibraryContent) => {
    return (
      video.showInThisLanguage && video.visibleTo.includes(userProfile.userType)
    );
  };
  const renderArrows = () => {
    return (
      <Flex
        d={{ base: "flex", lg: videoList.length >= 5 ? "flex" : "none" }}
        justifyContent="space-between"
        my={{ base: "20px", lg: "5px" }}
      >
        <Flex
          onClick={() => customSlider.current.slickPrev()}
          cursor="pointer"
          zIndex="10"
        >
          <ChevronLeftIcon color="white" w="40px" h="40px" />
        </Flex>

        <Flex
          onClick={() => customSlider.current.slickNext()}
          cursor="pointer"
          zIndex="10"
        >
          <ChevronRightIcon color="white" w="40px" h="40px" />
        </Flex>
      </Flex>
    );
  };

  return (
    <Container
      maxW="container.xl"
      d="flex"
      flexDir="column"
      p={padding}
      pb="50px"
      zIndex="20"
    >
      <Flex
        alignItems="center"
        mt="30px"
        w="100%"
        justifyContent="left"
        flexDir={{ base: "column", lg: "row" }}
      >
        <Text as="h1" textStyle="h1" alignSelf="flex-start" mr="40px">
          {title}
        </Text>
        {showWatchAllButton ? (
          <Link
            to="hdays"
            smooth={true}
            style={{
              width: isMobile ? "100%" : "auto",
            }}
          >
            <Text
              style={{
                fontSize: "18px",
                fontFamily: "Arial",
                fontWeight: "bold",
                letterSpacing: "0px",
                color: "#FFFFFF",
                opacity: 1,
                textTransform: "uppercase",
              }}
              bgColor="red.500"
              py="5px"
              px={{ lg: "30px" }}
              // ml="20px"
              cursor="pointer"
              whiteSpace="nowrap"
              alignItems="center"
              textAlign="center"
              my={{ base: "20px", lg: "0" }}
              w={{ base: "100%", lg: "auto" }}
            >
              {t("hallhero.watch")}
            </Text>
          </Link>
        ) : (
          ""
        )}
      </Flex>
      <Flex
        w={{ base: "100vw", lg: "100%" }}
        flexDir="column"
        ml={{ base: "-2rem", lg: "0px" }}
        mt="15px"
      >
        <Slider
          ref={(slider: any) => (customSlider.current = slider)}
          dots={isMobile ? false : true}
          infinite={false}
          speed={500}
          slidesToShow={isMobile ? 1 : 5}
          accessibility={true}
          autoplay={false}
          autoplaySpeed={5000}
          arrows={false}
          centerPadding={isMobile ? "60px" : "0px"}
          centerMode={isMobile ? true : false}
          slidesToScroll={isMobile ? 1 : 5}
        >
          {videoList.filter(shouldDisplay).map((video, index) => (
            <VideoCard
              video={video}
              key={index}
              active={index == activeIndex}
              onClickEvent={(video, toggled) => {
                logStopPlay();
                console.log("STOP " + videoObj?.id);
                console.log("Video-->", video);
                setToggled(toggled);
                setVideoObj(video);
                if (!toggled) index = -1;
                setActiveIndex(index);
              }}
            />
          ))}
        </Slider>
        <Box
          display={{ base: "none", lg: "block" }}
          mt="1rem"
          mr={{ base: "4rem", lg: "0px" }}
        >
          {renderArrows()}
        </Box>
      </Flex>

      {toggled && (
        <Flex
          ref={ref}
          className="container-section"
          flexDirection={{ base: "column-reverse", lg: "row" }}
          justifyContent="space-around"
          padding="14px"
          onClick={() => {
            setPlayvideo(true);
          }}
        >
          <div className="container-txt">
            <Text className="title-sec-player" mt="5px" mb="20px">
              {videoObj?.nameLocalized}
            </Text>
            <Text className="subtitle-sec-player" mt="5px">
              {videoObj?.descriptionLocalized}
            </Text>
            <Text className="subtitle-2-player" mt="5px" mb="20px">
              <span id="subtitle-2-span"> 100% </span>
              {" - "}
              {videoObj?.dateLanguageLocalized}
            </Text>
            <Text className="text-player" mt="5px">
              {videoObj?.synopsisLocalized}
            </Text>
            <Text className="text2-player" mt="5px" mb="20px">
              {t("library.video.starring")} {videoObj?.starringLocalized}
            </Text>
            <Text className="text3-player" mt="5px">
              {videoObj?.type === "V" && (
                <>
                  {t("library.video.otherinfo")} {videoObj?.extraInfoLocalized}
                </>
              )}
              {videoObj?.type === "L" && (
                <>
                  {t("library.content.otherifo")} {videoObj?.extraInfoLocalized}
                </>
              )}
            </Text>
            {videoObj?.type === "L" && (
              <RRLink
                to={{ pathname: videoObj?.urlPathLocalized }}
                target="__blank"
                style={{
                  width: "auto",
                  textDecoration: "none",
                  marginTop: "20px",
                }}
              >
                <Text
                  style={{
                    fontSize: "18px",
                    fontFamily: "Arial",
                    fontWeight: "bold",
                    letterSpacing: "0px",
                    color: "#FFFFFF",
                    opacity: 1,
                    textTransform: "uppercase",
                    textDecoration: "none",
                  }}
                  bgColor="red.500"
                  py="5px"
                  px={{ lg: "30px" }}
                  // ml="20px"
                  cursor="pointer"
                  whiteSpace="nowrap"
                  alignItems="center"
                  textAlign="center"
                  my={{ base: "20px", lg: "0" }}
                  w={{ base: "auto" }}
                >
                  {t("library.link")}
                </Text>
              </RRLink>
            )}
          </div>
          {/* <div className="player-container"> */}
          <Flex w="100%">
            <CloseButton
              size="md"
              color="white"
              bgColor="gray"
              borderRadius="50%"
              onClick={() => { setToggled(false); logStopPlay(); }}
              position="absolute"
              right="5rem"
            />
            {videoObj?.type === "V" && (
              <ReactPlayer
                url={videoObj?.urlPathLocalized}
                playing={playvideo}
                controls={true}
                onStart={logStartPlay}
                onPause={logPausePlay}
                onEnded={logEndedPlay}
                width={640}
                height={360}
              />
            )}
            {videoObj?.type === "L" && (
              <Image
                src={
                  Constants.VIDEO_THUMB_PATH +
                  "active/" +
                  videoObj.thumbUrlPath +
                  "-big.jpg"
                }
                width={640}
                height={360}
              />
            )}
          </Flex>

          {/* </div> */}
        </Flex>
      )}
    </Container>
  );
};

export default VideoSection;
