import { useTranslation } from "react-i18next";
import TermsAndConditionsContentEN from "./TermsAndConditionsContentEN";
import TermsAndConditionsContentDA from "./TermsAndConditionsContentDA";
import TermsAndConditionsContentDE from "./TermsAndConditionsContentDE";
import TermsAndConditionsContentES from "./TermsAndConditionsContentES";
import TermsAndConditionsContentFR from "./TermsAndConditionsContentFR";
import TermsAndConditionsContentIT from "./TermsAndConditionsContentIT";
import TermsAndConditionsContentNL from "./TermsAndConditionsContentNL";
import TermsAndConditionsContentNO from "./TermsAndConditionsContentNO";
import TermsAndConditionsContentPL from "./TermsAndConditionsContentPL";
import TermsAndConditionsContentPT from "./TermsAndConditionsContentPT";
import TermsAndConditionsContentPTBR from "./TermsAndConditionsContentPTBR";
import TermsAndConditionsContentRU from "./TermsAndConditionsContentRU";
import TermsAndConditionsContentSV from "./TermsAndConditionsContentSV";
import TermsAndConditionsContentTR from "./TermsAndConditionsContentTR";
import TermsAndConditionsContentUK from "./TermsAndConditionsContentUK";

const TermsAndConditionsContent = ({ ...props }) => {
  const { t } = useTranslation();

  return <>{t("LNG") === "en" && <TermsAndConditionsContentEN />}
  {t("LNG") === "da" && <TermsAndConditionsContentDA />}
  {t("LNG") === "de" && <TermsAndConditionsContentDE />}
  {t("LNG") === "es" && <TermsAndConditionsContentES />}
  {t("LNG") === "fr" && <TermsAndConditionsContentFR />}
  {t("LNG") === "it" && <TermsAndConditionsContentIT />}
  {t("LNG") === "nl" && <TermsAndConditionsContentNL />}
  {t("LNG") === "no" && <TermsAndConditionsContentNO />}
  {t("LNG") === "pl" && <TermsAndConditionsContentPL />}
  {t("LNG") === "pt" && <TermsAndConditionsContentPT />}
  {t("LNG") === "pt-BR" && <TermsAndConditionsContentPTBR />}
  {t("LNG") === "ru" && <TermsAndConditionsContentRU />}
  {t("LNG") === "sv" && <TermsAndConditionsContentSV />}
  {t("LNG") === "tr" && <TermsAndConditionsContentTR />}
  {t("LNG") === "uk" && <TermsAndConditionsContentUK />}</>;
};

export default TermsAndConditionsContent;
