import React, { useEffect, useState, FC } from "react";
import { useTranslation } from "react-i18next";
import axiosHelper from "../helpers/axiosHelper";
import Constants from "../helpers/constants";
import {
  chakra,
  useMultiStyleConfig,
  Container,
  Flex,
  Image,
  Text,
  Wrap,
} from "@chakra-ui/react";
import Footer from "../layouts/Footer";
import Clock from "../components/home/counter/Clock";

import background from "../assets/home/Visuel-Sans-Textes-Degrade.png";
import logo from "./../assets/logo/MFlogo RGB187_K_S.png";
import bornToFarm from "./../img/btf-comingsoon.png";
import bgImage from "./../assets/home/counter/shutterstock_1117734563/shutterstock_1117734563@2x.png";

export type LayoutHeaderProps = {};

const ComingSoon: FC<LayoutHeaderProps> = ({}: LayoutHeaderProps, ...props) => {
  const styles = useMultiStyleConfig("Hall", props);
  const endDate = new Date(Constants.ONLINE_DATE);
  const { t } = useTranslation();

  const [time, setNewTime] = useState({
    days: 0,
    hours: 0,
    mins: 0,
    secs: 0,
  });

  useEffect(() => {
    // axiosHelper.logWebAppEvent("Coming Soon", null, null);
    const interval = setInterval(() => {
      const date = calculateCountDown(endDate);
      setNewTime(date);
    }, 1000);
    return function cleanup() {
      clearInterval(interval);
    };
  }, [endDate]);

  const calculateCountDown = (endDate: Date) => {
    let startDate = new Date();
    let diff = (endDate.getTime() - startDate.getTime()) / 1000;

    const timeLeft = {
      days: 0,
      hours: 0,
      mins: 0,
      secs: 0,
    };

    if (diff <= 0) return timeLeft;

    if (diff >= 86400) {
      // 24 * 60 * 60
      timeLeft.days = Math.floor(diff / 86400);
      diff -= timeLeft.days * 86400;
    }
    if (diff >= 3600) {
      // 60 * 60
      timeLeft.hours = Math.floor(diff / 3600);
      diff -= timeLeft.hours * 3600;
    }
    if (diff >= 60) {
      timeLeft.mins = Math.floor(diff / 60);
      diff -= timeLeft.mins * 60;
    }
    timeLeft.secs = Math.floor(diff);

    return timeLeft;
  };

  return (
    <chakra.div d="flex" flexDir="column" bgColor="#000000" minH="100vh">
      <chakra.div
        h="121px"
        position="sticky"
        top="0px"
        zIndex="100"
        flexShrink={0}
      >
        <Flex h={{ base: "48px", lg: "52px" }} bgColor="white">
          <Container maxW="container.xl" d="flex" flexDir="row">
            <Flex
              boxShadow="0px 1px 45px rgba(63, 63, 68, 0.35)"
              bgColor="white"
              h={{ base: "70px", lg: "119px" }}
              w={{ base: "100px", lg: "170px" }}
              justifyContent="center"
              alignItems="center"
            >
              <Image
                objectFit="cover"
                objectPosition="center"
                bgRepeat="no-repeat"
                overflow="hidden"
                alignItems="center"
                justifyContent="center"
                src={logo}
                h={{ base: "40px", lg: "68px" }}
                w={{ base: "70px", lg: "120px" }}
              />
            </Flex>

            <Flex
              w={{ base: "100%", lg: "100%" }}
              justifyContent={{ base: "flex-end" }}
              alignItems="center"
            >
              {/* <LanguageSwitcher /> */}
            </Flex>
          </Container>
        </Flex>
      </chakra.div>

      <chakra.div>
        <chakra.div w="100%" marginTop="-100px" bgColor="#000000">
          <Container
            maxW="container.xl"
            bgImage={background}
            sx={styles.heroContainer}
            p="1rem!important"
          >
            <Image
              objectFit="cover"
              objectPosition="center"
              bgRepeat="no-repeat"
              overflow="hidden"
              alignItems="center"
              justifyContent="center"
              src={bornToFarm}
              margin="0 auto"
              marginBottom={{ base: "140px", lg: "200px" }}
              w={{ base: "100%", lg: "80%" }}
            />
            <Text
              w="100%"
              color="white"
              fontSize={{ base: "30px", lg: "60px" }}
              fontWeight="bold"
              align="center"
            >
              COMING SOON
            </Text>
          </Container>
        </chakra.div>

        <Container
          maxW="container.xl"
          bgImage={bgImage}
          overflow="hidden"
          backgroundPosition="center"
          backgroundSize="cover"
          backgroundRepeat="no-repeat"
          flexDir="row"
          d="flex"
          pt={{ lg: "56px" }}
          pb={{ lg: "20px" }}
          justifyContent="space-between"
          padding="30px 0 0 0"
        >
          <Flex flexDir="column" w="full">
            <Wrap
              justify={"center"}
              spacing={{ base: "20px", lg: "30px" }}
              mb="30px"
            >
              <Clock
                digit={time.days}
                label={t("countdown.days")}
                maxNum={60}
                id={"hdays"}
                width={"170px"}
              />
              <Clock
                digit={time.hours}
                label={t("countdown.hours")}
                maxNum={24}
                id={"hhours"}
                width={"170px"}
              />
              <Clock
                digit={time.mins}
                label={t("countdown.minutes")}
                maxNum={60}
                id={"hmins"}
                width={"170px"}
              />
              <Clock
                digit={time.secs}
                label={t("countdown.seconds")}
                maxNum={60}
                id={"hsecs"}
                width={"170px"}
              />
            </Wrap>
          </Flex>
        </Container>
      </chakra.div>
      <Footer />
    </chakra.div>
  );
};

export default ComingSoon;
