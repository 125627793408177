import React, { FC, useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import {
  Container,
  Text,
  Flex,
  Image,
  useBreakpointValue,
} from "@chakra-ui/react";
import axiosHelper from "../../helpers/axiosHelper";
import Constants from "../../helpers/constants";

import triangleUp from "../../assets/plenary/triangoloUp.svg";
import triangleDown from "../../assets/plenary/triangoloDown.svg";

type Props = {
  showLastMessage: boolean
}
const PlenaryLeaderboard = (props: Props) => {
  const { showLastMessage } = props;
  const { t } = useTranslation();
  const isMobile = useBreakpointValue({ base: true, lg: false });
  const [ranking, setRanking] = useState(0);
  const [imgSequenceNo, setImgSequenceNo] = useState(1);

  const calculateImages = () => {
    setTimeout(() => {
      changeSequenceNo();
    }, 60000);
  };

  const nextSequenceNo = () => {
    if (imgSequenceNo === 3) {
      return 1;
    } else {
      return imgSequenceNo + 1;
    }
  };
  const changeSequenceNo = () => {
    setImgSequenceNo(nextSequenceNo);
  };

  useEffect(() => {
    fetchRanking();
    calculateImages();
  }, [ranking, calculateImages]);

  const fetchRanking = async () => {
    try {
      const result = await axiosHelper.getRank();
      if (result != null) {
        setRanking(result.rank);
      }
    } catch {
      // console.log("no ");
    }
  };

  return (
    <>
      <Flex
        maxW="container.xl"
        d="flex"
        flexFlow="column nowrap"
        alignItems="stretch"
        m={isMobile ? "0" : "1rem 2rem 2rem 2rem"}
        bgColor="rgba(255, 255, 255, 0.1)"
        boxShadow="0px 3px 6px #00000029"
        width={isMobile?"100%":"auto"}
      >
        <Flex d="flex" flexFlow="column nowrap" flexGrow={1} alignItems="stretch">
          <Flex d="flex" flexDir="column" p={isMobile ? "1rem" : "2rem"} pb="0">
            <Flex
              fontSize={isMobile ? "22px" : "40px"}
              lineHeight="40px"
              color="white"
              fontFamily="Arial Black, Arial-BoldMT, Arial"
              textTransform="uppercase"
              pb=".5rem"
              alignItems="center"
              justifyContent={isMobile ? "center" : "flex-start"}
              textAlign="center"
            >
              {t("counterhall.leaderboard")}
            </Flex>
            {showLastMessage &&
              <Flex
                fontSize={isMobile ? "12px" : "14px"}
                color="white"
                textAlign={isMobile ? "center" : "left"}
              >
                {/*  TODO X WOLF: QUESTO MESSAGGIO VA MESSO SOLO ALL'ULTIMA DOMANDA (NASCONDERE TUTTO IL DIV) */}
                {t("plenary.leaderboard.text")}
              </Flex>
            }

            {/* Puntatore Mobile */}
            {isMobile && (
              <>
                <Flex
                  w="100%"
                  d="flex"
                  flexDir="row"
                  alignItems="center"
                  justifyContent="center"
                  fontFamily="Arial Black, Arial-BoldMT, Arial"
                  color="red.500"
                  fontSize="30px"
                >
                  {ranking}
                </Flex>
                <Flex
                  w="100%"
                  d="flex"
                  flexDir="row"
                  justifyContent="space-between"
                  alignItems="stretch"
                  borderTop="1px dotted #c41230"
                >
                  {!isMobile && (
                    <Flex flexGrow={1} flexBasis={0} mr="1rem"></Flex>
                  )}
                  <Flex flexGrow={1} flexBasis={0} mx="1rem">
                    {ranking <= 1000 && (
                      <Image src={triangleDown} alt="Pointer" margin="0 auto" />
                    )}
                  </Flex>
                  <Flex flexGrow={1} flexBasis={0} mx="1rem">
                    {ranking > 1000 && ranking <= 2000 && (
                      <Image src={triangleDown} alt="Pointer" margin="0 auto" />
                    )}
                  </Flex>
                  <Flex flexGrow={1} flexBasis={0} ml="1rem">
                    {ranking > 2000 && (
                      <Image src={triangleDown} alt="Pointer" margin="0 auto" />
                    )}
                  </Flex>
                </Flex>
              </>
            )}

            {/* Punteggi */}
            <Flex
              w="100%"
              d="flex"
              flexDir="row"
              justifyContent="space-between"
              alignItems="stretch"
              mt="1rem"
              fontSize={isMobile ? ".8rem" : "inherit"}
              fontWeight={isMobile ? "normal" : "bold"}
              fontFamily={
                isMobile ? "inherit" : "Arial Black, Arial-BoldMT, Arial"
              }
            >
              {!isMobile && <Flex flexGrow={1} flexBasis={0} mr="1rem"></Flex>}
              <Flex
                flexGrow={1}
                flexBasis={0}
                justifyContent={isMobile ? "space-around" : "flex-start"}
                mx="1rem"
                color={ranking <= 1000 ? "red.500" : "#707070"}
              >
                1 - 1.000
              </Flex>
              <Flex
                flexGrow={1}
                flexBasis={0}
                justifyContent={isMobile ? "space-around" : "flex-start"}
                mx="1rem"
                color={
                  ranking > 1000 && ranking <= 2000 ? "red.500" : "#707070"
                }
              >
                1.001 - 2.000
              </Flex>
              <Flex
                flexGrow={1}
                flexBasis={0}
                justifyContent={isMobile ? "space-around" : "flex-start"}
                ml="1rem"
                color={ranking > 2000 ? "red.500" : "#707070"}
              >
                2.001+
              </Flex>
            </Flex>
            {/* Immagini gift */}
            <Flex
              w="100%"
              d="flex"
              flexDir="row"
              justifyContent="space-between"
              alignContent="stretch"
              mb={isMobile ? "2rem" : ".5rem"}
            >
              {!isMobile && (
                <Flex
                  flexGrow={1}
                  flexBasis={0}
                  border="1px dotted #c41230"
                  mr="1rem"
                  fontSize="55px"
                  justifyContent="space-around"
                  fontFamily="Arial Black, Arial-BoldMT, Arial"
                >
                  <Text
                    color="red.500"
                    alignSelf="center"
                    textAlign="center"
                    w="100%"
                    margin="0 auto"
                    flexGrow={1}
                    flexBasis={0}
                  >
                    {ranking}
                  </Text>
                </Flex>
              )}
              <Flex
                d="flex"
                flexDir="column"
                flexGrow={1}
                flexBasis={0}
                alignItems="center"
                justifyContent="center"
                mx="1rem"
              >
                {isMobile && (
                  <Image
                    src={
                      ranking <= 1000
                        ? Constants.LEADERBOARD_PATH +
                        `active/Level-1-Gift-${imgSequenceNo}.png`
                        : Constants.LEADERBOARD_PATH +
                        `inactive/Level-1-Gift-${imgSequenceNo}.png`
                    }
                    alt="Prize"
                  />
                )}
                {!isMobile && (
                  <Image
                    src={
                      ranking <= 1000
                        ? Constants.LEADERBOARD_PATH +
                        `active/landscape/Level-1-Gift-${imgSequenceNo}.png`
                        : Constants.LEADERBOARD_PATH +
                        `inactive/landscape/Level-1-Gift-${imgSequenceNo}.png`
                    }
                    alt="Prize"
                  />
                )}
              </Flex>
              <Flex
                flexGrow={1}
                flexBasis={0}
                mx="1rem"
                alignItems="center"
                justifyContent="center"
              >
                {isMobile && (
                  <Image
                    src={
                      ranking > 1000 && ranking <= 2000
                        ? Constants.LEADERBOARD_PATH +
                        `active/Level-2-Gift-${imgSequenceNo}.png`
                        : Constants.LEADERBOARD_PATH +
                        `inactive/Level-2-Gift-${imgSequenceNo}.png`
                    }
                    alt="Prize"
                  />
                )}
                {!isMobile && (
                  <Image
                    src={
                      ranking > 1000 && ranking <= 2000
                        ? Constants.LEADERBOARD_PATH +
                        `active/landscape/Level-2-Gift-${imgSequenceNo}.png`
                        : Constants.LEADERBOARD_PATH +
                        `inactive/landscape/Level-2-Gift-${imgSequenceNo}.png`
                    }
                    alt="Prize"
                  />
                )}
              </Flex>
              <Flex
                flexGrow={1}
                flexBasis={0}
                ml="1rem"
                alignItems="center"
                justifyContent="center"
              >
                {isMobile && (
                  <Image
                    src={
                      ranking > 2000
                        ? Constants.LEADERBOARD_PATH +
                        `active/Level-3-Gift-${imgSequenceNo}.png`
                        : Constants.LEADERBOARD_PATH +
                        `inactive/Level-3-Gift-${imgSequenceNo}.png`
                    }
                    alt="Prize"
                  />
                )}
                {!isMobile && (
                  <Image
                    src={
                      ranking > 2000
                        ? Constants.LEADERBOARD_PATH +
                        `active/landscape/Level-3-Gift-${imgSequenceNo}.png`
                        : Constants.LEADERBOARD_PATH +
                        `inactive/landscape/Level-3-Gift-${imgSequenceNo}.png`
                    }
                    alt="Prize"
                  />
                )}
              </Flex>
            </Flex>
            {/* Puntatore Desktop */}
            {!isMobile && (
              <Flex
                w="100%"
                d="flex"
                flexDir="row"
                justifyContent="space-between"
                alignItems="stretch"
                mb="2rem"
              >
                {!isMobile && (
                  <Flex flexGrow={1} flexBasis={0} mr="1rem"></Flex>
                )}
                <Flex flexGrow={1} flexBasis={0} mx="1rem">
                  {ranking <= 1000 && (
                    <Image src={triangleUp} alt="Pointer" margin="0 auto" />
                  )}
                </Flex>
                <Flex flexGrow={1} flexBasis={0} mx="1rem">
                  {ranking > 1000 && ranking <= 2000 && (
                    <Image src={triangleUp} alt="Pointer" margin="0 auto" />
                  )}
                </Flex>
                <Flex flexGrow={1} flexBasis={0} ml="1rem">
                  {ranking > 2000 && (
                    <Image src={triangleUp} alt="Pointer" margin="0 auto" />
                  )}
                </Flex>
              </Flex>
            )}
          </Flex>
        </Flex>
      </Flex>
    </>
  );
};

export default PlenaryLeaderboard;
