import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { chakra, Container } from "@chakra-ui/react";

import axiosHelper from "../helpers/axiosHelper";

import Header from "../components/Header";

import ClusterContent from "../components/cluster/ClusterContent";
import MainLayout from "../layouts/MainLayout";
import MainLayoutLogin from "../layouts/MainLayoutLogin";

const Cluster = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const [userProfile, setUserProfile] = useState<UserProfile>(
    axiosHelper.getUserProfile()!
  );
  useEffect(() => {}, [userProfile]);

  return (
    <MainLayoutLogin>
      <Container maxW="container.xl" d="flex" flexDir="column">
        <Header
          title={t("clusterchoice.title")}
          logo
          date="02/09/2021 - 00:00"
          mainLayoutLogin
        />
      </Container>
      <ClusterContent />
    </MainLayoutLogin>
  );
};

export default Cluster;
