import React, { useState, useRef } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";

import {
  chakra,
  useBreakpointValue,
  useMultiStyleConfig,
  Container,
  Text,
  Link,
  Flex,
  Divider,
  ListItem,
  UnorderedList,
  SimpleGrid,
  Box,
} from "@chakra-ui/react";

import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import axiosHelper from "./../../helpers/axiosHelper";
import bgImage from "./../../assets/prizes/prizes-page-bg@2x.jpg";

import { ChevronLeftIcon, ChevronRightIcon } from "@chakra-ui/icons";

const TermsAndConditionsContentPT = ({ ...props }) => {
  const { t } = useTranslation();
  const history = useHistory();
  const [userProfile, setUserProfile] = useState<UserProfile>(
    axiosHelper.getUserProfile()!
  );
  const styles = useMultiStyleConfig("PrizePage", props);

  const isMobile = useBreakpointValue({ base: true, lg: false });
  const customSlider = useRef<any>();

  return (
    <Container
      maxW="container.xl"
      bgImage={bgImage}
      d="flex"
      flexDir="column"
      flexDirection="column"
      style={{
        overflow: "hidden",
        backgroundPosition: "center",
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
      }}
      alignItems="center"
    >
      <Flex
        flexDir={{ base: "column", lg: "column" }}
        justifyContent="space-between"
        w={isMobile?"100%":"50%"}
        mt="2%"
      >
        <Text color="white" mb="2%" mt={isMobile ? "30px" : "0"} align="center">
          TERMOS E CONDIÇÕES
          <br />
          SUPER CONCURSO MF
          <br />
          Organizado de 2 de Julho de 2021 a 3 de Setembro de 2021
          <br />
          Pela marca MASSEY FERGUSON AGCO
          <br />
        </Text>
      </Flex>
      <chakra.div w="100%" pb="100px">
        <Flex
          flexDir={{ base: "column", lg: "column" }}
          justifyContent="space-between"
          w="100%"
          mt="3%"
        >
          <Text color="red.500" mt="1%" fontWeight="bold">
            Artigo 1 - Organização Geral
          </Text>
          <Text color="white" mt="1%">
            O departamento de Marketing EME MASSEY FERGUSON da AGCO SAS, com
            sede em 41 Blaise Pascal Avenue em Beauvais, está a organizar o NOVO
            EVENTO DIGITAL MASSEY FERGUSON no dia 3 de Setembro, incluindo um
            concurso de jogo gratuito e sem compromisso para o público geral.
          </Text>

          <Text color="red.500" fontWeight="bold" mt="1%">
            Artigo 2 - Elegibilidade para participar
          </Text>

          <Text color="white" mt="1%">
            Apenas os participantes de países Europeus e do Médio Oriente
            (exceto os países sujeitos a medidas restritivas do comércio) são
            elegíveis para participar. Apenas agricultores, empresas e aqueles
            relacionados a empresas agrícolas podem ganhar nesta competição.
          </Text>

          <Text color="white" mt="1%">
            Estão excluídos:
          </Text>

          <chakra.div>
            <UnorderedList ml="40px" color="white">
              <ListItem>Todas as pessoas com menos de 18 anos.</ListItem>
              <ListItem>Funcionários do Grupo AGCO e as suas famílias</ListItem>
              <ListItem>
                Concessionárias e distribuidores de produtos e serviços AGCO
              </ListItem>
              <ListItem>Empregados de empresas afiliadas com a AGCO</ListItem>
              <ListItem>
                Agências e Fornecedores contratados pela AGCO e familiares dos
                respetivos empregados
              </ListItem>
              <ListItem>
                Jornalistas convidados pela MASSEY FERGUSON para participar no
                evento
              </ListItem>
            </UnorderedList>
          </chakra.div>
          <Text color="white" mt="1%">
            Só é permitida uma participação por pessoa, por agregado familiar e
            entidade agrícola, mesmo nome, mesmo nome próprio, mesmo número de
            telefone, mesmo endereço de e-mail. A participação no jogo é
            estritamente pessoal. A empresa organizadora reserva o direito de
            realizar os controlos que julgar apropriados.
          </Text>
          <Text color="red.500" fontWeight="bold" mt="1%">
            Artigo 3 - Termos de participação
          </Text>
          <Text color="white" mt="1%">
            Ao inscrever-se no NOVO EVENTO DIGITAL MASSEY FERGUSON através da
            plataforma web da divisão organizadora da AGCO SAS, o participante
            participa automaticamente no concurso de jogos, pois é a base do
            evento. O participante consente em dar acesso às suas informações
            pessoais para ser contactado pela AGCO SAS via e-mail, telefone,
            redes sociais.
          </Text>
          <Text color="red.500" fontWeight="bold" mt="1%">
            Artigo 4 - Determinação dos vencedores
          </Text>
          <Text color="white" mt="1%">
            O resultado do concurso será anunciado entre 6 de setembro de 2021 e
            6 de outubro de 2021, nos escritórios da AGCO SAS. - Departamento de
            Marketing EME da Massey Ferguson em Beauvais - França.
          </Text>
          <Box display="block" mt="1%"></Box>
          <Text color="white">
            O sorteio terá lugar entre 6 de Setembro de 2021 e 6 de Outubro de
            2021, nos escritórios da AGCO SAS. - Departamento de Marketing EME
            da Massey Ferguson em Beauvais - França. O Departamento de Marketing
            EME da Massey Ferguson em Beauvais vai realizar este sorteio à porta
            fechada.
          </Text>
          <Text color="red.500" fontWeight="bold" mt="1%">
            Artigo 5 – Prémios
          </Text>
          <Text color="white" mt="1%">
            Haverá 3 níveis e 3 prémios por nível, portanto 9 vencedores no
            total neste concurso de jogo. Ao responder às perguntas após a
            inscrição, o participante terá a possibilidade de aumentar a sua
            pontuação e tentar alcançar o nível de prémios mais alto nesta
            competição.
          </Text>
          <Text color="white" mt="1%">
            Após o evento, cada participante será designado em 1 dos 3 prémios
            abaixo, dependendo da sua posição final no ranking do jogo - Posição
            final declarada na plataforma Web logo após o evento (3 de Setembro
            de 2021, 15:00h). Mesmo que o participante não responda a quaisquer
            perguntas, será automaticamente incluído no nível 3 de presentes
            para ganhar.
          </Text>

          <Text color="white" fontWeight="bold" mt="1%" fontStyle="italic">
            Nível 1: Vale até 20.000 euros - Classificação total: 1 - 1.000
          </Text>
          <Text color="white" mt="1%">
            Para os participantes da posição 1 à posição 1.000 na competição,
            serão incluídos na lista de participantes para o sorteio dos prêmios
            de nível 1.
            <br /> 3 presentes são acessíveis neste nível, com um valor máximo
            de 20 580 euros.
          </Text>
          <UnorderedList color="white" ml="40px" mt="1%">
            <ListItem>
              O primeiro prémio neste nível 1 é um desconto até 10 000 euros na
              compra de uma nova Máquina MF.
              <br />
              <br />
              Este desconto aplica-se apenas na compra de máquinas novas:
              Ferramentas para feno, Tratores, Ceifeira-Debulhadora,
              Enfardadeiras e Empilhadores telescópicos (tendo em conta o stock
              de máquinas no concessionário) da gama MASSEY FERGUSON, feito
              antes do dia 31 de Março de 2022.
              <br />
              <br />
              Regras de Desconto (* com base nos preços de venda a público, com
              opções incluídas):
              <br />
              <SimpleGrid
                w={isMobile ? "100%" : "50%"}
                autoFlow=""
                borderColor="white"
                columns={[2, null, 2]}
                border="1px solid white"
                mt="5px"
              >
                <Box bg="red.500" border="1px solid white">
                  <Text
                    color="white"
                    fontWeight="bold"
                    padding="1.5"
                    fontSize={isMobile ? ".7rem" : "1rem"}
                  >
                    Valor da Nova Máquina*:
                  </Text>
                </Box>
                <Box bg="red.500" border="1px solid white">
                  <Text
                    color="white"
                    fontWeight="bold"
                    padding="1.5"
                    fontSize={isMobile ? ".7rem" : "1rem"}
                  >
                    Desconto a aplicar
                  </Text>
                </Box>
                <Box border="1px solid white">
                  <Text
                    color="white"
                    padding="1.5"
                    fontSize={isMobile ? ".7rem" : "1rem"}
                  >
                    Abaixo de 25 000 euros*
                  </Text>
                </Box>
                <Box border="1px solid white">
                  <Text
                    color="white"
                    padding="1.5"
                    fontSize={isMobile ? ".7rem" : "1rem"}
                  >
                    1500€
                  </Text>
                </Box>
                <Box border="1px solid white">
                  <Text
                    color="white"
                    padding="1.5"
                    fontSize={isMobile ? ".7rem" : "1rem"}
                  >
                    Entre 25.000 euros &gt; 50.000 euros*
                  </Text>
                </Box>
                <Box border="1px solid white">
                  <Text
                    color="white"
                    padding="1.5"
                    fontSize={isMobile ? ".7rem" : "1rem"}
                  >
                    2500€
                  </Text>
                </Box>
                <Box border="1px solid white">
                  <Text
                    color="white"
                    padding="1.5"
                    fontSize={isMobile ? ".7rem" : "1rem"}
                  >
                    Entre 50.000 euros &gt; 100.000 euros*
                  </Text>
                </Box>
                <Box border="1px solid white">
                  <Text
                    color="white"
                    padding="1.5"
                    fontSize={isMobile ? ".7rem" : "1rem"}
                  >
                    4000€
                  </Text>
                </Box>
                <Box border="1px solid white">
                  <Text
                    color="white"
                    padding="1.5"
                    fontSize={isMobile ? ".7rem" : "1rem"}
                  >
                    Entre 100.000 euros &gt; 150.000 euros*
                  </Text>
                </Box>
                <Box border="1px solid white">
                  <Text
                    color="white"
                    padding="1.5"
                    fontSize={isMobile ? ".7rem" : "1rem"}
                  >
                    6000€
                  </Text>
                </Box>
                <Box border="1px solid white">
                  <Text
                    color="white"
                    padding="1.5"
                    fontSize={isMobile ? ".7rem" : "1rem"}
                  >
                    Entre 150.000 euros &gt; 200.000 euros*
                  </Text>
                </Box>
                <Box border="1px solid white">
                  <Text
                    color="white"
                    padding="1.5"
                    fontSize={isMobile ? ".7rem" : "1rem"}
                  >
                    8000€
                  </Text>
                </Box>
                <Box border="1px solid white">
                  <Text
                    color="white"
                    padding="1.5"
                    fontSize={isMobile ? ".7rem" : "1rem"}
                  >
                    A partir de 200.000 euros*
                  </Text>
                </Box>
                <Box border="1px solid white">
                  <Text
                    color="white"
                    padding="1.5"
                    fontSize={isMobile ? ".7rem" : "1rem"}
                  >
                    10 000€
                  </Text>
                </Box>
              </SimpleGrid>
            </ListItem>
            <ListItem mt="26px">
              O segundo prémio neste nível 1 é uma opção MF Guide de correção
              RTK gratuita para qualquer Trator/Ceifeira-Debulhadora MF
              compatível, comprado antes de 31 de Março de 2022.
              <br />
              <br />
              A opção MF Guide precisa de ser selecionada pelo vencedor quando a
              máquina for comprada.
              <br />O valor máximo deste preço não excederá 16.060 euros. As
              taxas de subscrição anual estão excluídas (encargo para o
              vencedor).
            </ListItem>
            <ListItem mt="26px">
              O terceiro e último prémio neste nível 1 é um MF 1520 A Stage 5,
              por um valor de 19.890 euros*, incluindo um opcional de pneus no
              valor de 690 euros* (* Lista de Preços de Venda a Público de 1 de
              Fevereiro de 2021).
            </ListItem>
          </UnorderedList>

          <Text color="white" mt="1%">
            1 participante só pode ganhar um prémio no jogo total, todos os
            níveis combinados.
          </Text>
          <Text color="white" fontWeight="bold" mt="1%" fontStyle="italic">
            Nível 2: Vale até 5000 euros - Classificação total: 1001 - 2000
          </Text>
          <Text color="white" mt="1%">
            Para os participantes da posição 1001 à posição 2000 na competição,
            serão incluídos na lista de participantes para o sorteio dos prêmios
            de nível 2.
            <br />3 prémios serão acessíveis neste nível, com um valor máximo de
            5.000 euros.
          </Text>

          <UnorderedList color="white" ml="40px" mt="1%">
            <ListItem>
              O primeiro prémio neste nível 2 é um MF Summer Pack de um valor
              total de 1000 euros incluindo:
              <UnorderedList color="white">
                <ListItem ml="20px" type="circle">
                  Um conjunto de Grelhadores em Barril de Óleo MF/AGCO Parts e 4
                  espreguiçadeiras MF/AGCO Parts, para um valor total de 340
                  euros
                </ListItem>
              </UnorderedList>
              <Text color="white" ml="-40px">
                E
              </Text>
              <UnorderedList color="white">
                <ListItem ml="20px" type="circle">
                  Um iPad Air Grey WIFI 64 GO personalizado por um valor de 669
                  euros
                </ListItem>
              </UnorderedList>
            </ListItem>
          </UnorderedList>

          <Text color="white" mt="1%">
            Estes presentes serão entregues em separado ao vencedor.
          </Text>

          <UnorderedList color="white" ml="40px">
            <ListItem mt="1%">
              O segundo prémio neste nível 2 é uma máquina de lavagem de pressão
              profissional com um valor de 2000 euros.
              <br />
              <br />O modelo da máquina de lavagem de pressão apresentado não é
              contratual e pode mudar dependendo do mercado vencedor. A nossa
              equipa de produtos complementares AGCO Parts fornecerá detalhes
              exatos do modelo ao vencedor após a seleção.
            </ListItem>
            <ListItem mt="1%">
              O terceiro e último prémio neste nível 2 por um valor total de
              5000 euros é um pacote de Serviço MF que inclui:
              <UnorderedList color="white" listStyleType="circle" mt="1%">
                <ListItem>
                  Voucher de 5000 euros, utilizável na loja do concessionário MF
                  local do vencedor para quaisquer itens disponíveis na AGCO
                  Parts.
                </ListItem>
                <ListItem mt="1%">
                  A oportunidade de realizar 1 visita ao nosso Centro de Serviço
                  Técnico AGCO, acompanhado pelo concessionário local, para
                  aconselhamento sobre o uso do equipamento Tractor e Empilhador
                  Telescópico da Massey Ferguson.
                </ListItem>
              </UnorderedList>
            </ListItem>
          </UnorderedList>

          <Text color="white" mt="1%">
            1 participante só pode ganhar um prémio no jogo total, todos os
            níveis combinados.
          </Text>

          <Text color="white" fontWeight="bold" mt="2%" fontStyle="italic">
            Nível 3: Vale até 250 euros - Classificação total: 2001 – último
          </Text>

          <Text color="white" mt="2%">
            Para os participantes desde a posição 2001 até o final da
            classificação, serão incluídos na lista de participantes para o
            sorteio dos prémios de nível 3.
            <br /> 3 prémios serão acessíveis neste nível, com um valor total de
            250 euros.
          </Text>

          <UnorderedList color="white " mt="1%">
            <ListItem>
              O primeiro prémio neste nível 3 é um Connectivity Pack que inclui
              3 presentes exclusivos da MASSEY FERGUSON:
              <UnorderedList color="white">
                <ListItem type="circle">
                  Um relógio inteligente da MASSEY FERGUSON - Preço da unidade:
                  180 euros
                </ListItem>
                <ListItem type="circle">
                  Um conjunto de Auriculares MASSEY FERGUSON - Preço da unidade:
                  39,90 euros
                </ListItem>
                <ListItem type="circle">
                  Um carregador de bateria portátil da MASSEY FERGUSON S - Preço
                  da unidade: 29,90 euros
                </ListItem>
                <Box display="block" mt="1%"></Box>
              </UnorderedList>
            </ListItem>

            <ListItem mt="1%">
              O segundo prémio neste nível 3 é um Voucher Eletrónico com um
              valor de 250 euros, utilizável em várias prestações na loja online
              MASSEY FERGUSON:{" "}
              <Link href="https://shop.masseyferguson.com">
                https://shop.masseyferguson.com
              </Link>.{" "}
              Este voucher eletrónico é válido durante 36 meses após a sua
              receção.
            </ListItem>

            <ListItem mt="1%">
              O terceiro e último prémio neste nível 3 é um Pacote de Prémios
              incluindo:
              <UnorderedList color="white" listStyleType="circle">
                <ListItem>
                  Um Modelo de Escala MF 8S - Preço da unidade: 70 euros
                </ListItem>
                <ListItem>
                  O Modelo de Escala MF 5S exclusivo - Preço da unidade: 65
                  euros
                </ListItem>
                <ListItem>
                  Um rato sem fios para PC da MASSEY FERGUSON - Preço da
                  unidade: 15 euros
                </ListItem>
                <ListItem>
                  2 jogos Farming Simulator 2022 para PC - Preço da unidade: 40
                  euros
                </ListItem>
                <ListItem>
                  O exclusivo boné de basebol Farming Simulator 2022 FS - Preço
                  da unidade: 20 euros
                </ListItem>
              </UnorderedList>
            </ListItem>
          </UnorderedList>
          <Text color="white" mt="1%">
            1 participante só pode ganhar um prémio no jogo total, todos os
            níveis combinados.
          </Text>
          <Text color="red.500" fontWeight="bold" mt="1%">
            Artigo 6 - Notificação e anúncio dos vencedores
          </Text>
          <Text color="white" mt="1%">
            A Equipa de Marketing da Massey Ferguson irá notificar os vencedores
            enviando-lhes um e-mail a convidá-los a contactar a AGCO
            <br />
            Se a pessoa selecionada no sorteio não responder a este e-mail no
            prazo de 10 dias, será realizado um segundo e último sorteio e este
            primeiro vencedor será desclassificado.
            <br />
            O vencedor será contactado pela Empresa AGCO SAS via e-mail ou por
            outra forma para enviar as informações necessárias para a entrega do
            prémio.
            <br />
            O participante concorda que, no caso de ganhar a competição, o seu
            nome será anunciado publicamente em anúncios, promoções ou em sites
            e redes sociais relevantes.
            <br />
            O vencedor compromete-se a disponibilizar-se gratuitamente dentro de
            limites razoáveis para promoção de áudio, foto, imagem e/ou texto
            via rádio, internet, televisão ou imprensa escrita e concorda com a
            publicação e uso para fins publicitários pela AGCO e por empresas
            afiliadas à AGCO.
            <br />
          </Text>
          <Text color="red.500" fontWeight="bold" mt="1%">
            Artigo 7 - Exclusão de participantes
          </Text>

          <Text color="white" mt="1%">
            A AGCO reserva o direito de excluir participantes da competição.
            Isto aplica-se em particular às violações das condições de
            participação, à falsificação de dados pessoais ou à manipulação ou
            suspeita de manipulação. A AGCO também pode excluir participantes
            que sejam razoavelmente suspeitos de utilizar os serviços da AGCO.
            <br />
            Se a AGCO estabelecer posteriormente que o participante criou algum
            motivo que levou à exclusão ou não tinha realmente o direito de
            participar no sentido do Artigo 2, a AGCO poderá excluir o
            participante da distribuição ou entrega dos prêmios ou reclamar
            quaisquer prêmios já entregues.
          </Text>
          <Text color="red.500" fontWeight="bold" mt="1%">
            Artigo 8 - Levantamento de lotes
          </Text>
          <Text color="white" mt="1%">
            Uma troca, devolução ou compensação financeira não é permitida em
            qualquer circunstância.
            <br />
            A empresa organizadora pode substituir os prémios por outros, de
            valores equivalentes, se circunstâncias externas impedirem a
            atribuição dos prémios anunciados. Isto não é negociável e não
            estará disponível qualquer alternativa em dinheiro.
            <br />O presente será enviado ao vencedor no prazo de 3 meses após a
            equipa de Marketing da Massey Ferguson receber toda a informação
            necessária para a entrega deste presente.
          </Text>
          <Text color="red.500" fontWeight="bold" mt="1%">
            Artigo 9 - Termos de utilização & Garantia
          </Text>
          <Text color="white" mt="1%">
            Os organizadores declaram toda a responsabilidade por todos os
            incidentes e/ou acidentes que possam ocorrer durante o gozo do
            prémio atribuído e/ou devido à sua utilização.
            <br />
            Os organizadores reservam o direito de encurtar, ampliar, modificar
            ou cancelar esta operação se as circunstâncias assim o exigirem, sem
            incorrer em qualquer responsabilidade.
            <br />
            Os organizadores não podem ser responsabilizados em caso de
            interrupção do serviço causada por um incidente técnico das redes de
            comunicação ou por um incidente técnico fora do seu controlo. Os
            organizadores não podem ser responsabilizados se, por qualquer
            motivo, a competição for parcial ou completamente modificada, adiada
            ou cancelada. Nenhuma compensação pode ser reclamada. Quaisquer
            formulários ilegíveis, incompletos e falsificados não serão
            incluídos. Portanto, a empresa organizadora não aceita qualquer
            responsabilidade se os formulários não estiverem registados ou forem
            impossíveis de verificar.
            <br />
          </Text>
          <Text color="red.500" fontWeight="bold" mt="1%">
            Artigo 10 - Aceitação e Disputas
          </Text>

          <Text color="white" mt="1%">
            Ao entrar neste concurso, todos os participantes reconhecem que
            aceitam esses termos e condições.
            <br />
            Cada participante sabe que a intervenção do acaso não lhe pode dar
            uma garantia que não seja a da expetativa de um ganho possível e
            aleatório.
            <br />
            Eventuais disputas serão enviadas ao departamento jurídico da AGCO
            SAS - 41 avenue Blaise Pascal - BP 60307 - BEAUVAIS (60026) França,
            o mais tardar até 3 de setembro (carimbo do correio como prova) por
            carta.
            <br />
            Esta regulação é regida pela lei francesa, que é a única aplicável.
            <br />
            AGCO SAS. - O departamento de marketing EME da MASSEY FERGUSON
            fornecerá feedback sobre qualquer dificuldade prática, interpretação
            ou solicitação de aplicação sobre esta regulação.
            <br />
          </Text>
          <Text color="red.500" fontWeight="bold" mt="1%">
            Artigo 11 - Divulgação dos termos e condições do concurso
          </Text>
          <Text color="white" mt="1%">
            Estes termos e condições estarão disponíveis online, na plataforma
            de eventos oficial da MF.
            <br />
            O participante com uma ligação à Internet não poderá solicitar
            qualquer reembolso, uma vez que a participação não implica qualquer
            custo adicional.
            <br />
            Uma cópia completa desses termos e condições está localizada no
            departamento jurídico da Empresa Organizadora.
            <br />
          </Text>
          <Text color="red.500" fontWeight="bold" mt="1%">
            Artigo 12 - Proteção de dados pessoais
          </Text>
          <Text color="white" mt="1%">
            Para participar neste concurso, os participantes devem
            necessariamente concordar que algumas informações pessoais sobre si
            serão utilizadas pela empresa organizadora para fins de gestão do
            concurso, mas também para fins de marketing.
            <br />
            Para mais informações, pode consultar a nossa Política de
            Privacidade na seguinte hiperligação{" "}
            <Link href="http://www.agcocorp.com/privacy.html">
              http://www.agcocorp.com/privacy.html
            </Link>
            <br />
            Cada vencedor concorda, sem qualquer compensação financeira ou
            outra, em conceder à empresa organizadora o direito de utilizar o
            seu nome, nome próprio, indicação sumária do seu local de residência
            e fotografia para todos os apoios promocionais ou editoriais.
            <br />
            De acordo com os regulamentos aplicáveis, "pode retirar-se total ou
            parcialmente a qualquer momento e sem fornecer à AGCO qualquer
            justificação". Pode exercer os seus direitos de acesso, retificação,
            eliminação e oposição por e-mail para dataprivacy@agcocorp.com˝
          </Text>
        </Flex>
      </chakra.div>
    </Container>
  );
};

export default TermsAndConditionsContentPT;
