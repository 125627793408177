import React from "react";
import ReactDom from "react-dom";
import videojs from "video.js";
import "../lib/video-js.css";
import PollOverlay from "./PollOverlay";
import { getSubscription, removeSubscription } from "../helpers/lsHelper";
import Constants from "../helpers/constants";
import { isIOS } from "react-device-detect";
import { setTextRange } from "typescript";

class VideoPlayer extends React.Component {
  urlDelBottone;

  constructor(props) {
    super(props);
  }

  componentDidUpdate(previousProps) {
    var src = `${this.props.src}`
    var type = "application/x-mpegURL";
    if (src.endsWith('mp4')) {
      type = "video/mp4";
    }
    
    if (previousProps.src !== this.props.src) {
      // console.log(this.player);
      this.player.src({
        src: `${this.props.src}`,
        type: type,
      });
      this.player.pause();
      this.play();
    }
    if (previousProps.mute !== this.props.mute){
      console.log('TOGGLING AUDIO TO ALLOW USER INTERACTION');
      this.player.muted(this.props.mute);
    }
  }

  componentDidMount() {
    const videoJsOptions = {
      autoplay: "any",
      controls: true,
      controlBar: {
        pictureInPictureToggle: false,
        subsCapsButton: false,
        progressControl: false,
        remainingTimeDisplay: false,
      },
      sources: [
        {
          src: this.props.src,
          type: "application/x-mpegURL",
        },
      ],
    };

    this.player = videojs(
      this.videoNode,
      videoJsOptions,
      function onPlayerReady(player) {
        // console.log("onPlayerReady", this);
        this.play();
      }
    );
    this.lsSubscription = getSubscription(
      Constants.QUESTION_TOPIC_NAME,
      (uuid) => {
        if (uuid != null && uuid.length > 4) {
          this.openModal(uuid);
        } else {
          this.closeModal();
        }
      }
    );

    const ModalDialog = videojs.getComponent("ModalDialog");

    //Con questo ci assicuriamo che carichi il contenuto anche la prima volta che apre la modal
    var contentEl = document.createElement("div");

    //Lo aggiungiamo sotto in "content"
    var modal = new ModalDialog(this.player, {
      content: contentEl,
      temporary: false,
      pauseOnOpen: false,
      closeable: false,
      description: "Poll",
      label: "test",
      //fillAlways: true,
    });

    this.modal = this.player.addChild(modal);
    // setTimeout(()=> {
    //   this.openModal('4dd1c761-f474-4839-a87f-559ed302ab8c');
    // }, 5000)
  }

  // destroy player on unmount
  componentWillUnmount() {
    if (this.player) {
      this.player.dispose();
    }
    if (this.lsSubscription) {
      removeSubscription(this.lsSubscription);
    }
  }

  openModal(uuid) {
    console.log("Q " + uuid);
    if (this.modal) {
      this.modal.open();
    } else {
      console.log("");
    }
    if (isIOS && this.player.isFullscreen()) {
      this.player.on("pause", () => {
        this.play();
      });
      this.player.exitFullscreen();
    }

    var propstosend = {
      questionId: uuid,
      modal: this.modal,
    };
    // console.log(propstosend);
    ReactDom.render(<PollOverlay {...propstosend} />, this.modal.contentEl());
  }
  closeModal() {
    if (this.modal) {
      this.modal.close();
    } else {
      console.log("nx");
    }
  }
  play() {
    if (this.player) {
      var promise = this.player.play();

      if (promise !== undefined) {
        promise
          .then(function () {
            // Autoplay started!
            console.log("AUTOPLAY STARTED");
          })
          .catch(function (error) {
            // Autoplay was prevented.
            console.log("AUTOPLAY PREVENTED");
            console.log(error);
          });
      } else {
        console.log("PROMISE FAILED");
      }
    } else {
      console.log("tpnx");
    }
  }

  // wrap the player in a div with a `data-vjs-player` attribute
  // so videojs won't create additional wrapper in the DOM
  // see https://github.com/videojs/video.js/pull/3856

  render() {
    return (
      <div className="nopadding">
        <div className="video-container data-vjs-player">
          <video
            playsinline="playsinline"
            ref={(node) => (this.videoNode = node)}
            className="video-js vjs-16-9"
            poster={"/poster.jpg"}
          ></video>
        </div>
      </div>
    );
  }
}

export default VideoPlayer;
