import React, { FC, useState, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import Constants from "../../helpers/constants";
import { localizedDateFromString } from "../../helpers/utils";
import VideoPlayer from "../../components/VideoPlayer";
import PlenaryChat from "../../components/plenary/PlenaryChat";
import PlenaryTutorial from "../../components/plenary/PlenaryTutorial";
import PlenaryQuiz from "../../components/plenary/PlenaryQuiz";
import PlenaryCountdown from "../../components/plenary/PlenaryCountdown";
import PlenaryLeaderboard from "../../components/plenary/PlenaryLeaderboard";
import PlenaryThankYou from "../../components/plenary/PlenaryThankYou";
import PlenaryChatCountdown from '../../components/plenary/PlenaryChatCountdown';
import axiosHelper from "../../helpers/axiosHelper";
import {
    getSubscription,
    sendMessage,
    removeSubscription,
} from "../../helpers/lsHelper";

import bgImage from "../../assets/tutorial/shutterstock_1117734563 (2)/shutterstock_1117734563@2x.png";

import {
    chakra,
    Container,
    Text,
    useDisclosure,
    Flex,
    useBreakpointValue,
} from "@chakra-ui/react";
import { stat } from "fs";

export type LayoutHeaderProps = {
    usertype: string;
    status: EventStatus;
};

const PlenaryRedirect: FC<LayoutHeaderProps> = (
    { usertype, status }: LayoutHeaderProps,

    ...props
) => {
    const [userProfile, setUserProfile] = useState<UserProfile>(axiosHelper.getUserProfile()!);
    const [showThankYou, setShowThankYou] = useState(false);

    const { t } = useTranslation();
    const isMobile = useBreakpointValue({ base: true, lg: false });



    const isCustomer = () => {
        return "RSI".includes(userProfile.userType);
    }
    const doRedirect = () => {
        setTimeout(() => {
            window.location.replace(t('thankyou.redirecturl'));
        }, 10000);

    }
    useEffect(() => {
        console.log("USE EFFECT PlenaryRedirect");
        axiosHelper.logWebAppEvent("PlenaryRedirect", null, null);
        status.extra2 = '5';
        doRedirect();
    }, []);


    const calculatedBG = () => {
        if (showThankYou) {
            return bgImage;
        }
        if (isCustomer()) {
            return bgImage;
        }
        return Constants.PLENARY_BG_PATH + userProfile.userType + ".jpg"
    }



    return (
        <>
            <chakra.div
                maxW="container.xl"
                w="100%"
                m="0 auto"
                d="flex"
                flexDir="column"
                alignItems="stretch"
                alignContent="stretch"
                flexGrow={1}
                pb={isMobile ? "inherit" : "350px"}

                bgImage={isMobile ? bgImage : calculatedBG()}
                style={{
                    overflow: "hidden",
                    backgroundPosition: "bottom",
                    backgroundSize: "cover",
                    backgroundRepeat: "no-repeat",
                }}
            >
                <PlenaryThankYou status={status} continueChatting={doRedirect} okCallback={doRedirect} platformCallback={doRedirect} />

            </chakra.div>
        </>
    );
};

export default PlenaryRedirect;
