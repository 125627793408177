import {
  Button,
  chakra,
  Checkbox,
  Flex,
  HStack,
  Input,
  InputGroup,
  InputRightElement,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import Clock from "../home/counter/Clock";
import React, { FC, useState, useRef, useEffect } from "react";
import { useTranslation } from "react-i18next";
import axiosHelper from '../../helpers/axiosHelper';
import greenMark from '../../assets/question/greenmark.svg';
import redCross from '../../assets/question/redcross.svg';

type Props = {
  clickOpen?: boolean;
  clickClose?: () => void;
  qq: QuizQuestion;
};

const QuestionModal: FC<Props> = ({
  clickOpen = false,
  clickClose = () => { },
  qq
}) => {
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();
  const [countdown, setCountdown] = useState(0);
  const [maxNum, setMaxNum] = useState(20);
  const [activeIndex, setActiveIndex] = React.useState(-1);
  const [winningIndex, setWinningIndex] = useState(-1);
  const [losingIndex, setLosingIndex] = useState(-1);
  const [canSkip, setCanSkip] = useState(true);
  const [showTimeOutLabel, setShowTimeOutLabel] = useState(false);
  const [showRightLabel, setShowRightLabel] = useState(false);
  const [showWrongLabel, setShowWrongLabel] = useState(false);
  const interval = useRef<any>(null);
  const question = useRef<Question>();
  const timerHalted = useRef(false);
  const counter = useRef(0);
  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      setShowRightLabel(false);
      setShowWrongLabel(false);
      setShowTimeOutLabel(false);
      const q = await axiosHelper.getQuestion(qq.questionUuid, t('LNG'));
      question.current = q.data;
      setCountdown(q.data.d);
      setMaxNum(q.data.d);
      counter.current = q.data.d * 10;
      timerHalted.current = false;
      setLoading(false);
      startTimer();
    };
    if (qq != null) {
      fetchData();
    }

  }, [qq]);


  const startTimer = () => {
    if (interval.current != null) {
      clearInterval(interval.current);
    }
    interval.current = setInterval(() => {
      if (!timerHalted.current) {
        const oldCount = counter.current;
        counter.current = oldCount - 1;
        if (counter.current === 0) {
          if (activeIndex < 0) {
            verifyAnswer(-1);
          }
          setShowTimeOutLabel(true);
          stopTimer();
        }
        // console.log(counter.current);
        setCountdown(counter.current / 10);
      }
    }, 100);
  }

  const stopTimer = () => {
    // console.log('stopping counter');
    setCanSkip(false);
    if (interval.current != null) {
      clearInterval(interval.current);
    } else {
      // console.log('was already stopped');
    }
    setTimeout(clickClose, 3000);
  }

  const sendData = async (quid: string, answId: number, time: number, index: number) => {
    if (question.current != null) {
      const reply = await axiosHelper.sendAnswer(quid, answId, time);
      if (reply != null) {
        // console.log(reply);
        if (reply.data.wasCorrect) {
          setWinningIndex(index);
          setShowRightLabel(true);
        } else {
          setShowWrongLabel(true);
          setLosingIndex(index);
          let winidx = -1;
          for (let ans of question.current.o) {
            winidx++;
            // console.log(ans.r, reply.data.correctAnswer, winidx);
            if (ans.r === reply.data.correctAnswer) {
              setTimeout(() => {
                setWinningIndex(winidx);
              }, 1000);
              break;
            }
          }
        }
      }
    } else {
      console.log('QUESTION WAS NULL');
    }
  };

  function verifyAnswer(index: number, answer?: Option) {
    const time = counter.current;
    timerHalted.current = true;
    if (interval.current != null) {
      clearInterval(interval.current);
    } else {
      console.log('interval was null');
    }
    stopTimer();
    if (answer != null) {
      sendData(qq.id, answer.r, time, index);
    } else {
      sendData(qq.id, 0, time, index);
    }
  };

  const skipPressed = () => {
    if (interval.current != null) {
      clearInterval(interval.current);
    } else {
      console.log('interval was null');
    }
    verifyAnswer(-1);
  }

  return (
    <Modal closeOnOverlayClick={false} isOpen={clickOpen} onClose={clickClose} size="5xl">
      <ModalOverlay backgroundColor="rgba(0, 0, 0, 0.8)" />
      {loading ? (<></>) :
        (<ModalContent className="qst-modal-content">
          <ModalHeader className="qst-modal-header">
            <Flex flexDirection="row" justifyContent="space-around" paddingRight="14px" >
              <div className="question-text">
                <div className="question-title">{t('question.name')} {qq.pos}</div>
                {question.current?.t}
              </div>
              <HStack spacing={{ base: "auto", md: "10px" }}>
                <Clock
                  digit={countdown}
                  label={t("countdown.seconds")}
                  maxNum={maxNum}
                  id={'cds'}
                  width="150px"
                />
              </HStack>
            </Flex>
          </ModalHeader>
          {/* <ModalCloseButton /> */}
          <ModalBody className="cont-body" bgColor="black" height="500px">
            <Flex flexDirection="row" justifyContent=" space-around" flexWrap="wrap">
              {question.current?.o.map((answ, index) => (
                <Flex
                  key={index}
                  className={index === winningIndex ? "container-txt-icon-correct" : (index === losingIndex ? "container-txt-icon-wrong" : "container-txt-icon-unknown")}
                  onClick={() => verifyAnswer(index, answ)}
                >
                  <Text className="index-answer" mt="5px" > {index === winningIndex ? <img src={greenMark} /> : (index === losingIndex ? <img src={redCross} /> : <>{index + 1}</>)} </Text>
                  <Text className="txt-answer" mt="5px"> {answ.t} </Text>
                </Flex>
              ))}
            </Flex>
          </ModalBody>
          <ModalFooter className="footer-container">
            {canSkip &&
              <Button className="btn-skip" colorScheme="red" onClick={skipPressed}>{t('question.skip')}</Button>
            }
            {showTimeOutLabel ?
              <Text color="white" fontSize="30" fontWeight="bold">TIME OUT</Text>
              : (<>
                {showWrongLabel &&
                  <Text color="#c41230" fontSize="30" fontWeight="bold">{t('question.wronganswer')}</Text>
                }
                {showRightLabel &&
                  <Text color="#10954a" fontSize="30" fontWeight="bold">{t('question.correctanswer')}</Text>
                }</>
              )}
          </ModalFooter>
        </ModalContent>
        )}
    </Modal>
  );
};

export default QuestionModal;
