import React, { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";

import axiosHelper from "../helpers/axiosHelper";

import MainLayoutPlenary from "../layouts/MainLayoutPlenary";
import PlenaryContent from "../components/plenary/PlenaryContent";
import PlenaryContentLiveQA from "../components/plenary/PlenaryContentLiveQA";
import WaitingRoom from "./WaitingRoom";
const Plenary = () => {
  const [eventStatus, setEventStatus] = useState<EventStatus>();
  const [showCountdownInWR, setShowCountdownInWR] = useState(true);
  const [showWaitingRoom, setShowWaitingRoom] = useState(false);
  const [showPlenary, setShowPlenary] = useState(false);
  const [showPlenaryQA, setShowPlenaryQA] = useState(false);
  const [userProfile, setUserProfile] = useState<UserProfile>(
    axiosHelper.getUserProfile()!
  );
  const history = useHistory();

  const isCustomer = () => {
    return "RSI".includes(userProfile.userType);
  }

  const reloadUserProfile = () => {
    setUserProfile(axiosHelper.getUserProfile()!);
  };

  const handleEventStatus = () => {
    const now = new Date();
    const startDate = new Date(Date.parse(eventStatus!.startTime));
    const endDate = new Date(Date.parse(eventStatus!.endTime));
    // console.log(now.getTime() - startDate.getTime());
    if (now.getTime() < startDate.getTime()) {
      // console.log('###PLENARY### EVENT NOT YET ACTIVE MOSTRO WR');
      setShowPlenaryQA(false);
      setShowPlenary(false);
      setShowCountdownInWR(true);
      setShowWaitingRoom(true);
      // console.log('###PLENARY### schedulo cambio in plenary quando evento inizia');
      setTimeout(() => {
        setShowWaitingRoom(false);
        setShowPlenaryQA(false);
        setShowPlenary(true);
      }, startDate.getTime() - now.getTime());
    } else if (now.getTime() < endDate.getTime()) {
      if (eventStatus!.vKey.length > 2) {
        // console.log("###PLENARY### EVENT ACTIVE MOSTRO PLENARY QA");
        setShowPlenary(false);
        setShowWaitingRoom(false);
        setShowPlenaryQA(true);
      } else {
        // console.log("###PLENARY### EVENT ACTIVE MOSTRO PLENARY");
        setShowPlenaryQA(false);
        setShowWaitingRoom(false);
        setShowPlenary(true);
      }
      // console.log('###PLENARY### schedulo refetch event status quando finisce evento');
      setTimeout(() => {
        fetchEventStatus();
      }, startDate.getTime() - now.getTime() + 1000);
    } else {
      // console.log('###PLENARY### EVENT FINISHED MOSTRO WR');

      // setShowPlenaryQA(false);
      // setShowPlenary(false);
      // setShowCountdownInWR(false);
      // setShowWaitingRoom(false);
      history.push("/redirectThanks");

    }
  }

  useEffect(() => {
    if (eventStatus) {
      handleEventStatus();
    } else {
      fetchEventStatus();
    }
  }, [eventStatus])


  const fetchEventStatus = async () => {
    try {
      const result = await axiosHelper.getEventStatus();
      if (result != null) {
        // console.log("###PLENARY### GOT THE EVENT STATUS");
        // console.log(result);
        setEventStatus(result)
      }
    } catch {
      // console.log("###PLENARY### COULD NOT FETCH EVENT STATUS");
    }
  };


  return (<>
    {showWaitingRoom ? <WaitingRoom showCountdownWR={showCountdownInWR} /> : null}
    {showPlenary ?
      <MainLayoutPlenary reloadUserProfile={reloadUserProfile}>
        <PlenaryContent usertype={userProfile.userType} status={eventStatus!} />
      </MainLayoutPlenary> : null
    }
    {showPlenaryQA ?
      <MainLayoutPlenary reloadUserProfile={reloadUserProfile}>
        <PlenaryContentLiveQA usertype={userProfile.userType} eventStatus={eventStatus!} />
      </MainLayoutPlenary> : null
    }
  </>);
};

export default Plenary;
