import React, { FC, useRef, useState } from "react";
import { Link } from "react-router-dom";

import {
  chakra,
  useBreakpointValue,
  useMultiStyleConfig,
  Container,
  Text,
  Flex,
  Box,
} from "@chakra-ui/react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { useTranslation } from "react-i18next";

import background from "./../../../assets/home/prizesSection/shutterstock_1386762362/shutterstock_1386762362@2x.png";

import prize1Image from "../../../assets/home/prizesSection/Level-1-Gift-1_Discount.png";
import prize2Image from "../../../assets/home/prizesSection/Level-1-Gift-2_MFGuidewithRTKcorrectionforfree.png";
import prize3Image from "../../../assets/home/prizesSection/Level-1-Gift-3_WinAnMF1520.png";
import prize4Image from "../../../assets/home/prizesSection/Level-2-Gift-1_MFSummerPack.png";
import prize5Image from "../../../assets/home/prizesSection/Level-2-Gift-2_MF WithFarmersEveryday.png";
import prize6Image from "../../../assets/home/prizesSection/Level-2-Gift-3_MFSuperHeroServices.png";
import prize7Image from "../../../assets/home/prizesSection/Level-3-Gift-1_MFConnectivityPack.png";
import prize8Image from "../../../assets/home/prizesSection/Level-3-Gift-2_MFMerchandisingE-Voucher.png";
import prize9Image from "../../../assets/home/prizesSection/Level-3-Gift-3_MFGameAddictPack.png";

import { ChevronLeftIcon, ChevronRightIcon } from "@chakra-ui/icons";
import PrizesCard from "./PrizesCard";

export type Props = {};

const PrizesSection: FC<Props> = ({}: Props, ...props) => {
  const styles = useMultiStyleConfig("Hall", props);
  const customSlider = useRef<any>();
  const { t } = useTranslation();
  const isMobile = useBreakpointValue({ base: true, lg: false });
  const [rankArea, setRankArea] = useState("1 - 1000");

  const sliderBeforeChange = (previous: number, newIndex: number) => {
    // console.log(newIndex);
    if (newIndex >= 0 && newIndex < 3) {
      setRankArea("1 - 1000");
    } else if (newIndex >= 3 && newIndex < 6) {
      setRankArea("1001 - 2000");
    } else if (newIndex >= 6 && newIndex < 9) {
      setRankArea("2001 +");
    }
  };

  const renderArrows = () => {
    return (
      <Flex justifyContent="space-between" my={{ base: "20px", lg: "5px" }}>
        <Flex
          onClick={() => customSlider.current.slickPrev()}
          cursor="pointer"
          zIndex="10"
        >
          <ChevronLeftIcon color="white" w="40px" h="40px" />
        </Flex>

        <Flex
          onClick={() => customSlider.current.slickNext()}
          cursor="pointer"
          zIndex="10"
        >
          <ChevronRightIcon color="white" w="40px" h="40px" />
        </Flex>
      </Flex>
    );
  };

  return (
    <chakra.div w="100%" bgColor="#000000">
      <Container
        maxW="container.xl"
        bgImage={background}
        sx={styles.prizesSectionContainer}
      >
        <Text marginBottom="30px" as="h2" textStyle="h2">
          {t("prizes.title")}
        </Text>
        <Text text-transform="uppercase" as="h5" textStyle="h5">
          {t("prizes.subtitle")}
        </Text>
        <Text sx={styles.prizesSectionLabel}>{rankArea}</Text>
        <Flex sx={styles.prizesSectionWrapper}>
          <Slider
            ref={(slider: any) => (customSlider.current = slider)}
            dots={true}
            infinite={true}
            speed={500}
            beforeChange={sliderBeforeChange}
            slidesToShow={isMobile ? 1 : 3}
            slidesToScroll={isMobile ? 1 : 3}
            accessibility={true}
            autoplay
            autoplaySpeed={5000}
            arrows={false}
            centerPadding={isMobile ? "60px" : "0px"}
          >
            <PrizesCard
              title={t("prizescard_1.title")}
              subtitle={t("prizescard_1.subtitle")}
              label={t("prizescard_1.description")}
              image={prize1Image}
            />
            <PrizesCard
              title={t("prizescard_2.title")}
              subtitle={t("prizescard_2.subtitle")}
              label={t("prizescard_2.description")}
              image={prize2Image}
            />
            <PrizesCard
              title={t("prizescard_3.title")}
              subtitle={t("prizescard_3.subtitle")}
              label={t("prizescard_3.description")}
              image={prize3Image}
            />
            <PrizesCard
              title={t("prizescard_4.title")}
              subtitle={t("prizescard_4.subtitle")}
              label={t("prizescard_4.description")}
              image={prize4Image}
            />
            <PrizesCard
              title={t("prizescard_5.title")}
              subtitle={t("prizescard_5.subtitle")}
              label={t("prizescard_5.description")}
              image={prize5Image}
            />
            <PrizesCard
              title={t("prizescard_6.title")}
              subtitle={t("prizescard_6.subtitle")}
              label={t("prizescard_6.description")}
              image={prize6Image}
            />
            <PrizesCard
              title={t("prizescard_7.title")}
              subtitle={t("prizescard_7.subtitle")}
              label={t("prizescard_7.description")}
              image={prize7Image}
            />
            <PrizesCard
              title={t("prizescard_8.title")}
              subtitle={t("prizescard_8.subtitle")}
              label={t("prizescard_8.description")}
              image={prize8Image}
            />
            <PrizesCard
              title={t("prizescard_9.title")}
              subtitle={t("prizescard_9.subtitle")}
              label={t("prizescard_9.description")}
              image={prize9Image}
            />
          </Slider>
          <Box display="block" mt="1rem">
            {renderArrows()}
          </Box>
        </Flex>
        <Flex
          flexDir={{ base: "column", lg: "row" }}
          justifyContent="space-between"
          w={isMobile ? "100%" : "75%"}
          mt={isMobile ? "100px" : "30px"}
        >
          <Text
            color="white"
            mb={isMobile ? "20px" : "50px"}
            fontStyle="italic"
            fontSize="sm"
          >
            {" "}
            {t(`tutorial.rap.titledetail`)}
          </Text>
          <Link to="/termsandconditions">
            <Text color="white" fontSize="sm" mb="50px">
              {" "}
              {t(`termsandconditions`)}
            </Text>
          </Link>
        </Flex>
      </Container>
    </chakra.div>
  );
};

export default PrizesSection;
